import { createStore } from 'redux'
import { getProfile } from '../api/login'
import { isAddress } from '../utils/tools'
const TOKEN_KEY = 'REQUEST_TOKEN'

export const getToken = () => {
    return localStorage.getItem(TOKEN_KEY) || ''
}

export const clearToken = () => {
    localStorage.removeItem(TOKEN_KEY)
}

const initState = {
    account: '',
    chainId: 0,
    token: getToken(),
    currentUser: {},// 当前登录用户
    tokenPriceUpdate: {}, // 记录更新的价格信息
    pairUpdate: {},// 流动性更新
    getMarksRecords: {}, // 代币id->是否执行成功getMarks
    history: []
}



const addReducer = (state = initState, action) => {
    switch (action.type) {
        case 'pushHistory':
            return {
                ...state,
                history: [...state.history, action.value]
            }
        case 'setAccount':
            return {
                ...state,
                account: action.value
            }
        case 'setChainId':
            return {
                ...state,
                chainId: action.value
            }
        case 'setTokenPriceUpdate':
            return {
                ...state,
                tokenPriceUpdate: {
                    ...state.tokenPriceUpdate,
                    ...action.value
                }
            }
        case 'setPairUpdate':
            return {
                ...state,
                pairUpdate: {
                    ...state.pairUpdate,
                    ...action.value
                }
            }
        case 'setGetMarksRecords':
            return {
                ...state,
                getMarksRecords: {
                    ...state.getMarksRecords,
                    ...action.value
                }
            }
        case 'setToken':
            // 设置token
            const token = action.value
            localStorage.setItem('REQUEST_TOKEN', token)
            return {
                ...state,
                token
            }
        case 'setCurrentUser':
            return {
                ...state,
                currentUser: action.value
            }
        default:
            return {
                ...state
            }
    }
}
const store = createStore(addReducer)
export default store
