import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
export default function SetTopAddressCount(props) {

    const [topAddressEnable, setTopAddressEnable] = useState(true) // 是否开启置顶地址
    const [customAddressEnable, setCustomAddressEnable] = useState(false) // 是否开启自定义地址
    const [topAddressCount, setTopAddressCount] = useState(100) // 置顶地址数量
    const [customAddressList, setCustomAddressList] = useState([]) // 自定义地址列表

    const [amountEnable, setAmountEnable] = useState(true) // 是否允许按照金额筛选
    const [amountStart, setAmountStart] = useState(0) // 金额筛选开始值
    const [amountEnd, setAmountEnd] = useState(0) // 金额筛选结束值

    const [tmpInput, setTmpInput] = useState('') // 临时输入的分组名称
    const { t, i18n } = useTranslation()
    const currnetLanguage = i18n.language
    useEffect(() => {

        if (props.config) {
            setTopAddressEnable(props.config.topEnable)
            setCustomAddressEnable(props.config.customAddressEnable)
            setTopAddressCount(props.config.topAddress)
            setCustomAddressList(props.config.customAddressList)
            setAmountEnable(props.config.amountEnable)
            setAmountStart(props.config.amountStart)
            setAmountEnd(props.config.amountEnd)
        }

    }, [props.config])

    return <Modal centered contentClassName="search-modal" show={props.show} onHide={() => {
        props.handleClose && props.handleClose()
    }} >
        <Modal.Body>
            <div>
                <div className="login-header">{t('筛选交易地址')}</div>
            </div>
            <div>
                <div className="d-flex flex-row align-items-center mt-2 tx-table-filters-item">
                    <input
                        onChange={e => {
                            setTopAddressEnable(e.target.checked)
                        }}
                        checked={topAddressEnable}
                        type="checkbox" />

                    {currnetLanguage === 'zh' ? <>
                        查看持币前 <input onChange={e => {
                            setTopAddressCount(e.target.value)
                        }} style={{ width: '100px', marginLeft: '5px', marginRight: '5px' }}
                            value={topAddressCount}
                            className="form-control" /> 名
                    </> : <>
                        View the top <input onChange={e => {
                            setTopAddressCount(e.target.value)
                        }} style={{ width: '100px', marginLeft: '5px', marginRight: '5px' }}
                            value={topAddressCount}
                            className="form-control" /> holders
                    </>}
                </div>
                <div className="d-flex flex-row align-items-center mt-2 tx-table-filters-item">
                    <input onChange={e => {
                        setCustomAddressEnable(e.target.checked)
                    }} checked={customAddressEnable} type="checkbox" /> {t('自定义筛选地址列表')}
                </div>
                {customAddressEnable && <div className="mt-2">
                    <Form.Control onChange={e => {
                        setTmpInput(e.target.value)
                        setCustomAddressList(e.target.value.toLowerCase().split('\n'))
                    }} value={tmpInput} size="sm" as={'textarea'} rows={3} type="text" placeholder={t('输入要筛选的地址, 每行一个')} />
                </div>}
                {!props.hideAmountRange && <div className="d-flex flex-row align-items-center mt-2 tx-table-filters-item">
                    <input onChange={e => {
                        setAmountEnable(e.target.checked)
                    }} checked={amountEnable} type="checkbox" /> {t('按交易额筛选')}:
                    <input onChange={e => {
                        setAmountStart(e.target.value)
                    }} style={{ width: '100px', marginLeft: '5px', marginRight: '5px' }}
                        value={amountStart}
                        className="form-control" placeholder="$" />
                    ~
                    <input onChange={e => {
                        setAmountEnd(e.target.value)
                    }} style={{ width: '100px', marginLeft: '5px', marginRight: '5px' }}
                        value={amountEnd}
                        placeholder="$"
                        className="form-control" />
                </div>}
            </div>
            <div className="mt-2">
                <div onClick={() => {
                    props.ok && props.ok({
                        topAddressEnable,
                        topAddressCount,
                        customAddressEnable,
                        customAddressList,
                        amountEnable,
                        amountStart,
                        amountEnd
                    })
                }} className="btn btn-primary btn-md text-light">{t('确定')}</div>
                <div onClick={() => {
                    props.handleClose && props.handleClose()
                }} style={{ marginLeft: '5px' }} className="btn btn-danger btn-md text-light">{t('取消')}</div>
            </div>
        </Modal.Body>
    </Modal>
}