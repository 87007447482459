import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";


export default function TokenHoldersChart(props) {

    const [series, setSeries] = useState([])
    const groups = [
        {
            name: 'Top1~10',
            start: 1,
            end: 10
        },
        {
            name: 'Top10~30',
            start: 10,
            end: 30
        },
        {
            name: 'Top30~60',
            start: 30,
            end: 60
        },
        {
            name: 'Top60~100',
            start: 60,
            end: 100
        },
    ]
    useEffect(() => {
        // 计算每一个持有者的比例
        // 获取总数量
        let total = 0
        let m = {}
        props.holders.forEach((item, ind) => {
            total += parseFloat(item.balance)
            groups.forEach((iItem, iInd) => {
                if (ind + 1 >= iItem.start && ind + 1 < iItem.end) {
                    if (!m[iInd]) m[iInd] = 0
                    m[iInd] += parseFloat(item.balance)
                }
            })
        })
        // const total = props.holders.reduce((prev, current, index, arr) => {
        //     return parseFloat(prev.balance) + parseFloat(current.balance)
        // })
        // 其他数量为 
        const others = parseFloat(props.total) - total
        let series = []
        groups.forEach((item, ind) => {
            series.push(m[ind] || 0)
        })

        series.push(others)
        setSeries(series)
    }, [props.holders, props.total])


    const options = {
        chart: {
            height: '100%', // 将图表的高度设置为父元素的高度
            width: '100%', // 将图表的宽度设置为父元素的宽度
            type: 'pie',
        },
        labels: [...groups.map(item => item.name), 'Others'],

        legend: {
            position: 'bottom'
        }
    }


    return <div className="apex-pie-chart-holders">
        <ReactApexChart options={options} series={series} width={'100%'} type="pie" />
    </div>
}