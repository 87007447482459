import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap'

import { useWeb3React } from "@web3-react/core"
import store from "../../../store";
import { eventBus, getShortAddress, isAddress, isMobile } from "../../../utils/tools";
import { AuthSignature, getProfile, LoginGetNonce } from "../../../api/login";
import { toast } from 'react-toastify'
import { injected } from '../../../utils/connector'
import { useTranslation } from "react-i18next";





export default function LoginModal(props) {

    const [currentSetp, setCurrentSetp] = useState(1) // 第一步连接钱包, 第二步登录
    const [isConnectedWallet, setIsConnectedWallet] = useState(false) // 是否已经连接了钱包
    const [loading, setLoading] = useState(false) // 是否正在登录中
    const [ingText, setIngText] = useState('') // 正在登录中的提示文字
    // const [isLogin, setIsLogin] = useState(false) // 是否已经登录

    const { t } = useTranslation()
    // 判断是否连接钱包, 如果没有连接钱包, 则先连接
    // 如果已连接, 就提示签名登录
    const { active, chainId, account, library, connector, activate, deactivate } = useWeb3React()

    const timerLoading = useRef(null)
    async function connect() {
        if (!window.ethereum) {
            if (isMobile()) {
                toast.error(t('请使用钱包的Dapp浏览器打开'));
            } else {
                toast.error(t('请安装Metamask钱包插件'));
            }
        }

        setLoading(true)
        try {
            await activate(injected)
        } catch (ex) {
            console.log(ex)
        }
        setLoading(false)
    }

    const clearTimer = () => {
        if (timerLoading.current) {
            clearInterval(timerLoading.current)
        }
    }
    useEffect(() => {
        let count = 0
        timerLoading.current = setInterval(() => {
            // 每隔一秒增加一个小数点
            setIngText(new Array(count).fill('.').join(''))
            // 如果小数点超过3个, 则清空
            count += 1
            if (count > 4) {
                setIngText('')
                count = 1
            }
        }, 300);
        return () => {
            clearTimer()
        }
    }, [])

    useEffect(() => {
        if (isAddress(account)) {
            store.dispatch({ type: "setAccount", value: account })
            setIsConnectedWallet(true)
            setCurrentSetp(2)
        } else {
            store.dispatch({ type: "setAccount", value: '' })
            setIsConnectedWallet(false)
            setCurrentSetp(1)
        }
    }, [account])

    async function disconnect() {
        try {
            deactivate()
        } catch (ex) {
            console.log(ex)
        }
    }

    const updateProfile = async () => {
        // 更新用户信息
        const { data } = await getProfile()
        // 将用户信息储存在state中
        if (data.code === 200) {
            store.dispatch({ type: "setCurrentUser", value: data.data })
            setTimeout(() => {
                eventBus.emit('login_success')
            }, 500);
        }
        // 登录失败, 提示用户

    }

    const handleLoginLogic = async () => {
        // 对数据进行签名
        if (!active) {
            // 提示先连接钱包
            toast.error(t('请先连接钱包'));
            return
        }
        // 从后端获取nonce、创建时间、过期时间, 2分钟内不登录就失效了
        setLoading(true)
        try {
            const { data } = await LoginGetNonce({
                wallet: account
            })
            if (data.code === 200) {
                const nonce = data.data

                const d = t('TMDex 想要使用您的账户登录').replace('{account}', account).replace('{href}', window.location.href).replace('{version}', 1).replace('{chainId}', chainId).replace('{nonce}', nonce)
                const signature = await library.getSigner().signMessage(d);

                console.log(active, signature)
                // 将签名、nonce、钱包和加密信息发给后端
                // 后端验证成功, 会返回token, 设置到缓存中
                // 验证失败, 返回失败
                const auth = await AuthSignature({
                    wallet: account,
                    sign: signature,
                    message: d
                })
                console.log(auth.data, '签名验证信息')
                if (auth.data.code === 200) {
                    // 登录成功, 这个接口还要把用户信息带回来
                    const token = auth.data.data

                    // 全局配置
                    store.dispatch({ type: "setToken", value: token })
                    store.dispatch({ type: "setAccount", value: account })
                    store.dispatch({ type: "setChainId", value: chainId })
                    // setIsLogin(true)
                    // 更新profile
                    updateProfile()
                    // 可以关闭登录框了
                    toast.success(t('登录成功!'));
                    props.handleClose && props.handleClose()
                }
            } else {
                // 提示错误
            }
        }
        catch (e) {
            // 提示错误
            console.log(e)
        }

        setLoading(false)

    }
    return <>
        <Modal centered contentClassName="search-modal" show={props.show} onHide={() => {
            props.handleClose && props.handleClose()
        }} >
            <Modal.Body>
                <div className="login-header">
                    {t('登录')}
                </div>
                <div>
                    <div>
                        {t('欢迎使用 TMDex, 请使用专属于Web3的方式进行登录吧~')}
                    </div>
                    <div className={currentSetp === 1 ? 'login-active' : ''}>
                        1. {currentSetp === 1 ? `[${t('正在进行')}${ingText}]` : ''} {t('连接钱包')}
                    </div>
                    <div className={currentSetp === 2 ? 'login-active' : ''}>
                        2. {currentSetp === 2 ? `[${t('正在进行')}${ingText}]` : ''}{t('签名登录')}
                    </div>

                </div>
                {!isConnectedWallet && currentSetp === 1 && <div className="login-btn-box mt-4">
                    {!loading && <Button onClick={() => {
                        connect()
                    }} className="login-btn" variant="primary" >
                        <img className="login-btn-box-icon" src="https://pancakeswap.finance/images/wallets/metamask.png" alt="" />
                        {t('连接钱包')}</Button>}

                    {loading && <Button onClick={() => {
                        connect()
                    }} className="login-btn" variant="primary" >
                        <i style={{ fontSize: '15px', marginRight: '5px' }} className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                        {t('连接中')}{ingText}</Button>}
                </div>}
                {isConnectedWallet && currentSetp === 2 && account && <div className="login-wallet-connected mt-2">
                    <div>
                        {getShortAddress(account)}
                    </div>
                    <div>
                        <Button onClick={() => {
                            disconnect()
                        }} variant="danger">{t('断开连接')}</Button>
                    </div>
                </div>}
                {currentSetp === 2 && <div className="login-btn-box mt-4">
                    {!loading && <Button onClick={() => {
                        handleLoginLogic()
                    }} className="login-btn" variant="primary" >
                        <img className="login-btn-box-icon" src="https://pancakeswap.finance/images/wallets/metamask.png" alt="" />
                        {t('使用Metamask登录')}</Button>}
                    {loading && <Button className="login-btn" variant="primary" >
                        <i style={{ fontSize: '15px', marginRight: '5px' }} className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                        {t('登录中')}{ingText}</Button>}
                </div>}
            </Modal.Body>
        </Modal>

    </>
}