import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Header2 from "../../layout/header2";
import Sidebar from "../../layout/sidebar";

import { useTranslation } from "react-i18next";
import { SwapBox } from "./swap";

const SwapIndex = (props) => {
    return <div>
        <Header2 />
        <Sidebar />
        <div className="content-body" id="monitor-index" >
            <div className="container-fluid">
                <div className="row">
                    <SwapBox />
                </div>
            </div>
        </div>
    </div>
}

export default SwapIndex;