

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { I18nextProvider, initReactI18next } from 'react-i18next';


import enTranslation from '../languages/en.json';
import zhTranslation from '../languages/zh.json';

i18n.use(LanguageDetector).use(initReactI18next).init({
    resources: {
        en: {
            translation: enTranslation
        },
        zh: {
            translation: zhTranslation
        }
    },
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false
    }
})

export default i18n;


