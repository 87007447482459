import React, { useEffect, useState } from "react";
import { getWalletTokenTxStatistic } from "../../../api/token";
import moment from "moment-timezone";
import ReactApexChart from "react-apexcharts";
import { converToNumberString, getShortAddress, isMobile } from "../../../utils/tools";
import SetCustomFilterAmount from "./SetCustomFilterAmount";
import SetTopAddressCount from "../DashboardComponents/SetTopAddressCount";
import { useTranslation } from "react-i18next";



const defaultFilterAmountList = [
    {
        start_amount_range: 0,
        end_amount_range: 100,
        alias: '$0~100'
    },
    {
        start_amount_range: 100,
        end_amount_range: 500,
        alias: '$100~500'
    },
    {
        start_amount_range: 500,
        end_amount_range: 1000,
        alias: '$500~1000'
    },
    {
        start_amount_range: 1000,
        end_amount_range: 1000000,
        alias: '$1000+'
    },
]


function TopTable(props) {

    const { buyList, sellList, type } = props
    const { t } = useTranslation()
    return <>
        {type === 'buy' && <table className="table" style={{ textAlign: 'center' }}>
            <thead>
                <tr>
                    <th>{t('地址')}</th>
                    <th>{t('买入次数')}</th>
                    <th>{t('买入金额')}</th>
                </tr>
            </thead>

            <tbody>
                {buyList && buyList.map((item, ind) => {
                    return <tr onClick={() => {
                        props.onClickUser && props.onClickUser(item.wallet)
                    }} className="pointer-btn" key={ind + '-buy-list'}>
                        <td>{getShortAddress(item.wallet)}</td>
                        <td>{item.count}</td>
                        <td>${converToNumberString(item.volume)}</td>
                    </tr>
                })}
            </tbody>
        </table>}
        {type === 'sell' && <table className="table" style={{ textAlign: 'center' }}>
            <thead>
                <tr>
                    <th>{t('地址')}</th>
                    <th>{t('卖出次数')}</th>
                    <th>{t('卖出金额')}</th>
                </tr>
            </thead>

            <tbody>
                {sellList && sellList.map((item, ind) => {
                    return <tr onClick={() => {
                        props.onClickUser && props.onClickUser(item.wallet)
                    }} className="pointer-btn" key={ind + '-sell-list'}>
                        <td>{getShortAddress(item.wallet)}</td>
                        <td>{item.count}</td>
                        <td>${converToNumberString(item.volume)}</td>
                    </tr>
                })}
            </tbody>
        </table>}

    </>
}

export default function GlobalAnaylizePanel(props) {

    const [currentTimeTab, setCurrentTimeTab] = useState(5) // 当前时间tab
    const [filterAmountList, setFilterAmountList] = useState([]) // 筛选额度列表
    const [filterAddressList, setFilterAddressList] = useState([]) // 筛选地址列表
    const [realTxCountSeries, setRealTxCountSeries] = useState([]) // 交易数series
    const [realTxCountValues, setRealTxCountValues] = useState([]) // 交易数values
    const [realTxAddressCountSeries, setRealTxAddressCountSeries] = useState([]) // 交易地址数series
    const [realTxAddressCountValues, setRealTxAddressCountValues] = useState([]) // 交易地址数values
    const [realTxAmountSeries, setRealTxAmountSeries] = useState([]) // 交易量series
    const [realTxAmountValues, setRealTxAmountValues] = useState([]) // 交易量values
    const [buyList, setBuyList] = useState([]) // 买单列表
    const [sellList, setSellList] = useState([]) // 卖单列表
    const [showFilterAmount, setShowFilterAmount] = useState(false) // 是否显示筛选额度
    const [showFilterAddress, setShowFilterAddress] = useState(false) // 是否显示筛选地址
    const [filterParams, setFilterParams] = useState({
        enableAmount: true,
        enableAddress: false
    }) // 筛选参数 enableAmount enableAddress
    const [txRecordFilters, setTxRecordFilters] = useState({
        topAddress: 100,
        topEnable: false,
        customAddressEnable: false,
        customAddressList: []
    }) // 交易记录筛选条件
    const [defaultMobileTab, setDefaultMobileTab] = useState('buy') // 默认的移动端tab
    const { t } = useTranslation()

    useEffect(() => {
        if (!props.pair?.pair || !props.tokenInfo?.address) return

        let addressList = []
        if (filterParams.enableAddress) {
            addressList = txRecordFilters.customAddressList
            const { topEnable, topAddress, customAddressEnable, customAddressList } = txRecordFilters
            if (customAddressEnable) {
                addressList = [...addressList, ...customAddressList]
            }
            if (topEnable && topAddress) {
                addressList = [...addressList, ...props.topAddressList.slice(0, topAddress).map(item => item.address)]
            }
        }

        // 更新数据
        // 如果有筛选额度, 则调用带筛选额度的接口
        // 如果没有筛选额度, 调用另一个接口
        if (filterParams.enableAmount && filterAmountList && filterAmountList.length > 0) {
            getWalletTokenTxStatistic({
                start_time: moment().unix() - 60 * currentTimeTab,
                end_time: moment().unix(),
                pair_address: props.pair.pair,
                token_address: props.tokenInfo.address,
                chain_id: props.tokenInfo.chain_id,
                addresses: addressList,
                params: filterAmountList || []
            }).then(({ data }) => {
                // 3张饼图
                // 1. 交易数
                let txCountSeries = []
                let txCountValues = []
                // 2. 交易地址数
                let txAddressCountSeries = []
                let txAddressCountValues = []
                // 3. 交易量
                let txAmountSeries = []
                let txAmountValues = []
                // 4. top买卖单
                if (data.code === 200) {
                    if (data.data.statistic) {
                        // 获取series 和 对应的值
                        // 1. 交易数
                        data.data.statistic.forEach(item => {
                            // 卖单:$0~100
                            txCountSeries.push(`${t('买单')}:$${item.start_amount_range}~${item.end_amount_range}`)
                            txCountSeries.push(`${t('卖单')}:$${item.start_amount_range}~${item.end_amount_range}`)
                            txCountValues.push(item.buy_count)
                            txCountValues.push(item.sell_count)

                            // 2. 交易地址数
                            txAddressCountSeries.push(`${t('买单')}:$${item.start_amount_range}~${item.end_amount_range}`)
                            txAddressCountSeries.push(`${t('卖单')}:$${item.start_amount_range}~${item.end_amount_range}`)
                            txAddressCountValues.push(item.buy_address_count)
                            txAddressCountValues.push(item.sell_address_count)

                            // 3. 交易量
                            txAmountSeries.push(`${t('买单')}:$${item.start_amount_range}~${item.end_amount_range}`)
                            txAmountSeries.push(`${t('卖单')}:$${item.start_amount_range}~${item.end_amount_range}`)
                            txAmountValues.push(parseFloat(item.buy_amount))
                            txAmountValues.push(parseFloat(item.sell_amount))
                        })



                    }
                    // console.log(txCountSeries, txCountValues, txAddressCountSeries, txAddressCountValues, txAmountSeries, txAmountValues, 'txCountSeries')
                    setRealTxCountSeries(txCountSeries)
                    setRealTxCountValues(txCountValues)
                    setRealTxAddressCountSeries(txAddressCountSeries)
                    setRealTxAddressCountValues(txAddressCountValues)
                    setRealTxAmountSeries(txAmountSeries)
                    setRealTxAmountValues(txAmountValues)

                    if (data.data.buy_list) {
                        setBuyList(data.data.buy_list)
                    }
                    if (data.data.sell_list) {
                        setSellList(data.data.sell_list)
                    }

                }

            })
        } else {

        }


    }, [filterAmountList, currentTimeTab, filterParams, props.pair, props.tokenInfo, txRecordFilters, props.topAddressList])

    // 默认给一个筛选额度列表
    useEffect(() => {
        setFilterAmountList(defaultFilterAmountList)
    }, [])

    const sum = (values) => {
        let sum = 0
        values.forEach(item => {
            sum += item
        })
        return sum
    }

    const options = {
        chart: {
            height: '100%', // 将图表的高度设置为父元素的高度
            width: '100%', // 将图表的宽度设置为父元素的宽度
            type: 'pie',
        },
        labels: [...realTxCountSeries],

        legend: {
            position: 'bottom'
        }
    }

    return <div>
        <SetCustomFilterAmount
            show={showFilterAmount}
            list={filterAmountList}
            handleClose={() => {
                setShowFilterAmount(false)
            }}
            ok={(info) => {
                // 保存信息
                setFilterAmountList(info)
                setShowFilterAmount(false)
            }}
        />
        <SetTopAddressCount
            config={txRecordFilters}
            show={showFilterAddress}
            hideAmountRange={true}
            handleClose={() => {
                setShowFilterAddress(false)
            }}
            ok={(info) => {
                // 保存信息
                const { topAddressEnable, topAddressCount, customAddressEnable, customAddressList } = info
                setTxRecordFilters({
                    ...txRecordFilters,
                    topEnable: topAddressEnable,
                    topAddress: topAddressCount,
                    customAddressEnable,
                    customAddressList
                })
                setShowFilterAddress(false)
            }}
        />
        <div className="d-flex flex-row align-items-center tx-table-filters mt-2">
            <div style={{ width: '150px', marginLeft: '20px' }} >
                <div className="d-flex flex-row align-items-center tx-table-filters-item "> <input onChange={(e) => {
                    setFilterParams({
                        ...filterParams,
                        enableAmount: e.target.checked
                    })
                }}
                    checked={filterParams.enableAmount}
                    type="checkbox" />
                    <span onClick={() => {
                        setShowFilterAmount(true)
                    }} className="tx-record-filter-span">{t('自定义筛选额度')}</span>
                </div>
            </div>
            <div style={{ width: '150px' }} ><div className="d-flex flex-row align-items-center tx-table-filters-item"> <input onChange={(e) => {
                setFilterParams({
                    ...filterParams,
                    enableAddress: e.target.checked
                })

            }}
                checked={filterParams.enableAddress}
                type="checkbox" />
                <span onClick={() => {
                    setShowFilterAddress(true)
                }} className="tx-record-filter-span">{t('按自定义地址筛选')}</span>
            </div></div>
            <div style={{ width: '300px' }} className="mt-2">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="tx-time-item" ></div>
                    <div onClick={() => setCurrentTimeTab(5)} className={"tx-time-item " + (currentTimeTab === 5 ? 'tx-time-item-active' : '')} >5M</div>
                    <div onClick={() => setCurrentTimeTab(60)} className={"tx-time-item " + (currentTimeTab === 60 ? 'tx-time-item-active' : '')} >1H</div>
                    <div onClick={() => setCurrentTimeTab(360)} className={"tx-time-item " + (currentTimeTab === 360 ? 'tx-time-item-active' : '')}>6H</div>
                    <div onClick={() => setCurrentTimeTab(60 * 24)} className={"tx-time-item " + (currentTimeTab === (60 * 24) ? 'tx-time-item-active' : '')}>24H</div>
                </div>
            </div>
        </div>
        <div>
            <div style={{ flexWrap: isMobile() ? 'nowrap' : 'wrap' }} className="d-flex flex-row align-items-center justify-content-center mt-2 tx-table-filters-scroll scroll-hidden">
                {sum(realTxCountValues) > 0 && <div style={{ width: '33%', minWidth: '300px' }} className="apex-pie-chart-holders">
                    <ReactApexChart options={{
                        ...options,
                        title: {
                            text: t('交易次数'),
                            align: 'center'
                        },
                        labels: [...realTxCountSeries]
                    }} series={realTxCountValues} width={'100%'} type="pie" />
                </div>}

                {sum(realTxAddressCountValues) > 0 && <div style={{ width: '33%', minWidth: '300px' }} className="apex-pie-chart-holders">
                    <ReactApexChart options={{
                        ...options,
                        title: {
                            text: t('交易地址数'),
                            align: 'center'
                        },
                        labels: [...realTxAddressCountSeries]
                    }} series={realTxAddressCountValues} width={'100%'} type="pie" />
                </div>}

                {sum(realTxAmountValues) > 0 && <div style={{ width: '33%', minWidth: '300px' }} className="apex-pie-chart-holders">
                    <ReactApexChart options={{
                        ...options,
                        title: {
                            text: t('交易量'),
                            align: 'center'
                        },
                        labels: [...realTxAmountSeries]
                    }} series={realTxAmountValues} width={'100%'} type="pie" />
                </div>}
            </div>
            {isMobile() === false && <div className="top-tx-statistic-list">
                {buyList && buyList.length > 0 && <div className="top-tx-statistic-list-item">
                    <div style={{ fontWeight: 'bold' }} className="text-success">
                        {t('TOP买家')}
                    </div>
                    <div className="full-width">
                        <TopTable buyList={buyList} sellList={sellList} type="buy" onClickUser={(wallet) => {
                            props.onClickUser && props.onClickUser(wallet)
                        }} />
                    </div>

                </div>}
                {sellList && buyList && sellList.length > 0 && buyList.length > 0 && <div style={{ width: '2px', height: '100%', minHeight: '800px', backgroundColor: '#000000' }}></div>}
                {sellList && sellList.length > 0 && <div className="top-tx-statistic-list-item">
                    <div style={{ fontWeight: 'bold' }} className="text-danger">
                        {t('TOP卖家')}
                    </div>
                    <div className="full-width">
                        <TopTable buyList={buyList} sellList={sellList} type="sell" onClickUser={(wallet) => {
                            props.onClickUser && props.onClickUser(wallet)
                        }} />
                    </div>
                </div>}
            </div>}
            {isMobile() && <div className="mt-4">
                <div className="global-top-header">
                    <div onClick={() => {
                        setDefaultMobileTab('buy')
                    }} className={`global-top-header-item global-top-header-item-left ${defaultMobileTab === 'buy' ? "global-top-header-item-left-active" : ""}`}>
                        {t('TOP买家')}
                    </div>

                    <div onClick={() => {
                        setDefaultMobileTab('sell')
                    }} className={`global-top-header-item global-top-header-item-right ${defaultMobileTab === 'sell' ? 'global-top-header-item-right-active' : ''}`}>
                        {t('TOP卖家')}
                    </div>
                </div>
                <div className="full-width">
                    <TopTable buyList={buyList} sellList={sellList} type={defaultMobileTab} onClickUser={(wallet) => {
                        props.onClickUser && props.onClickUser(wallet)
                    }} />
                </div>
            </div>}
        </div>
    </div>
}