import React, { useEffect } from "react";
import { useState } from "react";
import { NETWORK_LIST } from "../../utils/networks";
import { Modal } from "react-bootstrap";

const LogoPreview = (props) => {
    return <Modal backdrop={true} centered contentClassName="search-modal" show={props.show} onHide={() => {

        props.handleClose && props.handleClose()
    }} >
        <Modal.Body>
            <div>
                <img style={{ width: '100%', height: '100%' }} src={props.logo} alt="" />
            </div>
        </Modal.Body>
    </Modal>
}

export default function Logo(props) {
    // 先判断传进来的img能否显示
    // 再判断ave的img能否显示
    // 搞一个遍历
    // 如果都不显示, 则展示模拟的logo
    const [logoList, setLogoList] = useState([])
    const [logoIndex, setLogoIndex] = useState(0)
    const [logo, setLogo] = useState('')
    const [chainThumb, setChainThumb] = useState('')
    const [showPreview, setShowPreview] = useState(false)
    useEffect(() => {
        let list = []
        if (props.logo) {
            list.push(props.logo)
        }
        if (props.contract && props.chain) {
            // list.push(`https://www.avestorage.cloud/token_icon/${props.chain}/${props.contract}.png`)
        }
        // list.push('https://token-monitor.s3.amazonaws.com/default_logo.png')
        list.push('https://token-monitor.s3.amazonaws.com/16923263526871687157904907cropped-1687157904893%20%281%29%20%281%29.png')
        setLogoList(list)
        setLogoIndex(0)
        setLogo(list[0])
        NETWORK_LIST.forEach(item => {
            if (props.chain === item.shortname || props.chain === parseInt(item.chainId)) {
                setChainThumb(item.logo)
            }
        })

    }, [props])



    return <>
        <LogoPreview show={showPreview} logo={props.logo} handleClose={() => {
            setShowPreview(false)
        }} />
        <div className="logo-box" style={{ 'width': (props.size || '4.3') + 'rem' }}>
            <img onClick={() => {
                if (props.onClickLogo) {
                    props.onClickLogo()
                } else {
                    if (props.logoPreview && props.logo) {
                        // 展示preview
                        setShowPreview(true)
                    }
                }

            }} className="rounded-circle" style={{ 'height': (props.size || '4.3') + 'rem' }} src={logo} onError={({ currentTarget }) => {
                if (logoIndex < logoList.length) {
                    setLogoIndex(logoIndex + 1)
                    setLogo(logoList[logoIndex])
                    currentTarget.src = logo
                }
            }} alt="" />
            {chainThumb && <div className="logo-box-chain-thumb" style={{ 'width': (props.size / 3 + 0.07) + 'rem', 'height': (props.size / 3 + 0.1) + 'rem', 'borderRadius': '50%', 'backgroundColor': '#ffffff' }}>
                <img style={{ 'width': (props.size / 3) + 'rem', 'height': (props.size / 3) + 'rem', 'borderRadius': '50%' }} src={chainThumb} alt="" />
            </div>}
        </div>
    </>
}