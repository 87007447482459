
import { EventEmitter } from 'events'
import CryptoJS from "crypto-js"; //crypto-js加解密库
import { TmdAesKey } from './config';
import { NETWORK_LIST } from './networks';
import i18n from './i18n';
import { getTokenDetail } from '../api/token';
export const eventBus = new EventEmitter()

export const zeroAddress = '0x0000000000000000000000000000000000000000'
export const deadAddress = '0x000000000000000000000000000000000000dEaD'
// 传入一个数字字符串和语言, 返回对应的数量
export const convertToMaxUnit = (numString) => {

    if (i18n.language === 'zh') {
        const numArr = (numString + '').split('.')

        if (numArr[0].length <= 5) {
            return convertNanToEmpty(converToNumberString(numString))
            // return parseFloat(numString).toFixed(2)
        }
        // 从万开始
        const units = ['', '万', '亿', '万亿', '京', '万京'];

        let num = parseInt(numString);
        let unitIndex = 0;

        while (num >= 10000 && unitIndex < units.length - 1) {
            num /= 10000;
            unitIndex += 1;
        }

        return convertNanToEmpty(num.toFixed(1) + units[unitIndex])

    } else {
        const numArr = (numString + '').split('.')

        if (numArr[0].length <= 5) {
            return convertNanToEmpty(converToNumberString(numString))
            // return parseFloat(numString).toFixed(2)
        }

        const units = ['', 'K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'];
        // 1000 千 1000000 百万 1000000000 10亿 万亿 千万亿
        let num = parseInt(numString);
        let unitIndex = 0;

        while (num >= 1000 && unitIndex < units.length - 1) {
            num /= 1000;
            unitIndex += 1;
        }

        return convertNanToEmpty(num.toFixed(1) + units[unitIndex])
    }

}

export const convertNanToEmpty = (numString) => {
    if (Number.isNaN(numString)) {
        return ' - '
    }
    if (String(numString).indexOf('NaN') !== -1) {
        return ' - '
    }
    return numString
}

export const converToNumberString = (numString) => {
    // 保留4位有效数字
    if (numString === undefined) return ''
    if (!numString) return convertNanToEmpty(numString)



    const n = parseInt(numString)
    const arr = (numString + '').split('.')

    if (arr.length === 1) return convertNanToEmpty(numString)
    // console.log('nnnn', n)
    if (n === 0) {
        // 0后面保留 0.0{17}123
        if (arr.length === 2) {
            // 处理1
            const m = arr[1]
            const ml = arr[1].length
            let cc = 0
            // 获取从头开始有几个0,
            for (let i = 0; i < ml; i++) {
                if (m[i] === '0') {
                    cc += 1
                } else {
                    break
                }
            }
            let sliceStart = 0 // 截取开始
            let sliceEnd = 0 // 截取结束
            let ccF = ""
            if (cc > 2) {
                // 分红 0{6}
                ccF = `0{${cc}}`
                sliceStart = cc
                sliceEnd = cc + 3
            } else {
                // 只留4位
                sliceStart = 0
                sliceEnd = 3
            }
            // console.log(sliceStart, sliceEnd, "slice")
            const mm = m.slice(sliceStart, sliceEnd)
            // console.log(mm, arr)
            // 去除两端的0
            const fix = ccF + (mm).replace(/(0*$)/g, "")
            return convertNanToEmpty(arr[0] + (fix === "" ? '' : ("." + fix)))
        }
    } else {
        // 大于1, 直接截取后面3位即可
        // console.log(arr[0], 'aaad', arr[1])
        const fix = arr[1].slice(0, 3).replace(/(0*$)/g, "")

        return convertNanToEmpty(arr[0] + '' + (fix === "" ? '' : ("." + fix)))
    }
}

//生成从minNum到maxNum的随机数
export function randomNum(minNum, maxNum) {
    switch (arguments.length) {
        case 1:
            return parseInt(Math.random() * minNum + 1, 10);
        case 2:
            return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
        default:
            return 0;
    }
}

export const formatTimeToDate = (timeString) => {
    // 根据时区, 格式化时间
    const date = new Date(timeString)
    // 如果是今天, 就直接 十分秒
    // 如果不是今天, 就年月日时分秒
    const addZero = (x) => {
        if (x < 10) {
            return '0' + x
        }
        return x
    }
    let month = addZero(date.getMonth() + 1);
    let day = addZero(date.getDate());
    let year = date.getFullYear();

    return year + "-" + month + "-" + day;
}

export const formatTimeString = (timeString, isMobile = false, notSeconds = false, hideYear = false) => {
    // 根据时区, 格式化时间
    const date = new Date(timeString)
    // 如果是今天, 就直接 十分秒
    // 如果不是今天, 就年月日时分秒
    const addZero = (x) => {
        if (x < 10) {
            return '0' + x
        }
        return x
    }
    let month = addZero(date.getMonth() + 1);
    let day = addZero(date.getDate());
    let year = date.getFullYear();
    let hours = addZero(date.getHours());
    let minutes = addZero(date.getMinutes());
    let seconds = addZero(date.getSeconds());

    const now = new Date()
    const nowYear = now.getFullYear();
    const nowMonth = addZero(now.getMonth() + 1)
    const nowDay = addZero(now.getDate())

    if ((nowYear === year && nowMonth === month && nowDay === day) || isMobile === true) {
        return hours + ":" + minutes + (notSeconds ? '' : (":" + seconds));
    }

    return (hideYear ? '' : (year + "-")) + month + "-" + day + " " + hours + ":" + minutes + (notSeconds ? '' : (":" + seconds));
}


export const convertNumberToString = (n) => {
    let eformat = Number(n)
    let number = eformat
    let tmpVal = '' + eformat
    let ee = tmpVal.replace(/[\d.-]/g, '')
    if (ee) {
        if (ee === 'E' || ee === 'e') {
            let pos = tmpVal.indexOf('E')
            if (pos === -1) {
                pos = tmpVal.indexOf('e')
            }
            // console.log('pos:', pos)
            let power = tmpVal.substr(pos + 1)
            // console.log('power:', power)
            if (power === '') {
                let baseValue = tmpVal.substr(0, pos)
                number = baseValue
                // 不调用Number函数了，防止转换为科学计数法了
            } else {
                let baseValue = tmpVal.substr(0, pos)
                let character = power.substr(0, 1)
                if (character === '-') {
                    let times = power.substr(1)
                    if (times === '' || times === '0') {
                        number = baseValue
                        // 不调用Number函数了，防止转换为科学计数法了
                    } else {
                        times = Number(times)
                        let baseValueLength = baseValue.length
                        let floatLength = baseValue.indexOf('.') + 1
                        let length = baseValueLength - floatLength
                        number = eformat.toFixed(length + times)
                        // 这里只能以字符串形式输出了，不能再调用Number函数
                    }
                } else {
                    let powerVal = Math.pow(10, power)
                    number = baseValue * powerVal
                }
            }
        }
    }
    // console.log('结果是：', number) // 结果是： 0.000000134
    return number + ''
}


export const getBlockBrowerHost = (chainId) => {
    for (let i = 0; i < NETWORK_LIST.length; i++) {
        const item = NETWORK_LIST[i]
        if (parseInt(item.chainId) === parseInt(chainId)) {
            return item.explorer
        }
    }
}

export const getTokenHolderTxList = (chainId, address, tokenAddress) => {
    const explorer = getBlockBrowerHost(chainId)
    if (explorer) {
        return `${explorer}/token/${tokenAddress}?a=${address}`
    }
    return ''
}

export const getBlockBrowerTxUrl = (chainId, txHash) => {
    const explorer = getBlockBrowerHost(chainId)
    if (explorer) {
        return `${explorer}/tx/${txHash}`
    }

    return ''
}

export const StreamLineUrl = (u) => {
    // 去掉url开始的http和两边的特殊字符
    return u.replace('http://', '').replace('https://', '')
}

export const getShortAddress = (address, count = 4) => {
    if (!address) return address
    if (address.length <= 2 * count) return address
    return address.slice(0, count) + '...' + address.slice(-1 * count)
}

export const uniqueArray = (arr) => {
    if (!Array.isArray(arr)) {
        console.log('type error!')
        return
    }
    var array = [];
    for (var i = 0; i < arr.length; i++) {
        if (array.indexOf(arr[i]) === -1) {
            array.push(arr[i])
        }
    }
    return array;
}


export const isAddress = (addr) => {
    return /^0x[a-fA-F0-9]{40}$/.test(addr)
}

export const isBlackHoleAddress = (addr) => {
    if (!addr) return false
    return addr.toLowerCase() === zeroAddress || addr.toLowerCase() === deadAddress.toLowerCase()
}

export const findWETHTokenFromCommonTokens = (chainId) => {
    const n = NETWORK_LIST.find(item => parseInt(item.chainId) === parseInt(chainId))
    if (!n) return null
    if (n && n.commonTokens && n.commonTokens.length > 0) {
        const weth = n.commonTokens.find(item => item.isWETH)
        return {
            ...weth,
            chain_id: parseInt(chainId),
        }
    }
    return null
}

export const findTokenFromCommonTokens = async (chainId, address) => {
    const n = NETWORK_LIST.find(item => parseInt(item.chainId) === parseInt(chainId))
    if (!n) return null
    let currencyInfo = null
    if (n && n.commonTokens && n.commonTokens.length > 0) {
        const weth = n.commonTokens.find(item => item.isWETH)
        if (address.toLowerCase() === weth.address.toLowerCase()) {
            currencyInfo = {
                ...weth,
                chain_id: parseInt(chainId),
            }
        } else {
            currencyInfo = n.commonTokens.find(item => item.address.toLowerCase() === address.toLowerCase())
            if (currencyInfo) {
                currencyInfo = {
                    ...currencyInfo,
                    chain_id: parseInt(chainId),
                }
            } else {
                const { data } = await getTokenDetail({
                    chain: n.shortname,
                    contract: address
                })
                if (data.code === 200 && data.data) {
                    currencyInfo = {
                        ...data.data,
                        isWETH: false,
                        address: address.toLowerCase(),
                        chain_id: parseInt(chainId),
                    }
                }
            }
        }
        return currencyInfo
    }
}

var AWS = require('aws-sdk');

var albumBucketName = "token-monitor";
var bucketRegion = "us-east-1";


AWS.config.region = bucketRegion; // 区域
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'us-east-1:9d54ee94-24a7-4c6f-ba16-a35a515b5465',
});


// var s3 = new AWS.S3();
export function AwsUpdate(file, key = '', onProgressCb = null) {
    console.log(file)
    var upload = new AWS.S3.ManagedUpload({
        params: {
            Bucket: albumBucketName,
            Key: key,
            Body: file
        }
    });

    upload.on('httpUploadProgress', (x) => {
        // const percent = Math.round(x.loaded / x.total * 10000) / 100
        // console.log('上传进度是', percent, "%")
        onProgressCb && onProgressCb(x)
    })

    var promise = upload.promise();
    return promise
}

export const socialMediaInfoList = ['website', 'telegram', 'twitter', 'qq', 'facebook', 'github', 'discord', 'instagram', 'reddit', 'mirror', 'youtube']

export const isUrl = (str) => {
    const reg = /^((https|http|ftp|rtsp|mms)?:\/\/)[^\s]+/
    return reg.test(str)
}

export const getChainNameWithChainId = (chainId) => {
    for (let i = 0; i < NETWORK_LIST.length; i++) {
        const item = NETWORK_LIST[i]
        if (parseInt(item.chainId) === parseInt(chainId)) {
            return item.shortname
        }
    }
    return ''
}

export const formatInputAmount = (input, decimals) => {
    const t = input + ''
    const arr = t.split('.')
    if (arr.length === 1) {
        return t
    }
    if (arr.length === 2) {
        if (arr[1].length <= decimals) {
            return t
        }
        return arr[0] + '.' + arr[1].slice(0, decimals)
    }
    return input
}

export function aesDecrypt(text) {
    let key = CryptoJS.enc.Utf8.parse(TmdAesKey);

    let decryptedData = CryptoJS.AES.decrypt(text, key, {
        iv: key,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return decryptedData.toString(CryptoJS.enc.Utf8);
};


export const isMobile = () => {
    return window.innerWidth < 768
}

export const getLanguageInfo = (lang) => {
    switch (lang) {
        case 'zh':
            return {
                'text': '简体中文',
                'icon': 'flag-icon-cn'
            }
        case 'en':
            return {
                'text': 'English',
                'icon': 'flag-icon-us'
            }
        default:
            return {}

    }

}