import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { eventBus, getBlockBrowerTxUrl, getTokenHolderTxList } from "../../../utils/tools";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
export default function HolderActionModal(props) {

    const [scanUrl, setScanUrl] = useState('') // 区块浏览器地址
    const { t } = useTranslation()
    useEffect(() => {
        const { type, info } = props.info
        if (type === 'tx' || type === 'lp') {
            const url = getBlockBrowerTxUrl(parseInt(info.chain_id), info.tx_hash)
            setScanUrl(url)
        }
        if (type === 'holder') {
            const url = getTokenHolderTxList(parseInt(info.chain_id), info.address, info.tokenAddress)
            setScanUrl(url)
        }
    }, [props.info])

    return <Modal centered contentClassName="search-modal" show={props.show} onHide={() => {
        props.handleClose && props.handleClose()
    }} >
        <Modal.Body>
            <div className="holders-action-box">
                <div onClick={() => {
                    window.open(scanUrl, '_blank')
                    // const w = window.open(scanUrl, '_blank')
                    // w.location.href = scanUrl
                    props.handleClose && props.handleClose()
                }} className="btn btn-outline-primary full-width">{t('在区块浏览器中查看')}</div>
                <div onClick={() => {
                    // 监控该用户
                    // 跳转到用户监控页面
                    // 将这个用户地址作为临时地址传入, 展示地址信息
                    const { type } = props.info
                    let address = ''
                    if (type === 'tx') {
                        address = props.info?.info?.from
                    }
                    if (type === 'holder') {
                        address = props.info?.info?.address
                    }
                    if (type === 'lp') {
                        address = props.info?.info?.from_address
                    }
                    eventBus.emit('addWalletMonitor', address)
                    props.handleClose && props.handleClose()

                }} className="btn btn-outline-primary mt-2 full-width">{t('监控该用户')}</div>
                <div onClick={() => {
                    // 分析该用户
                    // 跳转到用户分析页面
                    // 将这个用户地址作为临时地址传入, 展示地址信息
                    const { type } = props.info
                    let address = ''
                    if (type === 'tx') {
                        address = props.info?.info?.from
                    }
                    if (type === 'holder') {
                        address = props.info?.info?.address
                    }
                    if (type === 'lp') {
                        address = props.info?.info?.from_address
                    }
                    eventBus.emit('addWalletObserver', address)
                    props.handleClose && props.handleClose()
                }} className="btn btn-outline-primary mt-2 full-width">{t('分析该用户')}</div>
                <div onClick={() => {
                    let address = ''
                    const { type } = props.info
                    if (type === 'tx') {
                        address = props.info?.info?.from
                    }
                    if (type === 'holder') {
                        address = props.info?.info?.address
                    }
                    if (type === 'lp') {
                        address = props.info?.info?.from_address
                    }
                    eventBus.emit('addAddressToWalletObserver', address)
                    props.handleClose && props.handleClose()
                }} className="btn btn-outline-primary mt-2 full-width">{t('添加地址到观察钱包')}</div>
                {props.info.type === 'tx' && <div onClick={() => {
                    navigator.clipboard.writeText(props.info?.info?.tx_hash)
                    toast.success(t('已复制'))
                    props.handleClose && props.handleClose()
                }} className="btn btn-outline-primary mt-2 full-width">{t('复制交易哈希')}</div>}
                <div onClick={() => {
                    const { type } = props.info
                    let address = ''
                    if (type === 'tx') {
                        address = props.info?.info?.from
                    }
                    if (type === 'holder') {
                        address = props.info?.info?.address
                    }
                    if (type === 'lp') {
                        address = props.info?.info?.from_address
                    }
                    navigator.clipboard.writeText(address)
                    toast.success(t('已复制'))
                    props.handleClose && props.handleClose()
                }} className="btn btn-outline-primary mt-2 full-width">{t('复制用户地址')}</div>

            </div>
        </Modal.Body>
    </Modal>
}