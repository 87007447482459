import React, { Component, useEffect } from 'react';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
// import Homepage from './pages/index';
import Dashboard from './pages/dashboard';
// import Exchange from './pages/exchange';
// import Tbi from './pages/data-tbi';
import Settings from './pages/settings';
import SwapIndex from './pages/swap/index'
// import AccountAffiliate from './pages/account-affiliate';
// import AccountApi from './pages/account-api';
// import AccountDeposit from './pages/account-deposit';
// import AccountOverview from './pages/account-overview';
// import AccountWithdraw from './pages/account-withdraw';
// import BankAcc from './pages/add-bank-acc';
// import DebitCard from './pages/add-debit-card';
// import FundingRate from './pages/data-funding-rate';
// import IndexPrice from './pages/data-index-price';
// import InsuranceFund from './pages/data-insurance-fund';
// import LastPrice from './pages/data-last-price';
// import MarkPrice from './pages/data-mark-price';
// import Demo from './pages/demo';
// import Lock from './pages/lock';
// import Otp1 from './pages/otp-1';
// import Otp2 from './pages/otp-2';
// import Reset from './pages/reset';
// import SettingAccount from './pages/settings-account';
// import Preferences from './pages/settings-preferences';
// import Security from './pages/settings-security';
// import Signin from './pages/signin';
// import Signup from './pages/signup';
// import VerifyStep1 from './pages/verify-step-1';
// import VerifyStep2 from './pages/verify-step-2';
// import VerifyStep3 from './pages/verify-step-3';
// import VerifyStep4 from './pages/verify-step-4';
// import VerifyStep5 from './pages/verify-step-5';
// import VerifyStep6 from './pages/verify-step-6';

import PageIndex from './pages/index/index'
import GainPage from './pages/filters/gain';
import AdminIndex from './pages/admin/index'
import CheckTokenSecurity from './pages/check'
import NewPoolPage from './pages/filters/pool';
import MonitorIndex from './pages/monitors';
// import DappsIndex from './pages/dapps';
import { useHistory, withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import store from '../store';



function usePageViews() {

    let location = useLocation()

    useEffect(
        () => {
            // ga.send(['pageview', location.pathname])
            console.log('路由新增', location.pathname)
        },
        [location]
    )
}



// const history = createbrow()
function Index2() {

    // const history = useHistory()
    // useEffect(() => {
    //     const unlistener = history.listen((x, y) => {
    //         console.log(x, y, '路由变化')
    //     })
    //     return () => {
    //         unlistener()
    //     }
    // }, [history])

    return <>
        {/* <BrowserRouter basename={'/demo/tradix_react'}> */}
        <BrowserRouter >
            <div id="main-wrapper">
                <Switch>
                    <Route path='/' exact component={PageIndex} />
                    <Route path='/gain/filters' exact component={GainPage} />
                    <Route path='/new-pools/filters' exact component={NewPoolPage} />
                    <Route path='/pools/filters' exact component={CheckTokenSecurity} />
                    <Route path='/check' exact component={CheckTokenSecurity} />
                    <Route path='/check/:chain/:token' exact component={CheckTokenSecurity} />
                    <Route path='/monitors' exact component={MonitorIndex} />
                    <Route path='/dashboard' component={Dashboard} />
                    <Route path='/settings' component={Settings} />
                    <Route path='/token/:chain/:token' component={Dashboard} />
                    {/* <Route path='/dapps' component={DappsIndex} /> */}
                    {/* <Route path='/exchange' component={Exchange} /> */}
                    {/* <Route path='/account-overview' component={AccountOverview} /> */}
                    {/* <Route path='/data-tbi' component={Tbi} />
                            <Route path='/account-affiliate' component={AccountAffiliate} />
                            <Route path='/account-api' component={AccountApi} />
                            <Route path='/account-deposit' component={AccountDeposit} />
                            <Route path='/account-withdraw' component={AccountWithdraw} />
                            <Route path='/add-bank-acc' component={BankAcc} />
                            <Route path='/add-debit-card' component={DebitCard} />
                            <Route path='/data-funding-rate' component={FundingRate} />
                            <Route path='/data-index-price' component={IndexPrice} />
                            <Route path='/data-insurance-fund' component={InsuranceFund} />
                            <Route path='/data-last-price' component={LastPrice} />
                            <Route path='/data-mark-price' component={MarkPrice} />
                            <Route path='/demo' component={Demo} />
                            <Route path='/lock' component={Lock} />
                            <Route path='/otp-1' component={Otp1} />
                            <Route path='/otp-2' component={Otp2} />
                            <Route path='/reset' component={Reset} />
                            <Route path='/settings-account' component={SettingAccount} />
                            <Route path='/settings-preferences' component={Preferences} />
                            <Route path='/settings-security' component={Security} />
                            <Route path='/signin' component={Signin} />
                            <Route path='/signup' component={Signup} />
                            <Route path='/verify-step-1' component={VerifyStep1} />
                            <Route path='/verify-step-2' component={VerifyStep2} />
                            <Route path='/verify-step-3' component={VerifyStep3} />
                            <Route path='/verify-step-4' component={VerifyStep4} />
                            <Route path='/verify-step-5' component={VerifyStep5} />
                            <Route path='/verify-step-6' component={VerifyStep6} /> */}
                </Switch>
            </div>
        </BrowserRouter>

    </>
}

const IndexRouter = withRouter((props) => {
    useEffect(() => {
        if (props?.location?.pathname) {
            store.dispatch({ type: "pushHistory", value: props.location.pathname })
        }

    }, [props])
    return <div id="main-wrapper">
        <Switch>
            <Route path='/' exact component={PageIndex} />
            <Route path='/gain/filters' exact component={GainPage} />
            <Route path='/new-pools/filters' exact component={NewPoolPage} />
            <Route path='/pools/filters' exact component={CheckTokenSecurity} />
            <Route path='/check' exact component={CheckTokenSecurity} />
            <Route path='/check/:chain/:token' exact component={CheckTokenSecurity} />
            <Route path='/monitors' exact component={MonitorIndex} />
            <Route path='/dashboard' component={Dashboard} />
            <Route path='/settings' component={Settings} />
            <Route path='/token/:chain/:token' component={Dashboard} />
            {/* <Route path='/dapps' component={DappsIndex} /> */}
            <Route path='/swap' component={SwapIndex} />
            <Route path='/admin.x' component={AdminIndex} />
        </Switch>
    </div>
})

class Index extends Component {
    render() {
        return (
            <>
                {/* <BrowserRouter basename={'/demo/tradix_react'}> */}
                <BrowserRouter>
                    <IndexRouter />
                </BrowserRouter>

            </>
        );
    }
}

export default Index;