import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NETWORK_LIST } from '../../../utils/networks';

const TmpModal = (props) => {
    const { t } = useTranslation()
    const [tmpValue, setTmpValue] = useState('')
    return <Modal centered contentClassName="search-modal" show={props.show} onHide={() => {
        props.onHide && props.onHide()
    }} >
        <Modal.Body>
            <div >
                {props.moreModalParams.title && <div className="login-header">{props.moreModalParams.title}</div>}
                <div className='mt-4'>
                    {props.moreModalParams.type === 'select' && <div>
                        {props.moreModalParams.values.map((item, ind) => {
                            return <Form.Check onChange={e => {
                                setTmpValue(e.target.value)
                            }} inline id={`${ind}-${props.moreModalParams.title || ''}`} name={props.moreModalParams.title} type='radio' value={item} label={props.moreModalParams.labels[ind]}></Form.Check>
                        })}
                    </div>}

                    {props.moreModalParams.type === 'range' && <div className='d-flex flex-direction-row align-items-center'>
                        <Form.Control onChange={e => {
                            let t = tmpValue
                            if (typeof tmpValue === 'string') {
                                t = {
                                    min: '',
                                    max: ''
                                }
                            }
                            t.min = e.target.value
                            setTmpValue({
                                ...t
                            })
                        }} inline value={tmpValue?.min} style={{ width: '40%', marginRight: '3px' }} placeholder={props.moreModalParams.min !== '' ? `${t('最小值')}: ${props.moreModalParams.min}` : ''} /> ~ <Form.Control onChange={e => {
                            let t = tmpValue
                            if (typeof tmpValue === 'string') {
                                t = {
                                    min: '',
                                    max: ''
                                }
                            }
                            t.max = e.target.value
                            console.log(t)
                            setTmpValue({
                                ...t
                            })
                        }} style={{ width: '40%', marginLeft: '3px' }} value={tmpValue?.max} inline placeholder={props.moreModalParams.max !== '' ? `${t('最大值')}: ${props.moreModalParams.max}` : ''} />
                    </div>}
                </div>
                <div className='mt-4'>
                    <div className='btn btn-primary btn-sm text-light' onClick={() => {
                        props.ok && props.ok(tmpValue)
                    }}> {t('确定')} </div>
                    <div style={{ marginLeft: '10px' }} className='btn btn-danger btn-sm dl-2' onClick={() => {
                        props.onHide && props.onHide()
                    }}> {t('取消')} </div>

                </div>

            </div>
        </Modal.Body>


    </Modal>
}


export default function RankHeaderFilterBox(props) {
    const { t } = useTranslation()

    const [moreModalParams, setMoreModalParams] = useState({
        show: false,
        type: 'range', // 范围 range 单选 radio
        min: '',
        max: '',
        value: ''
    })

    const [moreBtnText, setMoreBtnText] = useState({
        window: '更多', // 更多按钮对应的文字
        buyTax: '自定义',
        sellTax: '自定义',
        cap: '自定义',
        rate: '自定义',
        volume: '自定义',
        txCount: '自定义',
    })

    const [filterParams, setFilterParams] = useState({
        chain_id: '0',
        window: '5m',
        desc: '0',
        buyTax: {
            min: 0,
            max: 0
        },
        sellTax: {
            min: 0,
            max: 0
        },
        cap: {
            min: 0,
            max: 0
        },
        rate: {
            min: 0,
            max: 0
        },
        volume: { // 交易额范围
            min: 0,
            max: 0
        },
        txCount: {
            min: 0, // 交易笔数
            max: 0
        },
        tokenCheckRes: {
            normal: false, // 交易正常
            noPause: false, // 不存在暂停交易开关
            canSell: false, // 代币可以全部卖出
            noLimit: false, // 不限购/限卖
            noChangeLimit: false, // 不可修改限购额度
            noChangeTax: false, // 不可修改交易税率
            noChangeTaxForAddr: false, // 不可针对特定地址修改税率
            noCoolDown: false, // 无交易冷却机制
            giveUpOwner: false, // 放弃所有权
            noMint: false, // 不可增发
            noTakeBack: false, // 不存在取回所有权开关
            noChangeBalance: false, // 不可篡改余额
            noHideOwner: false, // 无隐藏Owner
            noRisk: false, // 无外部合约调用风险
            lockOver70: false, // 已锁池超过70%
        }
    })
    const [filterLoading, setFilterLoading] = useState(false) // 是否正在筛选中
    const [isGainPage, setIsGainPage] = useState(false) // 是否是涨幅榜


    useEffect(() => {
        if (props.type === 'gain') {
            setIsGainPage(true)
        } else {
            setIsGainPage(false)
        }
    }, [props.type])

    useEffect(() => {
        props.onChange && props.onChange(filterParams)
    }, [filterParams])

    const changeSwitchFilerParams = (key, v) => {
        const a = {
            ...filterParams
        }
        a.tokenCheckRes[key] = !a.tokenCheckRes[key]
        setFilterParams(a)
    }
    const changeFilterParams = (key, v) => {
        setFilterParams({
            ...filterParams,
            [key]: v
        })
    }

    const showMoreModalInfo = (info) => {
        setMoreModalParams({
            ...info,
            show: true
        })
    }




    return <>
        <TmpModal show={moreModalParams.show} onHide={() => {
            setMoreModalParams({
                ...moreModalParams,
                show: false
            })
        }}
            moreModalParams={moreModalParams}
            ok={(v) => {
                setFilterParams({
                    ...filterParams,
                    [moreModalParams.name]: v
                })
                if (moreModalParams.type === 'select') {
                    setMoreBtnText({
                        ...moreBtnText,
                        [moreModalParams.name]: v.toUpperCase()
                    })
                }
                if (moreModalParams.type === 'range') {
                    let tt = `${v.min} ~ ${v.max}`
                    if (moreModalParams.ext === '%') {
                        tt = `${v.min}% ~ ${v.max}%`
                    }
                    if (moreModalParams.ext === '$') {
                        tt = `$${v.min} ~ $${v.max}`
                    }

                    setMoreBtnText({
                        ...moreBtnText,
                        [moreModalParams.name]: tt
                    })
                }

                setMoreModalParams({
                    ...moreModalParams,
                    show: false
                })
            }}
        />
        <div className='card'>
            <div className='card-body'>
                <div className='filter-page-body'>
                    <div style={{ width: '100%' }} className="row">
                        <div className="col-12 col-md-12 col-xl-12 col-lg-12 col-sm-12">
                            <div className='filter-page-body-item mt-2'>
                                <div className='filter-page-body-item-header'>
                                    <div>{t('交易信息筛选')}</div>
                                    {/* <div className='btn btn-outline-primary btn-sm'>
                                        <i className='mdi mdi-ear-hearing'></i>  闪崩监控
                                    </div> */}
                                </div>

                                <div className='filter-page-body-item-body'>
                                    <div className='filter-page-body-item-body-row'>
                                        <div className='filter-page-body-item-body-row-left'> {t('公链')}:</div>
                                        <div className='filter-page-body-item-body-row-right'>
                                            <select value={filterParams.chain_id} onChange={e => {
                                                changeFilterParams('chain_id', e.target.value)
                                            }} className="form-control form-select form-select-sm text-light" style={{ width: '100px', marginLeft: '5px', fontSize: '12px' }}>
                                                <option value="0">全部</option>
                                                {NETWORK_LIST && NETWORK_LIST.map(item => {
                                                    return <option value={item.chainId}>{item.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    {isGainPage && <div className='filter-page-body-item-body-row'>
                                        <div className='filter-page-body-item-body-row-left'> {t('榜单')}:</div>
                                        <div className='filter-page-body-item-body-row-right'>
                                            <div onClick={changeFilterParams.bind(this, 'desc', '0')} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.desc === '0' ? 'filter-active' : ''}`}>{t('涨幅榜')}</div>
                                            <div onClick={changeFilterParams.bind(this, 'desc', '1')} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.desc === '1' ? 'filter-active' : ''}`}>{t('跌幅榜')}</div>
                                        </div>
                                    </div>}
                                    <div className='filter-page-body-item-body-row'>
                                        <div className='filter-page-body-item-body-row-left'> {t('时间区间')}:</div>
                                        <div className='filter-page-body-item-body-row-right'>
                                            <div onClick={changeFilterParams.bind(this, 'window', '5m')} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.window === '5m' ? 'filter-active' : ''}`}>5M</div>
                                            <div onClick={changeFilterParams.bind(this, 'window', '1h')} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.window === '1h' ? 'filter-active' : ''}`}>1H</div>
                                            <div onClick={changeFilterParams.bind(this, 'window', '4h')} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.window === '4h' ? 'filter-active' : ''}`}>4H</div>
                                            <div onClick={changeFilterParams.bind(this, 'window', '1d')} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.window === '1d' ? 'filter-active' : ''}`}>24H</div>
                                            <div onClick={showMoreModalInfo.bind(this, {
                                                type: 'select',
                                                name: 'window',
                                                title: t('选择时间区间'),
                                                values: ['1m', '3m', '10m', '15m', '30m', '2h'],
                                                labels: ['1m', '3m', '10m', '15m', '30m', '2h']
                                            })} className='filter-page-body-item-body-row-right-item'>
                                                <div className='btn btn-outline-primary btn-sm'>
                                                    <i className='mdi mdi-pencil text-primary'></i>
                                                    {t(moreBtnText.window, { defaultValue: moreBtnText.window }) || t('更多')}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {/* <div className='filter-page-body-item-body-row'>
                                        <div className='filter-page-body-item-body-row-left'>{t('买入税率')}:</div>
                                        <div className='filter-page-body-item-body-row-right'>
                                            <div onClick={changeFilterParams.bind(this, 'buyTax', { min: 0, max: 0 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.buyTax.min === 0 && filterParams.buyTax.max === 0 ? 'filter-active' : ''}`}>{t('不限制')}</div>
                                            <div onClick={changeFilterParams.bind(this, 'buyTax', { min: 0, max: 5 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.buyTax.min === 0 && filterParams.buyTax.max === 5 ? 'filter-active' : ''}`}>0~5%</div>
                                            <div onClick={changeFilterParams.bind(this, 'buyTax', { min: 5, max: 10 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.buyTax.min === 5 && filterParams.buyTax.max === 10 ? 'filter-active' : ''}`}>5~10%</div>
                                            <div onClick={changeFilterParams.bind(this, 'buyTax', { min: 10, max: 100 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.buyTax.min === 10 && filterParams.buyTax.max === 100 ? 'filter-active' : ''}`}>10%+</div>
                                            <div onClick={showMoreModalInfo.bind(this, {
                                                type: 'range',
                                                name: 'buyTax',
                                                title: t('自定义买入税率范围'),
                                                min: 0,
                                                max: 100,
                                                ext: '%'
                                            })} className='filter-page-body-item-body-row-right-item'>
                                                <div className='btn btn-outline-primary btn-sm'>
                                                    <i className='mdi mdi-pencil text-primary'></i>
                                                    {t(moreBtnText.buyTax, { defaultValue: moreBtnText.buyTax }) || t('自定义')}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='filter-page-body-item-body-row'>
                                        <div className='filter-page-body-item-body-row-left'> {t('卖出税率')}:</div>
                                        <div className='filter-page-body-item-body-row-right'>
                                            <div onClick={changeFilterParams.bind(this, 'sellTax', { min: 0, max: 0 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.sellTax.min === 0 && filterParams.sellTax.max === 0 ? 'filter-active' : ''}`}>{t('不限制')}</div>
                                            <div onClick={changeFilterParams.bind(this, 'sellTax', { min: 0, max: 5 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.sellTax.min === 0 && filterParams.sellTax.max === 5 ? 'filter-active' : ''}`}>0~5%</div>
                                            <div onClick={changeFilterParams.bind(this, 'sellTax', { min: 5, max: 10 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.sellTax.min === 5 && filterParams.sellTax.max === 10 ? 'filter-active' : ''}`}>5~10%</div>
                                            <div onClick={changeFilterParams.bind(this, 'sellTax', { min: 10, max: 100 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.sellTax.min === 10 && filterParams.sellTax.max === 100 ? 'filter-active' : ''}`}>10%+</div>
                                            <div onClick={showMoreModalInfo.bind(this, {
                                                type: 'range',
                                                name: 'sellTax',
                                                title: t('自定义卖出税率范围'),
                                                min: 0,
                                                max: 100,
                                                ext: '%'
                                            })} className='filter-page-body-item-body-row-right-item'>
                                                <div className='btn btn-outline-primary btn-sm'>
                                                    <i className='mdi mdi-pencil text-primary'></i>
                                                    {t(moreBtnText.sellTax, { defaultValue: moreBtnText.sellTax }) || t('自定义')}
                                                </div>
                                            </div>
                                        </div>

                                    </div> */}

                                    <div className='filter-page-body-item-body-row'>
                                        <div className='filter-page-body-item-body-row-left'> {t('流动池大小')}:</div>
                                        <div className='filter-page-body-item-body-row-right'>
                                            <div onClick={changeFilterParams.bind(this, 'cap', { min: 0, max: 0 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.cap.min === 0 && filterParams.cap.max === 0 ? 'filter-active' : ''}`}> {t('不限制')} </div>
                                            <div onClick={changeFilterParams.bind(this, 'cap', { min: 0, max: 10000 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.cap.min === 0 && filterParams.cap.max === 10000 ? 'filter-active' : ''}`}>$0~10000</div>
                                            <div onClick={changeFilterParams.bind(this, 'cap', { min: 10000, max: 100000 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.cap.min === 10000 && filterParams.cap.max === 100000 ? 'filter-active' : ''}`}>$10000~100000</div>
                                            <div onClick={changeFilterParams.bind(this, 'cap', { min: 100000, max: 1000000000000 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.cap.min === 100000 && filterParams.cap.max === 1000000000000 ? 'filter-active' : ''}`}>$100000+</div>
                                            <div onClick={showMoreModalInfo.bind(this, {
                                                type: 'range',
                                                name: 'cap',
                                                title: t('自定义流动性范围'),
                                                min: 0,
                                                max: '',
                                                ext: '$'
                                            })} className='filter-page-body-item-body-row-right-item'>
                                                <div className='btn btn-outline-primary btn-sm'>
                                                    <i className='mdi mdi-pencil text-primary'></i>
                                                    {t(moreBtnText.cap, { defaultValue: moreBtnText.cap }) || t('自定义')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='filter-page-body-item-body-row'>
                                        <div className='filter-page-body-item-body-row-left'> {t('涨跌幅')}:</div>
                                        <div className='filter-page-body-item-body-row-right'>
                                            <div onClick={changeFilterParams.bind(this, 'rate', { min: 0, max: 0 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.rate.min === 0 && filterParams.rate.max === 0 ? 'filter-active' : ''}`}>{t('不限制')}</div>
                                            <div onClick={changeFilterParams.bind(this, 'rate', { min: 0, max: 20 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.rate.min === 0 && filterParams.rate.max === 20 ? 'filter-active' : ''}`}>0~20%</div>
                                            <div onClick={changeFilterParams.bind(this, 'rate', { min: 20, max: 50 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.rate.min === 20 && filterParams.rate.max === 50 ? 'filter-active' : ''}`}>20~50%</div>
                                            <div onClick={changeFilterParams.bind(this, 'rate', { min: 50, max: 100000000 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.rate.min === 50 && filterParams.rate.max === 100000000 ? 'filter-active' : ''}`}>50%+</div>
                                            <div onClick={showMoreModalInfo.bind(this, {
                                                type: 'range',
                                                name: 'rate',
                                                title: t('自定义涨跌幅范围'),
                                                min: '',
                                                max: '',
                                                ext: '%'
                                            })} className='filter-page-body-item-body-row-right-item'>
                                                <div className='btn btn-outline-primary btn-sm'>
                                                    <i className='mdi mdi-pencil text-primary'></i>
                                                    {t(moreBtnText.rate, { defaultValue: moreBtnText.rate }) || t('自定义')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='filter-page-body-item-body-row'>
                                        <div className='filter-page-body-item-body-row-left'> {t('交易额')}:</div>
                                        <div className='filter-page-body-item-body-row-right'>
                                            <div onClick={changeFilterParams.bind(this, 'volume', { min: 0, max: 0 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.volume.min === 0 && filterParams.volume.max === 0 ? 'filter-active' : ''}`}>{t('不限制')}</div>
                                            <div onClick={changeFilterParams.bind(this, 'volume', { min: 0, max: 10000 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.volume.min === 0 && filterParams.volume.max === 10000 ? 'filter-active' : ''}`}>$0~10000</div>
                                            <div onClick={changeFilterParams.bind(this, 'volume', { min: 10000, max: 50000 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.volume.min === 10000 && filterParams.volume.max === 50000 ? 'filter-active' : ''}`}>$10000~50000</div>
                                            <div onClick={changeFilterParams.bind(this, 'volume', { min: 50000, max: 500000000000 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.volume.min === 50000 && filterParams.volume.max === 500000000000 ? 'filter-active' : ''}`}>$50000+</div>
                                            <div onClick={showMoreModalInfo.bind(this, {
                                                type: 'range',
                                                name: 'volume',
                                                title: t('自定义交易额范围'),
                                                min: 0,
                                                max: '',
                                                ext: '$'
                                            })} className='filter-page-body-item-body-row-right-item'>
                                                <div className='btn btn-outline-primary btn-sm'>
                                                    <i className='mdi mdi-pencil text-primary'></i>
                                                    {t(moreBtnText.volume, { defaultValue: moreBtnText.volume }) || t('自定义')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='filter-page-body-item-body-row'>
                                        <div className='filter-page-body-item-body-row-left'> {t('交易笔数')}:</div>
                                        <div className='filter-page-body-item-body-row-right'>
                                            <div onClick={changeFilterParams.bind(this, 'txCount', { min: 0, max: 0 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.txCount.min === 0 && filterParams.txCount.max === 0 ? 'filter-active' : ''}`}>{t('不限制')}</div>
                                            <div onClick={changeFilterParams.bind(this, 'txCount', { min: 0, max: 10 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.txCount.min === 0 && filterParams.txCount.max === 10 ? 'filter-active' : ''}`}>0~10</div>
                                            <div onClick={changeFilterParams.bind(this, 'txCount', { min: 10, max: 100 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.txCount.min === 10 && filterParams.txCount.max === 100 ? 'filter-active' : ''}`}>10~100</div>
                                            <div onClick={changeFilterParams.bind(this, 'txCount', { min: 100, max: 1000000000 })} className={`filter-page-body-item-body-row-right-item pointer-btn ${filterParams.txCount.min === 100 && filterParams.txCount.max === 1000000000 ? 'filter-active' : ''}`}>100+</div>
                                            <div onClick={showMoreModalInfo.bind(this, {
                                                type: 'range',
                                                name: 'txCount',
                                                title: t('自定义交易笔数范围'),
                                                min: 0,
                                                max: '',
                                                ext: ''
                                            })} className='filter-page-body-item-body-row-right-item'>
                                                <div className='btn btn-outline-primary btn-sm'>
                                                    <i className='mdi mdi-pencil text-primary'></i>
                                                    {t(moreBtnText.txCount, { defaultValue: moreBtnText.txCount }) || t('自定义')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-12 col-md-5 col-xl-5 col-lg-5 col-sm-12">
                            <div className='filter-page-body-item mt-2'>
                                <div className='filter-page-body-item-header'>
                                    代币信息筛选
                                </div>
                                <div className='filter-page-body-item-body'>
                                    <div className='filter-page-body-item-body-row'>
                                        <div className='filter-page-body-item-body-row-right-token-check'>

                                            <div onClick={changeSwitchFilerParams.bind(this, 'normal')} className='filter-page-check-res-item'>
                                                <input checked={filterParams.tokenCheckRes.normal} type="checkbox" />交易正常
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-2 filter-tips'>
                                    (代币信息筛选条件仅从已存在的合约检查结果中筛选, 未检查过的代币不会出现在筛选结果中)
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

        </div>
    </>
}