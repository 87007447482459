

import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Button, Alert, Form } from 'react-bootstrap'
import { isAddress } from "../../../utils/tools";
import { getTokenOwner, verifyTokenOwner } from "../../../api/token";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


export default function CertifiedModal(props) {
    const [verifyType, setVerifyType] = useState(1) // 1. 所有权验证 2. 交易哈希验证
    const [loading, setLoading] = useState(false) // 是否正在登录中
    const [tokenOwner, setTokenOwner] = useState('') // 代币所有者
    const [error, setError] = useState('') // 错误信息
    const account = useSelector(state => state.account)
    const { t } = useTranslation()

    useEffect(() => {
        let ignore = true
        if (props?.tokenInfo && isAddress(props?.tokenInfo?.address)) {
            // 去后台获取owner
            getTokenOwner({
                chain_id: props.tokenInfo.chain_id,
                address: props.tokenInfo.address
            }).then(({ data }) => {
                if (data.code === 200) {
                    if (ignore) {
                        setTokenOwner(data.data)
                    }

                }
            })
        }
        return () => {
            ignore = false
        }
    }, [props.tokenInfo])

    const verifyLogic = async () => {
        // 验证逻辑
        const { data } = await verifyTokenOwner({
            chain_id: props.tokenInfo.chain_id + '',
            address: props.tokenInfo.address,
            chain: props.tokenInfo.chain_name
        })
        if (data.code === 200) {
            // 成功
            toast.success(t('认证成功'))
            props.ok && props.ok()
        } else {
            // 失败
            setError(t('认证失败, 请稍后重试'))
        }
    }

    return <Modal centered contentClassName="search-modal" show={props.show} onHide={() => {
        props.handleClose && props.handleClose()
    }} >
        <Modal.Body>
            <div>
                <div className="login-header">{t('认证项目方')}</div>
                <div>
                    <Form>
                        <Form.Group className="mt-2">
                            <Form.Label>{t('代币合约')}</Form.Label>
                            <Form.Control style={{ backgroundColor: '#000000' }} type="text" value={props?.tokenInfo?.address || ''} readOnly />
                        </Form.Group>
                        <Form.Group className="mt-2">
                            <Form.Label>{t('公链')}</Form.Label>
                            <Form.Control style={{ backgroundColor: '#000000' }} type="text" value={props?.tokenInfo?.chain_name?.toUpperCase() || ''} readOnly />
                        </Form.Group>
                        <Form.Group className="mt-2">
                            <Form.Label>{t('认证方式')}</Form.Label>
                            <Form.Check onChange={() => {
                                setVerifyType(1)
                            }} checked={verifyType === 1} style={{ marginLeft: '10px' }} inline label={t('所有权验证')} name="group1" type={'radio'} />
                            <Form.Check onChange={() => {
                                setVerifyType(3)
                            }} checked={verifyType === 3} inline label={t('人工验证')} name="group1" type={'radio'} />
                            {/* <Form.Check onChange={() => {
                                setVerifyType(2)
                            }} checked={verifyType === 2} inline label="交易哈希验证" name="group1" type={'radio'} /> */}
                        </Form.Group>
                        {verifyType === 1 && <Form.Group className="mt-2">
                            {tokenOwner.toLowerCase() === account.toLowerCase() && <Alert variant='success'>
                                {t('所有权验证: 您当前的钱包地址与代币Owner一致, 请点击下方按钮进行验证')}
                            </Alert>}
                            {tokenOwner.toLowerCase() !== account.toLowerCase() && <Alert variant='danger'>
                                {t('所有权验证: 您当前的钱包地址不是项目方的所有者, 请切换登录钱包或更换其他方式进行验证')}
                            </Alert>}

                        </Form.Group>}
                        {verifyType === 2 && <Form.Group className="mt-2">
                            <Form.Label>{t('输入交易哈希')}</Form.Label>
                            <Form.Control size="sm" as={'textarea'} rows={3} type="text" placeholder={t('交易哈希验证: 请输入代币合约部署时的交易哈希(以 0x... 开头), 我们将会验证是否是当前登录地址发行了该代币')} />
                        </Form.Group>}
                        {verifyType === 3 && <Form.Group className="mt-2">
                            <Alert variant='info'>
                                {t('如您已经放弃所有权, 可选择人工验证, 我们将会在 24 小时内完成验证. 具体请私信')}
                                <a target="_blank" rel="noopener noreferrer" href="https://t.me/TMDev">
                                    @TMDev
                                </a>
                            </Alert>
                        </Form.Group>}
                    </Form>

                </div>
                {error && <div className="mt-2">
                    <Alert variant='danger'>
                        {error}
                    </Alert>
                </div>}
                <div className="mt-2">
                    <Button onClick={verifyLogic.bind(this)} disabled={tokenOwner.toLowerCase() !== account.toLowerCase()} style={{ color: '#ffffff' }} size="md">
                        {loading && <span>
                            <i style={{ fontSize: '15px', marginRight: '5px' }} className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                        </span>}
                        {t('验证')}</Button>
                </div>
            </div>
        </Modal.Body>
    </Modal>
}