import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Form, InputGroup, Modal, Nav, Spinner, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import PerfectScrollbar from "react-perfect-scrollbar";
import Logo from "../../components/logo";
import { converToNumberString, convertNumberToString, eventBus, findTokenFromCommonTokens, findWETHTokenFromCommonTokens, formatInputAmount, isMobile, zeroAddress } from "../../../utils/tools";
import { NETWORK_LIST } from "../../../utils/networks";
import { GetSwapRouterList } from "../../../api/swap";
import SearchModal from "../../components/SearchModal";
import { useSelector } from "react-redux";
import { Multicall3, NewMulticall3, NewMulticall3Contract } from "../../../utils/multicall3";
import { FactoryAbi, dexAbi, tokenAbi } from "../../../utils/abi";
import { BigNumber, ethers } from "ethers";
import { set } from "date-fns";
import { toast } from "react-toastify";
import { getTokenDetail } from "../../../api/token";
import { t, use } from "i18next";

const options = NETWORK_LIST.map(item => {
    return {
        label: item.name,
        value: item.chainId,
        image: item.logo
    }
})

const Option = ({ innerProps, label, data }) => (
    <div className="div-row-center" style={{ height: '35px', paddingLeft: '10px' }} {...innerProps}>
        <Logo size="1.5" logo={data.image} chain={data.chain_id} />
        <span style={{ marginLeft: '7px' }}>{label}</span>
    </div>
);

const customStyles = {
    control: (provided) => () => {
        return {
            ...provided,
            backgroundColor: 'black',
            color: 'white',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            height: '44px',
        }
    },
    singleValue: (provided, state) => ({
        ...provided,
        color: 'inherit', // 继承父元素的字体颜色
    }),
    input: (provided) => ({
        ...provided,
        color: 'white'
    }),

    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'rgb(20,23,34)' : 'black',
        color: '#cccccc',
    })
};
export const ChooseExchange = (props) => {

    const [swapRouterList, setSwapRouterList] = useState([])
    const [routerOptions, setRouterOptions] = useState([])
    const [filteredRouterOptions, setFilteredRouterOptions] = useState([]) // 过滤后的路由
    const [selectedChainId, setSelectedChainId] = useState(0) // 选择的公链
    const [selectedSwap, setSelectedSwap] = useState(null) // 选择的交易所
    const { t } = useTranslation()
    useEffect(() => {
        let list = [...routerOptions]
        if (selectedChainId) {
            list = routerOptions.filter(item => item.chain_id === selectedChainId)
        }
        setFilteredRouterOptions(list)
    }, [routerOptions, selectedChainId])

    const appendSwap = useRef(null)

    useEffect(() => {
        if (props.chainId) {
            setSelectedChainId(props.chainId)
        }
        let flag = false // 是否查找到对应的swap
        if (props.router && props.chainId) {
            const a = routerOptions.find(item => item.value?.toLowerCase() === props.router?.toLowerCase() && parseInt(item.chain_id) === parseInt(props.chainId))
            if (a) {
                flag = true
                setSelectedSwap(a)
                props.onSwapChange && props.onSwapChange(a)
            }

        } else {
            if (props.factory && props.chainId) {
                const a = routerOptions.find(item => item.factory?.toLowerCase() === props.factory?.toLowerCase() && parseInt(item.chain_id) === parseInt(props.chainId))
                if (a) {
                    flag = true
                    setSelectedSwap(a)
                    props.onSwapChange && props.onSwapChange(a)
                }

            }
        }

        if (!flag) {
            if (props.chainId && props.name && props.router && props.factory) {
                // 数据都有, 尝试去获取weth, 然后拼接添加到路由列表中
                const _router = new ethers.Contract(props.router, dexAbi, initProvider())
                _router.WETH().then(weth => {
                    const s = {
                        label: props.name,
                        value: props.router,
                        factory: props.factory,
                        image: '',
                        chain_id: props.chainId,
                        weth: weth
                    }
                    appendSwap.current = {
                        ...s
                    }
                    setRouterOptions([...routerOptions, s])
                    setSelectedSwap(s)
                    props.onSwapChange && props.onSwapChange(s)
                }).catch(e => {
                    console.log('获取swap的WETH失败', e, props)
                })

            }
        }

    }, [props.chainId, props.name, props.router, props.factory, routerOptions])

    useEffect(() => {
        GetSwapRouterList({}).then(({ data }) => {
            if (data.code === 200) {
                setSwapRouterList(data.data)
                let list = data.data.map(item => {
                    return {
                        label: item.name,
                        value: item.router,
                        factory: item.factory,
                        image: item.logo,
                        chain_id: item.chain_id,
                        weth: item.weth
                    }
                })
                if (appendSwap.current) {
                    list.push(appendSwap.current)
                }
                setRouterOptions(list)
            }
        })
    }, [])


    return <div className="row div-col-center full-width">
        <div className={props.fullWidth ? `col-12 mt-2` : `col-11 col-md-8 col-lg-6 col-xxl-6 col-xl-6 col-xs-10 mt-2`}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', maxWidth: '700px' }}>
                <div style={{ width: '200px', marginRight: '10px' }}>
                    <Select
                        placeholder={t('全部公链')}
                        styles={{
                            ...customStyles
                        }}
                        value={options.find(item => item.value === selectedChainId)}
                        options={options}
                        isSearchable
                        onChange={(e) => {
                            setSelectedChainId(e.value)
                        }}
                        components={{ Option }}
                    />

                </div>
                <div style={{ width: '100%' }}>
                    <Select
                        placeholder={t('请选择交易所...')}
                        styles={customStyles}
                        options={filteredRouterOptions}
                        isSearchable
                        value={filteredRouterOptions.find(item => parseInt(item.chain_id) === parseInt(selectedSwap?.chain_id) && item.value?.toLowerCase() === selectedSwap?.value?.toLowerCase())}
                        onChange={(e) => {
                            setSelectedSwap(e)
                            props.onSwapChange && props.onSwapChange(e)
                        }}
                        components={{ Option }}
                    />

                </div>
            </div>
        </div>
    </div>
}

export const TokenSelect = (props) => {
    const [selectToken, setSelectToken] = useState(null)

    const [showTokenSeaarchModal, setShowTokenSeaarchModal] = useState(false)
    const [chainIdList, setChainIdList] = useState([]) // 在搜索框中展示的常用代币的公链
    const { t } = useTranslation()
    useEffect(() => {
        setChainIdList(props.chains || [])
    }, [props.chains])

    useEffect(() => {
        setSelectToken(props.token)
    }, [props.token])

    return <div style={{ width: '45%' }}>
        <SearchModal chainIdList={chainIdList} show={showTokenSeaarchModal} handleClose={() => {
            setShowTokenSeaarchModal(false)
        }} onSelectItem={item => {
            setSelectToken(item)
            setShowTokenSeaarchModal(false)
            props.onSelect && props.onSelect(item)
            console.log('已选中', item)

        }} />
        <span onClick={e => {
            setShowTokenSeaarchModal(true)
        }} className="div-row-center" style={{ justifyContent: 'space-between', backgroundColor: '#000000', fontSize: '15px', fontWeight: 'bold', paddingLeft: '10px', paddingRight: '10px', height: '44px', cursor: 'pointer' }}>
            <Logo size="1.8" logo={selectToken?.logo || ''} chain={selectToken?.chain_id || 0} />
            <div style={{ marginLeft: '10px', fontSize: '15px', fontWeight: 'bold', maxWidth: '90%', overflow: 'hidden' }}>
                {selectToken?.symbol || t('请选择')}
            </div>
            <div>
                <i style={{ fontSize: '20px' }} className="mdi mdi-menu-down"></i>
            </div>
        </span>
    </div>
}

export const TokenSelectAndSetValueRow = (props) => {

    const [showTokenSeaarchModal, setShowTokenSeaarchModal] = useState(false)
    const [inputText, setInputText] = useState('')

    const [chainIdList, setChainIdList] = useState([]) // 在搜索框中展示的常用代币的公链

    const [selectToken, setSelectToken] = useState(null)

    const [tokenDecimals, setTokenDecimals] = useState(18)
    const [tokenBalance, setTokenBalance] = useState(0)
    const [loading, setLoading] = useState(false)

    const account = useSelector(state => state.account)
    const chainId = useSelector(state => state.chainId)
    useEffect(() => {
        if (props.token) {
            setSelectToken(props.token)
        }
    }, [props.token])

    useEffect(() => {
        eventBus.addListener('refreshBalance', getBalance)
        return () => {
            eventBus.removeListener('refreshBalance', getBalance)
        }
    }, [selectToken, account, chainId])

    useEffect(() => {
        setChainIdList(props.chains || [])
    }, [props.chains])

    useEffect(() => {
        setInputText(props.value)
    }, [props.value])


    useEffect(() => {
        setTokenBalance(convertNumberToString(props.balance))
        setTokenDecimals(props.decimals || 18)
    }, [props.balance, props.decimals])

    useEffect(() => {
        // 获取token的余额
        // 点击余额, 直接写入数据中
        getBalance()
    }, [selectToken, chainId, account])

    const getBalance = async () => {
        // 获取decimals和balance
        if (selectToken && account && selectToken.chain_id === chainId) {
            const provider = initProvider()
            setLoading(true)
            try {
                if (selectToken.isWETH) {
                    const decimals = 18
                    const balance = await provider.getBalance(account)
                    setTokenBalance(balance.toString() / Math.pow(10, decimals))
                    setTokenDecimals(decimals)
                } else {
                    const n = new Multicall3(selectToken.chain_id, provider)
                    const n1 = NewMulticall3Contract(selectToken.address, tokenAbi)
                    const res = await n.call([n1.encode("decimals", []), n1.encode("balanceOf", [account])])
                    if (res[0].success && res[1].success) {
                        const decimals = parseInt(res[0].result[0])
                        const balance = res[1].result[0]
                        setTokenBalance(balance.toString() / Math.pow(10, decimals))
                        setTokenDecimals(decimals)
                    }
                }
            } catch (e) {

            }
            setLoading(false)
        } else {
            // setTokenBalance(null)
            // setTokenDecimals(18)
        }
    }

    // const handleInputChange = (val) => {
    // setInputText(val);
    // props.onInput && props.onInput(val)
    // };

    // const debouncedHandleInputChange = debounce(handleInputChange, 500);


    return <>
        <div className="full-width div-row-center">
            {props.topLeftText && <div className="full-width" style={{ textAlign: 'left', marginBottom: '5px', marginTop: '5px', fontSize: '13px', flex: 1 }}>
                {props.topLeftText || ''}
            </div>}
            <div onClick={() => {
                try {
                    const numericValue = convertNumberToString(tokenBalance)
                    setInputText(numericValue);
                    props.onInput && props.onInput(numericValue)
                } catch (e) {
                    console.log(e)
                }

            }} className="full-width" style={{ textAlign: 'right', marginBottom: '5px', marginTop: '5px', fontSize: '13px', flex: 1, cursor: 'pointer' }}>
                {t('余额')}: {loading ? <>
                    <Spinner style={{ width: '10px', height: '10px' }} />
                </> : <span>
                    {tokenBalance ? converToNumberString(tokenBalance) : '0'}
                </span>}
            </div>
        </div>

        <div className="div-row-center">
            <SearchModal chainIdList={chainIdList} show={showTokenSeaarchModal} handleClose={() => {
                setShowTokenSeaarchModal(false)
            }} onSelectItem={item => {
                setSelectToken(item)
                setShowTokenSeaarchModal(false)
                props.onSelect && props.onSelect(item)

            }} />

            <div style={{ flex: 2, height: '44px', }}>
                <div style={{ fontSize: '15px', fontWeight: 'bold', backgroundColor: '#000000', borderRadius: '7px' }}>
                    <InputGroup>
                        <Form.Control value={inputText} className="form-control-shadow-outline-none"
                            onFocus={e => {
                                props.onFocus && props.onFocus()
                            }}
                            onChange={e => {
                                const inputValue = e.target.value;
                                const numericValue = inputValue.replace(/[^0-9.]/g, ''); // 仅保留数字和小数点

                                setInputText(numericValue);
                                props.onInput && props.onInput(numericValue)
                                // debouncedHandleInputChange(numericValue);
                            }} />
                        <InputGroup.Text className="div-row-center">
                            {props.rightBtn ? <div className="div-row-center" style={{ fontSize: '15px', fontWeight: 'bold', height: '100%', borderLeft: '0.3px solid #a1a0a7', paddingLeft: '15px', cursor: 'pointer' }}>
                                {props.rightBtn}
                            </div> : <span onClick={e => {
                                setShowTokenSeaarchModal(true)
                            }} className="div-row-center" style={{ fontSize: '15px', fontWeight: 'bold', height: '100%', borderLeft: '0.3px solid #a1a0a7', paddingLeft: '15px', cursor: 'pointer' }}>
                                <Logo size="1.8" logo={selectToken?.logo || ''} chain={selectToken?.chain_id || 0} />
                                <div style={{ marginLeft: '10px', fontSize: '15px', fontWeight: 'bold', maxWidth: '3rem', overflow: 'hidden' }}>
                                    {selectToken?.symbol || t('请选择')}
                                </div>
                                <div>
                                    <i style={{ fontSize: '20px' }} className="mdi mdi-menu-down"></i>
                                </div>
                            </span>}

                        </InputGroup.Text>
                    </InputGroup>
                </div>
            </div>
        </div>
    </>
}

export const initProvider = () => {
    return new ethers.providers.Web3Provider(window.ethereum)
}

export const AddLiquidityModal = (props) => {
    const [errMsg, setErrMsg] = useState('')
    // const progress = (props.currentStep / props.total) * 100;
    const [token0, setToken0] = useState(null) // 选择的代币
    const [token1, setToken1] = useState(null) // 选择的代币
    const [token0Input, setToken0Input] = useState('') // token0数量
    const [token1Input, setToken1Input] = useState('') // token1数量
    const [currentPair, setCurrentPair] = useState('') // 当前交易对
    const [currentStep, setCurrentStep] = useState(0)
    const [swap, setSwap] = useState(null) // 选择的交易所
    const { t } = useTranslation()

    const account = useSelector(state => state.account)

    const token0Decimals = useRef(18)
    const token1Decimals = useRef(18)

    const [createPairLoading, setCreatePairLoading] = useState(false)
    const [approveLoading, setApproveLoading] = useState(false)
    const [addLiquidityLoading, setAddLiquidityLoading] = useState(false)

    useEffect(() => {
        setToken0(props.token0)
        setToken1(props.token1)
        setToken0Input(props.token0Input)
        setToken1Input(props.token1Input)
        setCurrentPair(props.currentPair)
        setSwap(props.swap)

    }, [props])

    useEffect(() => {
        if (!props.show) return
        setCurrentStep(1)
        // if (currentPair === zeroAddress || currentPair === '') {
        //     setCurrentStep(1)
        // } else {
        //     setCurrentStep(2)
        // }
    }, [currentPair, props.show])



    const createPair = async () => {
        if (!swap) return
        if (!token0) return
        if (!token1) return
        setErrMsg('')
        const provider = initProvider()
        const signer = provider.getSigner()
        const _factory = new ethers.Contract(swap.factory, FactoryAbi, signer)
        setCreatePairLoading(true)
        try {
            const tx = await _factory.createPair(token0.isWETH ? swap.weth : token0.address, token1.isWETH ? swap.weth : token1.address)
            await tx.wait()

            const pair = await _factory.getPair(token0.address, token1.address)
            setCurrentPair(pair)
        } catch (e) {
            handleErrMsg(e)
        }

        setCreatePairLoading(false)
    }

    const handleErrMsg = (e) => {
        let text = JSON.stringify(e)
        if (e.reason) {
            text = e.reason
        }
        setErrMsg(text)
    }

    const approve = async () => {
        setErrMsg('')
        if (!swap) return
        const provider = initProvider()
        const signer = provider.getSigner()
        const _router = new ethers.Contract(swap.value, dexAbi, signer)

        setApproveLoading(true)
        try {
            // 授权
            // 获取授权数量
            const n = new Multicall3(swap.chain_id, provider)
            let list = []
            if (!token0.isWETH) {
                const n1 = NewMulticall3Contract(token0.address, tokenAbi)
                list.push(n1.encode("allowance", [account, swap.value]))
                list.push(n1.encode("decimals", []))
            }
            if (!token1.isWETH) {
                const n1 = NewMulticall3Contract(token1.address, tokenAbi)
                list.push(n1.encode("allowance", [account, swap.value]))
                list.push(n1.encode("decimals", []))
            }

            const res = await n.call(list)
            // 判断是否需要授权
            let ind = 0
            let token0Allowance = BigNumber.from('0x00')
            let token1Allowance = BigNumber.from('0x00')
            let t0Decimals = 18
            let t1Decimals = 18
            if (!token0.isWETH) {
                if (res[ind].success) {
                    token0Allowance = res[ind].result[0]
                }
                ind++
                if (res[ind].success) {
                    t0Decimals = parseInt(res[ind].result[0])
                }
                ind++
            }
            if (!token1.isWETH) {
                if (res[ind].success) {
                    token1Allowance = res[ind].result[0]
                }
                ind++
                if (res[ind].success) {
                    t1Decimals = parseInt(res[ind].result[0])
                }
                ind++
            }
            token0Decimals.current = t0Decimals
            token1Decimals.current = t1Decimals
            // 如果授权的高于当前的数量, 则不需要授权
            // 否则调起授权
            let approveFlag = false
            if (!token0.isWETH && token0Allowance.lt(ethers.utils.parseUnits(formatInputAmount(token0Input, t0Decimals), t0Decimals))) {
                // 授权
                const n1 = new ethers.Contract(token0.isWETH ? swap.weth : token0.address, tokenAbi, signer)
                const tx = await n1.approve(swap.value, ethers.utils.parseUnits(formatInputAmount(token0Input, t0Decimals), t0Decimals))
                await tx.wait()
                approveFlag = true
            }
            if (!token1.isWETH && token1Allowance.lt(ethers.utils.parseUnits(formatInputAmount(token1Input, t0Decimals), t1Decimals))) {
                // 授权
                const n1 = new ethers.Contract(token1.isWETH ? swap.weth : token1.address, tokenAbi, signer)
                const tx = await n1.approve(swap.value, ethers.utils.parseUnits(formatInputAmount(token1Input, t0Decimals), t1Decimals))
                await tx.wait()
                approveFlag = true
            }
            if (approveFlag) {
                approve()
            } else {
                setCurrentStep(2)
            }
        } catch (e) {
            handleErrMsg(e)
        }
        setApproveLoading(false)

    }

    const addLiquidity = async () => {
        setErrMsg('')
        const provider = initProvider()
        const router = new ethers.Contract(swap.value, dexAbi, provider.getSigner())
        const deadline = Date.parse(new Date()) / 1000 + 60 * 20
        let isWETH = token0.isWETH || token1.isWETH
        let x = null
        setAddLiquidityLoading(true)
        try {
            if (isWETH) {
                const tokenAddress = token0.isWETH ? token1.address : token0.address
                const tokenInput = token0.isWETH ? token1Input : token0Input
                const currencyInput = token0.isWETH ? token0Input : token1Input
                const tokenDecimals = token0.isWETH ? token1Decimals.current : token0Decimals.current
                x = await router.addLiquidityETH(
                    tokenAddress,
                    ethers.utils.parseUnits(tokenInput + '', parseInt(tokenDecimals)),
                    ethers.utils.parseUnits('0', 0),
                    ethers.utils.parseUnits('0', 0),
                    account,
                    ethers.utils.parseUnits(deadline + '', 0),
                    {
                        value: ethers.utils.parseEther(currencyInput + ''),
                    }
                )
            } else {
                x = await router.addLiquidity(
                    token0.address,
                    token1.address,
                    ethers.utils.parseUnits(token0Input + '', parseInt(token0Decimals.current)),
                    ethers.utils.parseUnits(token1Input + '', parseInt(token1Decimals.current)),
                    ethers.utils.parseUnits('0', 0),
                    ethers.utils.parseUnits('0', 0),
                    account,
                    ethers.utils.parseUnits(deadline + '', 0)
                )
            }
            if (x) {
                const xx = await x.wait()
                props.onSuccess && props.onSuccess()
            }
        } catch (e) {
            handleErrMsg(e)
        }
        setAddLiquidityLoading(false)

    }

    useEffect(() => {
        if (currentStep === 1) {
            // 创建流动池
            // createPair()
            console.log('调用授权')
            approve()
        }
        if (currentStep === 2) {
            // 授权
            // approve()
            console.log('调用加池子')
            addLiquidity()
        }
        if (currentStep === 3) {
            // 添加流动性
            // addLiquidity()
        }
    }, [currentStep])

    return <Modal centered contentClassName="search-modal" show={props.show} onHide={() => {
        props.handleClose && props.handleClose()
    }} >
        <Modal.Body>
            <div>
                <div className="login-header">
                    <div style={{ 'width': '100%' }}>
                        <div className="stake-modal-bar">
                            {/* <div className={"stake-modal-bar-item-box " + (currentStep >= 1 ? 'stake-modal-bar-item-box-success' : '')}>
                                <div className="stake-modal-bar-item">1</div>
                                <div className="stake-modal-bar-item-title"> {t('创建流动池')}</div>
                            </div>
                            <div className={"stake-modal-bar-item-solid " + (currentStep > 1 ? 'stake-modal-bar-item-solid-success' : '')}></div> */}
                            <div className={"stake-modal-bar-item-box " + (currentStep >= 1 ? 'stake-modal-bar-item-box-success' : '')}>
                                <div className="stake-modal-bar-item">1</div>
                                <div className="stake-modal-bar-item-title"> {t('授权')}</div>
                            </div>
                            <div className={"stake-modal-bar-item-solid " + (currentStep > 1 ? 'stake-modal-bar-item-solid-success' : '')}></div>
                            <div className={"stake-modal-bar-item-box " + (currentStep >= 2 ? 'stake-modal-bar-item-box-success' : '')}>
                                <div className="stake-modal-bar-item">2</div>
                                <div className="stake-modal-bar-item-title"> {t('添加流动性')}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {errMsg && <div className="mt-4">
                        <Alert variant="danger">
                            <div>
                                {errMsg}
                            </div>
                        </Alert>
                    </div>}
                    {/* {currentStep === 1 && <div className="full-width mt-4">
                        {createPairLoading && <div className="div-col-center">
                            <Spinner />
                        </div>}
                        <div className="div-col-center mt-4">
                            {createPairLoading ? <div className="">
                                正在创建流动池...
                            </div> : <div className="div-row-center">
                                <div onClick={() => {
                                    createPair()
                                }} className="btn btn-primary text-light">
                                    创建流动池
                                </div>
                                <div style={{ marginLeft: '5px' }} onClick={() => {
                                    props.onHide && props.onHide()
                                }} className="btn btn-danger text-light">
                                    关闭
                                </div>
                            </div>}
                        </div>
                    </div>} */}
                    {currentStep === 1 && <div className="full-width mt-4">
                        {approveLoading && <div className="div-col-center">
                            <Spinner />
                        </div>}
                        <div className="div-col-center mt-4">
                            {approveLoading ? <div >
                                {t('授权中...')}
                            </div> : <div className="div-row-center">
                                <div onClick={() => {
                                    approve()
                                }} className="btn btn-primary text-light">
                                    {t('授权')}
                                </div>
                                <div style={{ marginLeft: '5px' }} onClick={() => {
                                    props.onHide && props.onHide()
                                }} className="btn btn-danger text-light">
                                    {t('关闭')}
                                </div>
                            </div>
                            }
                        </div>
                    </div>}
                    {currentStep === 2 && <div className="full-width mt-4">
                        {addLiquidityLoading && <div className="div-col-center">
                            <Spinner />
                        </div>}
                        <div className="div-col-center mt-4">
                            {addLiquidityLoading ? <div >
                                {t('正在添加流动性...')}
                            </div> : <div className="div-row-center">
                                <div onClick={() => {
                                    addLiquidity()
                                }} className="btn btn-primary text-light">
                                    {t('添加流动性')}
                                </div>
                                <div style={{ marginLeft: '5px' }} onClick={() => {
                                    props.onHide && props.onHide()
                                }} className="btn btn-danger text-light">
                                    {t('关闭')}
                                </div>
                            </div>}
                        </div>
                    </div>}

                </div>
            </div>
        </Modal.Body>
    </Modal>
}


export const RemoveLiquidityModal = (props) => {
    const [errMsg, setErrMsg] = useState('')
    // const progress = (props.currentStep / props.total) * 100;
    const [token0, setToken0] = useState(null) // 选择的代币
    const [token1, setToken1] = useState(null) // 选择的代币
    const [removeAmountInput, setRemoveAmountInput] = useState('') // 移除数量
    const [pairInfo, setPairInfo] = useState(null) // 当前交易对
    const [currentStep, setCurrentStep] = useState(0)
    const [receiveWETH, setReceiveWETH] = useState(false) // 是否接收WETH
    const [swap, setSwap] = useState(null) // 选择的交易所
    const { t } = useTranslation()

    const account = useSelector(state => state.account)

    const token0Decimals = useRef(18)

    const [approveLoading, setApproveLoading] = useState(false)
    const [addLiquidityLoading, setAddLiquidityLoading] = useState(false)

    useEffect(() => {
        setToken0(props.token0)
        setToken1(props.token1)
        setRemoveAmountInput(props.removeAmountInput)
        setPairInfo(props.pairInfo)
        setSwap(props.swap)
        setReceiveWETH(props.receiveWETH)

    }, [props])

    useEffect(() => {
        if (!props.show) return
        setCurrentStep(1)
    }, [pairInfo, props.show])




    const handleErrMsg = (e) => {
        let text = JSON.stringify(e)
        if (e.reason) {
            text = e.reason
        }
        setErrMsg(text)
    }

    const approve = async () => {
        setErrMsg('')
        if (!swap) return
        const provider = initProvider()
        const signer = provider.getSigner()
        const _router = new ethers.Contract(swap.value, dexAbi, signer)

        setApproveLoading(true)
        try {
            // 授权
            // 获取授权数量
            const n = new Multicall3(swap.chain_id, provider)
            let list = []
            const n1 = NewMulticall3Contract(pairInfo.address, tokenAbi)
            list.push(n1.encode("allowance", [account, swap.value]))
            list.push(n1.encode("decimals", []))


            const res = await n.call(list)
            // 判断是否需要授权
            let ind = 0
            let tokenAllowance = res[0].result[0]
            let tokenDecimals = parseInt(res[1].result[0])

            token0Decimals.current = tokenDecimals
            // 如果授权的高于当前的数量, 则不需要授权
            // 否则调起授权
            let approveFlag = false
            if (tokenAllowance.lt(ethers.utils.parseUnits(removeAmountInput + '', tokenDecimals))) {
                // 授权
                const n1 = new ethers.Contract(pairInfo.address, tokenAbi, signer)
                const tx = await n1.approve(swap.value, ethers.utils.parseUnits(removeAmountInput + '', tokenDecimals))
                await tx.wait()
                approveFlag = true
            }

            if (approveFlag) {
                approve()
            } else {
                setCurrentStep(2)
            }
        } catch (e) {
            handleErrMsg(e)
        }
        setApproveLoading(false)

    }

    const removeLiquidity = async () => {
        setErrMsg('')
        const provider = initProvider()
        const router = new ethers.Contract(swap.value, dexAbi, provider.getSigner())
        const deadline = Date.parse(new Date()) / 1000 + 60 * 20
        let x = null
        setAddLiquidityLoading(true)
        let removeInput = ethers.utils.parseUnits(removeAmountInput + '', parseInt(pairInfo.decimals))
        // 移除的数量高于余额, 那么移除的数量就设置为余额
        if (removeInput.gt(pairInfo.balance)) {
            removeInput = pairInfo.balance
        }
        try {
            if (receiveWETH || (!token0.isWETH && !token1.isWETH)) {
                const token0Address = token0.isWETH ? swap.weth : token0.address
                const token1Address = token1.isWETH ? swap.weth : token1.address
                x = await router.removeLiquidity(
                    token0Address,
                    token1Address,
                    removeInput,
                    ethers.utils.parseUnits('0', 0),
                    ethers.utils.parseUnits('0', 0),
                    account,
                    ethers.utils.parseUnits(deadline + '', 0)
                )
            } else {
                const tokenAddress = token0.isWETH ? token1.address : token0.address
                x = await router.removeLiquidityETH(
                    tokenAddress,
                    removeInput,
                    ethers.utils.parseUnits('0', 0),
                    ethers.utils.parseUnits('0', 0),
                    account,
                    ethers.utils.parseUnits(deadline + '', 0)
                )
            }
            if (x) {
                const xx = await x.wait()
                props.onSuccess && props.onSuccess()
            }
        } catch (e) {
            console.log(e)
            handleErrMsg(e)
        }
        setAddLiquidityLoading(false)

    }

    useEffect(() => {
        if (currentStep === 1) {
            // 授权
            approve()
        }
        if (currentStep === 2) {
            // 移除流动性
            removeLiquidity()
        }

    }, [currentStep])

    return <Modal centered contentClassName="search-modal" show={props.show} onHide={() => {
        props.handleClose && props.handleClose()
    }} >
        <Modal.Body>
            <div>
                <div className="login-header">
                    <div style={{ 'width': '100%' }}>
                        <div className="stake-modal-bar">
                            <div className={"stake-modal-bar-item-box " + (currentStep >= 1 ? 'stake-modal-bar-item-box-success' : '')}>
                                <div className="stake-modal-bar-item">1</div>
                                <div className="stake-modal-bar-item-title"> {t('授权')}</div>
                            </div>
                            <div className={"stake-modal-bar-item-solid " + (currentStep > 1 ? 'stake-modal-bar-item-solid-success' : '')}></div>
                            <div className={"stake-modal-bar-item-box " + (currentStep >= 2 ? 'stake-modal-bar-item-box-success' : '')}>
                                <div className="stake-modal-bar-item">2</div>
                                <div className="stake-modal-bar-item-title"> {t('移除流动性')}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {errMsg && <div className="mt-4">
                        <Alert variant="danger">
                            <div>
                                {errMsg}
                            </div>
                        </Alert>
                    </div>}
                    {currentStep === 1 && <div className="full-width mt-4">
                        {approveLoading && <div className="div-col-center">
                            <Spinner />
                        </div>}
                        <div className="div-col-center mt-4">
                            {approveLoading ? <div className="">
                                {t('授权中...')}
                            </div> : <div className="div-row-center">
                                <div onClick={() => {
                                    approve()
                                }} className="btn btn-primary text-light">
                                    {t('授权')}
                                </div>
                                <div style={{ marginLeft: '5px' }} onClick={() => {
                                    props.onHide && props.onHide()
                                }} className="btn btn-danger text-light">
                                    {t('关闭')}
                                </div>
                            </div>}
                        </div>
                    </div>}
                    {currentStep === 2 && <div className="full-width mt-4">
                        {addLiquidityLoading && <div className="div-col-center">
                            <Spinner />
                        </div>}
                        <div className="div-col-center mt-4">
                            {addLiquidityLoading ? <div >
                                {t('正在移除流动性...')}
                            </div> : <div className="div-row-center">
                                <div onClick={() => {
                                    removeLiquidity()
                                }} className="btn btn-primary text-light">
                                    {t('移除流动性')}
                                </div>
                                <div style={{ marginLeft: '5px' }} onClick={() => {
                                    props.onHide && props.onHide()
                                }} className="btn btn-danger text-light">
                                    {t('关闭')}
                                </div>
                            </div>
                            }
                        </div>
                    </div>}
                </div>
            </div>
        </Modal.Body>
    </Modal>
}


export const AddLiquidityBox = (props) => {

    const [errorMsg, setErrorMsg] = useState('')
    const [selectedSwap, setSelectedSwap] = useState(null) // 选择的交易所

    const [token0, setToken0] = useState(null) // 选择的代币
    const [token1, setToken1] = useState(null) // 选择的代币
    const [token0Input, setToken0Input] = useState('') // token0数量
    const [token1Input, setToken1Input] = useState('') // token1数量
    const [currentPair, setCurrentPair] = useState('') // 当前交易对
    const [showAddLiquidityModal, setShowAddLiquidityModal] = useState(false) // 显示添加流动性的弹窗
    const focusType = useRef('token0')
    const [initLoading, setInitLoading] = useState(false)
    const [pairInfo, setPairInfo] = useState({
        token0: {
            balance: BigNumber.from('0x00'),
        },
        token1: {
            balance: BigNumber.from('0x00'),
        }
    }) // 当前交易对信息
    const [tokenBalanceAndAllowance, setTokenBalanceAndAllowance] = useState({
        token0: {
            balance: BigNumber.from('0x00'),
            allowance: BigNumber.from('0x00'),
            decimals: 18,
        },
        token1: {
            balance: BigNumber.from('0x00'),
            allowance: BigNumber.from('0x00'),
            decimals: 18
        }
    }) // 代币余额和授权情况

    const account = useSelector(state => state.account)
    const chainId = useSelector(state => state.chainId)
    useEffect(() => {
        setErrorMsg('')
        if (selectedSwap) {
            // 判断当前连接的网络是否和交易所的网络一致
            if (selectedSwap.chain_id && selectedSwap.chain_id !== chainId) {
                setErrorMsg(t('当前连接的网络与选择的交易所的网络不一致'))
            }

        }
    }, [selectedSwap, chainId])

    const propsSwapInfo = useRef({})
    useEffect(() => {
        if (props.tokenInfo?.chain_id && props.pair?.swap_factory) {
            const d = {
                chain_id: props.tokenInfo?.chain_id,
                factory: props.pair?.swap_factory
            }
            if (JSON.stringify(d) !== JSON.stringify(propsSwapInfo.current)) {
                setSelectedSwap(d)
                propsSwapInfo.current = d
            }

        }
        if (props.tokenInfo) {

            setToken1({
                ...props.tokenInfo,
                isWETH: false
            })

            const findToken0Logic = async () => {

                // 寻找第一个isWETH为true的代币
                const n = NETWORK_LIST.find(item => parseInt(item.chainId) === parseInt(props.tokenInfo.chain_id))
                if (n && n.commonTokens && n.commonTokens.length > 0) {
                    const weth = n.commonTokens.find(item => item.isWETH)
                    let currencyInfo = {
                        ...weth,
                        chain_id: parseInt(props.tokenInfo.chain_id),
                    }
                    if (props.pair) {
                        let currency = ''
                        if (props.pair.token0.toLowerCase() === props.tokenInfo.address.toLowerCase()) {
                            // token1是另一个地址
                            currency = props.pair.token1.toLowerCase()
                        }
                        if (props.pair.token1.toLowerCase() === props.tokenInfo.address.toLowerCase()) {
                            // token1是另一个地址
                            currency = props.pair.token0.toLowerCase()
                        }
                        if (currency && currency !== weth.address.toLowerCase()) { // 不是bnb
                            // 获取远程数据
                            const { data } = await getTokenDetail({
                                chain: props.tokenInfo.chain_name,
                                contract: currency
                            })
                            if (data.code === 200 && data.data) {
                                currencyInfo = {
                                    ...data.data,
                                    isWETH: false
                                }
                            }
                        }


                    }
                    setToken0({
                        ...currencyInfo
                    })

                }
            }

            findToken0Logic().catch(e => {
                console.log('获取底池信息失败', e)
            })



        }
    }, [props])

    useEffect(() => {
        const d = {
            factory: props.urlParams?.factory,
            chain_id: parseInt(props.urlParams?.chain_id),
            name: props.urlParams?.name,
            value: props.urlParams?.router
        }
        if (d.chain_id) {
            setSelectedSwap(d)
        }
        // 获取token0和token1的信息
        // findTokenLogic()
        if (props.urlParams?.token0 || props.urlParams?.token1) {
            if (props.urlParams?.token0) {
                findTokenFromCommonTokens(parseInt(props.urlParams?.chain_id), props.urlParams?.token0)
                    .then(token0Info => {
                        setToken0(token0Info)
                    })
            } else {
                const weth = findWETHTokenFromCommonTokens(parseInt(props.urlParams?.chain_id))
                setToken0(weth)
            }
            if (props.urlParams?.token1) {
                findTokenFromCommonTokens(parseInt(props.urlParams?.chain_id), props.urlParams?.token1)
                    .then(token1Info => {
                        setToken1(token1Info)
                    })
            } else {
                const weth = findWETHTokenFromCommonTokens(parseInt(props.urlParams?.chain_id))
                setToken1(weth)
            }
        }


    }, [props.urlParams, account])

    useEffect(() => {
        if (selectedSwap?.value && account && token0?.symbol && token1?.symbol && account) {

            if (window.ethereum && selectedSwap.chain_id === chainId && selectedSwap.chain_id === token0.chain_id && selectedSwap.chain_id === token1.chain_id) {

                getTokenBalanceAndAllowance()
            }
        }
    }, [selectedSwap, token0, token1, chainId, account])

    const getTokenBalanceAndAllowance = async () => {
        setErrorMsg('')

        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const n = new Multicall3(chainId, provider)
        setInitLoading(true)
        let list = []
        let token0Address = ''
        let token1Address = ''
        try {
            if (token0.isWETH) {
                list.push(n.getEthBalance(account))
                list.push(n.getEthBalance(account))
                list.push(n.getEthBalance(account))
                token0Address = selectedSwap.weth
            } else {
                const n1 = NewMulticall3Contract(token0.address, tokenAbi)
                list.push(n1.encode("balanceOf", [account]))
                list.push(n1.encode("decimals", []))
                // 授权
                list.push(n1.encode("allowance", [account, selectedSwap.value]))
                token0Address = token0.address
            }
            if (token1.isWETH) {
                list.push(n.getEthBalance(account))
                list.push(n.getEthBalance(account))
                list.push(n.getEthBalance(account))
                token1Address = selectedSwap.weth
            } else {
                const n1 = NewMulticall3Contract(token1.address, tokenAbi)
                list.push(n1.encode("balanceOf", [account]))
                list.push(n1.encode("decimals", []))
                // 授权
                list.push(n1.encode("allowance", [account, selectedSwap.value]))
                token1Address = token1.address
            }

            // 获取交易对, 然后获取交易对中两种代币的余额
            const n2 = NewMulticall3Contract(selectedSwap.factory, FactoryAbi)


            list.push(n2.encode("getPair", [token0Address, token1Address]))

            const res = await n.call(list)
            let balance = {
                token0: {
                    balance: BigNumber.from('0x00'),
                    allowance: BigNumber.from('0x00'),
                    decimals: 18
                },
                token1: {
                    balance: BigNumber.from('0x00'),
                    allowance: BigNumber.from('0x00'),
                    decimals: 18
                }
            }

            if (token0.isWETH) {
                balance.token0.balance = res[0].success ? res[0].result[0] : BigNumber.from('0x00')
                balance.token0.allowance = BigNumber.from('0x00')
            } else {
                balance.token0.balance = res[0].success ? res[0].result[0] : BigNumber.from('0x00')
                balance.token0.decimals = res[1].success ? parseInt(res[1].result[0]) : 18
                balance.token0.allowance = res[2].success ? res[2].result[0] : BigNumber.from('0x00')

            }
            if (token1.isWETH) {
                balance.token1.balance = res[3].success ? res[3].result[0] : BigNumber.from('0x00')
                balance.token1.allowance = BigNumber.from('0x00')

            } else {
                balance.token1.balance = res[3].success ? res[3].result[0] : BigNumber.from('0x00')
                balance.token1.decimals = res[4].success ? parseInt(res[4].result[0]) : 18
                balance.token1.allowance = res[5].success ? res[4].result[5] : BigNumber.from('0x00')
            }

            // 获取交易对
            const pairAddress = res[6].success ? res[6].result[0] : zeroAddress
            setCurrentPair(pairAddress)
            setTokenBalanceAndAllowance(balance)
            if (pairAddress !== zeroAddress) {
                // 存在pair, 获取当前二者的余额
                const n0 = NewMulticall3Contract(token0Address, tokenAbi)
                const n1 = NewMulticall3Contract(token1Address, tokenAbi)
                const l2 = [
                    n0.encode("balanceOf", [pairAddress]),
                    n1.encode("balanceOf", [pairAddress]),
                ]
                const res2 = await n.call(l2)
                setPairInfo({
                    token0: {
                        balance: res2[0].success ? res2[0].result[0] : BigNumber.from('0x00'),
                        decimals: balance.token0.decimals
                    },
                    token1: {
                        balance: res2[1].success ? res2[1].result[0] : BigNumber.from('0x00'),
                        decimals: balance.token1.decimals
                    }
                })
            }
        } catch (e) {
            console.log(e)
        }
        setInitLoading(false)
    }

    useEffect(() => {
        if (pairInfo.token0.balance.gt(BigNumber.from('0x00')) && pairInfo.token1.balance.gt(BigNumber.from('0x00')) && currentPair) {
            // 余额大于0, 说明已经添加过流动性了
            // 根据输入的数据, 自动计算对应的比例
            const token0Balance = pairInfo.token0.balance.toString() / Math.pow(10, pairInfo.token0.decimals)
            const token1Balance = pairInfo.token1.balance.toString() / Math.pow(10, pairInfo.token1.decimals)

            if (focusType.current === 'token0') {
                // 变更token1Input的值
                if (token0Input) {

                    //======
                    // token1Balance    token1Input
                    // ------------  =  -----------
                    // token0Balance     token0Input
                    //
                    // 比例不变
                    const x = parseFloat(token0Input) * (token1Balance / token0Balance)


                    setToken1Input(convertNumberToString(x))
                } else {
                    setToken1Input('')
                }
            } else {
                // 变更token0Input的值
                if (token1Input) {
                    const x = parseFloat(token1Input) * (token0Balance / token1Balance)

                    setToken0Input(convertNumberToString(x))
                } else {
                    setToken0Input('')
                }

            }
        }
    }, [pairInfo, currentPair, token0Input, token1Input, token0, token1])

    return <div style={{ minHeight: '700px' }}>
        <AddLiquidityModal
            token0={token0}
            token1={token1}
            swap={selectedSwap}
            token0Input={token0Input}
            token1Input={token1Input}
            currentPair={currentPair}
            show={showAddLiquidityModal} onHide={() => {
                setShowAddLiquidityModal(false)
            }}
            onSuccess={() => {
                toast.success('添加成功')
                getTokenBalanceAndAllowance()
                setShowAddLiquidityModal(false)
            }}
        />
        <div>
            <ChooseExchange
                chainId={selectedSwap?.chain_id}
                router={selectedSwap?.value}
                factory={selectedSwap?.factory}
                name={selectedSwap?.name}
                fullWidth={props.fullWidth}
                onSwapChange={swap => {
                    console.log('ppppp222333', swap)
                    setSelectedSwap(swap)
                }} />
        </div>
        <div className="row div-col-center mt-2">
            <div className={props.fullWidth ? 'col-12 mt-2' : `col-12 col-md-8 col-lg-6 col-xxl-6 col-xl-6 col-xs-10 mt-2`} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <div style={{ borderBottom: '0.5px solid #a1a0a7', marginBottom: '1rem', opacity: '0.4' }} >  </div>
                {/* <div className="add-lp-token-select-box "> */}
                <div>
                    <TokenSelectAndSetValueRow token={token0} value={token0Input} balance={tokenBalanceAndAllowance.token0['balance'].toString() / Math.pow(10, tokenBalanceAndAllowance.token0['decimals'])} onInput={e => {
                        setToken0Input(e)
                    }} onFocus={e => {
                        focusType.current = 'token0'
                    }} onSelect={token => {
                        setToken0(token)
                    }} chains={selectedSwap?.chain_id ? [selectedSwap.chain_id] : NETWORK_LIST.map(item => item.chainId)} />
                    <div style={{ marginBottom: '0rem' }} className="div-col-center mt-4">
                        <i className="fa fa-plus"></i>
                    </div>

                    <TokenSelectAndSetValueRow token={token1} value={token1Input} balance={tokenBalanceAndAllowance.token1['balance'].toString() / Math.pow(10, tokenBalanceAndAllowance.token1['decimals'])} onInput={e => {
                        setToken1Input(e)
                    }} onFocus={e => {
                        focusType.current = 'token1'
                    }} onSelect={token => {
                        setToken1(token)
                    }} chains={selectedSwap?.chain_id ? [selectedSwap.chain_id] : NETWORK_LIST.map(item => item.chainId)} />
                </div>
                <div style={{ borderBottom: '0.5px solid #a1a0a7', marginBottom: '1rem', marginTop: '1rem', opacity: '0.4' }} >  </div>
                {errorMsg &&
                    <div className="full-width ">
                        <Alert variant="warning" >
                            {errorMsg}
                        </Alert>
                    </div>}
            </div>

        </div>

        <div className="row div-col-center mt-2">
            <div style={{ paddingLeft: '10px', paddingRight: '10px' }} className={props.fullWidth ? `col-12 mt-2` : `col-12 col-md-8 col-lg-6 col-xxl-6 col-xl-6 col-xs-10 mt-2`}>
                <div className="full-width div-row-center liq-box">
                    {currentPair === zeroAddress && <div className="liq-box-left">
                        {t('首次添加流动性')} !
                    </div>}
                </div>
                {parseFloat(token0Input) > 0 && parseFloat(token1Input) > 0 && token0?.symbol && token1?.symbol && <>
                    <div className="full-width div-row-center liq-box">
                        <div className="liq-box-left">
                            {token0?.symbol} Per {token1?.symbol}
                        </div>
                        <div className="liq-box-right">
                            {(currentPair === zeroAddress || (parseInt(pairInfo.token0.balance.toString()) === 0) || (parseInt(pairInfo.token1.balance.toString()) === 0)) ? `${converToNumberString((parseFloat(token0Input) / parseFloat(token1Input)))}` : `${converToNumberString(pairInfo.token0.balance.toString() / Math.pow(10, pairInfo.token0.decimals) / (pairInfo.token1.balance.toString() / Math.pow(10, pairInfo.token1.decimals)))}`}
                        </div>
                    </div>
                    <div className="full-width div-row-center liq-box">
                        <div className="liq-box-left">
                            {token1?.symbol} Per {token0?.symbol}
                        </div>
                        <div className="liq-box-right">
                            {(currentPair === zeroAddress || (parseInt(pairInfo.token0.balance.toString()) === 0) || (parseInt(pairInfo.token1.balance.toString()) === 0)) ? `${converToNumberString((parseFloat(token1Input) / parseFloat(token0Input)))}` : `${converToNumberString((pairInfo.token1.balance.toString() / Math.pow(10, pairInfo.token1.decimals)) / (pairInfo.token0.balance.toString() / Math.pow(10, pairInfo.token0.decimals)))}`}
                        </div>
                    </div>
                </>}
                {currentPair && token0Input && pairInfo.token0.decimals && <div className="full-width div-row-center liq-box">
                    <div className="liq-box-left">
                        Share Of Pool
                    </div>
                    <div className="liq-box-right">
                        {((currentPair === zeroAddress) ||
                            (parseInt(pairInfo.token0.balance.toString()) === 0) ||
                            (parseInt(pairInfo.token1.balance.toString()) === 0)) ? <div>
                            100%
                        </div> : <div>
                            {((parseFloat(token0Input) / parseFloat(pairInfo.token0.balance.toString() / Math.pow(10, pairInfo.token0.decimals)) * 100)).toFixed(2)}%
                        </div>}
                        {/* {currentPair !== zeroAddress && <div>
                            
                        </div>} */}
                    </div>
                </div>}

            </div>

        </div>
        <div className="row div-col-center div-col-center mt-2">
            {chainId === 0 ? <div className="full-width div-col-center mt-2">
                <Button onClick={() => {
                    eventBus.emit('request_login')
                }} type="primary" className="text-light">{t('请先登录')}</Button>
            </div> : <>
                <div className={props.fullWidth ? `div-row-center col-12 mt-2` : `div-row-center col-12 col-md-8 col-lg-6 col-xxl-6 col-xl-6 col-xs-10 mt-2`} style={{ justifyContent: 'center' }}>
                    <Button disabled={initLoading} onClick={async () => {
                        setErrorMsg('')
                        console.log(selectedSwap, token0, token1, 'sssselectswap')
                        if (!selectedSwap || !selectedSwap.value) {
                            setErrorMsg(t('请选择交易所'))
                            return
                        }
                        if (!account) {
                            setErrorMsg(t('请先连接钱包'))
                            return
                        }
                        if (selectedSwap.chain_id !== chainId) {
                            setErrorMsg(t('当前连接的网络与选择的交易所的网络不一致'))
                            return
                        }
                        // 判断是否选择的代币
                        if (!token0?.symbol || !token1?.symbol) {
                            setErrorMsg(t('请选择代币'))
                            return
                        }

                        // 判断是否输入了值
                        if (!token0Input || !token1Input) {
                            setErrorMsg(t('请输入要添加的代币数量'))
                            return
                        }

                        await getTokenBalanceAndAllowance()

                        const token0Amount = ethers.utils.parseUnits(formatInputAmount(token0Input, tokenBalanceAndAllowance.token0.decimals), tokenBalanceAndAllowance.token0.decimals)
                        const token1Amount = ethers.utils.parseUnits(formatInputAmount(token1Input, tokenBalanceAndAllowance.token1.decimals), tokenBalanceAndAllowance.token1.decimals)
                        if (token0Amount.gt(tokenBalanceAndAllowance.token0.balance)) {
                            // setErrorMsg(token0.symbol + '余额不足')
                            setErrorMsg(t('{name}余额不足').replace('{name}', token0.symbol))
                            return
                        }
                        if (token1Amount.gt(tokenBalanceAndAllowance.token1.balance)) {
                            // setErrorMsg(token1.symbol + '余额不足')
                            setErrorMsg(t('{name}余额不足').replace('{name}', token1.symbol))
                            return
                        }

                        // 获取余额
                        setShowAddLiquidityModal(true)
                    }} variant="primary text-light">
                        {initLoading && <span style={{ marginRight: '10px', marginLeft: '10px' }}><Spinner className="spinner-sm" style={{ width: '12px', height: '12px' }} /></span>}
                        {t('确认')} </Button>
                </div>
            </>}
        </div>


    </div>
}


const RemoveLiquidityBox = (props) => {

    const [selectedSwap, setSelectedSwap] = useState(null) // 选择的交易所
    const [token0, setToken0] = useState(null) // 选择的代币
    const [token1, setToken1] = useState(null) // 选择的代币
    const [errMsg, setErrMsg] = useState('') // 错误信息
    const [removeAmountInput, setRemoveAmountInput] = useState('') // 移除的数量
    const [receiveWETH, setReceiveWETH] = useState(false) // 是否接收WETH

    const [showRemoveLiquidityModal, setShowRemoveLiquidityModal] = useState(false) // 显示添加流动性的弹窗
    const chainId = useSelector(state => state.chainId)
    const [pairInfo, setPairInfo] = useState({
        token0: {
            Balance: BigNumber.from('0x00'),
            decimals: 18
        },
        token1: {
            Balance: BigNumber.from('0x00'),
            decimals: 18
        },
        balance: BigNumber.from('0x00'),
        decimals: 18,
        totalSupply: BigNumber.from('0x00'),
        address: '' // 流动池地址
    })

    const account = useSelector(state => state.account)

    useEffect(() => {
        if (account) {
            getPairInfo()
        }

    }, [token0, token1, selectedSwap, account])

    const propsSwapInfo = useRef({})
    useEffect(() => {

        if (props.tokenInfo?.chain_id && props.pair?.swap_factory) {
            const d = {
                chain_id: props.tokenInfo?.chain_id,
                factory: props.pair?.swap_factory
            }
            if (JSON.stringify(d) !== JSON.stringify(propsSwapInfo.current)) {
                setSelectedSwap(d)
                propsSwapInfo.current = d
            }

        }

        if (props.tokenInfo) {
            setToken1({
                ...props.tokenInfo,
                isWETH: false
            })


            const findToken0Logic = async () => {
                // 寻找第一个isWETH为true的代币
                const n = NETWORK_LIST.find(item => parseInt(item.chainId) === parseInt(props.tokenInfo.chain_id))
                if (n && n.commonTokens && n.commonTokens.length > 0) {
                    const weth = n.commonTokens.find(item => item.isWETH)
                    let currencyInfo = {
                        ...weth,
                        chain_id: parseInt(props.tokenInfo.chain_id),
                    }
                    if (props.pair) {
                        let currency = ''
                        if (props.pair.token0.toLowerCase() === props.tokenInfo.address.toLowerCase()) {
                            // token1是另一个地址
                            currency = props.pair.token1.toLowerCase()
                        }
                        if (props.pair.token1.toLowerCase() === props.tokenInfo.address.toLowerCase()) {
                            // token1是另一个地址
                            currency = props.pair.token0.toLowerCase()
                        }
                        if (currency && currency !== weth.address.toLowerCase()) { // 不是bnb
                            // 获取远程数据
                            const { data } = await getTokenDetail({
                                chain: props.tokenInfo.chain_name,
                                contract: currency
                            })
                            if (data.code === 200 && data.data) {
                                currencyInfo = {
                                    ...data.data,
                                    isWETH: false
                                }
                            }
                        }

                        setToken0({
                            ...currencyInfo
                        })

                    }
                }
            }

            findToken0Logic().catch(e => {
                console.log('获取底池信息失败', e)
            })


        }
    }, [props, chainId])


    useEffect(() => {
        const d = {
            factory: props.urlParams?.factory,
            chain_id: parseInt(props.urlParams?.chain_id),
            name: props.urlParams?.name,
            value: props.urlParams?.router
        }

        if (d.chain_id) {
            setSelectedSwap(d)
        }

        if (props.urlParams?.token0 || props.urlParams?.token1) {
            // 获取token0和token1的信息
            // findTokenLogic()
            if (props.urlParams?.token0) {
                findTokenFromCommonTokens(parseInt(props.urlParams?.chain_id), props.urlParams?.token0)
                    .then(token0Info => {
                        setToken0(token0Info)
                    })
            } else {
                const weth = findWETHTokenFromCommonTokens(parseInt(props.urlParams?.chain_id))
                setToken0(weth)
            }
            if (props.urlParams?.token1) {
                findTokenFromCommonTokens(parseInt(props.urlParams?.chain_id), props.urlParams?.token1)
                    .then(token1Info => {
                        setToken1(token1Info)
                    })
            } else {
                const weth = findWETHTokenFromCommonTokens(parseInt(props.urlParams?.chain_id))
                setToken1(weth)
            }
        }


    }, [props.urlParams, account])

    const getPairInfo = async () => {
        setErrMsg('')
        if (selectedSwap && selectedSwap.weth && token0 && token1 && token0.symbol && token1.symbol) {
            if (selectedSwap.chain_id !== token0.chain_id) {

                setErrMsg(t('当前连接的网络与选择的交易所的网络不一致'))
                return
            }
            // 获取pair
            const token0Address = token0.isWETH ? selectedSwap.weth : token0.address
            const token1Address = token1.isWETH ? selectedSwap.weth : token1.address
            const provider = initProvider()
            const _factory = new ethers.Contract(selectedSwap.factory, FactoryAbi, provider)
            const pair = await _factory.getPair(token0Address, token1Address)
            if (pair === zeroAddress) {
                setErrMsg(t('未找到交易对'))
                setPairInfo({
                    token0: {
                        Balance: BigNumber.from('0x00'),
                        decimals: 18
                    },
                    token1: {
                        Balance: BigNumber.from('0x00'),
                        decimals: 18
                    },
                    balance: BigNumber.from('0x00'),
                    decimals: 18,
                })
                return
            } else {

                // 获取当前用户持有的余额
                // 交易对中持有两种代币的余额
                // 获取两种代币的精度
                const n1 = NewMulticall3Contract(token0Address, tokenAbi)
                const n2 = NewMulticall3Contract(token1Address, tokenAbi)
                const n3 = NewMulticall3Contract(pair, tokenAbi)
                const list = [
                    n1.encode("balanceOf", [pair]),
                    n2.encode("balanceOf", [pair]),
                    n1.encode("decimals", []),
                    n2.encode("decimals", []),
                    n3.encode("totalSupply", []),
                    n3.encode("decimals", []),
                    n3.encode("balanceOf", [account]),
                ]
                const n = new Multicall3(selectedSwap.chain_id, provider)
                const res = await n.call(list)
                // setCurrentPair(pair)
                const pairInfo = {
                    token0: {
                        balance: res[0].success ? res[0].result[0] : BigNumber.from('0x00'),
                        decimals: res[2].success ? parseInt(res[2].result[0]) : 18
                    },
                    token1: {
                        balance: res[1].success ? res[1].result[0] : BigNumber.from('0x00'),
                        decimals: res[3].success ? parseInt(res[3].result[0]) : 18
                    },
                    balance: res[6].success ? res[6].result[0] : BigNumber.from('0x00'),
                    decimals: res[5].success ? parseInt(res[5].result[0]) : 18,
                    address: pair,
                    totalSupply: res[4].success ? res[4].result[0] : BigNumber.from('0x00')

                }
                setPairInfo(pairInfo)

            }

        }
    }

    const changeRemoveAmountInputPercent = (percent) => {
        const balance = pairInfo.balance.toString() / Math.pow(10, pairInfo.decimals)
        // setRemoveAmountInput(((balance * percent) + '').slice(0, pairInfo.decimals || 18))
        setRemoveAmountInput(formatInputAmount(convertNumberToString((balance * percent)), pairInfo.decimals || 18))
    }

    const getTokenSymbol = (token) => {
        if (!token || !token.symbol) return ''
        let symbol = token.symbol
        if (token.isWETH && receiveWETH) {
            symbol = `W${symbol}`
        }
        return symbol
    }

    const getTokenAmount = (type) => {
        // 计算移除的数量
        if (!removeAmountInput) return 0
        try {
            const totalSupply = pairInfo.totalSupply.toString() / Math.pow(10, pairInfo.decimals)
            let tokenBalance = BigNumber.from('0x00')
            if (type === 'token0') {
                tokenBalance = pairInfo.token0.balance.toString() / Math.pow(10, pairInfo.token0.decimals)
            } else {
                tokenBalance = pairInfo.token1.balance.toString() / Math.pow(10, pairInfo.token1.decimals)
            }

            // 要移除的数量占总共的占比 然后计算出对应的不同代币的数量
            return converToNumberString(parseFloat(removeAmountInput) / parseFloat(totalSupply) * tokenBalance)
        } catch (e) {
            return 0
        }

    }

    return <div style={{ minHeight: '700px' }}>
        <RemoveLiquidityModal
            token0={token0}
            token1={token1}
            swap={selectedSwap}
            removeAmountInput={removeAmountInput}
            pairInfo={pairInfo}
            receiveWETH={receiveWETH}
            show={showRemoveLiquidityModal} onHide={() => {
                setShowRemoveLiquidityModal(false)
            }}
            onSuccess={() => {
                toast.success(t('移除流动性成功'))
                getPairInfo()
                setShowRemoveLiquidityModal(false)
            }}
        />
        <div>
            <ChooseExchange
                chainId={selectedSwap?.chain_id}
                router={selectedSwap?.value}
                factory={selectedSwap?.factory}
                name={selectedSwap?.name}
                fullWidth={props.fullWidth}
                onSwapChange={swap => {
                    setSelectedSwap(swap)
                }} />
        </div>
        <div className="row div-col-center mt-2">
            <div className={props.fullWidth ? `col-12 mt-2` : `col-12 col-md-8 col-lg-6 col-xxl-6 col-xl-6 col-xs-10 mt-2`} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <div style={{ borderBottom: '0.5px solid #a1a0a7', marginBottom: '1rem', opacity: '0.4' }} >  </div>

                <div className="div-row-center" style={{ justifyContent: 'center' }}>
                    <TokenSelect token={token0} onSelect={token => {
                        setToken0(token)
                    }} chains={selectedSwap?.chain_id ? [selectedSwap.chain_id] : NETWORK_LIST.map(item => item.chainId)} />
                    <span style={{ width: '10%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', fontSize: '20px', fontWeight: 'bold' }}>+</span>
                    <TokenSelect token={token1} onSelect={token => {
                        setToken1(token)
                    }} chains={selectedSwap?.chain_id ? [selectedSwap.chain_id] : NETWORK_LIST.map(item => item.chainId)} />
                </div>
                <div style={{ borderBottom: '0.5px solid #a1a0a7', marginBottom: '1rem', opacity: '0.4', marginTop: '1rem' }}>  </div>
            </div>
            {pairInfo.address !== '' && pairInfo.address !== zeroAddress && <div className={props.fullWidth ? `col-12 mt-2` : `col-12 col-md-8 col-lg-6 col-xxl-6 col-xl-6 col-xs-10 mt-2`} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <div>
                    <div>
                        <TokenSelectAndSetValueRow
                            topLeftText={t('查找到流动性')}
                            value={removeAmountInput}
                            balance={pairInfo?.balance?.toString() / Math.pow(10, pairInfo?.decimals)}
                            decimals={pairInfo?.decimals}
                            onInput={e => {
                                setRemoveAmountInput(e)
                            }}
                            rightBtn={<div>
                                {`${token0?.symbol}/${token1?.symbol}`}
                            </div>} />
                    </div>
                    <div className="div-row-center mt-2">
                        <div style={{ flex: 1 }}>
                            <div onClick={changeRemoveAmountInputPercent.bind(this, 0.25)} className="div-col-center remove-lp-percent-item">25%</div>
                        </div>
                        <div style={{ flex: 1 }}>
                            <div onClick={changeRemoveAmountInputPercent.bind(this, 0.5)} className="div-col-center remove-lp-percent-item">50%</div>
                        </div>
                        <div style={{ flex: 1 }}>
                            <div onClick={changeRemoveAmountInputPercent.bind(this, 0.75)} className="div-col-center remove-lp-percent-item">75%</div>
                        </div>
                        <div style={{ flex: 1 }}>
                            <div onClick={changeRemoveAmountInputPercent.bind(this, 1)} className="div-col-center remove-lp-percent-item">100%</div>
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    {(token0.isWETH || token1.isWETH) && <div className="full-width div-row-center liq-box">
                        <div className="liq-box-left">
                            {t('接收W')} W{`${token0.isWETH ? token0.symbol : token1.symbol}`}
                        </div>
                        <div className="liq-box-right">
                            <Form.Check
                                style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}
                                type="switch"
                                checked={receiveWETH}
                                onChange={e => {
                                    setReceiveWETH(e.target.checked)
                                }}
                            />

                        </div>
                    </div>}
                    <div className="full-width div-row-center liq-box">
                        <div className="liq-box-left">
                            {t('预计获得')}
                        </div>
                        <div className="liq-box-right">
                            {getTokenAmount('token0')} {getTokenSymbol(token0)} + {getTokenAmount('token1')} {getTokenSymbol(token1)}
                        </div>
                    </div>
                </div>
            </div>}
            {errMsg && <div className={props.fullWidth ? `col-12 mt-2` : `col-12 col-md-8 col-lg-6 col-xxl-6 col-xl-6 col-xs-10 mt-2`} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <Alert variant="warning" >
                    {errMsg}
                </Alert>
            </div>}


            <div className="row div-col-center div-col-center mt-2">
                {chainId === 0 ? <div className="full-width div-col-center mt-2">
                    <Button onClick={() => {
                        eventBus.emit('request_login')
                    }} type="primary" className="text-light">{t('请先登录')}</Button>
                </div> : <>
                    <div className={props.fullWidth ? `div-row-center col-12 mt-2` : `div-row-center col-12 col-md-8 col-lg-6 col-xxl-6 col-xl-6 col-xs-10 mt-2`} style={{ justifyContent: 'center' }}>
                        <Button onClick={async () => {
                            if (!selectedSwap || !selectedSwap.value) {
                                setErrMsg(t('请选择交易所'))
                                return
                            }

                            if (!account) {
                                setErrMsg(t('请先连接钱包'))
                                return
                            }
                            if (selectedSwap.chain_id !== chainId) {
                                setErrMsg(t('当前连接的网络与选择的交易所的网络不一致'))
                                return
                            }
                            // 判断是否选择的代币
                            if (!token0?.symbol || !token1?.symbol) {
                                setErrMsg(t('请选择代币'))
                                return
                            }

                            // 判断是否输入了值
                            if (!removeAmountInput) {
                                setErrMsg(t('请输入要移除的流动性数量'))
                                return
                            }


                            await getPairInfo()
                            // 获取余额
                            setShowRemoveLiquidityModal(true)
                        }} variant="primary text-light"> {t('确认')} </Button>
                    </div>
                </>}
            </div>


        </div>
    </div>
}

export const LiquidityBox = (props) => {


    const { t } = useTranslation()
    const [activeKey, setActiveKey] = useState('add-lp')
    useEffect(() => {
        if (props.urlParams && props.urlParams.action === 'addLp') {
            setActiveKey('add-lp')
        }
        if (props.urlParams && props.urlParams.action === 'removeLp') {
            setActiveKey('remove-lp')
        }
    }, [props.urlParams])

    return <div>

        <Tab.Container defaultActiveKey={'add-lp'} activeKey={activeKey} onSelect={(k) => {
            setActiveKey(k)
        }}>
            <div className="">
                <div className="card-header">
                    <Nav variant="pills" className="token-option-header-nav">
                        <Nav.Link eventKey="add-lp">
                            {t('添加流动性')}
                        </Nav.Link>
                        <Nav.Link eventKey="remove-lp">
                            {t('移除流动性')}
                        </Nav.Link>
                    </Nav>
                </div>
                <div className={isMobile() ? '' : 'card-body'}>
                    <div>
                        <Tab.Content>
                            <Tab.Pane eventKey="add-lp">
                                {activeKey === 'add-lp' && <AddLiquidityBox {...props} />}
                            </Tab.Pane>
                            <Tab.Pane eventKey="remove-lp">
                                {activeKey === 'remove-lp' && <RemoveLiquidityBox {...props} />}
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </div>

            </div>
        </Tab.Container >


    </div >
}