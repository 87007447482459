import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
// 设置自定义筛选额度
export default function SetCustomFilterAmount(props) {

    const [filterAmountList, setFilterAmountList] = useState([]) // 自定义筛选额度列表
    const { t } = useTranslation()
    useEffect(() => {
        setFilterAmountList(props.list)
    }, [props.list])

    return <Modal centered contentClassName="search-modal" show={props.show} onHide={() => {
        props.handleClose && props.handleClose()
    }} >
        <Modal.Body>
            <div>
                <div className="login-header">{t('自定义筛选额度')}</div>
            </div>
            <div>
                {/* 根据传来的筛选额度做推算 */}
                {filterAmountList && filterAmountList.length > 0 && filterAmountList.map((item, ind) => {
                    return <div key={ind + 'fff-list'} className="custom-filter-amount-item mt-2">
                        {ind + 1}.  {t('额度范围')}: <input placeholder="$"
                            value={item.start_amount_range}
                            onChange={e => {
                                let list = [...filterAmountList]
                                list[ind].start_amount_range = e.target.value
                                setFilterAmountList(list)
                            }} className="form-control custom-filter-amount-input" type="text" />
                        ~
                        <input placeholder="$"
                            onChange={e => {
                                let list = [...filterAmountList]
                                list[ind].end_amount_range = e.target.value
                                setFilterAmountList(list)
                            }}
                            value={item.end_amount_range}
                            className="form-control custom-filter-amount-input" type="text" />
                        <span className="custom-filter-amount-item"> {t('备注')}: <input placeholder={t('(选填)')}
                            value={item.alias}
                            onChange={e => {
                                let list = [...filterAmountList]
                                list[ind].alias = e.target.value
                                setFilterAmountList(list)
                            }}
                            className="form-control custom-filter-amount-input" type="text" />
                            <i onClick={() => {
                                let list = [...filterAmountList]
                                list.splice(ind, 1)
                                setFilterAmountList(list)
                            }} style={{ cursor: 'pointer' }} className="fa fa-close "></i>
                        </span>
                    </div>
                })}

                {filterAmountList.length < 10 && <div>
                    <div onClick={() => {
                        if (filterAmountList.length >= 10) {
                            toast.error(t('最多添加10个过滤项'))
                            return
                        }
                        setFilterAmountList([...filterAmountList, {
                            start_amount_range: '',
                            end_amount_range: '',
                            alias: ''
                        }])
                    }} className="btn btn-primary btn-sm text-light"> + {t('添加过滤项')}</div>
                </div>}
                <div className="d-flex flex-row align-items-center mt-2 tx-table-filters-item">
                    <div onClick={() => {
                        props.ok && props.ok(filterAmountList)
                    }} className="btn btn-primary text-light">
                        {t('确认')}
                    </div>
                    <div onClick={() => {
                        props.handleClose && props.handleClose()
                    }} style={{ marginLeft: '10px' }} className="btn btn-danger text-light">
                        {t('取消')}
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
}