
import React, { useEffect, useRef, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LiquidityBox } from "./liquidityBox";
import { SwapMainBox } from "./swapMainBox";
export const SwapBox = (props) => {
    const { t } = useTranslation()
    const [activeKey, setActiveKey] = useState('swap')
    const [urlParams, setUrlParams] = useState({})
    const params = new URLSearchParams(window.location.search)

    const paramsToObject = (entries) => {
        const result = {}
        for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
            result[key] = value;
        }
        return result;
    }
    const tmpParams = useRef({})
    useEffect(() => {
        const p = paramsToObject(params.entries())
        if (JSON.stringify(p) === JSON.stringify(tmpParams.current)) {
            return
        }
        setUrlParams(p)
        tmpParams.current = p
        if (p.action === 'addLp') {
            setActiveKey('liquidity')
        }
        if (p.action === 'removeLp') {
            setActiveKey('liquidity')
        }
        if (p.action === 'buy' || p.action === 'sell') {
            setActiveKey('swap')
        }

    }, [params])


    return <div>
        <Tab.Container defaultActiveKey={'swap'} activeKey={activeKey} onSelect={(k) => {
            setActiveKey(k)
        }}>
            <div className="card">
                <div className="card-header">
                    <Nav variant="pills" className="token-option-header-nav">
                        <Nav.Link eventKey="swap">
                            {t('兑换')}
                        </Nav.Link>
                        <Nav.Link eventKey="liquidity">
                            {t('流动性')}
                        </Nav.Link>
                        {/* <Nav.Link eventKey="bot">
                        Bot
                    </Nav.Link>
                    <Nav.Link eventKey="cross">
                        跨链
                    </Nav.Link> */}
                    </Nav>
                    {props.showCloseBtn && <div style={{ position: 'absolute', right: '0px', cursor: 'pointer' }}>
                        <i onClick={() => {
                            props.onClickCloseBtn && props.onClickCloseBtn()
                        }} style={{ fontSize: '20px' }} className="mdi mdi-close"></i>
                    </div>}
                </div>
                <div className="">
                    <div>
                        <Tab.Content>
                            <Tab.Pane eventKey="swap">
                                <div style={{ minHeight: '700px' }} className=" mt-4">
                                    <SwapMainBox urlParams={urlParams} {...props} />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="liquidity">
                                <LiquidityBox {...props} urlParams={urlParams} />
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </div>

            </div>
        </Tab.Container>
    </div>

}