import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { debounce } from 'lodash';
import { searchToken } from "../../../api/token";
import Logo from "../logo"
import { converToNumberString, getChainNameWithChainId, getShortAddress, uniqueArray } from "../../../utils/tools";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NETWORK_LIST } from "../../../utils/networks";
import axios from "axios";

export default function SearchModal(props) {
    const history = useHistory()
    const [searchText, setSearchText] = useState('');
    const [searchTmpText, setSearchTmpText] = useState('');
    const [searchList, setSearchList] = useState([])
    const [historyList, setHistoryList] = useState([])
    const [commonTokenChainIdList, setCommonTokenChainIdList] = useState([])
    const LOCALSTORAGE_TM_SEARCH_HISTORY_KEY = 'tm_search_history'

    const { t } = useTranslation()
    const formatSerachItem = (text) => {
        if (text.length < 8) {
            return text
        }
        if (text.length === 42) {
            return getShortAddress(text)
        }

        return text.slice(0, 7) + '...'
    }

    useEffect(() => {
        // 从本地获取搜索历史
        let history = localStorage.getItem(LOCALSTORAGE_TM_SEARCH_HISTORY_KEY)
        if (history) {
            setHistoryList(JSON.parse(history))
        }
    }, [])


    // 设置文本
    function handleText(event) {
        event.persist();
        setSearchTmpText(event.target.value)
        debounceSetText(event);
    }

    const debounceSetText = debounce((event) => {
        setSearchText(event.target.value);
    }, 600);



    const searchReq = useRef(null)

    useEffect(() => {
        // 调用搜索接口
        if (!searchText) {
            setSearchList([])
            return
        }
        if (searchReq.current) {
            searchReq.current.cancel()
        }
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        searchReq.current = source
        // 传入取消信息
        searchToken({
            name: searchText
        }, {
            cancelToken: source.token
        }).then(({ data }) => {
            if (data.code === 200) {
                setSearchList(data.data)
            }
        })
    }, [searchText])

    useEffect(() => {
        let n = []
        if (props.chainIdList) {
            NETWORK_LIST.forEach(item => {
                if (props.chainIdList.includes(item.chainId)) {
                    n.push(item)
                }
            })
        }
        setCommonTokenChainIdList(n)
    }, [props.chainIdList])

    return <>
        <Modal centered contentClassName="search-modal" show={props.show} onHide={() => {
            props.handleClose && props.handleClose()
        }} >
            <Modal.Body>
                <div>
                    <Form>
                        <Form.Control
                            value={searchTmpText}
                            onChange={handleText}
                            type="text"
                            size="sm"
                            className="search-modal-search-input"
                            placeholder={t('请输入代币名称/合约地址')}
                            autoFocus
                        />
                    </Form>

                </div>
                {historyList && historyList.length > 0 && <div className="search-modal-search-box" >
                    <div className="search-modal-search-box-title" >
                        {t('搜索历史')}
                    </div>
                    <div className="search-modal-search-box-content" >
                        <div className="search-history">
                            {historyList.map((item, ind) => {
                                return <div onClick={() => {
                                    setSearchText(item)
                                    setSearchTmpText(item)
                                }} key={'serach-history-k' + item + ind} className="search-history-item">{formatSerachItem(item)}</div>
                            })}
                        </div>
                    </div>
                </div>}

                {commonTokenChainIdList && commonTokenChainIdList.length > 0 && searchList.length === 0 && commonTokenChainIdList.map(item => {
                    return <div key={'common-c-' + item.chainId} className="search-modal-search-box" >
                        <div className="search-modal-search-box-title" >
                            {item?.shortname?.toUpperCase()}常用代币
                        </div>
                        <div className="search-modal-search-box-content" >
                            <div className="search-history">
                                {item.commonTokens && item.commonTokens.map(token => {
                                    return <div style={{ padding: '5px' }} onClick={() => {
                                        props.onSelectItem && props.onSelectItem({
                                            ...token,
                                            chain_id: item.chainId,
                                            // address: ''
                                        })
                                    }} key={`${item.chainId}-${token.address}-ad`} className="div-row-center search-history-item">
                                        <div>
                                            <Logo size="1.2" logo={token.logo} chain={item.chainId} />
                                        </div>
                                        <div style={{ marginLeft: '5px', marginRight: '5px' }}>{token.symbol}</div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                })}

                {searchList && searchList.length > 0 && <div className="search-modal-search-box" >
                    <div className="search-modal-search-box-title" >
                        {t('搜索结果')}
                    </div>
                    <div className="search-modal-search-box-list" >
                        <div className="search-modal-search-box-list-item">
                            <div className="search-modal-search-box-list-item-avatar">
                                Symbol</div>
                            <div className="search-modal-search-box-list-item-price">
                                {t('价格')}</div>
                            <div className="search-modal-search-box-list-item-price">
                                {t('流动市值')}</div>
                            {/* <div className="search-modal-search-box-list-item-increase">
                                涨幅</div> */}
                        </div>
                        {searchList.map((item, ind) => {
                            return <div onClick={() => {
                                // 保存搜索历史
                                const newH = uniqueArray([searchText, ...historyList]).slice(0, 15)
                                setHistoryList(newH)
                                // 去重复

                                setSearchText('')
                                setSearchList([])
                                setSearchTmpText('')
                                if (props.onSelectItem) {
                                    props.onSelectItem(item)
                                } else {
                                    localStorage.setItem(LOCALSTORAGE_TM_SEARCH_HISTORY_KEY, JSON.stringify(newH))
                                    const shortname = getChainNameWithChainId(item.chain_id)
                                    history.push(`/token/${shortname}/${item.address}${item.pair ? `?pair=${item.pair || ''}` : ''}`)
                                    props.handleClose && props.handleClose()
                                }


                            }} key={'search-box-' + item.address + '-' + ind} className="search-modal-search-box-list-item">
                                <div className="search-modal-search-box-list-item-avatar">
                                    <Logo size="1.6" chain={item.chain_id} contract={item.address} logo={item.logo} />
                                    <span className="search-modal-search-box-list-item-title">
                                        {item.symbol} </span> ({getShortAddress(item.address)})</div>
                                <div className="search-modal-search-box-list-item-price">
                                    ${converToNumberString(item.price)}</div>
                                <div className={(item.cap >= 0 ? "text-success" : 'text-danger') + ' search-modal-search-box-list-item-increase'} >
                                    ${converToNumberString(item.cap)}</div>
                            </div>
                        })}

                    </div>
                </div>}

            </Modal.Body>
        </Modal>
    </>
}