import Network from "./network";


export const GetSwapRouterList = (params) => {
    // 获取代币信息
    return Network.request({
        url: '/swap/get-routers',
        method: 'get',
        params
    })
}