import Network from "./network";


export const CheckToken = (params) => {
    // 获取代币信息
    return Network.request({
        url: '/v1/check',
        method: 'get',
        params
    })
}