import React, { useEffect, useRef, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { getWalletTokenAnalyze, getWalletTokenLiquidityRecordsList, getWalletTokenTransferRecordsList, getWalletTokenTxsList } from "../../../api/token";
import { converToNumberString, convertToMaxUnit, eventBus, formatTimeString, getBlockBrowerTxUrl, getShortAddress, isMobile } from "../../../utils/tools";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function WalletTxStatistic(props) {
    const { t } = useTranslation()
    const [wallet, setWallet] = useState('')
    const [tokenInfo, setTokenInfo] = useState({})
    const [pair, setPair] = useState('')
    const [currentTab, setCurrentTab] = useState(0)
    const [globalInfo, setGlobalInfo] = useState({}) // 当前全局信息 当前持仓, 持仓价值等
    const [txList, setTxList] = useState([]) // 交易列表
    const [transferList, setTransferList] = useState([]) // 转账列表
    const [lpList, setLpList] = useState([]) // 流动性列表

    const marksRecords = useSelector(state => state.getMarksRecords)
    const markTimer = useRef(null)

    const getTextClass = (n) => {
        if (parseInt(n) > 0) {
            return 'text-success'
        }
        if (parseInt(n) < 0) {
            return 'text-danger'
        }
        return 'text-light'
    }

    const fetchData = async () => {
        // 更新代币信息
        // 1. 获取当前总体信息

        // 2. 获取当前列表信息
        if (!wallet || !tokenInfo?.address || !pair?.pair) return

        getWalletTokenAnalyze({
            wallet,
            pair_id: pair.id + '',
            chain_id: tokenInfo.chain_id + '',
            token_address: tokenInfo.address,
            pair_address: pair.pair
        })
            .then(({ data }) => {
                if (data.code === 200) {
                    setGlobalInfo(data.data)
                }
            })

        if (currentTab === 0) {
            // 获取交易列表
            getWalletTokenTxsList({
                wallet,
                pair_id: pair.id + '',
                chain_id: tokenInfo.chain_id + '',
                token_address: tokenInfo.address,
                pair_address: pair.pair,
                currency: pair.currency
            }).then(({ data }) => {
                if (data.code === 200) {
                    setTxList(data.data)

                    // 发射事件来更新k线上的marks
                    emitEvent(data.data)
                }

            })

        }
    }

    useEffect(() => {
        if (!wallet || !tokenInfo?.address || !pair?.pair) return
        if (currentTab === 0) {
            // 获取交易列表
            getWalletTokenTxsList({
                wallet,
                pair_id: pair.id + '',
                chain_id: tokenInfo.chain_id + '',
                token_address: tokenInfo.address,
                pair_address: pair.pair,
                currency: pair.currency
            }).then(({ data }) => {
                if (data.code === 200) {
                    setTxList(data.data)

                    // 发射事件来更新k线上的marks
                    emitEvent(data.data)
                }

            })

        }
        if (currentTab === 1) { // 转账记录
            getWalletTokenTransferRecordsList({
                wallet,
                pair_id: pair.id + '',
                chain_id: tokenInfo.chain_id + '',
                token_address: tokenInfo.address,
                pair_address: pair.pair,
                currency: pair.currency
            }).then(({ data }) => {
                if (data.code === 200) {
                    console.log(data.data, 'transferList')
                    setTransferList(data.data)
                }
            })
        }
        if (currentTab === 2) {
            getWalletTokenLiquidityRecordsList({
                wallet,
                pair_id: pair.id + '',
                chain_id: tokenInfo.chain_id + '',
                token_address: tokenInfo.address,
                pair_address: pair.pair,
                currency: pair.currency
            }).then(({ data }) => {
                if (data.code === 200) {
                    console.log(data.data, 'transferList')
                    setLpList(data.data)
                }
            })
        }
    }, [currentTab])


    const emitEvent = (info) => {

        const key = `${pair.pair}-${pair.currency}`
        if (marksRecords[key]) {
            eventBus.emit('marksUpdate', {
                key,
                info
            })
        } else {
            // 执行一个时间戳
            startTimer().then(() => {
                eventBus.emit('marksUpdate', {
                    key,
                    info
                })
            })
        }
    }

    const startTimer = () => {
        return new Promise((resolve, reject) => {
            if (markTimer.current) {
                reject('ing')
            }
            markTimer.current = setInterval(() => {
                const key = `${pair.pair}-${pair.currency}`
                if (marksRecords[key]) {
                    clearInterval(markTimer.current)
                    resolve()
                }
            }, 1000)
        })
    }

    useEffect(() => {
        fetchData()
    }, [wallet, tokenInfo, pair])

    useEffect(() => {
        if (props.wallet && (props.wallet !== wallet)) {
            setWallet(props.wallet)
        }
        if (props.tokenInfo.address !== tokenInfo.address) {
            setTokenInfo(props.tokenInfo)
        }
        if (props.pair.pair !== pair.pair) {
            setPair(props.pair)
        }
    }, [props.wallet])

    return <div>
        <div style={{ flexWrap: 'wrap' }} className="d-flex flex-row full-width">
            <div className="wallet-tx-statistic-item">
                <div className="wallet-tx-statistic-item-name">{t('当前持仓')}</div>
                <div className="wallet-tx-statistic-item-count text-light">{convertToMaxUnit(globalInfo.balance) || ''}</div>
            </div>
            <div className="wallet-tx-statistic-item">
                <div className="wallet-tx-statistic-item-name">{t('持仓价值')}</div>
                <div className="wallet-tx-statistic-item-count text-light">${converToNumberString(globalInfo.balance_in_usd)} </div>
            </div>
            <div className="wallet-tx-statistic-item">
                <div className="wallet-tx-statistic-item-name">{t('成本价')}</div>
                <div className="wallet-tx-statistic-item-count text-light">${converToNumberString(globalInfo.buy_cost)}</div>
            </div>
            <div className="wallet-tx-statistic-item">
                <div className="wallet-tx-statistic-item-name">{t('持仓盈亏')}</div>
                <div className={"wallet-tx-statistic-item-count " + getTextClass(globalInfo.estimated_profit)}> {parseFloat(globalInfo.estimated_profit) >= 0 ? '+' : '-'} ${converToNumberString(Math.abs(parseFloat(globalInfo.estimated_profit)))} <sup>{converToNumberString(parseFloat(globalInfo.estimated_profit_rate * 100))}%</sup> </div>
            </div>
        </div>

        <Tab.Container defaultActiveKey="tx">
            <div className="card-header">
                <div className="nav">
                    <div style={{ marginRight: '5px' }} onClick={() => {
                        setCurrentTab(0)
                    }} className={"nav-item nav-pills pointer-btn " + (currentTab === 0 ? 'text-primary' : '')}>{t('交易')}</div>
                    <div style={{ marginRight: '5px' }} onClick={() => {
                        setCurrentTab(1)
                    }} className={"nav-item nav-pills pointer-btn " + (currentTab === 1 ? 'text-primary' : '')}>{t('转账')}</div>
                    <div className={"nav-item nav-pills pointer-btn " + (currentTab === 2 ? 'text-primary' : '')} onClick={() => {
                        setCurrentTab(2)
                    }}>{t('流动性')}</div>
                </div>
            </div>
            <div className={isMobile() ? '' : "card-body"}>

                {currentTab === 0 && <div>
                    <table
                        className="table table-striped"
                    >
                        <thead>
                            <tr style={{ textAlign: 'center' }}>
                                <th>{t('操作')}</th>
                                <th>{t('时间')}</th>
                                <th>{t('单价')}($)</th>
                                <th>{t('成交量')}</th>
                                <th>{t('成交额')}($)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {txList && txList.length > 0 && txList.map((item, index) => {
                                return <tr className={item.type === 1 ? 'text-success' : 'text-danger'} style={{ fontSize: '12px', textAlign: 'center' }} key={index}>
                                    <td style={{ width: '50px' }}>{item.type === 1 ? t('买') : t('卖')}</td>
                                    <td>{formatTimeString(item.time * 1000, isMobile())}</td>
                                    <td>
                                        {/* {item.price_symbol === "$" && "$"} */}
                                        {convertToMaxUnit(item.price)}
                                        {/* {item.price_symbol !== "$" && item.price_symbol} */}
                                    </td>
                                    <td>
                                        {convertToMaxUnit(item.amount)}</td>
                                    <td>
                                        {convertToMaxUnit(item.volume)}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>

                </div>}

                {currentTab === 1 && <div>
                    <table
                        className="table table-striped"
                    >
                        <thead>
                            <tr style={{ textAlign: 'center' }}>
                                <th>{t('操作')}</th>
                                <th>{t('时间')}</th>
                                <th>{t('地址')}</th>
                                <th>{t('数量')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transferList && transferList.length > 0 && transferList.map((item, index) => {
                                return <tr className={item.type === 1 ? 'text-success' : 'text-danger'} style={{ fontSize: '12px', textAlign: 'center' }} key={index}>
                                    <td style={{ width: '50px' }}>{item.type === 1 ? t('转入') : t('转出')}</td>
                                    <td>{formatTimeString(item.time * 1000)}</td>
                                    <td>
                                        {getShortAddress(item.relation_address)}
                                    </td>
                                    <td>
                                        {convertToMaxUnit(item.amount)}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>

                </div>}

                {currentTab === 2 && <div>
                    <table className="table table-striped" >
                        <thead>
                            <tr>
                                <th scope="col">
                                    {t('时间')}
                                </th>
                                <th style={{ 'textAlign': 'center' }} scope="col">
                                    {t('流动性')}
                                </th>
                                <th scope="col">{t('价值')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lpList && lpList.map((item, ind) => {
                                return <tr onClick={() => {
                                    // 获取要跳转的链接
                                    // 打开新的页面
                                    const url = getBlockBrowerTxUrl(item.chain_id, item.tx_hash)
                                    window.open(url, '_blank')
                                    // const w = window.open(url, '_blank')
                                    // w.location.href = url
                                }} style={{ 'cursor': 'pointer' }} key={ind + 'liquidity-record'}>
                                    <td className="text-success">
                                        {formatTimeString(item.time)}
                                    </td>
                                    <td style={{ 'textAlign': 'center' }}> {convertToMaxUnit(item.token0_amount)} {item.token0_symbol}/{convertToMaxUnit(item.token1_amount)}{item.token1_symbol} </td>
                                    <td>${convertToMaxUnit(item.value)}</td>
                                </tr>
                            })}
                        </tbody>
                    </table></div>}
            </div>
        </Tab.Container>

    </div>
}