import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";

import safeImg from "../../assets/img/safe6.png";
import dangerImg from "../../assets/img/danger2.png";
import warnImg from "../../assets/img/warn.png";
import { Files, Search } from 'react-bootstrap-icons'
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { converToNumberString, convertNumberToString, convertToMaxUnit, formatTimeString, formatTimeToDate, getChainNameWithChainId, getShortAddress, isAddress, isBlackHoleAddress, isMobile } from "../../utils/tools";
import SweetAlert2 from 'react-sweetalert2';
import { CheckToken } from "../../api/check";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import ScoreDashboard from "../components/ScoreProgress";
import { set } from "date-fns";
import { getHotSearchRankList } from "../../api/token";
import Logo from "../components/logo";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { NETWORK_LIST } from "../../utils/networks";
import { useTranslation } from "react-i18next";
import i18n from "../../utils/i18n";


export const txInfoList = [
    {
        name: 'is_honeypot',
        true_label: i18n.t('模拟交易失败, 可能是蜜罐'),
        false_label: i18n.t('看起来不像是蜜罐'),
        renounce_true_label: i18n.t('模拟交易失败, 可能是蜜罐'),
        renounce_false_label: i18n.t('看起来不像是蜜罐'),
    },
    {
        name: 'transfer_pausable',
        true_label: i18n.t('可暂停交易'),
        false_label: i18n.t('无交易开关'),
        renounce_true_label: i18n.t('因放弃所有权, 无法暂停交易'),
        renounce_false_label: i18n.t('无交易开关'),
    },
    {
        name: 'cannot_sell_all',
        true_label: i18n.t('代币不能全部卖出'),
        false_label: i18n.t('代币可以全部卖出'),
        renounce_true_label: i18n.t('代币不能全部卖出'),
        renounce_false_label: i18n.t('代币可以全部卖出'),
    },
    {
        name: 'trading_cooldown',
        true_label: i18n.t('存在交易冷却机制'),
        false_label: i18n.t('无交易冷却机制'),
        renounce_true_label: i18n.t('存在交易冷却机制'),
        renounce_false_label: i18n.t('无交易冷却机制'),
    },
    {
        name: 'is_anti_whale',
        true_label: i18n.t('防巨鲸(限购/限卖)'),
        false_label: i18n.t('不限购/限卖'),
        renounce_true_label: i18n.t('防巨鲸(限购/限卖)'),
        renounce_false_label: i18n.t('不限购/限卖'),
    },
    {
        name: 'anti_whale_modifiable',
        true_label: i18n.t('可修改限购数量'),
        false_label: i18n.t('不可修改限购数量'),
        renounce_true_label: i18n.t('因放弃所有权, 无法修改限购数量'),
        renounce_false_label: i18n.t('不可修改限购数量'),
    },
    {
        name: 'is_anti_whale_modifiable',
        true_label: i18n.t('可修改限购/限卖额度'),
        false_label: i18n.t('不可修改限购/限卖额度'),
        renounce_true_label: i18n.t('因放弃所有权, 无法修改限购/限卖额度'),
        renounce_false_label: i18n.t('不可修改限购/限卖额度'),
    },
    {
        name: 'slippage_modifiable',
        true_label: i18n.t('可修改交易税率'),
        false_label: i18n.t('不可修改交易税率'),
        renounce_true_label: i18n.t('因放弃所有权, 不可修改交易税率'),
        renounce_false_label: i18n.t('不可修改交易税率'),
    },
    {
        name: 'is_blacklisted',
        true_label: i18n.t('可设置黑名单'),
        false_label: i18n.t('无黑名单机制'),
        renounce_true_label: i18n.t('因放弃所有权, 不可新增黑名单地址'),
        renounce_false_label: i18n.t('无黑名单机制'),
    },
    {
        name: 'personal_slippage_modifiable',
        true_label: i18n.t('可针对特定地址修改税率'),
        false_label: i18n.t('不可针对特定地址修改税率'),
        renounce_true_label: i18n.t('因放弃所有权, 不可针对特定地址修改税率'),
        renounce_false_label: i18n.t('可针对特定地址修改税率'),
    },
    {
        name: 'is_mintable',
        true_label: i18n.t('可增发代币'),
        false_label: i18n.t('未发现可增发开关'),
        renounce_true_label: i18n.t('因放弃所有权, 无法增发代币'),
        renounce_false_label: i18n.t('未发现可增发开关'),
    },
    {
        name: 'can_take_back_ownership',
        true_label: i18n.t('可取回合约所有权'),
        false_label: i18n.t('未发现取回所有权开关'),
        renounce_true_label: i18n.t('可取回合约所有权'),
        renounce_false_label: i18n.t('未发现取回所有权开关'),
    },
    {
        name: 'owner_change_balance',
        true_label: i18n.t('Owner可篡改余额'),
        false_label: i18n.t('未发现Owner修改余额开关'),
        renounce_true_label: i18n.t('Owner可篡改余额'),
        renounce_false_label: i18n.t('未发现Owner修改余额开关'),
    },
    {
        name: 'hidden_owner',
        true_label: i18n.t('存在隐藏Owner'),
        false_label: i18n.t('未发现隐藏Owner'),
        renounce_true_label: i18n.t('存在隐藏Owner'),
        renounce_false_label: i18n.t('未发现隐藏Owner'),
    },
    {
        name: 'external_call',
        true_label: i18n.t('存在调用外部合约的方法'),
        false_label: i18n.t('未发现调用外部合约风险'),
        renounce_true_label: i18n.t('存在调用外部合约的方法'),
        renounce_false_label: i18n.t('未发现调用外部合约风险')
    },
    {
        name: 'unlock_liquidity',
        true_label: i18n.t('流动性未锁定或锁定比例过低'),
        false_label: i18n.t('流动性已锁定'),
        renounce_true_label: i18n.t('流动性未锁定或锁定比例过低'),
        renounce_false_label: i18n.t('流动性已锁定'),
    },
    {
        name: 'cannot_buy',
        true_label: i18n.t('无法买入'),
        false_label: i18n.t('模拟买入成功'),
        renounce_true_label: i18n.t('无法买入'),
        renounce_false_label: i18n.t('模拟买入成功'),
    },
    {
        name: 'low_liquidity',
        true_label: i18n.t('流动性过低'),
        false_label: i18n.t('流动性正常'),
        renounce_true_label: i18n.t('流动性过低'),
        renounce_false_label: i18n.t('流动性正常'),
    },
    {
        name: 'creator_holds_too_many_tokens',
        true_label: i18n.t('创建者持有过多代币'),
        false_label: i18n.t('创建者持有比例正常'),
        renounce_true_label: i18n.t('创建者持有过多代币'),
        renounce_false_label: i18n.t('创建者持有比例正常'),
        falseHidden: true
    },
    {
        name: 'owner_holds_too_many_tokens',
        true_label: i18n.t('所有者持有过多代币'),
        false_label: i18n.t('所有者持有比例正常'),
        renounce_true_label: i18n.t('所有者持有过多代币'),
        renounce_false_label: i18n.t('所有者持有比例正常'),
        falseHidden: true
    },
    {
        name: 'simulate_trade_failed',
        true_label: i18n.t('模拟交易失败'),
        false_label: i18n.t('模拟交易正常'),
        renounce_true_label: i18n.t('模拟交易失败'),
        renounce_false_label: i18n.t('模拟交易正常'),
    },
    {
        name: 'black_record_exists',
        true_label: i18n.t('疑似存在拉黑记录'),
        false_label: i18n.t('无拉黑记录'),
        renounce_true_label: i18n.t('疑似存在拉黑记录'),
        renounce_false_label: i18n.t('无拉黑记录'),
    },
    {
        name: 'high_tax_wallets',
        true_label: i18n.t('存在高税地址'),
        false_label: i18n.t('无高税地址'),
        renounce_true_label: i18n.t('存在高税地址'),
        renounce_false_label: i18n.t('无高税地址'),
    },
    {
        name: 'is_proxy',
        true_label: i18n.t('存在可升级的代理合约, 有貔貅风险'),
        false_label: i18n.t('不存在代理合约'),
        renounce_true_label: i18n.t('存在可升级的代理合约, 有貔貅风险'),
        renounce_false_label: i18n.t('不存在代理合约'),
    },
    {
        name: 'inBlackList',
        true_label: i18n.t('已人工审计为高风险代币'),
        false_label: '',
        renounce_true_label: i18n.t('已人工审计为高风险代币'),
        renounce_false_label: '',
        falseHidden: true
    },
    {
        name: 'inWhiteList',
        true_label: i18n.t('已通过人工审计,合约安全'),
        false_label: '',
        renounce_true_label: i18n.t('已通过人工审计,合约安全'),
        renounce_false_label: '',
        falseHidden: true
    },
    {
        name: 'verified',
        true_label: i18n.t('已开源'),
        false_label: i18n.t('未开源'),
        renounce_true_label: i18n.t('已开源'),
        renounce_false_label: i18n.t('未开源'),

    },
    {
        name: 'serial_scammer',
        true_label: i18n.t('职业骗子'),
        false_label: '',
        renounce_true_label: i18n.t('职业骗子'),
        renounce_false_label: '',
        falseHidden: true
    }

]



// 支持的公链
const supportChains = NETWORK_LIST.filter(item => item.check)

function ChainItem(props) {
    return <div>
        <img src={props?.logo} alt={props?.name} width="20" height="20" />
        <span style={{ marginLeft: '10px' }} >{props?.name}</span>
    </div>
}

function CopyIcon(props) {
    const { t } = useTranslation()
    return <Files onClick={() => {
        navigator.clipboard.writeText(props?.address)
        props.setSwalProps && props.setSwalProps({
            show: true,
            title: t('已复制'),
            text: props?.address,
        })
    }} style={{ width: '12px', height: '12px', cursor: 'pointer', marginLeft: '5px' }} />
}


function LockDetailRow(props) {

    // 锁定数量 状态 解锁倒计时
    const [amount, setAmount] = useState('0')
    const [percent, setPercent] = useState(0) // 锁定数量占比
    const [status, setStatus] = useState('') // 状态: 锁定中 已解锁 
    const [countdown, setCountdown] = useState('')
    const [textClass, setTextClass] = useState('')
    const countdownTimer = useRef(null)
    const { t } = useTranslation()
    useEffect(() => {
        setAmount(convertToMaxUnit(props?.info?.amount))


        if (props.total && props?.info?.amount) {
            const percent = (Number(props?.info?.amount) / Number(props?.total) * 100).toFixed(2)
            setPercent(percent)
        }




        const now = Date.parse(new Date()) / 1000
        const end = parseInt(Date.parse(new Date(props?.info?.end_time)) / 1000)
        if (now > end) {
            setStatus(t('已解锁'))
            setCountdown('')    // 已解锁不显示倒计时
            setTextClass('text-danger')
        } else {
            setStatus(t('锁定中'))
            countdownTimer.current && clearInterval(countdownTimer.current)
            countdownTimer.current = setInterval(() => {
                const now = Date.parse(new Date()) / 1000
                const end = parseInt(Date.parse(new Date(props?.info?.end_time)) / 1000)
                if (now > end) {
                    clearInterval(countdownTimer.current)
                    setStatus(t('已解锁'))
                    setTextClass('text-danger')
                    setCountdown('00:00:00:00')
                } else {
                    // 0天0时0分0秒
                    const diff = end - now
                    const day = Math.floor(diff / (24 * 60 * 60))
                    const hour = Math.floor((diff - day * 24 * 60 * 60) / (60 * 60))
                    const minute = Math.floor((diff - day * 24 * 60 * 60 - hour * 60 * 60) / 60)
                    const second = diff - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60
                    setCountdown(`${day}${t('天')}${hour}${t('时')}${minute}${t('分')}${second}${t('秒')}`)
                    setTextClass('text-success')
                }
            }
                , 1000)
        }
    }, [props.info, props.total])


    return <tr className={`check-report-row-t-tr ${textClass}`}>
        <td className="check-report-row-t-td">{amount}({percent}%)</td>
        <td className="check-report-row-t-td">{status}</td>
        <td className="check-report-row-t-td">{countdown}</td>
    </tr>
}

export function CheckInfoResPanel(props) {

    const [apiRes, setApiRes] = useState({}); // api响应结果
    const [baseInfo, setBaseInfo] = useState({});
    const [tokenHolders, setTokenHolders] = useState([]); // token持有者
    const [lpHolders, setLpHolders] = useState([]); // lp持有者
    const [txInfo, setTxInfo] = useState([]); // 交易检查信息
    const [caInfo, setCaInfo] = useState([]); // 合约检查信息
    const [securityScore, setSecurityScore] = useState(0); // 安全分数
    const [fatTokenResult, setFatTokenResult] = useState({}); // fatToken检查结果

    const { t } = useTranslation()


    useEffect(() => {
        setApiRes(props.apiRes)
    }, [props])

    useEffect(() => {
        setSecurityScore(apiRes?.score?.toFixed(0) || 0)
        // 设置txCheck contract_check 并排序
        const tc = apiRes?.tx_check || []
        tc.sort((a, b) => {
            if (a.result && !b.result) {
                return -1; // a为true，b为false，将a排在b前面
            } else if (!a.result && b.result) {
                return 1; // a为false，b为true，将b排在a前面
            } else {
                return 0; // a和b都为true或false，保持原来的顺序不变
            }
        })
        const ac = apiRes?.contract_check || []
        ac.sort((a, b) => {
            if (a.level === 'security') return 1
            if (a.result && !b.result) {
                return -1; // a为true，b为false，将a排在b前面
            } else if (!a.result && b.result) {
                return 1; // a为false，b为true，将b排在a前面
            } else {
                return 0; // a和b都为true或false，保持原来的顺序不变
            }
        })
        setTxInfo(tc)
        setCaInfo(ac)
        setLpHolders(apiRes.lp_holders)
        setTokenHolders(apiRes.holders)
        let ft = {
            ...apiRes.fat_token,
            enable_change_tax: true
        }
        ft.buy_fee = parseFloat(ft.buy_fund_fee || 0) + parseFloat(ft.buy_burn_fee || 0) + parseFloat(ft.buy_lp_fee || 0) + parseFloat(ft.buy_reward_fee || 0)
        ft.sell_fee = parseFloat(ft.sell_fund_fee || 0) + parseFloat(ft.sell_burn_fee || 0) + parseFloat(ft.sell_lp_fee || 0) + parseFloat(ft.sell_reward_fee || 0)
        setFatTokenResult(ft)
    }, [apiRes])


    const getSafeImg = (item) => {



        if (apiRes.security_list?.indexOf(item.name) > -1) {
            return safeImg
        }
        if (apiRes.warning_list?.indexOf(item.name) > -1) {
            return warnImg
        }
        if (apiRes.danger_list?.indexOf(item.name) > -1) {
            return dangerImg
        }

    }
    const getTextSafeClassWithTax = (tax) => {
        if (tax >= 0 && tax < 5) {
            return 'text-success'
        }
        if (tax >= 5 && tax < 10) {
            return 'text-warning'
        }
        if (tax >= 10) {
            return 'text-danger'
        }
    }

    const getFunctionCheckItemLabel = (item) => {
        // 获取label
        // 权限已放弃, 不可更改税率 可更改税率
        let text = ''
        if (item.level === 'security') {
            text = t('合约已开源')
        }
        let renounceOwner = false
        if (isBlackHoleAddress(apiRes.owner_address)) {
            renounceOwner = true
        }
        const x = txInfoList.find(subItem => subItem.name === item.name)
        if (x) {
            if (renounceOwner) {
                if (item.result) {
                    return x.renounce_true_label
                } else {
                    return x.renounce_false_label
                }
            } else {
                if (item.result) {
                    return x.true_label
                } else {
                    return x.false_label
                }
            }

        } else {
            return item.name
        }


    }
    const getTextSafeClass = (item) => {
        if (apiRes.security_list?.indexOf(item.name) > -1) {
            return 'text-success'
        }
        if (apiRes.warning_list?.indexOf(item.name) > -1) {
            return 'text-warning'
        }
        if (apiRes.danger_list?.indexOf(item.name) > -1) {
            return 'text-danger'
        }
        return ''
    }


    return <>
        {!isNaN(securityScore) && securityScore > 0 && <>
            <div className="row check-report-header" >
                <div className="col-12">
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingTop: '0px', paddingBottom: '20px' }} className="card check-report-header-body">

                        {isMobile() === false && <h4 className="check-report-header-body-row">{t('检测报告')}</h4>}
                        <div style={{ position: 'relative' }}>
                            <ScoreDashboard score={securityScore} />
                            <div className="security-safe-list">
                                <div className="security-safe-list-item">
                                    <img className="security-safe-list-item-img" src={dangerImg} alt="" />
                                    {apiRes?.danger_list?.length || 0}
                                </div>
                                <div className="security-safe-list-item">
                                    <img className="security-safe-list-item-img" src={warnImg} alt="" />
                                    {apiRes?.warning_list?.length || 0}
                                </div>
                                <div className="security-safe-list-item">
                                    <img className="security-safe-list-item-img" src={safeImg} alt="" />
                                    {apiRes?.security_list?.length || 0}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row" style={{ maxWidth: '800px', width: '100%' }}>
                <div className="col-xl-6 col-xxl-6 col-lg-6 col-xxl-6 col-md-6">
                    {/* 基本信息一个card 交易检查一个card */}
                    {!fatTokenResult.template_id && <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    {t('基本信息')}
                                </div>
                                <div className="card-body">
                                    <div className="check-report-row">
                                        <div className="check-report-row-item">{t('全称')}</div>
                                        <div className="check-report-row-item">{apiRes.name}</div>
                                    </div>
                                    <div className="check-report-row">
                                        <div className="check-report-row-item">{t('简称')}</div>
                                        <div className="check-report-row-item">{apiRes.symbol}</div>
                                    </div>
                                    <div className="check-report-row">
                                        <div className="check-report-row-item">{t('精度')}</div>
                                        <div className="check-report-row-item">{apiRes.decimals}</div>
                                    </div>
                                    <div className="check-report-row">
                                        <div className="check-report-row-item">{t('总发行量')}</div>
                                        <div className="check-report-row-item">{convertNumberToString(apiRes.total_supply)}</div>
                                    </div>
                                    {apiRes.tip !== '' && <div className="check-report-row">
                                        <div className="check-report-row-item">{t('备注')}</div>
                                        <div className="check-report-row-item">{apiRes.tip}</div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>}
                    {fatTokenResult.template_id > 0 && <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <span className="text-success">{t('人工审计报告')}</span>
                                </div>
                                <div className="card-body">
                                    <div className="check-report-row">
                                        <div className="check-report-row-item">{t('全称')}</div>
                                        <div className="check-report-row-item">{apiRes.name}</div>
                                    </div>
                                    <div className="check-report-row">
                                        <div className="check-report-row-item">{t('简称')}</div>
                                        <div className="check-report-row-item">{apiRes.symbol}</div>
                                    </div>
                                    <div className="check-report-row">
                                        <div className="check-report-row-item">{t('精度')}</div>
                                        <div className="check-report-row-item">{apiRes.decimals}</div>
                                    </div>
                                    <div className="check-report-row">
                                        <div className="check-report-row-item">{t('总发行量')}</div>
                                        <div className="check-report-row-item">{convertNumberToString(apiRes.total_supply)}</div>
                                    </div>
                                    {apiRes.tip !== '' && <div className="check-report-row">
                                        <div className="check-report-row-item">{t('备注')}</div>
                                        <div className="check-report-row-item">{apiRes.tip}</div>
                                    </div>}
                                    {fatTokenResult.enable_off_trade && fatTokenResult.start_trade_block === 0 && <div className="check-report-row">
                                        <div className="check-report-row-item">
                                            <img src={warnImg} className="check-report-row-icon" alt="" />
                                        </div>
                                        <div className="check-report-row-item text-warning">
                                            {t('暂未开启交易')}
                                        </div>
                                    </div>}
                                    {fatTokenResult.enable_blacklist && !isBlackHoleAddress(fatTokenResult.owner) && <div className="check-report-row">
                                        <div className="check-report-row-item">
                                            <img src={warnImg} className="check-report-row-icon" alt="" />
                                        </div>
                                        <div className="check-report-row-item">
                                            {t('存在黑名单')}
                                        </div>
                                    </div>}
                                    {fatTokenResult.enable_change_tax && !isBlackHoleAddress(fatTokenResult.owner) && <div className="check-report-row">
                                        <div className="check-report-row-item">
                                            <img src={warnImg} className="check-report-row-icon" alt="" />
                                        </div>
                                        <div className="check-report-row-item">
                                            {t('可更改税率')}
                                        </div>
                                    </div>}
                                    {fatTokenResult?.creator && <div className="check-report-row">
                                        <div className="check-report-row-item">{t('创建者')}</div>
                                        <div className="check-report-row-item">
                                            {getShortAddress(fatTokenResult.creator)}
                                            <CopyIcon setSwalProps={(k) => {
                                                props.showMessageModal && props.showMessageModal(k)
                                            }} address={fatTokenResult.creator} />
                                        </div>
                                    </div>}
                                    <div className="check-report-row">
                                        <div className="check-report-row-item">{t('当前所有权')}</div>
                                        <div className="check-report-row-item">
                                            {getShortAddress(fatTokenResult.owner)}
                                            {isBlackHoleAddress(fatTokenResult.owner) ? `(${t('已弃权')})` : ''}
                                            <CopyIcon setSwalProps={(k) => {
                                                props.showMessageModal && props.showMessageModal(k)
                                            }} address={fatTokenResult.owner} />
                                        </div>
                                    </div>
                                    {fatTokenResult.reward_token_symbol && <div className="check-report-row">
                                        <div className="check-report-row-item">{t('分红代币')}</div>
                                        <div className="check-report-row-item">
                                            {fatTokenResult.reward_token_symbol}
                                        </div>
                                    </div>}
                                    {fatTokenResult.enable_kill_block && fatTokenResult.kill_block_number > 0 && <div className="check-report-row">
                                        <div className="check-report-row-item">{t('杀区块')}</div>
                                        <div className="check-report-row-item">
                                            {fatTokenResult.kill_block_number}{t('区块')}
                                        </div>
                                    </div>}
                                    {fatTokenResult.enable_wallet_limit && fatTokenResult.max_wallet_amount > 0 && <div className="check-report-row">
                                        <div className="check-report-row-item">
                                            {t('单钱包持币上限')}
                                        </div>
                                        <div className="check-report-row-item">
                                            {fatTokenResult.max_wallet_amount} {fatTokenResult.symbol}
                                        </div>
                                    </div>}

                                    {fatTokenResult.enable_swap_limit && fatTokenResult.max_swap_amount > 0 && <div className="check-report-row">
                                        <div className="check-report-row-item">
                                            {t('限购/限卖')}
                                        </div>
                                        <div className="check-report-row-item">
                                            {fatTokenResult.max_swap_amount} {fatTokenResult.symbol}
                                        </div>
                                    </div>}
                                    {fatTokenResult.enable_swap_limit && (fatTokenResult.max_buy_amount > 0 || fatTokenResult.max_sell_amount > 0) && <div className="check-report-row">
                                        <div className="check-report-row-item">
                                            {t('限制交易')}
                                        </div>
                                        <div className="check-report-row-item">
                                            &nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>}
                                    {fatTokenResult.enable_swap_limit && fatTokenResult.max_buy_amount > 0 && <div className="check-report-row check-report-row-small">
                                        <div className="check-report-row-item">
                                            &nbsp;&nbsp;&nbsp;|-{t('最大买入')}
                                        </div>
                                        <div className="check-report-row-item">
                                            &nbsp;&nbsp;&nbsp;   {fatTokenResult.max_buy_amount} {fatTokenResult.symbol}
                                        </div>
                                    </div>}
                                    {fatTokenResult.enable_swap_limit && fatTokenResult.max_sell_amount > 0 && <div className="check-report-row check-report-row-small">
                                        <div className="check-report-row-item">
                                            &nbsp;&nbsp;&nbsp;|-{t('最大卖出')}
                                        </div>
                                        <div className="check-report-row-item">
                                            {fatTokenResult.max_sell_amount} {fatTokenResult.symbol}
                                        </div>
                                    </div>}

                                    {fatTokenResult.buy_fee > 0 && <div className="check-report-row">
                                        <div className="check-report-row-item">{t('买入税')}:</div>
                                        <div className="check-report-row-item">{fatTokenResult.buy_fee}%</div>
                                    </div>}
                                    {fatTokenResult.buy_fund_fee > 0 && <div className="check-report-row check-report-row-small">
                                        <div className="check-report-row-item" > &nbsp;&nbsp;&nbsp; |-{t('营销税')}:</div>
                                        <div className="check-report-row-item">{fatTokenResult.buy_fund_fee}%</div>
                                    </div>}
                                    {fatTokenResult.buy_lp_fee > 0 && <div className="check-report-row check-report-row-small">
                                        <div className="check-report-row-item" > &nbsp;&nbsp;&nbsp; |-{t('回流税')}:</div>
                                        <div className="check-report-row-item">{fatTokenResult.buy_lp_fee}%</div>
                                    </div>}
                                    {fatTokenResult.buy_burn_fee > 0 && <div className="check-report-row check-report-row-small">
                                        <div className="check-report-row-item" > &nbsp;&nbsp;&nbsp; |-{t('销毁税')}:</div>
                                        <div className="check-report-row-item">{fatTokenResult.buy_burn_fee}%</div>
                                    </div>}
                                    {fatTokenResult.buy_reward_fee > 0 && <div className="check-report-row check-report-row-small">
                                        <div className="check-report-row-item" > &nbsp;&nbsp;&nbsp; |-{t('分红税')}:</div>
                                        <div className="check-report-row-item">{fatTokenResult.buy_reward_fee}%</div>
                                    </div>}
                                    {fatTokenResult.sell_fee > 0 && <div className="check-report-row">
                                        <div className="check-report-row-item">{t('卖出税')}:</div>
                                        <div className="check-report-row-item">{fatTokenResult.sell_fee}%</div>
                                    </div>}
                                    {fatTokenResult.sell_fund_fee > 0 && <div className="check-report-row check-report-row-small">
                                        <div className="check-report-row-item" > &nbsp;&nbsp;&nbsp; |-{t('营销税')}:</div>
                                        <div className="check-report-row-item">{fatTokenResult.sell_fund_fee}%</div>
                                    </div>}
                                    {fatTokenResult.sell_lp_fee > 0 && <div className="check-report-row check-report-row-small">
                                        <div className="check-report-row-item" > &nbsp;&nbsp;&nbsp; |-{t('回流税')}:</div>
                                        <div className="check-report-row-item">{fatTokenResult.sell_lp_fee}%</div>
                                    </div>}
                                    {fatTokenResult.sell_burn_fee > 0 && <div className="check-report-row check-report-row-small">
                                        <div className="check-report-row-item" > &nbsp;&nbsp;&nbsp; |-{t('销毁税')}:</div>
                                        <div className="check-report-row-item">{fatTokenResult.sell_burn_fee}%</div>
                                    </div>}
                                    {fatTokenResult.sell_reward_fee > 0 && <div className="check-report-row check-report-row-small">
                                        <div className="check-report-row-item" > &nbsp;&nbsp;&nbsp; |-{t('分红税')}:</div>
                                        <div className="check-report-row-item">{fatTokenResult.sell_reward_fee}%</div>
                                    </div>}

                                    {!isBlackHoleAddress(fatTokenResult.fund_address) && <div className="check-report-row">
                                        <div className="check-report-row-item">{t('营销钱包')}:</div>
                                        <div className="check-report-row-item">{
                                            getShortAddress(fatTokenResult.fund_address)
                                        } <CopyIcon setSwalProps={(k) => {
                                            props.showMessageModal && props.showMessageModal(k)
                                        }} address={fatTokenResult.fund_address} />
                                        </div>
                                    </div>}
                                    {/* {fatTokenResult.template_id > 2 && <div className="check-report-row">
                                        <div className="check-report-row-item">{t('机制')}</div>
                                        {fatTokenResult.template_id === 3 && <div className="check-report-row-item">
                                            持有至少 ${fatTokenResult.mush_hold_num} {fatTokenResult.symbol}, 可获得 ${fatTokenResult.reward_token_symbol} 分红
                                        </div>}
                                        {fatTokenResult.template_id === 4 && <div className="check-report-row-item" style={{ maxWidth: '50%' }}>
                                            添加 {fatTokenResult.symbol}/{fatTokenResult.currency_symbol} 流动池, 可获得 ${fatTokenResult.reward_token_symbol} 分红
                                        </div>}
                                        {fatTokenResult.template_id === 5 && <div className="check-report-row-item" style={{ maxWidth: '50%' }}>
                                            分红本币
                                        </div>}
                                        {fatTokenResult.template_id === 6 && <div className="check-report-row-item" style={{ maxWidth: '50%' }}>
                                            推荐返利
                                        </div>}
                                        {fatTokenResult.template_id === 6 && <div className="check-report-row-item" style={{ maxWidth: '50%' }}>
                                            添加 {fatTokenResult.symbol}/{fatTokenResult.currency_symbol} 流动池, 可获得 ${fatTokenResult.reward_token_symbol} 分红
                                            <br />
                                            推荐返利
                                        </div>}
                                    </div>} */}
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    {t('交易检查')}
                                </div>
                                <div className="card-body">
                                    {apiRes?.buy_tax !== "" && <div className="check-report-row">
                                        <div className="check-report-row-item">
                                            {t('买入税率')}
                                        </div>
                                        <div className={`check-report-row-item ${getTextSafeClassWithTax(parseFloat(apiRes.buy_tax || 0) * 100)}`}>{parseFloat(apiRes.buy_tax || 0) * 100} %</div>
                                    </div>}
                                    {apiRes?.sell_tax !== "" && <div className="check-report-row">
                                        <div className="check-report-row-item">
                                            {t('卖出税率')}
                                        </div>
                                        <div className={`check-report-row-item ${getTextSafeClassWithTax(parseFloat(apiRes.sell_tax || 0) * 100)}`}> {parseFloat(apiRes.sell_tax || 0) * 100} %</div>
                                    </div>}
                                    {apiRes?.buy_gas_in_usd !== "" && <div className="check-report-row">
                                        <div className="check-report-row-item">
                                            {t('买入Gas')}
                                        </div>
                                        <div className={`check-report-row-item `}>{apiRes.buy_gas}(≈${parseFloat(apiRes.buy_gas_in_usd).toFixed(2)}) </div>
                                    </div>}
                                    {apiRes?.sell_gas_in_usd !== "" && <div className="check-report-row">
                                        <div className="check-report-row-item">
                                            {t('卖出Gas')}
                                        </div>
                                        <div className={`check-report-row-item `}> {apiRes.sell_gas}(≈${parseFloat(apiRes.sell_gas_in_usd).toFixed(2)})</div>
                                    </div>}

                                    {txInfo && txInfo.filter(item => {
                                        const x = txInfoList.find(tx => tx.name === item.name)
                                        if (x && item.result === false && x.falseHidden === true) return false
                                        if (x && item.result === true && x.trueHidden === true) return false
                                        return true
                                    }).map(item => {
                                        return <div key={item.name} className="check-report-row">
                                            <div className="check-report-row-item">
                                                <img className="check-report-row-icon" src={getSafeImg(item)} alt="" />
                                            </div>
                                            <div className={`check-report-row-item ${getTextSafeClass(item)}`}>{getFunctionCheckItemLabel(item)}</div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-xxl-6 col-lg-6 col-xxl-6 col-md-6">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    {t('合约检查')}
                                </div>
                                <div className="card-body">
                                    {caInfo && caInfo.filter(item => {
                                        const x = txInfoList.find(tx => tx.name === item.name)
                                        if (x && item.result === false && x.falseHidden === true) return false
                                        if (x && item.result === true && x.trueHidden === true) return false
                                        return true
                                    }).map(item => {
                                        return <div key={item.name} className="check-report-row">
                                            <div className="check-report-row-item">
                                                <img className="check-report-row-icon" src={getSafeImg(item)} alt="" />
                                            </div>
                                            <div className={`check-report-row-item ${getTextSafeClass(item)}`}>{getFunctionCheckItemLabel(item)}</div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div style={{ width: '100%', paddingBottom: '0px' }} className="check-report-row">
                                        <div className="check-report-row-item text-reset">
                                            {t('持有人检查')}
                                        </div>
                                        <div className="check-report-row-item">
                                            {apiRes.holders_count !== "" && parseInt(apiRes.holders_count) > 0 && <span>{t('持有地址数')}:     {apiRes.holders_count}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {tokenHolders && tokenHolders.map((item, ind) => {
                                        return <div key={item.address}>
                                            <div className="check-report-row">
                                                <div className="check-report-row-item">
                                                    {ind + 1}. &nbsp;&nbsp;
                                                    {parseInt(item.is_locked) > 0 && <span style={{ marginLeft: '5px', marginRight: '5px' }}> <i className="fa fa-lock"></i></span>}
                                                    {item.tag && <span>{item.tag}</span>}
                                                    {!item.tag && <span>{getShortAddress(item.address)}</span>}
                                                    <CopyIcon setSwalProps={(k) => {
                                                        props.showMessageModal && props.showMessageModal(k)
                                                    }} address={item.address} />
                                                </div>
                                                <div className="check-report-row-item">
                                                    {convertToMaxUnit(item.balance)}({(parseFloat(item.percent) * 100).toFixed(2)}%)
                                                </div>
                                            </div>
                                            {item.locked_detail && <div className="check-report-row-t">
                                                <table>
                                                    <thead>
                                                        <tr className="check-report-row-t-tr">
                                                            <th className="check-report-row-t-td">{t('数量')}</th>
                                                            <th className="check-report-row-t-td">{t('状态')}</th>
                                                            <th className="check-report-row-t-td">{t('解锁倒计时')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {item.locked_detail.map((iItem, iInd) => {
                                                            return <LockDetailRow total={apiRes.total_supply} info={iItem} key={iItem.amount + '' + iInd} />
                                                        })}
                                                    </tbody>

                                                </table>
                                            </div>}

                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="check-report-row-item text-reset">
                                        {t('流动性检查')}
                                    </div>
                                    <div className="check-report-row-item">
                                        {apiRes.lp_holders_count !== "" && parseInt(apiRes.lp_holders_count) > 0 && <span>{t('LP持有地址数')}:     {apiRes.lp_holders_count}</span>}
                                    </div>
                                </div>
                                <div className="card-body">
                                    {lpHolders && lpHolders.map((item, ind) => {
                                        return <div key={item.address}>
                                            <div className="check-report-row">
                                                <div className="check-report-row-item">
                                                    {ind + 1}. &nbsp;&nbsp;
                                                    {parseInt(item.is_locked) > 0 && <span style={{ marginLeft: '5px', marginRight: '5px' }}> <i className="fa fa-lock"></i></span>}
                                                    {item.tag && <span>{item.tag}</span>}
                                                    {!item.tag && <span>{getShortAddress(item.address)}</span>}
                                                    <CopyIcon setSwalProps={(k) => {
                                                        props.showMessageModal && props.showMessageModal(k)
                                                    }} address={item.address} />
                                                </div>
                                                <div className="check-report-row-item">
                                                    {convertToMaxUnit(item.balance)}({(parseFloat(item.percent) * 100).toFixed(2)}%)
                                                </div>
                                            </div>
                                            {item.locked_detail && <div className="check-report-row-t">
                                                <table>
                                                    <thead>
                                                        <tr className="check-report-row-t-tr">
                                                            <th className="check-report-row-t-td">{t('数量')}</th>
                                                            <th className="check-report-row-t-td">{t('状态')}</th>
                                                            <th className="check-report-row-t-td">{t('解锁倒计时')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {item.locked_detail.map((iItem, iInd) => {
                                                            return <LockDetailRow total={apiRes.lp_total_supply} info={iItem} key={iItem.amount + '' + iInd} />
                                                        })}
                                                    </tbody>

                                                </table>
                                            </div>}

                                        </div>
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} className="col-12">
                        <a target="_blank" rel="noopener noreferrer" href="https://t.me/TM_ScamFucker">
                            <img src="https://token-monitor.s3.amazonaws.com/16910463393692023-08-03%2015.05.24.jpg" className="power-by-logo" alt="" />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://t.me/Pink_ChaHeYue">
                            <img src="https://token-monitor.s3.amazonaws.com/16910463996062023-08-03%2015.06.33.jpg" className="power-by-logo" alt="" />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://gopluslabs.io/">
                            <img src="https://token-monitor.s3.amazonaws.com/16910468706172023-08-03%2015.14.23.jpg" className="power-by-logo" alt="" />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://honeypot.is/">
                            <img src="https://token-monitor.s3.amazonaws.com/1691253844291WechatIMG44519.png" className="power-by-logo" alt="" />
                        </a>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '8px', marginBottom: '40px', fontSize: '12px' }} className="col-12">
                        Technical Support
                    </div>
                </div>
            </div>
        </>}
    </>
}

export default function CheckTokenSecurity(props) {
    const [contract, setContract] = useState('');
    const [checking, setChecking] = useState(false);
    const [tmpContract, setTmpContract] = useState('');
    const [selectChain, setSelectChain] = useState(supportChains[0]);
    const [swalProps, setSwalProps] = useState({});
    const [hotSearchList, setHotSearchList] = useState([]); // 热搜榜

    const [apiRes, setApiRes] = useState({}); // api响应结果
    const [hideHotSearchList, setHideHotSearchList] = useState(false); // 是否隐藏热搜榜

    const { chain, token } = useParams()

    const history = useHistory()

    const { t } = useTranslation()
    useEffect(() => {
        if (chain && token) {
            const tmpChain = supportChains.find(item => item.shortname === chain)
            if (tmpChain) {
                setContract(token)
                setTmpContract(token)
                setSelectChain(tmpChain)
            }
        } else {
            setContract('')
            setTmpContract('')
            setChecking(false)
            setSelectChain(supportChains[0])
            setApiRes({})
            setHideHotSearchList(false)
        }
        // 获取热搜榜
        getHotSearchList()
    }, [props])



    useEffect(() => {
        if (contract && selectChain?.shortname) {
            checkContractLogic()
        }

    }, [selectChain, contract])


    const showMessageModal = (info) => {
        setSwalProps({
            show: false,
            title: '',
            text: '',
        })
        setTimeout(() => {
            setSwalProps({
                ...info
            })
        }, 50);
    }

    const checkContractLogic = async () => {
        // 进行合约检查
        if (checking) return

        const returnLogic = () => {
            setChecking(false)
        }

        // 检查地址/公链是否正确
        if (!isAddress(contract)) {
            showMessageModal({
                show: true,
                title: t('错误'),
                text: t('请检查合约地址是否正确'),
            })
            return
        }
        setChecking(true)

        // 发送请求
        const { data } = await CheckToken({
            chain_id: selectChain.chainId,
            contract: contract,
        })
        // 格式化响应数据
        if (data.code === 200) {
            if (!data?.data?.symbol) {
                showMessageModal({
                    show: true,
                    title: t('错误'),
                    text: t('检测失败, 请稍后重试'),
                })
                return returnLogic()
            }
            // 拼接基本信息
            setApiRes(data.data)
            setHideHotSearchList(true)
        } else {
            showMessageModal({
                show: true,
                title: t('错误'),
                text: data.message || t('服务器繁忙, 请稍后再试')
            })
        }


        return returnLogic()
    }

    const getHotSearchList = async () => {

        const { data } = await getHotSearchRankList()
        if (data.code === 200) {
            setHotSearchList(data.data)
        }
    }
    const handleClickSearchItem = (item) => {
        const chainName = getChainNameWithChainId(item.chain_id)
        history.push(`/check/${chainName}/${item.address}`)

    }

    const minWidth = isMobile() ? '70' : '150'

    return (
        <div>
            <Header2 />
            <Sidebar />
            <div className="content-body" >
                <div className="card check-body-header">
                    <div>
                        <h3>{t('合约检查')}</h3>
                    </div>
                    {/* <div>
                        聚合多个代币检测工具, 为您提供更精准的检测服务
                    </div> */}
                    <div className="check-body-header-input">
                        <div className="input-group mb-3 mt-2  ">
                            <DropdownButton style={{ minWidth: minWidth + 'px' }} className="check-body-header-input-select" title={
                                <ChainItem logo={selectChain?.logo} name={selectChain?.name} />}
                            >
                                {supportChains && supportChains.map((item, index) => {
                                    return <Dropdown.Item onClick={() => {
                                        setSelectChain(supportChains[index])
                                    }} key={index}>
                                        <ChainItem logo={item.logo} name={item.name} />
                                    </Dropdown.Item>
                                })}
                            </DropdownButton>

                            {/* <div style={{ width: '10px', backgroundColor: '#000000' }}></div> */}
                            <input placeholder={t('输入要检查的代币合约')} type="text" name="usd_amount" className="form-control check-body-header-input-input text-end"
                                value={tmpContract} onChange={(e) => {
                                    setTmpContract(e.target.value)
                                }} />
                        </div>
                    </div>

                    <div className="mt-2">
                        <div onClick={() => {
                            if (!isAddress(tmpContract)) {
                                showMessageModal({
                                    show: true,
                                    title: t('错误'),
                                    text: t('请检查合约地址是否正确'),
                                })
                                return
                            }
                            if (contract === tmpContract) {
                                checkContractLogic()
                            } else {
                                history.push(`/check/${selectChain?.shortname}/${tmpContract}`)
                            }
                        }} className="check-body-header-btn">
                            {checking && <div>
                                <i style={{ fontSize: '15px' }} className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                            </div>}
                            {t('开始检测')}</div>
                    </div>
                </div>
                <div className="check-report">
                    <CheckInfoResPanel apiRes={apiRes} showMessageModal={(k) => {
                        showMessageModal(k)
                    }} />
                    {!hideHotSearchList && <div className="row" style={{ maxWidth: '800px', width: '100%' }}>
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    {t('热门搜索')}
                                </div>
                                <div className="card-body">
                                    <table style={{ fontSize: '12px', textAlign: 'center' }} className="table" tableLayout="fixed">
                                        <thead>
                                            <tr style={{ fontWeight: 'bold' }} className="text-light">
                                                <td style={{ textAlign: 'left', width: '40px' }}>
                                                    #
                                                </td>
                                                {isMobile() === false && <td>
                                                    {t('合约')}
                                                </td>}
                                                <td style={{ textAlign: 'left' }}>
                                                    {t('代币')}
                                                </td>
                                                {isMobile() === false && <td>
                                                    {t('热度')}
                                                </td>}
                                                <td>
                                                    {t('评分')}
                                                </td>
                                                <td></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {hotSearchList.length > 0 && hotSearchList.map((item, ind) => {
                                                return <tr key={`${item.address}-${ind}`} style={{ textAlign: 'center' }}>
                                                    <td style={{ textAlign: 'left', width: '40px' }}>
                                                        #{ind + 1}
                                                    </td>
                                                    {isMobile() === false && <td>
                                                        <span onClick={() => {
                                                            navigator.clipboard.writeText(item.address)
                                                            showMessageModal({
                                                                show: true,
                                                                text: t('已复制'),
                                                            })
                                                        }} className="hot-search-item-td-underline"> {getShortAddress(item.address)}</span>
                                                    </td>}
                                                    <td onClick={() => {
                                                        const chainName = getChainNameWithChainId(item.chain_id)
                                                        history.push('/token/' + chainName + '/' + item.address)
                                                    }} className="hot-search-item-td">
                                                        <Logo size="1.6" chain={item.chain_id} contract={item.address} logo={item.logo} />
                                                        <span style={{ maxWidth: '120px', overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} className="hot-search-item-td-text"> {item.symbol} </span>
                                                    </td>
                                                    {isMobile() === false && <td> <span role="img" aria-label="">🔥 </span>  {item.search_count} </td>}
                                                    <td onClick={() => handleClickSearchItem(item)} style={{ width: '150px', position: 'relative', padding: '0px', cursor: 'pointer' }}>
                                                        <ScoreDashboard fontSize={'12px'} offsetY={-16} score={parseFloat(item.score || 0).toFixed(2)} />
                                                        <span>
                                                            <div className="security-safe-list-hot-td">
                                                                <div className="security-safe-list-item-hot-td">
                                                                    <img className="security-safe-list-item-img-hot-td" src={dangerImg} alt="" />
                                                                    {item.danger_count || 0}
                                                                </div>
                                                                <div className="security-safe-list-item-hot-td">
                                                                    <img className="security-safe-list-item-img-hot-td" src={warnImg} alt="" />
                                                                    {item.warn_count || 0}
                                                                </div>
                                                                <div className="security-safe-list-item-hot-td">
                                                                    <img className="security-safe-list-item-img-hot-td" src={safeImg} alt="" />
                                                                    {item.security_count || 0}
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <Search onClick={() => handleClickSearchItem(item)} />
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>}

                    <div style={{ height: '60px' }}>

                    </div>
                </div>

            </div>
            <SweetAlert2 {...swalProps} />
        </div >
    )

}