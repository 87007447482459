import Network from "./network";


export const AddChainApi = (data) => {
    // 获取代币信息
    return Network.request({
        url: '/admin/add-chain',
        method: 'post',
        data
    })
}

export const UpdateTokenCheckApi = (data) => {
    return Network.request({
        url: '/admin/update-token-check',
        method: 'post',
        data
    })
}

export const AddSwapPairsApi = (data) => {
    // 获取代币信息
    return Network.request({
        url: '/admin/add-pairs',
        method: 'post',
        data
    })
}

export const AddSwapApi = (data) => {
    // 获取代币信息
    return Network.request({
        url: '/admin/add-swap',
        method: 'post',
        data
    })
}

export const getAllChains = (params) => {
    // 获取代币信息
    return Network.request({
        url: '/admin/get-chain-list',
        method: 'get',
        params
    })
}

export const getAllSwapList = (params) => {
    // 获取代币信息
    return Network.request({
        url: '/admin/get-swap-list',
        method: 'get',
        params
    })
}

export const updateTokenOwner = (data) => {
    return Network.request({
        url: '/admin/update-token-owner',
        method: 'post',
        data
    })
}


export const UpdateSwapRouter = (data) => {
    return Network.request({
        url: '/admin/set-swap-router',
        method: 'post',
        data
    })
}

export const clearTokenOwner = (data) => {
    return Network.request({
        url: '/admin/clear-token-owner',
        method: 'post',
        data
    })
}