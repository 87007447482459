import { CheckToken } from "../api/check"
import { NETWORK_LIST } from "./networks"
import { isBlackHoleAddress } from "./tools"

const txInfoList = [
    // {
    //     name: 'buyTax',
    //     label: '买入税率'
    // },
    // {
    //     name: 'sellTax',
    //     label: '卖出税率'
    // },
    // {
    //     name: 'buyGas',
    //     label: '买入Gas'
    // },
    // {
    //     name: 'sellGas',
    //     label: '卖出Gas'
    // },
    {
        name: 'functionCheck.is_honeypot',
        true_label: '模拟交易失败, 可能是蜜罐',
        false_label: '看起来不像是蜜罐',
        safe_level: 0, // 安全等级 1: 安全, 2: 警告, 3: 危险 0 未知
        true_level: 3, // 如果这一项是true的话, 给定的等级
        false_level: 1, // 如果这一项是false的话, 给定的等级
    },
    {
        name: 'functionCheck.transfer_pausable',
        true_label: '可暂停交易',
        false_label: '无交易开关',
        renounce_label: '因放弃所有权, 无法暂停交易',
        safe_level: 0,
        true_level: 3,
        false_level: 1,
    },
    {
        name: 'functionCheck.cannot_sell_all',
        true_label: '代币不能全部卖出',
        false_label: '代币可以全部卖出',
        safe_level: 0,
        true_level: 2,
        false_level: 1,
    },
    {
        name: 'functionCheck.trading_cooldown',
        true_label: '存在交易冷却机制',
        false_label: '无交易冷却机制',
        safe_level: 0,
        true_level: 2,
        false_level: 1,
    },
    {
        name: 'functionCheck.is_anti_whale',
        true_label: '防巨鲸(限购/限卖)',
        false_label: '不限购/限卖',
        safe_level: 0,
        true_level: 2,
        false_level: 1,
    },
    {
        name: 'functionCheck.is_anti_whale_modifiable',
        true_label: '可修改限购/限卖额度',
        false_label: '不可修改限购/限卖额度',
        renounce_label: '因放弃所有权, 无法修改限购/限卖额度',
        safe_level: 0,
        true_level: 3,
        false_level: 1,
    },
    {
        name: 'functionCheck.slippage_modifiable',
        true_label: '可修改交易税率',
        false_label: '不可修改交易税率',
        renounce_label: '因放弃所有权, 不可修改交易税率',
        safe_level: 0,
        true_level: 3,
        false_level: 1,
    },
    {
        name: 'functionCheck.is_blacklisted',
        true_label: '可设置黑名单',
        false_label: '无黑名单机制',
        renounce_label: '因放弃所有权, 不可新增黑名单地址',
        safe_level: 0,
        true_level: 3,
        false_level: 1,
    },
    {
        name: 'functionCheck.personal_slippage_modifiable',
        true_label: '可针对特定地址修改税率',
        false_label: '不可针对特定地址修改税率',
        renounce_label: '因放弃所有权, 不可针对特定地址修改税率',
        safe_level: 0,
        true_level: 3,
        false_level: 1,
    },
]

const caInfoList = [
    // 是否已放弃合约所有权(单独添加)
    // 合约是否开源(单独添加)
    // 是否可增发
    // 是否可取回所有权
    // 是否owner可篡改余额
    // 是否有隐藏owner
    // 是否有外部调用 
    {
        name: 'functionCheck.is_mintable',
        true_label: '可增发代币',
        false_label: '未发现可增发开关',
        renounce_label: '因放弃所有权, 无法增发代币',
        safe_level: 0,
        true_level: 3,
        false_level: 1,
    },
    {
        name: 'functionCheck.can_take_back_ownership',
        true_label: '可取回合约所有权',
        false_label: '未发现取回所有权开关',
        safe_level: 0,
        true_level: 2,
        false_level: 1,
    },
    {
        name: 'functionCheck.owner_change_balance',
        true_label: 'Owner可篡改余额',
        false_label: '未发现Owner修改余额开关',
        safe_level: 0,
        true_level: 3,
        false_level: 1,
    },
    {
        name: 'functionCheck.hidden_owner',
        true_label: '存在隐藏Owner',
        false_label: '未发现隐藏Owner',
        safe_level: 0,
        true_level: 3,
        false_level: 1,
    },
    {
        name: 'functionCheck.external_call',
        true_label: '存在调用外部合约的方法',
        false_label: '未发现调用外部合约风险',
        safe_level: 0,
        true_level: 2,
        false_level: 1,
    },
]



export const getCheckLogic = async (chainId, tokenAddress) => {
    // 获取完整结果
    const chain = NETWORK_LIST.find(item => parseInt(item.chainId) === parseInt(chainId))
    if (!chain) return null
    if (!chain.check) return null
    const { data } = await CheckToken({
        chain_id: chainId,
        contract: tokenAddress,
    })

    if (data.code !== 200) {
        return null
    }


    if (!data.data) return null
    if (!data?.data?.symbol) return null
    return data.data
    let tokenHolders = []
    let lpHolders = []
    let taxInfo = {}
    let baseInfo = {}
    let txInfo = {}
    let caInfo = {}

    const fullRes = data.data

    // 拼接基本信息
    baseInfo = {
        name: data.data.name,
        symbol: data.data.symbol,
        totalSupply: data.data.totalSupply,
        decimals: data.data.decimals,
        owner: data.data.owner_address // 所有者
    }
    const isRenounceOwner = isBlackHoleAddress(data.data.owner_address)

    tokenHolders = data.data.holders
    lpHolders = data.data.lp_holders
    taxInfo = {
        buyTax: data.data.buyTax,
        sellTax: data.data.sellTax,
        buyGas: data.data.buyGas,
        sellGas: data.data.sellGas
    }

    // 拼接交易检查信息
    const tmpTxInfo = txInfoList.map((item) => {
        const ka = item.name.split('.')
        let v = data.data[ka[0]]
        if (ka.length > 1) {
            v = data.data[ka[0]][ka[1]]
        }
        const name = item.name
        let label = ''
        let level = ''

        if (v) { // true
            label = item['true_label']
            level = item['true_level']
            // 如果是已经放弃所有权, 则显示renounce_label
            if (isRenounceOwner && item['renounce_label']) {
                label = item['renounce_label']
                level = item['false_level']
            }
        } else {
            label = item['false_label']
            level = item['false_level']
        }
        return {
            name,
            label,
            level
        }
    })
    // 根据level倒序
    tmpTxInfo.sort((a, b) => {
        return b.level - a.level
    })


    txInfo = tmpTxInfo

    caInfo = caInfoList.map(item => {
        const ka = item.name.split('.')
        let v = data.data[ka[0]]
        if (ka.length > 1) {
            v = data.data[ka[0]][ka[1]]
        }
        const name = item.name
        let label = ''
        let level = ''

        if (v) { // true
            label = item['true_label']
            level = item['true_level']
            // 如果是已经放弃所有权, 则显示renounce_label
            if (isRenounceOwner && item['renounce_label']) {
                label = item['renounce_label']
                level = item['false_level']
            }
        } else {
            label = item['false_label']
            level = item['false_level']
        }
        return {
            name,
            label,
            level
        }
    })

    // 添加是否弃权
    let tmpInfo = []
    if (isRenounceOwner) {
        tmpInfo.push({
            name: 'renounce_owner',
            label: '已放弃合约所有权',
            level: 1,
        })
    } else {
        tmpInfo.push({
            name: 'renounce_owner',
            label: '未放弃合约所有权',
            level: 2,
        })
    }

    // 添加是否已开源
    if (!data.data.functionCheck.verified) {
        tmpInfo.push({
            name: 'verified',
            label: '合约未开源, 无法扫描内部开关',
            level: 3,
        })
    }
    caInfo = [...tmpInfo, ...caInfo]

    // 根据level倒序
    caInfo.sort((a, b) => {
        return b.level - a.level
    })

    const scoreRes = calSecurityScore(txInfo, caInfo)
    return {
        tokenHolders,
        lpHolders,
        taxInfo,
        baseInfo,
        txInfo,
        caInfo,
        scoreRes,
        fullRes
    }
}

export const calSecurityScore = (txInfo, caInfo) => {
    // 设置安全/警告/危险项数量
    let securityItemCount = [0, 0, 0];
    let projectScore = 100;
    const safe = txInfo.filter(item => item.level === 1).length + caInfo.filter(item => item.level === 1).length
    const warning = txInfo.filter(item => item.level === 2).length + caInfo.filter(item => item.level === 2).length
    const danger = txInfo.filter(item => item.level === 3).length + caInfo.filter(item => item.level === 3).length
    const safeList = [...txInfo, ...caInfo].filter(item => item.level === 1)
    const warningList = [...txInfo, ...caInfo].filter(item => item.level === 2)
    const dangerList = [...txInfo, ...caInfo].filter(item => item.level === 3)
    securityItemCount = { safe, warning, danger }
    // 计算安全评分
    const scoreMapping = {
        1: 1,
        2: 3,
        3: 8
    };
    const totalScore = [...txInfo, ...caInfo].reduce((sum, item) => {
        const score = scoreMapping[item.level] || 0;
        return sum + score;
    }, 0);
    const numberOfCheckItems = txInfo.length + caInfo.length; // 假设检查项的数量是14个

    let minScore = 20;
    const maxScore = 100;

    // 如果有警告项, 则最低分数为35
    if ([...txInfo, ...caInfo].filter(item => item.level === 2).length > 0) {
        minScore = 35
    }
    if ([...txInfo, ...caInfo].filter(item => item.level === 3).length > 0) {
        minScore = 45
    }
    const scoreRange = maxScore - minScore;

    // 最大值
    const max = numberOfCheckItems * 8
    const min = numberOfCheckItems * 1 // 最小值
    // 让最大和最小值落在20-100之间
    const projectDangerScore = Math.round((totalScore - min) / (max - min) * scoreRange) + minScore;
    console.log(('最终得分'), projectDangerScore)
    // setSecurityScore(projectDangerScore)

    projectScore = projectDangerScore
    return {
        securityItemCount,
        projectScore,
        safeList,
        warningList,
        dangerList
    }
}