import { HOLDERS_ADD_POOL, HOLDERS_SWAP, HOLDERS_TRANSFER, TASK_NEW_POOL, TASK_POOL_FALL, TASK_POOL_RAISE, TASK_POOL_TARGET_VALUE, TASK_PRICE_FALL, TASK_PRICE_RAISE, TASK_PRICE_TARGET_PRICE, TASK_WALLET_POOL, TASK_WALLET_SWAP, TASK_WALLET_TRANSFER, } from "../jsx/components/DashboardComponents/MonitorModal"
import { converToNumberString, formatTimeString, getBlockBrowerTxUrl, getChainNameWithChainId, getShortAddress } from "./tools"
import i18n from "./i18n"
import { Wallet } from "react-bootstrap-icons"
export const formatTaskRecordContent = (info) => {
    if (!info) return ''
    // 根据不同类型返回不同的内容
    console.log(info, '=====aaaa')
    switch (info.task_type) {
        case TASK_PRICE_TARGET_PRICE:
            return i18n.t('symbol价格达到price').replace('{symbol}', info.token_symbol).replace('{price}', converToNumberString(info.current_price))
        case TASK_PRICE_RAISE:
            return i18n.t('symbol价格涨幅达到percent').replace('{symbol}', info.token_symbol).replace('{percent}', info.current_raise)
        case TASK_PRICE_FALL:
            return i18n.t('symbol价格跌幅达到percent').replace('{symbol}', info.token_symbol).replace('{percent}', info.current_fall)
        case TASK_POOL_TARGET_VALUE:
            return i18n.t('symbol流动池总价值达到price').replace('{symbol}', info.pool_symbol).replace('{price}', converToNumberString(info.current_cap))
        case TASK_POOL_RAISE:
            return i18n.t('symbol流动池总价值涨幅达到percent').replace('{symbol}', info.pool_symbol).replace('{percent}', converToNumberString(info.current_cap_raise))
        case TASK_POOL_FALL:
            return i18n.t('symbol流动池总价值跌幅达到percent').replace('{symbol}', info.pool_symbol).replace('{percent}', converToNumberString(info.current_cap_fall))
        case HOLDERS_TRANSFER:
            const transferType = info.transfer_type === 'receive' ? i18n.t('接收') : i18n.t('转出')
            return i18n.t("#鲸鱼异动,代币转移").replace('{num}', info.whale_rank).replace('{action}', transferType).replace('{amount}', converToNumberString(info.transfer_amount)).replace('{symbol}', info.token_symbol)
        case HOLDERS_ADD_POOL:
            const addType = parseInt(info.pool_action) === 1 ? i18n.t('添加') : i18n.t('移除')
            return i18n.t(`#鲸鱼异动,添加流动性`).replace('{num}', info.whale_rank).replace('{action}', addType).replace('{token0_amount}', converToNumberString(info.pool_action_token_0_amount)).replace('{token0_symbol}', info.pool_action_token_0_symbol).replace('{token1_amount}', converToNumberString(info.pool_action_token_1_amount)).replace('{token1_symbol}', info.pool_action_token_1_symbol)
        case HOLDERS_SWAP:
            return i18n.t(`#鲸鱼异动,兑换`).replace('{num}', info.whale_rank).replace('{input_amount}', converToNumberString(info.input_token_amount)).replace('{input_symbol}', info.input_token_symbol).replace('{output_amount}', converToNumberString(info.output_token_amount)).replace('{output_symbol}', info.output_token_symbol)
        case TASK_WALLET_SWAP:
            return i18n.t('使用x枚x兑换x枚x').replace('{wallet}', getShortAddress(info.whale_address, 4)).replace('{input_amount}', converToNumberString(info.input_token_amount)).replace('{input_symbol}', info.input_token_symbol).replace('{output_amount}', converToNumberString(info.output_token_amount)).replace('{output_symbol}', info.output_token_symbol)
        case TASK_WALLET_POOL:
            return i18n.t('x添加了流动性(x枚y+x枚y)').replace('{wallet}', getShortAddress(info.whale_address, 4)).replace('{token0_amount}', converToNumberString(info.pool_action_token_0_amount)).replace('{token0_symbol}', info.pool_action_token_0_symbol).replace('{token1_amount}', converToNumberString(info.pool_action_token_1_amount)).replace('{token1_symbol}', info.pool_action_token_1_symbol).replace('{value}', converToNumberString(info.pool_action_value))
        case TASK_WALLET_TRANSFER:
            return i18n.t(`x转出了x枚x`).replace('{wallet}', getShortAddress(info.whale_address, 4)).replace('{action}', info.transfer_type === 'receive' ? i18n.t('接收') : i18n.t('转出')).replace('{amount}', converToNumberString(info.transfer_amount)).replace('{symbol}', info.token_symbol)
        case TASK_NEW_POOL:
            return i18n.t('新池开盘: USDT/ETH').replace('{token0}', info.pool_action_token_0_symbol).replace('{token1}', info.pool_action_token_1_symbol)
        default:
            return ''

    }

}

export const formatTaskRecordDetail = (info) => {
    // 包含 1个message 一句话内容, 一个columns列表(table里的信息) 一个links(对应的链接)
    if (!info) return { message: '', columns: [], links: [] }

    let columns = []
    let links = []
    let x = { columns: [], links: [] }
    switch (info.task_type) {
        case TASK_PRICE_TARGET_PRICE:
            x = handleTaskPriceTargetPriceNotification(info)
            break
        case TASK_PRICE_RAISE:
            x = handleTaskPriceRaiseNotification(info)
            break
        case TASK_PRICE_FALL:
            x = handleTaskPriceFallNotification(info)
            break
        case TASK_POOL_TARGET_VALUE:
            x = handleTaskPoolTargetValueNotification(info)
            break
        case TASK_POOL_RAISE:
            x = handleTaskPoolRaiseNotification(info)
            break
        case TASK_POOL_FALL:
            x = handleTaskPoolFallNotification(info)
            break
        case HOLDERS_TRANSFER:
            x = handleTaskHolderTransferNotification(info)
            break
        case HOLDERS_ADD_POOL:
            x = handleTaskHolderAddPoolNotification(info)
            break
        case HOLDERS_SWAP:
            x = handleTaskHolderSwapNotification(info)
            break
        case TASK_WALLET_SWAP:
            x = handleTaskWalletSwapNotification(info)
            break
        case TASK_WALLET_POOL:
            x = handleTaskWalletPoolNotification(info)
            break
        case TASK_WALLET_TRANSFER:
            x = handleTaskWalletTransferNotification(info)
            break
        case TASK_NEW_POOL:
            x = handleTaskNewPoolNotification(info)
            break
        default:
            return ''

    }

    return {
        message: formatTaskRecordContent(info),
        columns: x.columns,
        links: x.links
    }
}


const handleTaskNewPoolNotification = (info) => {
    const chainName = getChainNameWithChainId(info.chain_id)
    const columns = [
        {
            name: i18n.t('交易对'),
            value: `${info.pool_action_token_0_symbol}/${info.pool_action_token_1_symbol}`
        },
        {
            name: i18n.t('公链'),
            value: chainName.toUpperCase()
        },
        {
            name: i18n.t('触发关键字'),
            value: info?.keyword
        },
        {
            name: i18n.t('加池金额'),
            value: `$${converToNumberString(info.pool_action_value)}`
        },
        {
            name: i18n.t('加池钱包'),
            value: getShortAddress(info.whale_address, 4),
            copy: true,
            copyValue: info.whale_address
        },

        {
            name: i18n.t('时间'),
            value: formatTimeString(info.time * 1000)
        },
        {
            name: i18n.t('备注'),
            value: info.note
        },
    ]

    const links = [
        {
            name: i18n.t('交易哈希'),
            value: getBlockBrowerTxUrl(info.chain_id, info.tx_hash)
        },
        {
            name: i18n.t('查看') + ' ' + info.pool_action_token_0_symbol,
            value: '',
            router: `/token/${chainName}/${info.pool_action_token_0}${info.pool_address ? `?pair=${info.pool_address}` : ''}`
        },
        {
            name: i18n.t('查看') + ' ' + info.pool_action_token_1_symbol,
            value: '',
            router: `/token/${chainName}/${info.pool_action_token_1}${info.pool_address ? `?pair=${info.pool_address}` : ''}`
        }
    ]

    return {
        columns,
        links
    }
}

const handleTaskWalletTransferNotification = (info) => {
    const transferType = info.transfer_type === 'receive' ? i18n.t('转入') : i18n.t('转出')
    const actionAddress = info.transfer_type === 'receive' ? info.from_address : info.to_address
    const columns = [
        {
            name: i18n.t('公链ID'),
            value: info.chain_id
        },
        {
            name: i18n.t('监控地址'),
            value: info.whale_address
        },
        {
            name: i18n.t('动作'),
            value: transferType + info.token_symbol
        },
        {
            name: `${transferType}${i18n.t('钱包')}`,
            value: actionAddress
        },
        {
            name: i18n.t('数量'),
            value: `${converToNumberString(info.transfer_amount)}`
        },
        {
            name: i18n.t('时间'),
            value: formatTimeString(info.time * 1000)
        },
        {
            name: i18n.t('备注'),
            value: info.note
        },
    ]
    const links = [
        {
            name: i18n.t('交易哈希'),
            value: getBlockBrowerTxUrl(info.chain_id, info.tx_hash)
        },
    ]
    return {
        columns,
        links
    }

}

const handleTaskWalletPoolNotification = (info) => {
    const actionType = parseInt(info.pool_action) === 1 ? i18n.t('加池') : i18n.t('撤池')
    const columns = [
        {
            name: i18n.t('交易对'),
            value: info.pool_symbol
        },
        {
            name: i18n.t('公链ID'),
            value: info.chain_id
        },
        {
            name: i18n.t('监控地址'),
            value: info.whale_address
        },
        {
            name: i18n.t('动作'),
            value: actionType
        },
        {
            name: i18n.t('数量'),
            value: `${converToNumberString(info.pool_action_token_0_amount)}${info.pool_action_token_0_symbol}+${info.pool_action_token_1_amount}${info.pool_action_token_1_symbol}`
        },
        {
            name: i18n.t('交易额'),
            value: `$${converToNumberString(info.pool_action_value)}`
        },
        {
            name: i18n.t('时间'),
            value: formatTimeString(info.time * 1000)
        },
        {
            name: i18n.t('备注'),
            value: info.note
        },
    ]
    const links = [
        {
            name: i18n.t('交易哈希'),
            value: getBlockBrowerTxUrl(info.chain_id, info.tx_hash)
        },
    ]
    return {
        columns,
        links
    }
}

const handleTaskWalletSwapNotification = (info) => {
    const columns = [
        {
            name: i18n.t('公链'),
            value: info.chain_name?.toUpperCase()
        },
        {
            name: i18n.t('监控地址'),
            value: info.whale_address
        },
        {
            name: i18n.t('动作'),
            value: i18n.t('使用x枚x兑换x枚x').replace('{wallet}', '').replace('{input_amount}', converToNumberString(info.input_token_amount)).replace('{input_symbol}', info.input_token_symbol).replace('{output_amount}', converToNumberString(info.output_token_amount)).replace('{output_symbol}', info.output_token_symbol)
        },
        {
            name: i18n.t('交易额'),
            value: `$${converToNumberString(info.swap_volume_in_usd)}`
        },
        {
            name: i18n.t('时间'),
            value: formatTimeString(info.time * 1000)
        },
        {
            name: i18n.t('备注'),
            value: info.note
        },
    ]
    const links = [
        {
            name: i18n.t('交易哈希'),
            value: getBlockBrowerTxUrl(info.chain_id, info.tx_hash)
        },
    ]
    return {
        columns,
        links
    }
}

const handleTaskHolderSwapNotification = (info) => {
    const columns = [
        {
            name: i18n.t('交易对'),
            value: info.pool_symbol
        },
        {
            name: i18n.t('公链'),
            value: info.chain_name?.toUpperCase()
        },
        {
            name: i18n.t('鲸鱼地址'),
            value: info.whale_address
        },
        {
            name: i18n.t('鲸鱼排名'),
            value: info.whale_rank
        },
        {
            name: i18n.t('动作'),
            value: i18n.t('使用x枚x兑换x枚x').replace('{wallet}', '').replace('{input_amount}', converToNumberString(info.input_token_amount)).replace('{input_symbol}', info.input_token_symbol).replace('{output_amount}', converToNumberString(info.output_token_amount)).replace('{output_symbol}', info.output_token_symbol)
        },
        {
            name: i18n.t('交易额'),
            value: `$${converToNumberString(info.swap_volume_in_usd)}`
        },
        {
            name: i18n.t('时间'),
            value: formatTimeString(info.time * 1000)
        },
        {
            name: i18n.t('备注'),
            value: info.note
        },
    ]
    const links = [
        {
            name: i18n.t('交易哈希'),
            value: getBlockBrowerTxUrl(info.chain_id, info.tx_hash)
        },
    ]
    return {
        columns,
        links
    }
}

const handleTaskHolderAddPoolNotification = (info) => {
    const actionType = parseInt(info.pool_action) === 1 ? i18n.t('加池') : i18n.t('撤池')
    const columns = [
        {
            name: i18n.t('交易对'),
            value: info.pool_symbol
        },
        {
            name: i18n.t('公链'),
            value: info.chain_name?.toUpperCase()
        },
        {
            name: i18n.t('鲸鱼地址'),
            value: info.whale_address
        },
        {
            name: i18n.t('鲸鱼排名'),
            value: info.whale_rank
        },
        {
            name: i18n.t('动作'),
            value: actionType
        },
        {
            name: i18n.t('数量'),
            value: `${converToNumberString(info.pool_action_token_0_amount)}${info.pool_action_token_0_symbol}+${converToNumberString(info.pool_action_token_1_amount)}${info.pool_action_token_1_symbol}`
        },
        {
            name: i18n.t('交易额'),
            value: `$${converToNumberString(info.pool_action_value)}`
        },
        {
            name: i18n.t('时间'),
            value: formatTimeString(info.time * 1000)
        },
        {
            name: i18n.t('备注'),
            value: info.note
        },
    ]
    const links = [
        {
            name: i18n.t('交易哈希'),
            value: getBlockBrowerTxUrl(info.chain_id, info.tx_hash)
        },
    ]
    return {
        columns,
        links
    }
}

const handleTaskHolderTransferNotification = (info) => {
    const transferType = info.transfer_type === 'receive' ? i18n.t('接收') : i18n.t('转出')
    const actionAddress = info.transfer_type === 'receive' ? info.from_address : info.to_address
    const columns = [
        {
            name: i18n.t('交易对'),
            value: info.pool_symbol
        },
        {
            name: i18n.t('公链'),
            value: info.chain_name?.toUpperCase()
        },
        {
            name: i18n.t('鲸鱼地址'),
            value: info.whale_address
        },
        {
            name: i18n.t('鲸鱼排名'),
            value: info.whale_rank
        },
        {
            name: i18n.t('动作'),
            value: transferType
        },
        {
            name: `${transferType}${i18n.t('地址')}`,
            value: actionAddress
        },
        {
            name: `${transferType}${i18n.t('数量')}`,
            value: `${converToNumberString(info.transfer_amount)}(${info.token_percent * 100}%)`
        },
        {
            name: i18n.t('时间'),
            value: formatTimeString(info.time * 1000)
        },
        {
            name: i18n.t('备注'),
            value: info.note
        },
    ]
    const links = []
    return {
        columns,
        links
    }
}

const handleTaskPoolFallNotification = (info) => {
    const columns = [
        {
            name: i18n.t('交易对'),
            value: info.pool_symbol
        },
        {
            name: i18n.t('公链'),
            value: info.chain_name?.toUpperCase()
        },
        {
            name: i18n.t('当前市值'),
            value: converToNumberString(info.current_cap)
        },
        {
            name: i18n.t('初始市值'),
            value: converToNumberString(info.origin_cap)
        },
        {
            name: i18n.t('当前跌幅'),
            value: converToNumberString(info.current_cap_fall)
        },
        {
            name: i18n.t('目标跌幅'),
            value: converToNumberString(info.target_cap_fall)
        },

        {
            name: i18n.t('时间'),
            value: formatTimeString(info.time * 1000)
        },
        {
            name: i18n.t('备注'),
            value: info.note
        },
        {
            name: '',
            value: i18n.t('初始市值已更新为当前市值')
        },
    ]
    const links = [
        {
            name: i18n.t('交易哈希'),
            value: getBlockBrowerTxUrl(info.chain_id, info.tx_hash)
        },
    ]
    return {
        columns,
        links
    }
}

const handleTaskPoolRaiseNotification = (info) => {
    const columns = [
        {
            name: i18n.t('交易对'),
            value: info.pool_symbol
        },
        {
            name: i18n.t('公链'),
            value: info.chain_name?.toUpperCase()
        },
        {
            name: i18n.t('当前市值'),
            value: converToNumberString(info.current_cap)
        },
        {
            name: i18n.t('初始市值'),
            value: converToNumberString(info.origin_cap)
        },
        {
            name: i18n.t('当前涨幅'),
            value: converToNumberString(info.current_cap_raise)
        },
        {
            name: i18n.t('目标涨幅'),
            value: converToNumberString(info.target_cap_raise)
        },

        {
            name: i18n.t('时间'),
            value: formatTimeString(info.time * 1000)
        },
        {
            name: i18n.t('备注'),
            value: info.note
        },
        {
            name: '',
            value: i18n.t('初始市值已更新为当前市值')
        },
    ]
    const links = [
        {
            name: i18n.t('交易哈希'),
            value: getBlockBrowerTxUrl(info.chain_id, info.tx_hash)
        },
    ]
    return {
        columns,
        links
    }
}

const handleTaskPoolTargetValueNotification = (info) => {
    const columns = [
        {
            name: i18n.t('交易对'),
            value: info.pool_symbol
        },
        {
            name: i18n.t('公链'),
            value: info.chain_name?.toUpperCase()
        },
        {
            name: i18n.t('当前市值'),
            value: converToNumberString(info.current_cap)
        },
        {
            name: i18n.t('初始市值'),
            value: converToNumberString(info.origin_cap)
        },
        {
            name: i18n.t('目标市值'),
            value: converToNumberString(info.target_cap)
        },
        {
            name: i18n.t('时间'),
            value: formatTimeString(info.time * 1000)
        },
        {
            name: i18n.t('备注'),
            value: info.note
        },
    ]
    const links = [
        {
            name: i18n.t('交易哈希'),
            value: getBlockBrowerTxUrl(info.chain_id, info.tx_hash)
        },
    ]
    return {
        columns,
        links
    }
}

const handleTaskPriceFallNotification = (info) => {
    // 价格达到$xxxx
    const columns = [
        {
            name: i18n.t('交易对'),
            value: info.pool_symbol
        },
        {
            name: i18n.t('公链'),
            value: info.chain_name?.toUpperCase()
        },
        {
            name: i18n.t('现价'),
            value: converToNumberString(info.current_price)
        },
        {
            name: i18n.t('初始价格'),
            value: converToNumberString(info.origin_price)
        },
        {
            name: i18n.t('当前跌幅'),
            value: converToNumberString(info.current_fall)
        },
        {
            name: i18n.t('目标跌幅'),
            value: converToNumberString(info.target_fall)
        },
        {
            name: i18n.t('时间'),
            value: formatTimeString(info.time * 1000)
        },
        {
            name: i18n.t('备注'),
            value: info.note
        },
        {
            name: '',
            value: i18n.t('初始价格已更新为当前价格')
        }
    ]
    const links = [
        {
            name: i18n.t('交易哈希'),
            value: getBlockBrowerTxUrl(info.chain_id, info.tx_hash)
        },
    ]
    return {
        columns,
        links
    }
}

const handleTaskPriceRaiseNotification = (info) => {
    // 价格达到$xxxx
    const columns = [
        {
            name: i18n.t('交易对'),
            value: info.pool_symbol
        },
        {
            name: i18n.t('公链'),
            value: info.chain_name?.toUpperCase()
        },
        {
            name: i18n.t('现价'),
            value: converToNumberString(info.current_price)
        },
        {
            name: i18n.t('初始价格'),
            value: converToNumberString(info.origin_price)
        },
        {
            name: i18n.t('当前涨幅'),
            value: converToNumberString(info.current_raise)
        },
        {
            name: i18n.t('目标涨幅'),
            value: converToNumberString(info.target_raise)
        },
        {
            name: i18n.t('时间'),
            value: formatTimeString(info.time * 1000)
        },
        {
            name: i18n.t('备注'),
            value: info.note
        },
        {
            name: '',
            value: i18n.t('初始价格已更新为当前价格')
        }
    ]
    const links = [
        {
            name: i18n.t('交易哈希'),
            value: getBlockBrowerTxUrl(info.chain_id, info.tx_hash)
        },
    ]
    return {
        columns,
        links
    }

}

const handleTaskPriceTargetPriceNotification = (info) => {
    // 价格达到$xxxx
    const columns = [
        {
            name: i18n.t('交易对'),
            value: info.pool_symbol
        },
        {
            name: i18n.t('公链'),
            value: info.chain_name?.toUpperCase()
        },
        {
            name: i18n.t('现价'),
            value: converToNumberString(info.current_price)
        },
        {
            name: i18n.t('初始价格'),
            value: converToNumberString(info.origin_price)
        },
        {
            name: i18n.t('目标价格'),
            value: converToNumberString(info.target_price)
        },
        {
            name: i18n.t('时间'),
            value: formatTimeString(info.time * 1000)
        },
        {
            name: i18n.t('备注'),
            value: info.note
        },
    ]
    const links = [
        {
            name: i18n.t('交易哈希'),
            value: getBlockBrowerTxUrl(info.chain_id, info.tx_hash)
        },
    ]
    return {
        columns,
        links
    }
}