import React, { useEffect, useRef, useState } from 'react';
import TimeDatePicker from '../element/datepicker';
import PageTitle from '../element/page-title';
import Footer2 from '../layout/footer2';
// import { Link } from 'react-router-dom';
// import { Row, Col, Card } from 'react-bootstrap';
import Header2 from '../layout/header2';
import SettingsSubmenu from '../layout/settings-submenu';
import Sidebar from '../layout/sidebar';
import { Alert, Modal, Nav, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { getBindTgCode, getNotificationTypeList, unbindTelegramNotificationType } from '../../api/login';
import { NOTIFICATION_TYPE_TELEGRAM } from '../components/DashboardComponents/MonitorModal';
import { toast } from 'react-toastify';
import { withSwal } from 'react-sweetalert2';
import i18n from '../../utils/i18n';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { eventBus } from '../../utils/tools';
import { Files } from 'react-bootstrap-icons';




export const ConfirmButton = withSwal(({ swal, ok = null, cancel = null, children = null, title = null }, ref) => (
    <span onClick={() => {
        swal.fire({
            title,
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: i18n.t('确认'),
            cancelButtonText: i18n.t('取消'),
        }).then((a) => {
            if (a.isConfirmed) {
                // 删除
                ok && ok()
            } else {
                cancel && cancel()
            }
        })

    }}>{children}</span>
));

export const BindTelegramModal = (props) => {

    const { t } = useTranslation()

    const getBindCode = () => {
        return `bd_${props.code || ''}`
    }

    return <Modal centered contentClassName="search-modal" show={props.show} onHide={() => {
        props.handleClose && props.handleClose()
    }} >
        <Modal.Body>
            <div>
                <div className="login-header"> {t('绑定Telegram')} </div>
                <div className='mt-2'>

                    <div>
                        {t('部分钱包不支持直接打开Telegram, 如果没能成功跳转到Telegram机器人, 请点击下方复制链接, 在系统浏览器中打开.')}
                    </div>

                    <Alert variant='success' className='mt-2'>
                        <div>
                            <span onClick={() => {
                                navigator.clipboard.writeText(props?.url)
                                toast.success(t('已复制'))
                            }} style={{ borderBottom: '1px dashed #999999', cursor: 'pointer', wordBreak: 'break-all' }}> {props.url || ''} <Files /> </span>
                        </div>
                    </Alert>
                    <div className='mt-2'>
                        {t('或在Telegram中搜索')} <span onClick={() => {
                            navigator.clipboard.writeText('TmdMsgBot')
                            toast.success(t('已复制'))
                        }} style={{ borderBottom: '1px dashed #999999', cursor: 'pointer' }}>TmdMsgBot <Files /></span> {t('开启机器人')}, {t('并发送下方的绑定码, 即可完成绑定')}.
                    </div>
                    <Alert variant='warning' className='mt-2'>
                        <div style={{ width: '100%' }}>
                            <div style={{ fontWeight: '400' }}>{t('绑定码')}</div>
                            <div style={{ textAlign: 'center', width: '100%' }}>
                                <span onClick={() => {
                                    navigator.clipboard.writeText(getBindCode())
                                    toast.success(t('已复制'))
                                }} style={{ borderBottom: '1px dashed #999999', fontSize: '16px', textAlign: 'center', fontWeight: 'bold', cursor: 'pointer' }}>
                                    {getBindCode()} <Files />
                                </span>
                            </div>
                        </div>
                    </Alert>
                    <div>
                        <div onClick={() => {
                            props.handleClose && props.handleClose()
                        }} className='btn btn-danger btn-sm'> {t('关闭')} </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
}

function Settings() {
    const [currentTab, setCurrentTab] = useState(0) // 0通知 1联系我们 2app下载
    const [notificationTypes, setNotificationTypes] = useState(0) // 0通知 1联系我们 2app下载
    const [showBindTelegramModal, setShowBindTelegramModal] = useState(false) // 展示绑定telegram的modal
    const [bindTelegramCode, setBindTelegramCode] = useState('') // 绑定telegram的code
    const [bindTelegramUrl, setBindTelegramUrl] = useState('') // 绑定telegram的url
    const notificationTypesTimer = useRef(null)
    const { t, i18n } = useTranslation()
    const currnetLanguage = i18n.language; // 获取当前语言

    const fetchData = async () => {
        const { data } = await getNotificationTypeList({})
        if (data.code === 200 && data.data) {
            // 更新通知列表
            const m = {}
            const n = {}
            data.data.forEach(item => {
                if (item.extra !== "") {
                    item.extra = JSON.parse(item.extra)
                }
                m[item.type] = item
                n[item.type] = item.id
            })
            setNotificationTypes(m)
            if (m[NOTIFICATION_TYPE_TELEGRAM] && notificationTypesTimer.current) {
                console.log('已停止定时器')
                toast.success(t('已绑定'))
                setShowBindTelegramModal(false)
                clearInterval(notificationTypesTimer.current)
                return
            }
        } else {
            setNotificationTypes({})
        }
    }


    // 获取通知列表
    useEffect(() => {
        fetchData()
    }, [])

    const unbindTelegram = async (item) => {
        // 解绑tg信息
        const { data } = await unbindTelegramNotificationType({
            id: item.id
        })
        if (data.code === 200) {
            toast.success(t('已解绑'))
            fetchData()
        } else {
            toast.error(t('解绑失败'))
        }
    }

    const startTimer = () => {
        notificationTypesTimer.current = setInterval(() => {
            fetchData()
        }, 2000);

    }

    const currentUser = useSelector(state => state.currentUser)

    const bindTelegram = async () => {
        if (!currentUser || !currentUser.id) {
            toast(t('请先登录'))
            eventBus.emit('request_login')
            return
        }
        const { data } = await getBindTgCode()
        const code = data.data
        const url = `https://t.me/TmdMsgBot?start=${code}_${currnetLanguage}`
        setBindTelegramUrl(url)
        setBindTelegramCode(`${code}_${currnetLanguage}`)
        setShowBindTelegramModal(true)
        // alert(url)
        // 打开一个新页面
        window.open(url, '_blank')
        // const w = window.open(url, '_blank')
        // w.location.href = url
        // 打开一个定时器进行轮询
        startTimer()
    }

    const setLanguage = (lang) => {
        i18n.changeLanguage(lang)
    }

    return (
        <>
            <BindTelegramModal
                show={showBindTelegramModal}
                url={bindTelegramUrl}
                code={bindTelegramCode}
                handleClose={() => {
                    setShowBindTelegramModal(false)
                    clearInterval(notificationTypesTimer.current)
                }}
            />
            <Header2 />
            <Sidebar />
            {/* <PageTitle /> */}

            <div className="content-body">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card sub-menu">
                                <div className="card-body">
                                    {/* <SettingsSubmenu /> */}
                                    {/* <Tab.Container defaultActiveKey={'notify-setting'} > */}
                                    <div className='card-header'>
                                        <ul className="d-flex">
                                            <li className="nav-item">
                                                <span to={"#"} onClick={() => setCurrentTab(0)} className={"pointer-btn settings-item " + (currentTab === 0 ? 'text-primary' : 'text-body')}>
                                                    <i className="mdi mdi-account-settings-variant"></i>
                                                    <span  >{t('通知设置')}</span>
                                                </span>
                                            </li>
                                            <li className="nav-item">
                                                <span to={"#"} onClick={() => setCurrentTab(3)} className={"pointer-btn settings-item " + (currentTab === 3 ? 'text-primary' : 'text-body')}>
                                                    <i className="mdi mdi-web"></i>
                                                    <span  >{t('语言设置')}</span>
                                                </span>
                                            </li>
                                            <li className="nav-item">
                                                <span to={"#"} onClick={() => setCurrentTab(1)} className={"pointer-btn settings-item " + (currentTab === 1 ? 'text-primary' : 'text-body')}>
                                                    <i className="mdi mdi-phone"></i>
                                                    <span  >{t('联系我们')}</span>
                                                </span>
                                            </li>
                                            <li className="nav-item">
                                                <span to={"#"} onClick={() => setCurrentTab(2)} className={" pointer-btn settings-item " + (currentTab === 2 ? 'text-primary' : 'text-body')}>
                                                    <i className="mdi mdi-cloud-download"></i>
                                                    <span className={currentTab === 2 ? 'text-primary' : ''} >{t('APP下载')}</span>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className='row'>
                                {currentTab === 0 && <div className='col-12'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th className='text-center'>
                                                            {t('通知类型')}
                                                        </th>
                                                        <th className='text-center'>
                                                            {t('绑定信息')}
                                                        </th>
                                                        <th className='text-center'>
                                                            {t('操作')}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className='text-center'>
                                                            Telegram
                                                        </td>
                                                        <td className='text-center'>
                                                            {notificationTypes[NOTIFICATION_TYPE_TELEGRAM] && <a target='_blank' rel='noopener noreferrer' href={`https://t.me/${notificationTypes[NOTIFICATION_TYPE_TELEGRAM].extra.user_name}`}>        {notificationTypes[NOTIFICATION_TYPE_TELEGRAM].extra.user_name}</a>}
                                                            {!notificationTypes[NOTIFICATION_TYPE_TELEGRAM] && t('未绑定')}
                                                        </td>
                                                        <td className='text-center'>
                                                            {/* {notificationTypes[NOTIFICATION_TYPE_TELEGRAM] && <span className='btn text-primary'>换绑</span>} */}
                                                            {!notificationTypes[NOTIFICATION_TYPE_TELEGRAM] && <span onClick={bindTelegram.bind(this)} className='btn text-primary'>{t('绑定')}</span>}
                                                            {notificationTypes[NOTIFICATION_TYPE_TELEGRAM] && <ConfirmButton title={t('解绑后所有通知将不可用,确认解绑?')} ok={unbindTelegram.bind(this, notificationTypes[NOTIFICATION_TYPE_TELEGRAM])}>  <span className='btn text-primary'>{t('解绑')}</span>   </ConfirmButton>}

                                                        </td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td className='text-center'>
                                                            邮箱
                                                        </td>
                                                        <td className='text-center'>
                                                            未绑定
                                                        </td>
                                                        <td className='text-center'>
                                                            <span className='btn text-primary'>换绑</span>
                                                            <span className='btn text-primary'>绑定</span>
                                                            <span className='btn text-primary'>解绑</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='text-center'>
                                                            APP
                                                        </td>
                                                        <td className='text-center'>
                                                            Iphone 14
                                                        </td>
                                                        <td className='text-center'>
                                                            <span className='btn text-primary'>换绑</span>
                                                            <span className='btn text-primary'>绑定</span>
                                                            <span className='btn text-primary'>解绑</span>
                                                        </td>
                                                    </tr> */}
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>}
                                {currentTab === 1 && <div className='col-12'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <table className='table'>
                                                <tbody>
                                                    <tr>
                                                        <td className='text-center'>
                                                            Telegram
                                                        </td>
                                                        <td className='text-center'>
                                                            <a target='_blank' rel='noopener noreferrer' href="https://t.me/tokenmonitor">https://t.me/tokenmonitor</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='text-center'>
                                                            {t('邮箱')}
                                                        </td>
                                                        <td className='text-center'>
                                                            <a target='_blank' rel='noopener noreferrer' href="mailto:bd@tmdex.io">bd@tmdex.io</a>
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>}
                                {currentTab === 2 && <div className='col-12'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            {t('即将开放, 敬请期待~')}
                                        </div>
                                    </div>
                                </div>}
                                {currentTab === 3 && <div className='col-12'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <div onClick={() => setLanguage('en')} className='mobile-language-setting-item'>
                                                <span className='mobile-language-setting-item-icon'>
                                                    {i18n.language === 'en' && <span>  <i className="fa fa-check"></i></span>}
                                                </span >
                                                <span className='mobile-language-setting-item-text'>
                                                    <i style={{ marginRight: '5px' }} className="flag-icon flag-icon-us"></i>

                                                    English</span>
                                            </div>
                                            <div onClick={() => setLanguage('zh')} className='mobile-language-setting-item'>
                                                <div className='mobile-language-setting-item-icon'>
                                                    {i18n.language === 'zh' && <span>  <i className="fa fa-check"></i></span>}
                                                </div >
                                                <span className='mobile-language-setting-item-text'>
                                                    <i style={{ marginRight: '5px' }} className="flag-icon flag-icon-cn"></i>

                                                    简体中文</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer2 />
        </>
    )
}

export default Settings;