
const BSC_MAINNET = 56
const OKX_MAINNET = 66
const CORE_MAINNET = 1116
const GOERLI_TESTNET = 5
const ETH_MAINNET = 1
const BSC_NETWORK = {
    chainId: BSC_MAINNET,
    name: 'BSC',
    shortname: 'bsc',
    symbol: 'BNB',
    logo: 'https://token-monitor.s3.amazonaws.com/bsc_thumb_logo.png',// 缩略图
    check: true, // 是否允许进行合约检查
    explorer: 'https://bscscan.com',
    multicall: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
    multicall3: '0xcA11bde05977b3631167028862bE2a173976CA11',
    swap_func: '0x1d4d9d219d5ff0dd25f89a24d377acfe3027b3f4',
    commonTokens: [
        {
            name: 'BNB',
            symbol: 'BNB',
            isWETH: true,
            logo: 'https://token-monitor.s3.amazonaws.com/bsc_thumb_logo.png',
            address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
        },
        {
            name: 'USDT',
            symbol: 'USDT',
            address: '0x55d398326f99059ff775485246999027b3197955',
            isWETH: false,
            logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png'
        },
        {
            name: 'USDC',
            symbol: 'USDC',
            address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
            isWETH: false,
            logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png'
        },
    ]
}

const OKX_NETWORK = {
    chainId: OKX_MAINNET,
    name: 'OKTC',
    symbol: 'OKT',
    shortname: 'oec',
    logo: 'https://token-monitor.s3.amazonaws.com/okx_logo.jpeg',// 缩略图
    check: true, // 是否允许进行合约检查
    explorer: 'https://www.oklink.com/oktc',
    multicall: '0x94fEadE0D3D832E4A05d459eBeA9350c6cDd3bCa',
    multicall3: '0xcA11bde05977b3631167028862bE2a173976CA11',
    commonTokens: [
        {
            name: 'OKT',
            symbol: 'OKT',
            isWETH: true,
            logo: 'https://token-monitor.s3.amazonaws.com/okx_logo.jpeg'
        }
    ]
}

const CORE_NETWORK = {
    chainId: CORE_MAINNET,
    name: 'CORE',
    symbol: 'CORE',
    shortname: 'core',
    logo: 'https://scan.coredao.org/images/icon.png',// 缩略图
    check: true, // 是否允许进行合约检查
    explorer: 'https://scan.coredao.org',
    multicall: '0x42fCE0f1f0a291b44e72d7A8f04f41228F84304A',
    multicall3: '0x01b4cc56552487F3BbEA86c5e0961495dD892eE1',
    swap_func: '0x72012f43306567F2e1a527715b91CdC42B86Dd76', // swap中, 进行模拟交易的临时地址
    commonTokens: [
        {
            name: 'CORE',
            symbol: 'CORE',
            isWETH: true,
            logo: 'https://scan.coredao.org/images/icon.png',
            address: '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f'
        }
    ]
}
const GOERLI_TEST_NETWORK = {
    chainId: GOERLI_TESTNET,
    name: 'GOERLI',
    symbol: 'ETH',
    shortname: 'goerli',
    logo: 'https://token-monitor.s3.amazonaws.com/168610464410723669.png',
    check: false, // 是否允许进行合约检查
    explorer: 'https://goerli.etherscan.io',
    multicall: '0x77dca2c955b15e9de4dbbcf1246b4b85b651e50e',
    multicall3: '0xcA11bde05977b3631167028862bE2a173976CA11',
    commonTokens: [
        {
            name: 'ETH',
            symbol: 'ETH',
            isWETH: true,
            logo: 'https://ipfs.gateway.fatsale.finance/ipfs/QmTho9ytAZbS8YJrMWref176cboKqxrYQRo8MAFv6CGsWK'
        }
    ]
}

const ETH_NETWORK = {
    chainId: ETH_MAINNET,
    name: 'ETH',
    symbol: 'ETH',
    shortname: 'eth',
    logo: 'https://ipfs.gateway.fatsale.finance/ipfs/QmTho9ytAZbS8YJrMWref176cboKqxrYQRo8MAFv6CGsWK',
    check: true, // 是否允许进行合约检查
    explorer: 'https://etherscan.io',
    multicall: '0xeefba1e63905ef1d7acba5a8513c70307c1ce441',
    multicall3: '0xcA11bde05977b3631167028862bE2a173976CA11',
    commonTokens: [
        {
            name: 'ETH',
            symbol: 'ETH',
            isWETH: true,
            logo: 'https://ipfs.gateway.fatsale.finance/ipfs/QmTho9ytAZbS8YJrMWref176cboKqxrYQRo8MAFv6CGsWK',
            address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'
        },
        {
            name: 'USDT',
            symbol: 'USDT',
            address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
            isWETH: false,
            logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png'
        }
    ]
}

export const NETWORK_LIST = [
    ETH_NETWORK,
    BSC_NETWORK,
    OKX_NETWORK,
    CORE_NETWORK,
    GOERLI_TEST_NETWORK,
]