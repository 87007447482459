import React, { useEffect, useState } from 'react';
import Header2 from '../../layout/header2';
import Sidebar from '../../layout/sidebar';
import Footer2 from '../../layout/footer2';
import { getHotRank, getIncreaseRankList, getNewPoolList, getTokenCollectionList, getTokenOptionsList } from '../../../api/token';
import Logo from '../../components/logo';
import { convertNumberToString, getChainNameWithChainId, isMobile } from '../../../utils/tools';
import { convertToMaxUnit } from '../../../utils/tools';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Nav, Tab } from 'react-bootstrap';
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpinerLoading } from '../../MobileChartContainer';

function RankList(props) {
    const { t } = useTranslation()
    const history = useHistory()
    let style = { height: '80vh', overflowY: 'scroll', overflowX: 'hidden' }
    const [loading, setLoading] = useState(true)
    // if (props.hideHeader) {
    style = {
        ...style,
        padding: '0',
        paddingLeft: '5px',
        paddingRight: '5px',
        marginTop: '5px',
        position: 'relative'
    }
    // }

    useEffect(() => {
        setLoading(props.loading)
    }, [props.loading])

    return <div className='card'>
        {!props.hideHeader && <div className='card-header' >
            <span>{props.title || ''}</span>
            {!props.hideFiltersBtn && <span className='text-primary pointer-btn' onClick={() => {
                props.onFiltersClick && props.onFiltersClick()
            }}> {t('高级筛选')} <i className='mdi mdi-chevron-double-right'></i> </span>}

        </div>}
        <div style={style} className=' scroll-hidden'>
            <table
                className="table table-striped"
                style={{ fontSize: '12px' }}
            >
                <thead>
                    <tr >
                        <th>{t('代币')}</th>
                        <th>{t('现价')}</th>
                        <th>{t('涨跌幅')}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.list && props.list.map((item, ind) => {
                        return <tr key={'title-' + (props.title || '') + ind} onClick={() => {
                            const chainName = getChainNameWithChainId(item.chain_id)
                            history.push('/token/' + chainName + '/' + item.address)
                        }}>
                            <td className='index-table-td'>
                                <Logo size="1.9" chain={getChainNameWithChainId(item.chain_id)} contract={item.address} logo={item.logo} />
                                <span style={{ marginLeft: '10px' }}>
                                    {item.symbol}
                                </span>
                            </td>
                            <td className='text-light' style={{ width: '25%' }}>
                                {convertToMaxUnit(item.price)}
                            </td>
                            <td className={item.increase >= 0 ? 'text-success' : 'text-danger'} style={{ width: '25%', wordWrap: 'break-word', whiteSpace: 'nowrap' }}>
                                {convertToMaxUnit((item.increase * 100).toFixed(2)) + '%'}
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
            {loading && <div className='tv-loading-mask'>
                <SpinerLoading />
            </div>}
        </div>

    </div>
}


const gainFilters = [
    '1m',
    '3m',
    '5m',
    '10m',
    '15m',
    '30m',
    '1h',
    '2h',
    '4h',
    '24h',
]

function PageIndex() {
    const [gainList, setGainList] = useState([]) // 涨幅榜
    const [declineList, setDeclineList] = useState([]) // 跌幅榜
    const [hotList, setHotList] = useState([]) // 热门榜
    const [newPoolList, setNewPoolList] = useState([]) // 新增流动池
    const [currentGainWindow, setCurrentGainWindow] = useState('10m') // 当前涨幅榜的时间窗口
    const [currentNewPoolWindow, setCurrentNewPoolWindow] = useState('5m') // 当前新币榜的时间窗口
    const [optionCollects, setOptionCollects] = useState([]) // 自选代币分组
    const [optionList, setOptionList] = useState([]) // 自选代币列表
    const [selectOptionCollectId, setSelectOptionCollectId] = useState(0) // 当前选中的自选代币分组id
    const history = useHistory()

    const account = useSelector(state => state.account)

    const [loading, setLoading] = useState({
        gain: false,
        hot: false,
        newPool: false,
    })
    const newPoolRank = () => {
        // 获取新币榜
        setLoading({
            ...loading,
            newPool: true
        })

        getNewPoolList({
            page: 1,
            size: 200,
            window: currentNewPoolWindow,
            cap_min: "10000",
            cap_max: "10000000000000000",
            chain_id: 0,
        }).then(list => {
            if (list.data.code === 200) {
                setNewPoolList(list.data.data)
            }
            setLoading({
                ...loading,
                newPool: false
            })
        }).catch(e => {
            setLoading({
                ...loading,
                newPool: false
            })
        })

    }
    const declineRank = () => {
        getIncreaseRankList({
            page: 1,
            size: 50,
            window: "10m",
            cap_min: "10000",
            cap_max: "10000000000000000",
            volume_min: "1",
            volume_max: "10000000000000",
            tx_count_min: 1,
            tx_count_max: 100000,
            rate_min: -100,
            rate_max: 0,
            desc: 1
        }).then(list => {
            if (list.data.code === 200) {
                setDeclineList(list.data.data)
            }
        })
    }

    const gainRank = () => {
        setLoading({
            ...loading,
            gain: true
        })

        getIncreaseRankList({
            page: 1,
            size: 50,
            window: currentGainWindow,
            cap_min: "10000",
            cap_max: "10000000000000000",
            volume_min: "",
            volume_max: "",
            tx_count_min: 0,
            tx_count_max: 0,
            rate_min: 0,
            rate_max: 0,
            desc: 0
        }).then(list => {
            if (list.data.code === 200) {
                setGainList(list.data.data)
            }
            setLoading({
                ...loading,
                gain: false
            })
        }).catch(e => {
            setLoading({
                ...loading,
                gain: false
            })
        })
    }

    const hotRank = () => {

        setLoading({
            ...loading,
            hot: true
        })

        getHotRank({}).then(list => {
            if (list.data.code === 200) {
                setHotList(list.data.data)
            }
            setLoading({
                ...loading,
                hot: false
            })
        }).catch(e => {
            setLoading({
                ...loading,
                hot: false
            })
        })
    }

    const getOptionList = async () => {
        const { data } = await getTokenCollectionList()
        if (data.code === 200 && data.data && data.data.length > 0) {
            setOptionCollects(data.data)
            setSelectOptionCollectId(data.data[0].id)
        }
    }

    useEffect(() => {
        if (!selectOptionCollectId) return
        getTokenOptionsList({
            page: 1,
            collect_id: selectOptionCollectId
        }).then(({ data }) => {
            if (data.code === 200) {
                setOptionList(data.data)
            }
        })
    }, [selectOptionCollectId])

    // 获取热门榜、涨幅榜、跌幅榜、新币榜
    useEffect(() => {
        gainRank()
        // declineRank()
        newPoolRank()
        hotRank()

        getOptionList()
    }, [])

    useEffect(() => {
        gainRank()
    }, [currentGainWindow])
    useEffect(() => {
        newPoolRank()
    }, [currentNewPoolWindow])
    const { t } = useTranslation()
    const renderOptionRank = () => {
        return <div>
            {isMobile() && optionCollects && optionCollects.length > 0 && <div style={{ paddingLeft: '10px', paddingRight: '10px', marginTop: '3px', lineHeight: '30px' }} className="token-option-header mt-2">
                <Nav className="token-option-header-nav" variant="pills">
                    {optionCollects.map((item, ind) => {
                        return <Nav.Link active={item.id === selectOptionCollectId} onClick={() => {
                            setSelectOptionCollectId(item.id)
                        }} key={'option-collect-' + ind} eventKey={'option-collect-' + ind} className={item.id === selectOptionCollectId ? '' : 'text-muted'} >
                            {item.name || t('默认')}
                        </Nav.Link>
                    })}
                </Nav>
            </div>}
            {optionList && optionList.length > 0 && <div>
                <RankList hideHeader={isMobile()} hideFiltersBtn={true} title={t('自选榜')} list={optionList} />
            </div>}
        </div>
    }

    return (
        <>
            <Header2 />
            <Sidebar />
            <div className="content-body">
                {isMobile() === false && <div className='index-list'>
                    <div className='index-list-item'>
                        <RankList title={t('热门榜')} loading={loading['hot']} list={hotList} hideFiltersBtn={true} />
                    </div>
                    <div className='index-list-item'>
                        <RankList title={t('涨幅榜')} loading={loading['gain']} list={gainList} onFiltersClick={() => {
                            history.push('/gain/filters')
                        }} />
                    </div>
                    <div className='index-list-item'>
                        <RankList title={t('新币榜')} loading={loading['newPool']} list={newPoolList} onFiltersClick={() => {
                            history.push('/new-pools/filters')
                        }} />
                    </div>
                    <div style={{ height: '100%' }} className='index-list-item  open-position-table'>
                        {renderOptionRank()}
                    </div>
                    {/* <div className='index-list-item'>
                        <RankList title={'跌幅榜'} list={declineList} />
                    </div> */}
                </div>}
                {isMobile() === true && <div className='index-list'>
                    <Tab.Container defaultActiveKey="hot-rank" onSelect={(k) => {
                    }}>
                        <div className='card full-width'>
                            <div className="card-header">
                                <Nav variant="pills">
                                    <Nav.Link eventKey="hot-rank">
                                        {t('热门榜')}
                                    </Nav.Link>
                                    <Nav.Link eventKey="gain-rank">
                                        {t('涨幅榜')}
                                    </Nav.Link>
                                    <Nav.Link eventKey="new-pool-rank">
                                        {t('新币榜')}
                                    </Nav.Link>
                                    {account && <Nav.Link eventKey="options-rank">
                                        {t('自选代币')}
                                    </Nav.Link>}
                                </Nav>
                            </div>
                            <PerfectScrollbar>
                                <div style={{ minHeight: '700px', height: '100%' }} className=" open-position-table">
                                    <div className="market-history market-order">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="hot-rank">
                                                <RankList hideHeader={true} title={t('热门榜')} loading={loading['hot']} list={hotList} hideFiltersBtn={true} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="gain-rank">
                                                <div style={{ paddingLeft: '10px', paddingRight: '10px', marginTop: '3px' }} className="token-option-header mt-2">
                                                    <Nav className="token-option-header-nav" variant="pills">
                                                        {gainFilters.map((item, ind) => {
                                                            return <Nav.Link active={currentGainWindow === item} onClick={() => {
                                                                setCurrentGainWindow(item)
                                                            }} key={'gainFilters-' + ind} eventKey={item} className={currentGainWindow === item ? '' : 'text-muted'} >
                                                                {item}
                                                            </Nav.Link>
                                                        })}
                                                    </Nav>
                                                    <div onClick={() => {
                                                        history.push('/gain/filters')
                                                    }} style={{ fontSize: '13px', marginRight: '10px', flexDirection: 'row' }} className="d-flex flex-direction-row token-option-header-plus text-primary pointer-btn">
                                                        {t('高级筛选')} <i className='mdi mdi-chevron-double-right'></i>
                                                    </div>
                                                </div>
                                                <RankList hideHeader={true} title={t('涨幅榜')} loading={loading['gain']} list={gainList} onFiltersClick={() => {
                                                    history.push('/gain/filters')
                                                }} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="new-pool-rank">
                                                <div style={{ paddingLeft: '10px', paddingRight: '10px', marginTop: '3px' }} className="token-option-header mt-2">
                                                    <Nav className="token-option-header-nav" variant="pills">
                                                        {gainFilters.map((item, ind) => {
                                                            return <Nav.Link active={currentNewPoolWindow === item} onClick={() => {
                                                                setCurrentNewPoolWindow(item)
                                                            }} key={'gainFilters-' + ind} eventKey={item} className={currentNewPoolWindow === item ? '' : 'text-muted'} >
                                                                {item}
                                                            </Nav.Link>
                                                        })}
                                                    </Nav>
                                                    <div onClick={() => {
                                                        history.push('/new-pools/filters')
                                                    }} style={{ fontSize: '13px', marginRight: '10px', flexDirection: 'row' }} className="d-flex flex-direction-row token-option-header-plus text-primary pointer-btn">
                                                        {t('高级筛选')} <i className='mdi mdi-chevron-double-right'></i>
                                                    </div>
                                                </div>
                                                <RankList hideHeader={true} title={t('新币榜')} loading={loading['newPool']} list={newPoolList} onFiltersClick={() => {
                                                    history.push('/new-pools/filters')
                                                }} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="options-rank">
                                                {renderOptionRank()}
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </div>
                                <div style={{ height: '20px' }}></div>
                            </PerfectScrollbar>
                        </div>
                    </Tab.Container>


                </div>}
            </div>
            <Footer2 />
        </>

    );
}

export default PageIndex;