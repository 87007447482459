import React, { createContext, useEffect, useRef, useState } from 'react';
import { eventBus } from '../../../utils/tools';
import { baseUrl, wssUrl } from '../../../utils/config';

const WebSocketContext = createContext();

const WebSocketProvider = ({ children }) => {
    const [webSocket, setWebSocket] = useState(null);
    const reconnectIntervalRef = useRef(null);

    useEffect(() => {
        connectWebSocket()
    }, []);

    const connectWebSocket = () => {
        const socket = new WebSocket(wssUrl); // 替换为实际的 WebSocket 服务器地址

        setWebSocket(socket);
        clearReconnectInterval()
        // 断线重连
        socket.addEventListener('close', handleDisconnect);
        socket.addEventListener('error', handleDisconnect);

        socket.addEventListener('open', handleInitSocket)


        // 在组件卸载时关闭 WebSocket 连接
        return () => {
            socket.close();
            socket.removeEventListener('close', handleDisconnect);
            socket.removeEventListener('open', handleInitSocket);

        };
    }

    const handleInitSocket = () => {
        // 发送全局的socket连接成功事件
        eventBus.emit('websocketConnectedSuccess')
    }

    const clearReconnectInterval = () => {
        // 清除重连计时器
        if (reconnectIntervalRef.current) {
            clearInterval(reconnectIntervalRef.current);
            reconnectIntervalRef.current = null;
        }
    };

    const handleDisconnect = () => {
        // 断线后尝试重新连接
        console.log('连接中断')
        if (!reconnectIntervalRef.current) {
            reconnectIntervalRef.current = setInterval(() => {
                if (!webSocket || webSocket.readyState === WebSocket.CLOSED) {
                    // 重新连接 WebSocket
                    console.log('正在进行断线重连...', new Date().toLocaleTimeString())
                    connectWebSocket();
                }
            }, 5000); // 每隔 5 秒尝试一次重新连接
        }
    };

    return (
        <WebSocketContext.Provider value={webSocket}>
            {children}
        </WebSocketContext.Provider>
    );
};

export { WebSocketContext, WebSocketProvider };
