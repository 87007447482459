import React from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from '../../utils/tools';
// import { Row, Col, Card } from 'react-bootstrap'



function Footer2() {

    return (
        <>
            {isMobile() === false && <div className="footer dashboard">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-8 col-12">
                            <div className="copyright">
                                <p>© Copyright {new Date().getFullYear()}  <Link to="#" href="#">TokenMonitor</Link> I
                                    All Rights Reserved</p>
                            </div>
                        </div>
                        <div className="col-sm-4 col-12">
                            <div className="footer-social">
                                <ul>
                                    <li>
                                        <a href="https://t.me/tokenmonitor" rel="noopener noreferrer" target="_blank"><i className="fa fa-telegram"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/TokenMonitor" rel="noopener noreferrer" target="_blank"><i className="fa fa-twitter"></i></a>
                                    </li>
                                    {/* <li><Link to={'#'}><i className="fa fa-twitter"></i></Link></li>
                                    <li><Link to={'#'}><i className="fa fa-linkedin"></i></Link></li>
                                    <li><Link to={'#'}><i className="fa fa-youtube"></i></Link></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default Footer2;