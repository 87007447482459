import React, { useContext, useEffect, useRef } from 'react';
import { WebSocketContext } from './WebSocketContext';
import { eventBus } from '../../../utils/tools';
import store from '../../../store';
import { useTranslation } from 'react-i18next';

const WebSocketComponent = () => {
    const webSocket = useContext(WebSocketContext);
    // 已订阅事件
    // 断线重连后重新订阅所有事件
    const subscribed = useRef([])
    const listeners = useRef(new Set())
    const timer = useRef(null)
    useEffect(() => {

        // 监听全局事件, 向socket中发送订阅消息
        if (webSocket) {
            // 在这里使用 WebSocket 对象进行通信
            webSocket.addEventListener('message', handleMessage);


            // 订阅价格
            eventBus.addListener('subscribePrice', handleSubscribePrice)

            eventBus.addListener('subscribeKline', handleSubscribeKline)
            eventBus.addListener('subscribeTxs', handleSubscribeTxs)
            eventBus.addListener('subscribePoolChange', handleSubscribePoolChange)
            eventBus.addListener('websocketConnectedSuccess', handleWebsocketConnected)

            // 每5s, 进行一次心跳
            timer.current = setInterval(() => {
                try {
                    webSocket.send(JSON.stringify({
                        type: 'ping',
                    }));
                } catch (e) {
                    console.log('心跳失败', e)

                }

            }, 5000)
        }

        return () => {
            // 组件卸载时，关闭 WebSocket 连接
            if (timer.current) {
                clearInterval(timer.current)
            }
            if (webSocket) {

                // 在组件卸载时移除 WebSocket 事件监听器等
                webSocket.removeEventListener('message', handleMessage);
                eventBus.removeListener('subscribeKline', handleSubscribeKline)
                eventBus.removeListener('subscribePrice', handleSubscribePrice)
                eventBus.removeListener('subscribeTxs', handleSubscribeTxs)
                eventBus.removeListener('subscribePoolChange', handleSubscribePoolChange)
                eventBus.removeListener('websocketConnectedSuccess', handleWebsocketConnected)
            }
        };
    }, [webSocket]);


    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilitychange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilitychange);
        }
    })

    const handleVisibilitychange = () => {
        if (!document.hidden) {
            eventBus.emit('pageShow')
        } else {
            eventBus.emit('pageHide')
        }
    }


    const handleWebsocketConnected = () => {
        // 重新连接socket之后, 重新订阅所有事件
        if (webSocket && subscribed.current) {
            subscribed.current.forEach(item => {
                webSocket.send(JSON.stringify(item));
            })
        }
    }

    const handleSubscribeKline = (data) => {
        // 更新k线
        const d = {
            "type": "subscribe",
            "data": {
                "type": "kline", // 订阅价格
                ...data
            }
        }
        subscribed.current = [...subscribed.current, d]
        try {
            webSocket.send(JSON.stringify(d));
        } catch (e) {
            console.log('向websocket通信失败', d, e)
        }

    }

    const handleSubscribePoolChange = (data) => {
        const d = {
            "type": "subscribe",
            "data": {
                "type": "pairChange", // 订阅价格
                ...data
            }
        }
        subscribed.current = [...subscribed.current, d]
        try {
            webSocket.send(JSON.stringify(d));
        } catch (e) {
            console.log('向websocket通信失败', d, e)
        }
    }

    const handleSubscribeTxs = (data) => {
        const d = {
            "type": "subscribe",
            "data": {
                "type": "txs", // 订阅价格
                ...data
            }
        }
        subscribed.current = [...subscribed.current, d]
        try {
            webSocket.send(JSON.stringify(d));
        } catch (e) {
            console.log('向websocket通信失败', d, e)
        }
    }

    const handleSubscribePrice = (data) => {
        // 订阅价格
        const d = {
            "type": "subscribe",
            "data": {
                "type": "price", // 订阅价格
                ...data
            }
        }
        subscribed.current = [...subscribed.current, d]
        try {
            webSocket.send(JSON.stringify(d));
        } catch (e) {
            console.log('向websocket通信失败', d, e)
        }
    }
    const handleMessage = (event) => {
        // 处理 WebSocket 消息
        // console.log('Received message:', event.data);
        // 接收到消息, 向外发射全局事件
        const d = JSON.parse(event.data)
        if (d.type === 'pong') {
            return
        }
        if (d.type === 'kline_update') {
            eventBus.emit('klineUpdate', d.data)
        }
        if (d.type === 'txs_update') {
            eventBus.emit('txsUpdate', d.data)
        }
        if (d.type === 'pair_update') {
            eventBus.emit('pairUpdate', d.data)
            store.dispatch({
                type: 'setPairUpdate',
                value: {
                    [parseInt(d.data.pair_id)]: d.data
                }
            })
        }
        if (d.type === 'price_update') {
            // 发射全局事件, 将数据发射出去, 有需要的组件进行自行处理
            eventBus.emit('priceUpdate', d.data)
            // { par, token, price, time }
            store.dispatch({
                type: 'setTokenPriceUpdate',
                value: {
                    [d.data.token]: d.data
                }
            })
        }

    };

    return (
        <div>
            {/* WebSocket 组件的渲染 */}
        </div>
    );
};

export default WebSocketComponent;
