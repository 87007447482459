export const TmdEnv = 'production' // production, development, local
export const TmdVersion = '1.0.0'
export const TmdAesKey = 'fd3fa2769a6c86a1bfa71d71059b1987'

let tmdBaseUrl = ''
let tmdBaseWssUrl = ''
let tgBotName = ''
if (TmdEnv === 'local') {
    tmdBaseUrl = "http://127.0.0.1:8909"
    tmdBaseWssUrl = "ws://127.0.0.1:8909/ws"
    tgBotName = 'fatsale_test_bot'
}
if (TmdEnv === 'production') {
    tmdBaseUrl = 'https://api.tmdex.org'
    tmdBaseWssUrl = "wss://api.tmdex.org/ws"
    tgBotName = 'TmdMsgBot'
}

export const baseUrl = tmdBaseUrl
export const wssUrl = tmdBaseWssUrl
export const tmdBotName = tgBotName