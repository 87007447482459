import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { getBindTgCode, getNotificationTypeList, getTaskNotifications } from "../../../api/login";
import { Accordion, Nav, OverlayTrigger, Tab, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import { converToNumberString, eventBus, getChainNameWithChainId, getShortAddress, isAddress, isMobile } from "../../../utils/tools";
import { StoreTokenMonitorTasks, deleteTask, updateTaskStatus } from "../../../api/monitor";
import { set } from "date-fns";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useTranslation } from "react-i18next";
import { tmdBotName } from "../../../utils/config";
import { BindTelegramModal } from "../../pages/settings";
import { useSelector } from "react-redux";
export const NOTIFICATION_TYPE_TELEGRAM = 0
export const NOTIFICATION_TYPE_MAIL = 1
export const TASK_PRICE_TARGET_PRICE = 0 // 按照目标价格通知
export const TASK_PRICE_RAISE = 1 // 按照涨幅通知
export const TASK_PRICE_FALL = 2 // 按照跌幅通知
export const TASK_POOL_TARGET_VALUE = 3 // 按照池子目标大小监控
export const TASK_POOL_RAISE = 4 // 按照池子涨幅监控
export const TASK_POOL_FALL = 5 // 按照池子跌幅监控

export const HOLDERS_TRANSFER = 6 // 监控持有者转账 
export const HOLDERS_SWAP = 7 // 监控持有者交易
export const HOLDERS_ADD_POOL = 8 // 监控持有者加撤池

export const TASK_WALLET_SWAP = 9 // 监控某个钱包的交易
export const TASK_WALLET_TRANSFER = 10 // 监控某个钱包的转账
export const TASK_WALLET_POOL = 11 // 监控某个钱包的加撤池子交易
export const TASK_NEW_POOL = 12 // 监控新的流动池子


export const TaskListTable = (props) => {
    const [currentActiveKey, setCurrentActiveKey] = useState('price-monitor') // 当前激活的tab [price-monitor, pool-monitor, top-monitor

    const [list, setList] = useState({}) // 任务列表 通过不同的key, 对应不同的tab

    const history = useHistory()
    const { t, i18n } = useTranslation()
    useEffect(() => {
        // 格式化这个tasks, 按照类型进行分类
        formatTasks(props.tasks)
    }, [props.tasks])

    const formatTasks = (tasks) => {
        if (!tasks || tasks.length === 0) {
            setList({})
            return
        }
        const priceTasks = [] // 价格监控任务
        const poolTasks = [] // 流动池监控任务
        const topTasks = [] // 巨鲸监控任务
        const walletTasks = [] // 钱包监控任务
        const newPoolTasks = [] // 新币开盘任务
        tasks.forEach(item => {
            item['info'] = {}
            if (item.extra) {
                item['info'] = JSON.parse(item.extra)
            }

            if ([TASK_PRICE_TARGET_PRICE, TASK_PRICE_RAISE, TASK_PRICE_FALL].includes(item.type)) {
                priceTasks.push(item)
            }
            if ([TASK_POOL_TARGET_VALUE, TASK_POOL_RAISE, TASK_POOL_FALL].includes(item.type)) {
                poolTasks.push(item)
            }
            if ([HOLDERS_TRANSFER, HOLDERS_SWAP, HOLDERS_ADD_POOL].includes(item.type)) {
                topTasks.push(item)
            }
            if ([TASK_WALLET_SWAP, TASK_WALLET_TRANSFER, TASK_WALLET_POOL].includes(item.type)) {
                walletTasks.push(item)
            }
            if (item.type === TASK_NEW_POOL) {
                newPoolTasks.push(item)
            }
        })
        const m = {}
        m['price-monitor'] = priceTasks
        m['pool-monitor'] = poolTasks
        m['top-monitor'] = topTasks
        m['wallet-monitor'] = walletTasks
        m['new-pool'] = newPoolTasks
        setList(m)
    }

    const getPrice = (task) => {
        if ([TASK_PRICE_TARGET_PRICE, TASK_PRICE_RAISE, TASK_PRICE_FALL].includes(task.type) && task.info && task.info['target_price']) {
            return task.info?.current_price
        }
        if ([TASK_POOL_TARGET_VALUE, TASK_POOL_RAISE, TASK_POOL_FALL].includes(task.type) && task.info && task.info['pool_target_value']) {
            return task.info?.current_pool_cap
        }

        return ''
    }
    const getContent = (task) => {
        if (task.type === TASK_PRICE_TARGET_PRICE) {
            let x = ''
            const price = Number(getPrice(task))
            const target = Number(task.info?.target_price)
            if (price > target) {
                x = '跌破'
            } else {
                x = '突破'
            }

            return t('价格突破x时通知').replace('{action}', x).replace('{price}', converToNumberString(target))
        }
        if (task.type === TASK_PRICE_RAISE) {
            const x = task.info?.target_raise
            return t('价格上涨x时通知').replace('{x}', x)
        }
        if (task.type === TASK_PRICE_FALL) {
            const x = task.info?.target_fall
            return t('价格下跌x时通知').replace('{x}', x)
        }
        if (task.type === TASK_POOL_TARGET_VALUE) {
            let x = ''
            const price = Number(getPrice(task))
            const target = Number(task.info?.pool_target_value)
            if (price > target) {
                x = '跌破'
            } else {
                x = '突破'
            }
            return t('流动市值突破x时通知').replace('{action}', x).replace('{price}', converToNumberString(target))
        }
        if (task.type === TASK_POOL_RAISE) {
            const x = task.info?.pool_target_raise
            return t('流动池市值每上涨x时通知').replace('{x}', x)
        }
        if (task.type === TASK_POOL_FALL) {
            const x = task.info?.pool_target_fall
            return t('流动池市值每下跌x时通知').replace('{x}', x)
        }
        return ''
    }


    const renderTaskList = (list, tableFirstColumnName) => {
        const style = { 'fontSize': '12px', overflowY: 'scroll' }
        if (props.hidenToken) {
            style['maxHeight'] = '200px'
        }
        return <div style={style} className="mt-2">
            <table className="table" style={{ 'wordWrap': 'break-word', 'tableLayout': 'fixed', 'textAlign': 'center', 'wordBreak': 'break-all', ...style }}>
                <thead>
                    <tr>
                        {!props.hidenToken && <th>
                            {t('代币')}
                        </th>}
                        {!props.hidenToken && <th>
                            {t('公链')}
                        </th>}
                        {isMobile() === false && <th>
                            {tableFirstColumnName}
                        </th>}
                        <th>
                            {t('内容')}
                        </th>
                        <th>{t('状态')}</th>
                        <th className="text-center">{t('操作')}</th>
                    </tr>
                </thead>
                <tbody>
                    {list?.map((item, ind) => {
                        return <tr key={item.id + '-pm' + ind} className={"task-modal-list-item-tr " + (item.id === props.selectedId ? 'task-modal-list-item-tr-selected' : '')}>
                            {!props.hidenToken && <td onClick={() => {
                                // 跳转到对应代币详情
                                const chainName = getChainNameWithChainId(item.chain_id)
                                if (!item.extra) return
                                const extra = JSON.parse(item.extra)
                                const pool = extra.pool || ''
                                history.push('/token/' + chainName + '/' + item.token + `${pool ? ('?pair=' + pool) : ''}`)
                            }} className="hover-underline">
                                {item.token_symbol}
                            </td>}
                            {!props.hidenToken && <td onClick={() => {
                                props.onClickTask && props.onClickTask(item)
                            }}>
                                {item.chain.toUpperCase()}
                            </td>}
                            {isMobile() === false && <td onClick={() => {
                                props.onClickTask && props.onClickTask(item)
                            }}> ${converToNumberString(getPrice(item))}</td>}
                            <td onClick={() => {
                                props.onClickTask && props.onClickTask(item)
                            }} style={{ fontSize: '12px' }} >{getContent(item)}</td>
                            <td onClick={() => {
                                props.onClickTask && props.onClickTask(item)
                            }} style={{ fontSize: '12px' }}>{
                                    item.notification_status === 0 ? <>
                                        {item.status === 0 ? <span className="text-danger">{t('已暂停')}</span> : <span className="text-success"> {t('运行中')}</span>}
                                    </> : <span className="text-muted">{t('已失效')}</span>

                                }</td>
                            <td style={{ fontSize: '16px' }}>
                                {item.status === 1 && <span>
                                    <OverlayTrigger
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip id={"button-tooltip-" + ind}>{t('暂停')}</Tooltip>}
                                    >
                                        <i onClick={async () => {
                                            const { data } = await updateTaskStatus({
                                                task_id: item.id,
                                                status: 0
                                            })
                                            if (data.code === 200) {
                                                toast.success(t('已暂停此任务'))
                                                props.refresh && props.refresh()
                                            } else {
                                                toast.error(t('操作失败'))
                                            }

                                        }} style={{ cursor: 'pointer' }} className="fa fa-stop-circle-o text-danger monitor-task-list-btn"></i>
                                    </OverlayTrigger>

                                </span>}
                                {item.status === 0 && <span>
                                    <OverlayTrigger
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip id={"button-tooltip-" + ind}>{t('启动')}</Tooltip>}
                                    >
                                        <i onClick={async () => {
                                            const { data } = await updateTaskStatus({
                                                task_id: item.id,
                                                status: 1
                                            })
                                            if (data.code === 200) {
                                                toast.success(t('已启动此任务'))
                                                props.refresh && props.refresh()
                                            } else {
                                                toast.error(t('操作失败'))
                                            }
                                        }} style={{ cursor: 'pointer' }} className="fa fa-play-circle-o text-warning monitor-task-list-btn"></i>
                                    </OverlayTrigger>

                                </span>}
                                <span>
                                    <OverlayTrigger
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip id={"button-tooltip-" + ind}>{t('删除')}</Tooltip>}
                                    >
                                        <i onClick={async () => {
                                            const { data } = await deleteTask({
                                                task_id: item.id,
                                            })
                                            if (data.code === 200) {
                                                toast.success(t('已删除'))
                                                props.refresh && props.refresh()
                                            } else {
                                                toast.error(t('操作失败'))
                                            }

                                        }} style={{ cursor: 'pointer' }} className="fa fa-trash text-danger monitor-task-list-btn"></i>
                                    </OverlayTrigger>

                                </span>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>

    }


    const style = { 'fontSize': '12px', overflowY: 'scroll' }
    if (props.hidenToken) {
        style['maxHeight'] = '200px'
    }

    return <>
        <div>
            {props.type === 'token' && <Tab.Container defaultActiveKey="price-monitor" activeKey={currentActiveKey} onSelect={(k) => {
                setCurrentActiveKey(k)
            }}>
                <div className="card-header">
                    <Nav variant="pills" className="token-option-header-nav">
                        <Nav.Link eventKey="price-monitor">
                            {t('价格监控')}({list['price-monitor']?.length || 0})
                        </Nav.Link>
                        <Nav.Link eventKey="pool-monitor">
                            {t('流动池监控')}({list['pool-monitor']?.length || 0})
                        </Nav.Link>
                        <Nav.Link eventKey="top-monitor">
                            {t('巨鲸变动监控')}({list['top-monitor']?.length || 0})
                        </Nav.Link>
                    </Nav>
                </div>
                <div>
                    <Tab.Content>
                        <Tab.Pane eventKey="price-monitor">
                            {renderTaskList(list['price-monitor'], t('初始价格'))}
                        </Tab.Pane>
                        <Tab.Pane eventKey="pool-monitor">
                            {renderTaskList(list['pool-monitor'], t('初始流动市值'))}

                        </Tab.Pane>
                        <Tab.Pane eventKey="top-monitor">
                            <div style={style} className="mt-2">
                                <table className="table" style={{ 'wordWrap': 'break-word', 'tableLayout': 'fixed', 'textAlign': 'center', 'wordBreak': 'break-all', 'maxHeight': '200px' }}>
                                    <thead>
                                        <tr >
                                            {!props.hidenToken && <th>
                                                {t('代币')}
                                            </th>}
                                            {!props.hidenToken && <th>
                                                {t('公链')}
                                            </th>}
                                            <th>
                                                {t('监控范围')}
                                            </th>
                                            <th>
                                                {t('监控动作')}
                                            </th>
                                            {isMobile() === false && <th>
                                                {t('报警阈值')}
                                            </th>}
                                            <th>{t('状态')}</th>
                                            <th className="text-center">{t('操作')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list['top-monitor']?.map((item, ind) => {
                                            return <tr key={item.id + '-pm' + ind} className={"task-modal-list-item-tr " + (item.id === props.selectedId ? 'task-modal-list-item-tr-selected' : '')}>
                                                {!props.hidenToken && <td >
                                                    {item.token_symbol}
                                                </td>}
                                                {!props.hidenToken && <td onClick={() => {
                                                    props.onClickTask && props.onClickTask(item)
                                                }}>
                                                    {item.chain.toUpperCase()}
                                                </td>}
                                                <td onClick={() => {
                                                    props.onClickTask && props.onClickTask(item)
                                                }}>Top {item.info?.holders_top}</td>
                                                <td onClick={() => {
                                                    props.onClickTask && props.onClickTask(item)
                                                }} style={{ fontSize: '12px' }} >
                                                    {item.type === HOLDERS_TRANSFER && t('转账')}
                                                    {item.type === HOLDERS_SWAP && t('交易')}
                                                    {item.type === HOLDERS_ADD_POOL && t('加撤池')}
                                                </td>
                                                {isMobile() === false && <td onClick={() => {
                                                    props.onClickTask && props.onClickTask(item)
                                                }} style={{ fontSize: '12px' }} >
                                                    {converToNumberString(item.info?.holders_notify_value || 0)}
                                                </td>}
                                                <td onClick={() => {
                                                    props.onClickTask && props.onClickTask(item)
                                                }} style={{ fontSize: '12px' }}>{
                                                        item.notification_status === 0 ? <>
                                                            {item.status === 0 ? <span className="text-danger">{t('已暂停')}</span> : <span className="text-success"> {t('运行中')}</span>}
                                                        </> : <>  <span className="text-muted">{t('已失效')}</span>  </>
                                                    }</td>
                                                <td style={{ fontSize: '16px' }}>
                                                    {item.status === 1 && <span>
                                                        <OverlayTrigger
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={<Tooltip id={"button-tooltip-" + ind}>{t('暂停')}</Tooltip>}
                                                        >
                                                            <i onClick={async () => {
                                                                const { data } = await updateTaskStatus({
                                                                    task_id: item.id,
                                                                    status: 0
                                                                })
                                                                if (data.code === 200) {
                                                                    toast.success(t('已暂停此任务'))
                                                                    props.refresh && props.refresh()
                                                                } else {
                                                                    toast.error(t('操作失败'))
                                                                }

                                                            }} style={{ cursor: 'pointer' }} className="fa fa-stop-circle-o text-danger monitor-task-list-btn"></i>
                                                        </OverlayTrigger>

                                                    </span>}
                                                    {item.status === 0 && <span>
                                                        <OverlayTrigger
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={<Tooltip id={"button-tooltip-" + ind}>{t('启动')}</Tooltip>}
                                                        >
                                                            <i onClick={async () => {
                                                                const { data } = await updateTaskStatus({
                                                                    task_id: item.id,
                                                                    status: 1
                                                                })
                                                                if (data.code === 200) {
                                                                    toast.success(t('已启动此任务'))
                                                                    props.refresh && props.refresh()
                                                                } else {
                                                                    toast.error(t('操作失败'))
                                                                }
                                                            }} style={{ cursor: 'pointer' }} className="fa fa-play-circle-o text-warning monitor-task-list-btn"></i>
                                                        </OverlayTrigger>

                                                    </span>}
                                                    <span>
                                                        <OverlayTrigger
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={<Tooltip id={"button-tooltip-" + ind}>{t('删除')}</Tooltip>}
                                                        >
                                                            <i onClick={async () => {
                                                                const { data } = await deleteTask({
                                                                    task_id: item.id,
                                                                })
                                                                if (data.code === 200) {
                                                                    toast.success(t('已删除'))
                                                                    props.refresh && props.refresh()
                                                                } else {
                                                                    toast.error(t('操作失败'))
                                                                }
                                                            }} style={{ cursor: 'pointer' }} className="fa fa-trash text-danger monitor-task-list-btn"></i>
                                                        </OverlayTrigger>

                                                    </span>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </div>
            </Tab.Container >}
            {props.type === 'wallet' && <div style={style} className="mt-2">
                <table className="table" style={{ 'wordWrap': 'break-word', 'tableLayout': 'fixed', 'textAlign': 'center', 'wordBreak': 'break-all', 'maxHeight': '200px' }}>
                    <thead>
                        <tr>
                            <th>
                                {t('钱包')}
                            </th>
                            <th>
                                {t('监控动作')}
                            </th>
                            <th>
                                {t('报警阈值')}
                            </th>
                            <th>{t('状态')}</th>
                            <th className="text-center">{t('操作')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list['wallet-monitor']?.map((item, ind) => {
                            return <tr key={item.id + '-wm' + ind} className={"task-modal-list-item-tr " + (item.id === props.selectedId ? 'task-modal-list-item-tr-selected' : '')}>
                                <td onClick={() => {
                                    props.onClickTask && props.onClickTask(item)
                                }}>
                                    {getShortAddress(item.wallet)}
                                </td>
                                <td onClick={() => {
                                    props.onClickTask && props.onClickTask(item)
                                }} style={{ fontSize: '12px' }} >
                                    {item.type === TASK_WALLET_TRANSFER && t('转账')}
                                    {item.type === TASK_WALLET_SWAP && t('交易')}
                                    {item.type === TASK_WALLET_POOL && t('加撤池')}
                                </td>
                                <td onClick={() => {
                                    props.onClickTask && props.onClickTask(item)
                                }} style={{ fontSize: '12px' }} >
                                    $  {converToNumberString(item.info?.wallet_option_value || 0)}
                                </td>
                                <td onClick={() => {
                                    props.onClickTask && props.onClickTask(item)
                                }} style={{ fontSize: '12px' }}>{
                                        item.notification_status === 0 ? <>
                                            {item.status === 0 ? <span className="text-danger">{t('已暂停')}</span> : <span className="text-success"> {t('运行中')}</span>}
                                        </> : <>  <span className="text-muted">{t('已失效')}</span>  </>

                                    }</td>
                                <td style={{ fontSize: '16px' }}>
                                    {item.status === 1 && <span>
                                        <OverlayTrigger
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id={"button-tooltip-" + ind}>{t('暂停')}</Tooltip>}
                                        >
                                            <i onClick={async () => {
                                                const { data } = await updateTaskStatus({
                                                    task_id: item.id,
                                                    status: 0
                                                })
                                                if (data.code === 200) {
                                                    toast.success(t('已暂停此任务'))
                                                    props.refresh && props.refresh()
                                                } else {
                                                    toast.error(t('操作失败'))
                                                }

                                            }} style={{ cursor: 'pointer' }} className="fa fa-stop-circle-o text-danger monitor-task-list-btn"></i>
                                        </OverlayTrigger>

                                    </span>}
                                    {item.status === 0 && <span>
                                        <OverlayTrigger
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id={"button-tooltip-" + ind}>{t('启动')}</Tooltip>}
                                        >
                                            <i onClick={async () => {
                                                const { data } = await updateTaskStatus({
                                                    task_id: item.id,
                                                    status: 1
                                                })
                                                if (data.code === 200) {
                                                    toast.success(t('已启动此任务'))
                                                    props.refresh && props.refresh()
                                                } else {
                                                    toast.error(t('操作失败'))
                                                }
                                            }} style={{ cursor: 'pointer' }} className="fa fa-play-circle-o text-warning monitor-task-list-btn"></i>
                                        </OverlayTrigger>

                                    </span>}
                                    <span>
                                        <OverlayTrigger
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id={"button-tooltip-" + ind}>{t('删除')}</Tooltip>}
                                        >
                                            <i onClick={async () => {
                                                const { data } = await deleteTask({
                                                    task_id: item.id,
                                                })
                                                if (data.code === 200) {
                                                    toast.success(t('已删除'))
                                                    props.refresh && props.refresh()
                                                } else {
                                                    toast.error(t('操作失败'))
                                                }

                                            }} style={{ cursor: 'pointer' }} className="fa fa-trash text-danger monitor-task-list-btn"></i>
                                        </OverlayTrigger>

                                    </span>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>}

            {props.type === 'new-pool' && <div style={style} className="mt-2">
                <table className="table" style={{ 'wordWrap': 'break-word', 'tableLayout': 'fixed', 'textAlign': 'center', 'wordBreak': 'break-all', 'maxHeight': '200px' }}>
                    <thead>
                        <tr>
                            <th>
                                {t('关键字')}
                            </th>
                            {/* <th>
                                {t('监控动作')}
                            </th> */}
                            <th>
                                {t('报警阈值')}
                            </th>
                            <th>{t('状态')}</th>
                            <th className="text-center">{t('操作')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list['new-pool']?.map((item, ind) => {
                            return <tr key={item.id + '-wm' + ind} className={"task-modal-list-item-tr " + (item.id === props.selectedId ? 'task-modal-list-item-tr-selected' : '')}>
                                <td onClick={() => {
                                    props.onClickTask && props.onClickTask(item)
                                }}>
                                    {item.info?.keywords?.join(',')}
                                </td>

                                <td onClick={() => {
                                    props.onClickTask && props.onClickTask(item)
                                }} style={{ fontSize: '12px' }} >
                                    $  {converToNumberString(item.info?.min_pool_size || 0)}
                                </td>
                                <td onClick={() => {
                                    props.onClickTask && props.onClickTask(item)
                                }} style={{ fontSize: '12px' }}>{
                                        item.notification_status === 0 ? <>
                                            {item.status === 0 ? <span className="text-danger">{t('已暂停')}</span> : <span className="text-success"> {t('运行中')}</span>}
                                        </> : <>  <span className="text-muted">{t('已失效')}</span>  </>

                                    }</td>
                                <td style={{ fontSize: '16px' }}>
                                    {item.status === 1 && <span>
                                        <OverlayTrigger
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id={"button-tooltip-" + ind}>{t('暂停')}</Tooltip>}
                                        >
                                            <i onClick={async () => {
                                                const { data } = await updateTaskStatus({
                                                    task_id: item.id,
                                                    status: 0
                                                })
                                                if (data.code === 200) {
                                                    toast.success(t('已暂停此任务'))
                                                    props.refresh && props.refresh()
                                                } else {
                                                    toast.error(t('操作失败'))
                                                }

                                            }} style={{ cursor: 'pointer' }} className="fa fa-stop-circle-o text-danger monitor-task-list-btn"></i>
                                        </OverlayTrigger>

                                    </span>}
                                    {item.status === 0 && <span>
                                        <OverlayTrigger
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id={"button-tooltip-" + ind}>{t('启动')}</Tooltip>}
                                        >
                                            <i onClick={async () => {
                                                const { data } = await updateTaskStatus({
                                                    task_id: item.id,
                                                    status: 1
                                                })
                                                if (data.code === 200) {
                                                    toast.success(t('已启动此任务'))
                                                    props.refresh && props.refresh()
                                                } else {
                                                    toast.error(t('操作失败'))
                                                }
                                            }} style={{ cursor: 'pointer' }} className="fa fa-play-circle-o text-warning monitor-task-list-btn"></i>
                                        </OverlayTrigger>

                                    </span>}
                                    <span>
                                        <OverlayTrigger
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id={"button-tooltip-" + ind}>{t('删除')}</Tooltip>}
                                        >
                                            <i onClick={async () => {
                                                const { data } = await deleteTask({
                                                    task_id: item.id,
                                                })
                                                if (data.code === 200) {
                                                    toast.success(t('已删除'))
                                                    props.refresh && props.refresh()
                                                } else {
                                                    toast.error(t('操作失败'))
                                                }

                                            }} style={{ cursor: 'pointer' }} className="fa fa-trash text-danger monitor-task-list-btn"></i>
                                        </OverlayTrigger>

                                    </span>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>}
        </div >
    </>
}

export const NotificationTypeBox = (props) => {
    const { t, i18n } = useTranslation()
    const currnetLanguage = i18n.language; // 获取当前语言
    const [notificationTypes, setNotificationTypes] = useState({}) // 通知方式
    const [selectedNotificationTypes, setSelectedNotificationTypes] = useState({}) // 选中的通知方式 [NOTIFICATION_TYPE_TELEGRAM]
    const [showBindTelegramModal, setShowBindTelegramModal] = useState(false) // 展示绑定telegram的modal
    const [bindTelegramCode, setBindTelegramCode] = useState('') // 绑定telegram的code
    const [bindTelegramUrl, setBindTelegramUrl] = useState('') // 绑定telegram的url
    const [language, setLanguage] = useState('') // 语言
    const [timezone, setTimezone] = useState('') // 时区
    const [note, setNote] = useState('') // 通知方式

    const currentUser = useSelector(state => state.currentUser)
    const notificationTypesTimer = useRef(null)


    useEffect(() => {

        let language = i18n.currentLanguage

        if (language === undefined) {
            if (navigator.language.toLowerCase() === 'zh-cn') {
                language = 'zh'
            } else {
                language = 'en'
            }
        }

        if (language === 'zh') {
            setLanguage('zh')
        } else {
            setLanguage('en')
        }

        // 北京时间和UTC时间
        const offset = new Date().getTimezoneOffset() / 60
        if (offset === -8) {
            setTimezone('beijing')
        } else {
            setTimezone('utc')
        }
        eventBus.addListener('login_success', getNotificationTypes)
        return () => {
            eventBus.removeListener('login_success', getNotificationTypes)
        }
    }, [])

    useEffect(() => {
        getNotificationTypes()
    }, [])

    const startTimer = () => {
        notificationTypesTimer.current = setInterval(() => {
            getNotificationTypes()
        }, 2000);

    }

    const getNotificationTypes = async () => {
        const { data } = await getNotificationTypeList()
        if (data.code === 200 && data.data) {
            const m = {}
            const n = {}
            data.data.forEach(item => {
                if (item.extra) {
                    item.extra = JSON.parse(item.extra)
                }

                m[item.type] = item
                n[item.type] = item.id
            })


            setNotificationTypes(m)
            setSelectedNotificationTypes(n) // 设置选中的通知方式和对应的id
            console.log(m, notificationTypesTimer.current)
            if (m[NOTIFICATION_TYPE_TELEGRAM] && notificationTypesTimer.current) {
                setShowBindTelegramModal(false)
                console.log('已停止定时器')
                clearInterval(notificationTypesTimer.current)
                return
            }
        }


    }

    useEffect(() => {
        console.log('获取tg信息', {
            selectedNotificationTypes, language, timezone, note, notificationTypes
        })
        props.onChange && props.onChange({
            selectedNotificationTypes, language, timezone, note
        })
    }, [selectedNotificationTypes, language, timezone, note, i18n.currentLanguage])

    return <>
        <BindTelegramModal
            show={showBindTelegramModal}
            url={bindTelegramUrl}
            code={bindTelegramCode}
            handleClose={() => {
                setShowBindTelegramModal(false)
                clearInterval(notificationTypesTimer.current)
            }}
        />
        <div className="d-flex flex-direction-row align-items-center mt-4">
            <div>
                {t('通知方式')}:
            </div>

            {notificationTypes[NOTIFICATION_TYPE_TELEGRAM] ? <div style={{ marginLeft: '5px', marginRight: '3px' }}>
                <input type="checkbox" checked={selectedNotificationTypes[NOTIFICATION_TYPE_TELEGRAM] > 0} onChange={e => {
                    const m = { ...selectedNotificationTypes }
                    if (e.target.checked) {
                        m[NOTIFICATION_TYPE_TELEGRAM] = notificationTypes[NOTIFICATION_TYPE_TELEGRAM].id
                    } else {
                        m[NOTIFICATION_TYPE_TELEGRAM] = 0
                    }
                    setSelectedNotificationTypes(m)
                }} style={{ marginLeft: '5px', marginRight: '3px' }} />
                Telegram({notificationTypes[NOTIFICATION_TYPE_TELEGRAM].extra.user_name})</div> : <div onClick={async () => {

                    if (!currentUser || !currentUser.id) {
                        toast(t('请先登录'))
                        eventBus.emit('request_login')
                        return
                    }

                    const { data } = await getBindTgCode()
                    const code = data.data
                    const url = `https://t.me/${tmdBotName}?start=${code}_${currnetLanguage}`

                    setBindTelegramUrl(url)
                    setBindTelegramCode(`${code}_${currnetLanguage}`)
                    setShowBindTelegramModal(true)

                    // 打开一个新页面
                    window.open(url, '_blank')

                    // const w = window.open('about:blank')
                    // w.location.href = url
                    // 打开一个定时器进行轮询
                    startTimer()
                }} style={{ marginLeft: '5px', marginRight: '3px' }} className="text-primary btn"> <i style={{ color: 'blueviolet' }} className="fa fa-life-ring"></i> {t('绑定Telegram')}</div>}

        </div>
        <div className="d-flex flex-direction-row align-items-center mt-2">
            <div>{t('语言')}:</div>
            <div style={{ marginLeft: '10px' }}>
                <select value={language} onChange={e => {
                    setLanguage(e.target.value)
                }} className="form-control form-select form-select-sm text-light" style={{ width: '100px', marginLeft: '5px', fontSize: '12px' }}>
                    <option value="zh">简体中文</option>
                    <option value="en">English</option>
                </select>
            </div>
        </div>
        <div className="d-flex flex-direction-row align-items-center mt-2">
            <div>{t('时区')}:</div>
            <div style={{ marginLeft: '10px' }}>
                <select value={timezone} onChange={e => {
                    setTimezone(e.target.value)
                }} className="form-control form-select form-select-sm text-light" style={{ width: '100px', marginLeft: '5px', fontSize: '12px' }}>
                    <option value="beijing">{t('北京时间')}</option>
                    <option value="utc">{t('UTC时间')}</option>
                </select>
            </div>
        </div>
        <div className="d-flex flex-direction-row align-items-center mt-2">
            <div>{t('备注')}:</div>
            <div style={{ marginLeft: '10px', width: '80%' }}>
                <input type="text" value={note} onChange={e => {
                    setNote(e.target.value)
                }} className="form-control form-control-sm" style={{ fontSize: '12px' }} placeholder={t('添加备注')} />
            </div>
        </div>
    </>
}


export default function MonitorModal(props) {

    const [notificationTypes, setNotificationTypes] = useState({}) // 通知方式
    const [notifications, setNotifications] = useState([]) // 当前已有的通知
    const [selectedNotificationTypes, setSelectedNotificationTypes] = useState([]) // 选中的通知方式 [NOTIFICATION_TYPE_TELEGRAM]
    const [currentActiveKey, setCurrentActiveKey] = useState('price-monitor') // 当前激活的tab [price-monitor, pool-monitor, top-monitor

    const [language, setLanguage] = useState('') // 语言
    const [timezone, setTimezone] = useState('') // 时区
    const [note, setNote] = useState('') // 备注

    // const [showBindTelegramModal, setShowBindTelegramModal] = useState(false) // 展示绑定telegram的modal
    // const [bindTelegramCode, setBindTelegramCode] = useState('') // 绑定telegram的code
    // const [bindTelegramUrl, setBindTelegramUrl] = useState('') // 绑定telegram的url

    const { t, i18n } = useTranslation()
    const i18nLanguage = i18n.language
    // const currnetLanguage = i18n.language; // 获取当前语言
    const [currnetLanguage, setCurrnetLanguage] = useState(i18nLanguage)

    useEffect(() => {
        // 初始化语言和时区
        let lang = i18nLanguage
        if (lang === undefined) {
            if (navigator.language.toLowerCase() === 'zh-cn') {
                lang = 'zh'
            } else {
                lang = 'en'
            }
        }

        setLanguage(lang)
        setCurrnetLanguage(lang)

    }, [i18nLanguage])
    const currentUser = useSelector(state => state.currentUser)
    // console.log(currnetLanguage, '=====lang')
    const [taskType, setTaskType] = useState({
        [TASK_PRICE_TARGET_PRICE]: true, // 按照目标价格通知
        [TASK_PRICE_RAISE]: true, // 按照涨幅通知
        [TASK_PRICE_FALL]: true, // 按照跌幅通知
        [TASK_POOL_TARGET_VALUE]: true, // 按照池子目标大小监控
        [TASK_POOL_RAISE]: true, // 按照池子涨幅监控
        [TASK_POOL_FALL]: true, // 按照池子跌幅监控
        [HOLDERS_TRANSFER]: true,
        [HOLDERS_SWAP]: true,
        [HOLDERS_ADD_POOL]: true,
        [TASK_WALLET_SWAP]: true,
        [TASK_WALLET_TRANSFER]: true,
        [TASK_WALLET_POOL]: true,
    }) // 任务类型
    const [taskParams, setTaskParams] = useState({
        target_price: '',
        target_raise: '', // 涨幅
        target_fall: '', // 跌幅
        pool_target_price: '',
        pool_target_raise: '', // 池子涨幅
        pool_target_fall: '', // 池子跌幅
        holders_top: '', // 持币前多少名
        holders_notify_value: '', // 持币用户操作报警阈值
        wallet_option_value: '', // 监控的钱包操作价值超过x的时候报警
    }) // 任务参数


    useEffect(() => {
        getNotifications()
    }, [props.token, props.type, props.wallet])

    // const startTimer = () => {
    //     notificationTypesTimer.current = setInterval(() => {
    //         // console.log(notificationTypes[NOTIFICATION_TYPE_TELEGRAM], notificationTypes, notificationTypesTimer.current)
    //         // if (notificationTypes[NOTIFICATION_TYPE_TELEGRAM] && notificationTypesTimer.current) {
    //         //     console.log('已停止定时器')
    //         //     clearInterval(notificationTypesTimer.current)
    //         //     return
    //         // }
    //         getNotificationTypes()
    //     }, 2000);

    // }


    const setTaskStatus = (name, value) => {
        console.log(name, value)
        const m = { ...taskType }
        m[name] = value
        console.log(m)
        setTaskType(m)
    }

    const setTaskParamsLogic = (name, value) => {
        const m = { ...taskParams }
        m[name] = value
        setTaskParams(m)
    }

    const getNotifications = async () => {
        if ((props.token?.address && !isNaN(parseInt(props.token?.chain_id))) || (props.type === 'wallet' && isAddress(props.wallet))) {
            const { data } = await getTaskNotifications({
                token: props.token?.address,
                chain_id: parseInt(props.token?.chain_id) + '',
                type: props.type || '', // 如果type是wallet, 则返回所有监控地址的任务, 如果type是token, 则返回所有监控这个token的任务
                wallet: props.wallet || ''
            })
            if (data.code === 200) {
                setNotifications(data.data || [])
            }
        }
    }
    // const getNotificationTypes = async () => {
    //     const { data } = await getNotificationTypeList()
    //     if (data.code === 200 && data.data) {
    //         const m = {}
    //         const n = {}
    //         data.data.forEach(item => {
    //             if (item.extra) {
    //                 item.extra = JSON.parse(item.extra)
    //             }

    //             m[item.type] = item
    //             n[item.type] = item.id
    //         })


    //         setNotificationTypes(m)
    //         setSelectedNotificationTypes(n) // 设置选中的通知方式和对应的id
    //         console.log(m, notificationTypesTimer.current)
    //         if (m[NOTIFICATION_TYPE_TELEGRAM] && notificationTypesTimer.current) {
    //             setShowBindTelegramModal(false)
    //             console.log('已停止定时器')
    //             clearInterval(notificationTypesTimer.current)
    //             return
    //         }
    //     }


    // }

    const paramsIsValid = () => {
        if (props.type === 'token') {
            if (currentActiveKey === 'price-monitor') {
                // 价格监控
                if (taskType[TASK_PRICE_TARGET_PRICE]) {
                    if (!taskParams['target_price']) {
                        toast.error(t('请输入目标价格'))
                        return false
                    }
                    // 价格不是数字
                    if (isNaN(Number(taskParams['target_price']))) {
                        toast.error(t('价格格式不正确'))
                        return false
                    }
                }
                if (taskType[TASK_PRICE_RAISE]) {
                    if (!taskParams['target_raise']) {
                        toast.error(t('请输入涨幅'))
                        return false
                    }
                    // 价格不是数字
                    if (isNaN(Number(taskParams['target_raise']))) {
                        toast.error(t('涨幅格式不正确'))
                        return false
                    }
                }
                if (taskType[TASK_PRICE_FALL]) {
                    if (!taskParams['target_fall']) {
                        toast.error(t('请输入跌幅'))
                        return false
                    }
                    // 价格不是数字
                    if (isNaN(Number(taskParams['target_fall']))) {
                        toast.error(t('跌幅格式不正确'))
                        return false
                    }
                }
            }
            if (currentActiveKey === 'pool-monitor') {
                // 流动池监控
                if (taskType[TASK_POOL_TARGET_VALUE]) {
                    if (!taskParams['pool_target_price']) {
                        toast.error(t('请输入目标流动池市值'))
                        return false
                    }
                    // 价格不是数字
                    if (isNaN(Number(taskParams['pool_target_price']))) {
                        toast.error(t('目标流动池市值格式不正确'))
                        return false
                    }
                }
                if (taskType[TASK_POOL_RAISE]) {
                    if (!taskParams['pool_target_raise']) {
                        toast.error(t('请输入涨幅'))
                        return false
                    }
                    // 价格不是数字
                    if (isNaN(Number(taskParams['pool_target_raise']))) {
                        toast.error(t('涨幅格式不正确'))
                        return false
                    }
                }
                if (taskType[TASK_POOL_FALL]) {
                    if (!taskParams['pool_target_fall']) {
                        toast.error(t('请输入跌幅'))
                        return false
                    }
                    // 价格不是数字
                    if (isNaN(Number(taskParams['pool_target_fall']))) {
                        toast.error(t('跌幅格式不正确'))
                        return false
                    }
                }
            }
            if (currentActiveKey === 'top-monitor') {
                if (!taskParams['holders_top']) {
                    toast.error(t('请输入监控范围'))
                    return false
                }
                if (isNaN(Number(taskParams['holders_top']))) {
                    toast.error(t('监控范围格式不正确'))
                    return false
                }
                if (!taskParams['holders_notify_value']) {
                    toast.error(t('请输入报警阈值'))
                    return false
                }
                if (isNaN(Number(taskParams['holders_notify_value']))) {
                    toast.error(t('报警阈值格式不正确'))
                    return false
                }
                if (!taskType[HOLDERS_SWAP] && !taskType[HOLDERS_TRANSFER] && !taskType[HOLDERS_ADD_POOL]) {
                    toast.error(t('请至少选择1种监控动作'))
                    return false
                }
            }
        }
        if (props.type === 'wallet') {
            console.log('wwwwwallet', taskType, '=====', TASK_WALLET_SWAP, TASK_WALLET_POOL, TASK_WALLET_TRANSFER)
            if (!taskType[TASK_WALLET_SWAP] && !taskType[TASK_WALLET_POOL] && !taskType[TASK_WALLET_TRANSFER]) {
                toast.error(t('请至少选择1种监控动作'))
                return false
            }
            if (!taskParams['wallet_option_value']) {
                toast.error(t('请输入报警阈值'))
                return false
            }
            if (isNaN(Number(taskParams['wallet_option_value']))) {
                toast.error(t('报警阈值格式不正确'))
                return false
            }
        }

        return true
    }

    const submit = async () => {

        // 添加任务
        // 验证参数
        if (!paramsIsValid()) {
            return
        }
        const notificationIdList = []
        Object.keys(selectedNotificationTypes).forEach(key => {
            if (selectedNotificationTypes[key] > 0) {
                notificationIdList.push(selectedNotificationTypes[key])
            }
        })
        if (notificationIdList.length === 0) {
            toast.error(t('请至少选择一种通知方式'))
            return
        }



        // 组装信息
        const tasks = []
        if (props.type === 'token') {
            if (currentActiveKey === 'price-monitor') {
                if (taskType[TASK_PRICE_TARGET_PRICE]) {
                    const task = {
                        type: TASK_PRICE_TARGET_PRICE,
                        target_price: taskParams['target_price'],
                        current_price: props.price?.price || '0',
                    }
                    tasks.push(task)
                }
                if (taskType[TASK_PRICE_RAISE]) {
                    const task = {
                        type: TASK_PRICE_RAISE,
                        target_raise: taskParams['target_raise'],
                        current_price: props.price?.price || '0',
                    }
                    tasks.push(task)
                }
                if (taskType[TASK_PRICE_FALL]) {
                    const task = {
                        type: TASK_PRICE_FALL,
                        target_fall: taskParams['target_fall'],
                        current_price: props.price?.price || '0',
                    }
                    tasks.push(task)
                }
            }
            if (currentActiveKey === 'pool-monitor') {
                if (taskType[TASK_POOL_TARGET_VALUE]) {
                    const task = {
                        type: TASK_POOL_TARGET_VALUE,
                        pool_target_value: taskParams['pool_target_price'],
                        current_pool_cap: props.pair?.cap || '0',
                    }
                    tasks.push(task)
                }
                if (taskType[TASK_POOL_RAISE]) {
                    const task = {
                        type: TASK_POOL_RAISE,
                        pool_target_raise: taskParams['pool_target_raise'],
                        current_pool_cap: props.pair?.cap || '0',
                    }
                    tasks.push(task)
                }
                if (taskType[TASK_POOL_FALL]) {
                    const task = {
                        type: TASK_POOL_FALL,
                        pool_target_fall: taskParams['pool_target_fall'],
                        current_pool_cap: props.pair?.cap || '0',
                    }
                    tasks.push(task)
                }
            }
            if (currentActiveKey === 'top-monitor') {
                if (taskType[HOLDERS_TRANSFER]) {
                    const task = {
                        type: HOLDERS_TRANSFER,
                        holders_top: taskParams['holders_top'],
                        holders_notify_value: taskParams['holders_notify_value'],
                    }
                    tasks.push(task)
                }
                if (taskType[HOLDERS_SWAP]) {
                    const task = {
                        type: HOLDERS_SWAP,
                        holders_top: taskParams['holders_top'],
                        holders_notify_value: taskParams['holders_notify_value'],
                    }
                    tasks.push(task)
                }
                if (taskType[HOLDERS_ADD_POOL]) {
                    const task = {
                        type: HOLDERS_ADD_POOL,
                        holders_top: taskParams['holders_top'],
                        holders_notify_value: taskParams['holders_notify_value'],
                    }
                    tasks.push(task)
                }
            }
        }
        if (props.type === 'wallet') {
            if (taskType[TASK_WALLET_TRANSFER]) {
                const task = {
                    type: TASK_WALLET_TRANSFER,
                    wallet_option_value: taskParams['wallet_option_value'],
                }
                tasks.push(task)
            }
            if (taskType[TASK_WALLET_SWAP]) {
                const task = {
                    type: TASK_WALLET_SWAP,
                    wallet_option_value: taskParams['wallet_option_value'],
                }
                tasks.push(task)
            }
            if (taskType[TASK_WALLET_POOL]) {
                const task = {
                    type: TASK_WALLET_POOL,
                    wallet_option_value: taskParams['wallet_option_value'],
                }
                tasks.push(task)
            }
        }

        if (tasks.length === 0) {
            toast.error(t('请至少创建一个任务'))
            return
        }
        // 判断tokenSymbol、chain
        // const tokenSymbol = `${props.pair?.token0_symbol || ''}/${props.pair?.token1_symbol || ''}`
        const tokenSymbol = `${props.token?.symbol || ''}`
        const chianName = props.token?.chain_name?.toUpperCase()

        // 发送给后端
        const list = tasks.map(item => {
            return {
                ...item,
                target_raise: Number(item.target_raise || 0),
                target_fall: Number(item.target_fall || 0),
                pool_target_raise: Number(item.pool_target_raise || 0),
                pool_target_fall: Number(item.pool_target_fall || 0),
                holders_top: Number(item.holders_top || 0),
                token: props.token?.address,
                chain_id: parseInt(props.token?.chain_id),
                notification_id_list: notificationIdList,
                wallet: props.wallet || '',
                pool: props.pair?.pair || '',
                token_symbol: tokenSymbol,
                chain_name: chianName,
                token0_symbol: props.pair?.token0_symbol || '',
                token1_symbol: props.pair?.token1_symbol || '',
                language,
                timezone,
                note: note,
            }
        })
        console.log(list)
        const { data } = await StoreTokenMonitorTasks({
            list: list
        })
        if (data.code === 200) {
            toast.success(t('已添加监控'))
            getNotifications()
            props.ok && props.ok() // 刷新外部的任务列表
            props.handleClose && props.handleClose()
            return
        }
        if (data.code === 10024) {
            toast.error(t('目前最多只能添加100个监控喔~'))
            return
        }

        // 关闭页面
        toast.error(t('添加失败'))
    }

    return <>

        <Modal centered contentClassName="search-modal" show={props.show} onHide={() => {
            props.handleClose && props.handleClose()
        }} >
            <Modal.Body>
                <div>
                    <div className="login-header">{t('添加监控')}</div>
                    {notifications.length > 0 && <div className="mt-2">
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header> {t('当前已存在x项监控任务').replace('{x}', notifications.length)} </Accordion.Header>
                                <Accordion.Body bsPrefix="tasks-accordion-body">
                                    <TaskListTable type={props.type} hidenToken={true} tasks={notifications} refresh={() => {
                                        getNotifications()
                                    }} />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    </div>}
                    <div>
                        {props.type === 'token' && <Tab.Container defaultActiveKey="price-monitor" activeKey={currentActiveKey} onSelect={(k) => {
                            setCurrentActiveKey(k)
                        }}>
                            <div className="card-header">
                                <Nav variant="pills">
                                    <Nav.Link eventKey="price-monitor">
                                        {t('价格监控')}
                                    </Nav.Link>
                                    <Nav.Link eventKey="pool-monitor">
                                        {t('流动池监控')}
                                    </Nav.Link>
                                    <Nav.Link eventKey="top-monitor">
                                        {t('巨鲸变动监控')}
                                    </Nav.Link>
                                </Nav>
                            </div>
                            <div>
                                <Tab.Content>
                                    <Tab.Pane eventKey="price-monitor">
                                        <div className="mt-2" style={{ paddingLeft: '10px' }}>
                                            {t('现价')}: <span style={{ textDecoration: 'underline' }} className="pointer-btn" onClick={() => {
                                                navigator.clipboard.writeText(props.price?.price || '')
                                                toast.success(t('已复制'))
                                            }}>$ {converToNumberString(props.price?.price)}</span>
                                        </div>
                                        <div className="mt-2">
                                            <div className={taskType[TASK_PRICE_TARGET_PRICE] ? 'text-light' : 'text-muted'}>
                                                <input type="checkbox" checked={taskType[TASK_PRICE_TARGET_PRICE]} onChange={e => setTaskStatus(TASK_PRICE_TARGET_PRICE, e.target.checked)} style={{ marginLeft: '10px', marginRight: '5px' }} />
                                                {t('按照目标价格通知')}
                                                {currnetLanguage === 'zh' ? <div style={{ paddingLeft: '27px' }}>
                                                    价格达到 $<input type="text" value={taskParams['target_price']} onChange={e => { setTaskParamsLogic('target_price', e.target.value) }} className="input-sm" /> 时进行通知
                                                </div> :
                                                    <div style={{ paddingLeft: '27px' }}>
                                                        Notify when the price reaches  $<input type="text" value={taskParams['target_price']} onChange={e => { setTaskParamsLogic('target_price', e.target.value) }} className="input-sm" />
                                                    </div>}
                                            </div>
                                            <div className={taskType[TASK_PRICE_RAISE] ? 'text-light' : 'text-muted'}>
                                                <input type="checkbox" checked={taskType[TASK_PRICE_RAISE]} onChange={e => setTaskStatus(TASK_PRICE_RAISE, e.target.checked)} style={{ marginLeft: '10px', marginRight: '5px' }} />
                                                {t('按照涨幅通知')}
                                                {currnetLanguage === 'zh' ? <div style={{ paddingLeft: '27px' }}>
                                                    以当前价格为基准, 每上涨  <input style={{ width: '30px', textAlign: 'center' }} type="text" value={taskParams['target_raise']} onChange={e => { setTaskParamsLogic('target_raise', e.target.value) }} className="input-sm" />% 进行通知
                                                </div> : <div style={{ paddingLeft: '27px' }}>
                                                    Based on the current price, every  <input style={{ width: '30px', textAlign: 'center' }} type="text" value={taskParams['target_raise']} onChange={e => { setTaskParamsLogic('target_raise', e.target.value) }} className="input-sm" />% increase will be notified
                                                </div>}
                                            </div>
                                            <div className={taskType[TASK_PRICE_FALL] ? 'text-light' : 'text-muted'}>
                                                <input type="checkbox" checked={taskType[TASK_PRICE_FALL]} onChange={e => setTaskStatus(TASK_PRICE_FALL, e.target.checked)} style={{ marginLeft: '10px', marginRight: '5px' }} />
                                                {t('按照跌幅通知')}
                                                {currnetLanguage === 'zh' ? <div style={{ paddingLeft: '27px' }}>
                                                    以当前价格为基准, 每下跌 <input style={{ width: '30px', textAlign: 'center' }} value={taskParams['target_fall']} onChange={e => { setTaskParamsLogic('target_fall', e.target.value) }} type="text" className="input-sm" />% 进行通知
                                                </div> : <div style={{ paddingLeft: '27px' }}>
                                                    Based on the current price, a notification will be made for every <input style={{ width: '30px', textAlign: 'center' }} value={taskParams['target_fall']} onChange={e => { setTaskParamsLogic('target_fall', e.target.value) }} type="text" className="input-sm" />% drop
                                                </div>}
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="pool-monitor">
                                        <div className="mt-2" style={{ paddingLeft: '10px' }}>
                                            {t('当前流动池市值')}: <span style={{ textDecoration: 'underline' }} className="pointer-btn" onClick={() => {
                                                navigator.clipboard.writeText(props.pair?.cap)
                                                toast.success(t('已复制'))
                                            }}>$ {converToNumberString(props.pair?.cap)}</span>
                                        </div>
                                        <div className="mt-2">
                                            <div className={taskType[TASK_POOL_TARGET_VALUE] ? 'text-light' : 'text-muted'}>
                                                <input type="checkbox" checked={taskType[TASK_POOL_TARGET_VALUE]} onChange={e => setTaskStatus(TASK_POOL_TARGET_VALUE, e.target.checked)} style={{ marginLeft: '10px', marginRight: '5px' }} />
                                                {t('按照目标流动池市值通知')}
                                                {currnetLanguage === 'zh' ? <div style={{ paddingLeft: '27px' }}>
                                                    流动市值达到 $<input type="text" value={taskParams['pool_target_price']} onChange={e => { setTaskParamsLogic('pool_target_price', e.target.value) }} className="input-sm" /> 时进行通知
                                                </div> : <div style={{ paddingLeft: '27px' }}>
                                                    Notify when liquid market capitalization reaches $<input type="text" value={taskParams['pool_target_price']} onChange={e => { setTaskParamsLogic('pool_target_price', e.target.value) }} className="input-sm" />
                                                </div>}
                                            </div>
                                            <div className={taskType[TASK_POOL_RAISE] ? 'text-light' : 'text-muted'}>
                                                <input type="checkbox" checked={taskType[TASK_POOL_RAISE]} onChange={e => setTaskStatus(TASK_POOL_RAISE, e.target.checked)} style={{ marginLeft: '10px', marginRight: '5px' }} />
                                                {t('按照涨幅通知')}
                                                {currnetLanguage === 'zh' ? <div style={{ paddingLeft: '27px' }}>
                                                    以当前流动池市值为基准, 每上涨  <input style={{ width: '30px', textAlign: 'center' }} type="text" value={taskParams['pool_target_raise']} onChange={e => { setTaskParamsLogic('pool_target_raise', e.target.value) }} className="input-sm" /> % 进行通知
                                                </div> : <div style={{ paddingLeft: '27px' }}>
                                                    Based on the current market value of the liquidity pool, every  <input style={{ width: '30px', textAlign: 'center' }} type="text" value={taskParams['pool_target_raise']} onChange={e => { setTaskParamsLogic('pool_target_raise', e.target.value) }} className="input-sm" />% increase will be notified
                                                </div>}

                                            </div>
                                            <div className={taskType[TASK_POOL_FALL] ? 'text-light' : 'text-muted'}>
                                                <input type="checkbox" checked={taskType[TASK_POOL_FALL]} onChange={e => setTaskStatus(TASK_POOL_FALL, e.target.checked)} style={{ marginLeft: '10px', marginRight: '5px' }} />
                                                {t('按照跌幅通知')}
                                                {currnetLanguage === 'zh' ? <div style={{ paddingLeft: '27px' }}>
                                                    以当前流动池市值为基准, 每下跌  <input style={{ width: '30px', textAlign: 'center' }} value={taskParams['pool_target_fall']} onChange={e => { setTaskParamsLogic('pool_target_fall', e.target.value) }} type="text" className="input-sm" /> % 进行通知
                                                </div> : <div style={{ paddingLeft: '27px' }}>
                                                    Based on the current market value of the liquidity pool, a notification will be made for every  <input style={{ width: '30px', textAlign: 'center' }} value={taskParams['pool_target_fall']} onChange={e => { setTaskParamsLogic('pool_target_fall', e.target.value) }} type="text" className="input-sm" />% drop
                                                </div>}
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="top-monitor">
                                        <div className="mt-2">
                                            <div className="mt-2">
                                                {t('监控范围')}:
                                                {currnetLanguage === 'zh' ? <>
                                                    持币前 <input placeholder="Max: 100" type="text" style={{ width: '80px' }} value={taskParams['holders_top']} onChange={(e) => {
                                                        setTaskParamsLogic('holders_top', e.target.value)
                                                    }} className="input-sm" /> 名地址
                                                </> : <>
                                                    Top <input placeholder="Max: 100" type="text" style={{ width: '80px' }} value={taskParams['holders_top']} onChange={(e) => {
                                                        setTaskParamsLogic('holders_top', e.target.value)
                                                    }} className="input-sm" /> addresses holding coins
                                                </>}

                                            </div>
                                            <div className="mt-2">
                                                {t('监控动作')}:
                                                <input style={{ marginLeft: '8px' }} type="checkbox" checked={taskType[HOLDERS_SWAP]} onChange={e => setTaskStatus(HOLDERS_SWAP, e.target.checked)} /> {t('交易')}
                                                <input style={{ marginLeft: '8px' }} type="checkbox" checked={taskType[HOLDERS_TRANSFER]} onChange={e => setTaskStatus(HOLDERS_TRANSFER, e.target.checked)} />  {t('转账')}
                                                <input style={{ marginLeft: '8px' }} type="checkbox" checked={taskType[HOLDERS_ADD_POOL]} onChange={e => setTaskStatus(HOLDERS_ADD_POOL, e.target.checked)} />  {t('加撤池')}
                                            </div>
                                            <div className="mt-2">
                                                {t('报警阈值')}: {currnetLanguage === 'zh' ? <>
                                                    用户持币变化超过 <input value={taskParams['holders_notify_value']} onChange={e => {
                                                        setTaskParamsLogic('holders_notify_value', e.target.value)
                                                    }} type="text" style={{ width: '80px' }} className="input-sm" /> {props.token?.symbol} 时进行通知
                                                </> : <>
                                                    Notify when the user's currency holdings change exceeds <input value={taskParams['holders_notify_value']} onChange={e => {
                                                        setTaskParamsLogic('holders_notify_value', e.target.value)
                                                    }} type="text" style={{ width: '80px' }} className="input-sm" />  {props.token?.symbol}
                                                </>}
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>

                        </Tab.Container>}
                        {props.type === 'wallet' && <div>
                            {/* 监控单个用户 */}
                            <div className="mt-2">
                                <div className="mt-2">
                                    {t('监控地址')}:  <span onClick={() => {
                                        navigator.clipboard.writeText(props.wallet)
                                        toast.success(t('已复制'))
                                    }}>{getShortAddress(props.wallet)} <i className="fa fa-copy pointer-btn"></i> </span>
                                </div>
                                <div className="mt-2">
                                    {t('监控动作')}:
                                    <input style={{ marginLeft: '8px' }} type="checkbox" checked={taskType[TASK_WALLET_SWAP]} onChange={e => setTaskStatus(TASK_WALLET_SWAP, e.target.checked)} /> {t('交易')}
                                    <input style={{ marginLeft: '8px' }} type="checkbox" checked={taskType[TASK_WALLET_TRANSFER]} onChange={e => setTaskStatus(TASK_WALLET_TRANSFER, e.target.checked)} />  {t('转账')}
                                    <input style={{ marginLeft: '8px' }} type="checkbox" checked={taskType[TASK_WALLET_POOL]} onChange={e => setTaskStatus(TASK_WALLET_POOL, e.target.checked)} />  {t('加撤池')}
                                </div>
                                <div className="mt-2">
                                    {t('报警阈值')}: {currnetLanguage === 'zh' ? <> 用户操作的价值超过 $<input value={taskParams['wallet_option_value']} onChange={e => {
                                        setTaskParamsLogic('wallet_option_value', e.target.value)
                                    }} type="text" style={{ width: '80px' }} className="input-sm" />  时进行通知</> : <>
                                        Notify when the value of a user action exceeds $<input value={taskParams['wallet_option_value']} onChange={e => {
                                            setTaskParamsLogic('wallet_option_value', e.target.value)
                                        }} type="text" style={{ width: '80px' }} className="input-sm" />
                                    </>}
                                </div>
                            </div>
                        </div>}
                    </div>
                    <NotificationTypeBox onChange={(e) => {
                        setSelectedNotificationTypes(e.selectedNotificationTypes || {})
                        setLanguage(e.language)
                        setTimezone(e.timezone)
                        setNote(e.note)
                    }} />
                    {/* <div className="d-flex flex-direction-row align-items-center mt-4">
                        <div>
                            {t('通知方式')}:
                        </div>

                        {notificationTypes[NOTIFICATION_TYPE_TELEGRAM] ? <div style={{ marginLeft: '5px', marginRight: '3px' }}>
                            <input type="checkbox" checked={selectedNotificationTypes[NOTIFICATION_TYPE_TELEGRAM] > 0} onChange={e => {
                                const m = { ...selectedNotificationTypes }
                                if (e.target.checked) {
                                    m[NOTIFICATION_TYPE_TELEGRAM] = notificationTypes[NOTIFICATION_TYPE_TELEGRAM].id
                                } else {
                                    m[NOTIFICATION_TYPE_TELEGRAM] = 0
                                }
                                setSelectedNotificationTypes(m)
                            }} style={{ marginLeft: '5px', marginRight: '3px' }} />
                            Telegram({notificationTypes[NOTIFICATION_TYPE_TELEGRAM].extra.user_name})</div> : <div onClick={async () => {

                                if (!currentUser || !currentUser.id) {
                                    toast(t('请先登录'))
                                    eventBus.emit('request_login')
                                    return
                                }

                                const { data } = await getBindTgCode()
                                const code = data.data
                                const url = `https://t.me/${tmdBotName}?start=${code}_${currnetLanguage}`

                                setBindTelegramUrl(url)
                                setBindTelegramCode(`${code}_${currnetLanguage}`)
                                setShowBindTelegramModal(true)

                                // 打开一个新页面
                                window.open(url, '_blank')

                                // const w = window.open('about:blank')
                                // w.location.href = url
                                // 打开一个定时器进行轮询
                                startTimer()
                            }} style={{ marginLeft: '5px', marginRight: '3px' }} className="text-primary btn"> <i style={{ color: 'blueviolet' }} className="fa fa-life-ring"></i> {t('绑定Telegram')}</div>}

                    </div>
                    <div className="d-flex flex-direction-row align-items-center mt-2">
                        <div>{t('语言')}:</div>
                        <div style={{ marginLeft: '10px' }}>
                            <select value={language} onChange={e => {
                                setLanguage(e.target.value)
                            }} className="form-control form-select form-select-sm text-light" style={{ width: '100px', marginLeft: '5px', fontSize: '12px' }}>
                                <option value="zh">简体中文</option>
                                <option value="en">English</option>
                            </select>
                        </div>
                    </div>
                    <div className="d-flex flex-direction-row align-items-center mt-2">
                        <div>{t('时区')}:</div>
                        <div style={{ marginLeft: '10px' }}>
                            <select value={timezone} onChange={e => {
                                setTimezone(e.target.value)
                            }} className="form-control form-select form-select-sm text-light" style={{ width: '100px', marginLeft: '5px', fontSize: '12px' }}>
                                <option value="beijing">{t('北京时间')}</option>
                                <option value="utc">{t('UTC时间')}</option>
                            </select>
                        </div>
                    </div> */}
                    {/* <div className="d-flex flex-direction-row align-items-center mt-2">
                        <div>{t('备注')}:</div>
                        <div style={{ marginLeft: '10px' }}>
                            <input type="text" value={note} onChange={e => {
                                setNote(e.target.value)
                            }} className="form-control form-control-sm" style={{ fontSize: '12px' }} placeholder={t('添加备注')} />
                        </div>
                    </div> */}
                    <div className="mt-2">
                        <div onClick={() => submit()} className="btn btn-sm btn-primary  text-light">{t('添加')}</div>
                        <div onClick={() => {
                            props.handleClose && props.handleClose()
                        }} className="btn btn-sm btn-danger text-light " style={{ marginLeft: '8px' }}>{t('取消')}</div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </>
}