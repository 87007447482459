import React, { useEffect, useState } from 'react';
import { Dropdown, Modal, Button, Navbar, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Search } from 'react-bootstrap-icons'
import SearchModal from '../components/SearchModal';
import store, { clearToken, getToken } from '../../store';
import { eventBus, getLanguageInfo, getShortAddress, isAddress, isMobile } from '../../utils/tools';
import LoginModal from '../components/LoginModal';
import { useSelector } from 'react-redux';
import { injected } from '../../utils/connector'
import { useWeb3React } from '@web3-react/core';
import { getProfile } from '../../api/login';
import Logo from '../components/logo';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';


const ProfileToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}

    >
        {children}
        <div className="profile_log">
            {/* 如果是已登录的状态, 给头像数据 */}
            <div className="user">
                {isMobile() === false ? <>
                    <span className="thumb"><i className="mdi mdi-account"></i></span>
                    <span className="arrow"><i className="la la-angle-down"></i></span>
                </> : <>
                    <img style={{ width: '28px', borderRadius: '50%' }} src="/logo.png" alt="" />
                </>}
            </div>
            {/* 如果是未登录状态, 给定登录按钮 */}
        </div>
    </div>
));

const LanguageToggle = React.forwardRef(({ children, onClick, language }, ref) => (
    <div
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <div className="language">
            <div className="icon">
                <i className={`flag-icon ${language.icon || ''}`}></i>
                <span>{language.text || ''}</span>
            </div>
        </div>
    </div>
));



function Header2(props) {
    const [showSearchModal, setShowSearchModal] = useState(false)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const currentUser = useSelector(state => state.currentUser);
    const stateAccount = useSelector(state => state.account);
    const { active, account, activate, deactivate, chainId } = useWeb3React()
    const routerHistory = useSelector(state => state.history);
    const history = useHistory()
    // 尝试连接钱包
    const { t, i18n } = useTranslation()
    const language = i18n.language; // 获取当前语言

    const isLogin = isAddress(stateAccount) && currentUser && currentUser.id > 0

    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    };

    useEffect(() => {
        // 尝试登录
        setTimeout(() => {
            activate(injected)
                .then(res => {
                    // 尝试登录成功了, 才会去获取用户信息, 判断最终得到的数据能否对上
                }).catch(e => {
                    console.log('登录失败')
                })
        }, 1000);
    }, [])

    const requestLoginEvent = () => {
        setShowLoginModal(true)
    }


    const handleToastMessageEvent = (d) => {
        const message = t(d.message)
        switch (d.type) {
            case 'success':
                toast.success(message)
                break;
            case 'error':
                toast.error(message)
                break;
            case 'info':
                toast.info(message)
                break;
            case 'warning':
                toast.warn(message)
                break;
            default:
                toast(message)
                break;
        }
    }

    useEffect(() => {

        // 接收信息
        eventBus.addListener('request_login', requestLoginEvent)
        eventBus.addListener('toast_message', handleToastMessageEvent)
        return () => {
            eventBus.removeListener('request_login', requestLoginEvent)
            eventBus.removeListener('toast_message', handleToastMessageEvent)
        }
    }, [])

    const logout = async () => {
        // 清除token
        // 清除用户信息
        // 断开钱包
        try {
            deactivate()
        } catch (e) {

        }
        // clearToken()
        store.dispatch({ type: "setCurrentUser", value: {} })
        store.dispatch({ type: 'setToken', value: '' })
        setTimeout(() => {
            eventBus.emit('logout_success')
        }, 500);

    }


    useEffect(() => {
        if (isAddress(account) && getToken()) {
            getProfile().then(({ data }) => {
                if (data.code === 200 && data.data.wallet.toLowerCase() === account.toLowerCase()) {
                    store.dispatch({ type: "setAccount", value: account })
                    store.dispatch({ type: "setCurrentUser", value: data.data })
                    store.dispatch({ type: "setChainId", value: chainId })

                    setTimeout(() => {
                        eventBus.emit('login_success')
                    }, 500);
                }
            })



        }
    }, [account])


    return (
        <>
            <div className="header dashboard">
                <SearchModal show={showSearchModal} handleClose={() => {
                    setShowSearchModal(false)
                }} />
                <LoginModal show={showLoginModal} handleClose={() => {
                    setShowLoginModal(false)
                }} />

                {isMobile() === false ? <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <nav className="navbar navbar-expand-lg navbar-light px-0 justify-content-between">
                                <Navbar className="bg-body-tertiary">
                                    {/* <Container> */}
                                    <Navbar.Brand href="/">
                                        <img
                                            alt=""
                                            src="/logo.png"
                                            width="30"
                                            height="30"
                                            className="d-inline-block align-top"
                                        />{' '}
                                        <span className='text-light' style={{ fontSize: '16px' }}> TMDex</span>
                                    </Navbar.Brand>
                                </Navbar>

                                <div className="header-right d-flex my-2 align-items-center">
                                    <div className='d-xs-none d-sm-block d-md-block d-lg-block' style={{ 'marginRight': '15px' }}>
                                        <div className="tm-search-box" onClick={() => {
                                            setShowSearchModal(true)
                                        }}>
                                            <Search style={{ 'marginLeft': '5px', 'marginRight': '10px' }} />      {t('搜索代币合约或名称')}
                                        </div>
                                    </div>
                                    <div className='d-sm-none d-md-none d-lg-none' style={{ 'marginRight': '15px' }}>
                                        <div onClick={() => {
                                            setShowSearchModal(true)
                                        }}>
                                            <Search style={{ 'marginLeft': '5px', 'marginRight': '10px' }} />
                                        </div>
                                    </div>
                                    <div className="language">
                                        <Dropdown>
                                            <Dropdown.Toggle as={LanguageToggle} language={getLanguageInfo(language)} />
                                            <Dropdown.Menu size="sm" title="">
                                                <Link to={'#'} onClick={() => handleChangeLanguage('en')} className="dropdown-item">
                                                    <i className="flag-icon flag-icon-us"></i> English
                                                </Link>
                                                <Link to={'#'} onClick={() => handleChangeLanguage('zh')} className="dropdown-item">
                                                    <i className="flag-icon flag-icon-cn"></i> 简体中文
                                                </Link>

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="dashboard_log">
                                        {isLogin && <Dropdown className="profile_log">
                                            <Dropdown.Toggle as={ProfileToggle} />
                                            <Dropdown.Menu size="sm" title="">
                                                <div className="user-email">
                                                    <div className="user">
                                                        <span className="thumb"><i className="mdi mdi-account"></i></span>
                                                        <div className="user-info">
                                                            <h6>{getShortAddress(currentUser.wallet || '')}</h6>
                                                            {/* <span>quixlab.com@gmail.com</span> */}
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="user-balance">
                                                    <div className="available">
                                                        <p>Available</p>
                                                        <span>0.00 USD</span>
                                                    </div>
                                                    <div className="total">
                                                        <p>Total</p>
                                                        <span>0.00 USD</span>
                                                    </div>
                                                </div> */}

                                                {/* <Link to={"./account-overview"} className="dropdown-item">
                                                    <i className="mdi mdi-account"></i> Account
                                                </Link>
                                                <Link to={"./data-tbi"} className="dropdown-item">
                                                    <i className="mdi mdi-history"></i> History
                                                </Link>
                                                <Link to={"./settings"} className="dropdown-item">
                                                    <i className="mdi mdi-settings"></i> Setting
                                                </Link>
                                                <Link to={"./lock"} className="dropdown-item">
                                                    <i className="mdi mdi-lock"></i> Lock
                                                </Link> */}
                                                <div onClick={() => {
                                                    logout()

                                                }} className="dropdown-item logout">
                                                    <i className="mdi mdi-logout"></i> {t('退出登录')}
                                                </div>

                                            </Dropdown.Menu>
                                        </Dropdown>}

                                        {!isLogin && <div className='profile_log'>
                                            <Button onClick={() => {
                                                // 展示连接钱包的弹窗
                                                setShowLoginModal(true)
                                            }} style={{ color: '#ffffff' }} variant="primary">{t('连接钱包')}</Button>
                                        </div>}

                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div> : <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <nav className="navbar navbar-expand-lg navbar-light px-0 justify-content-between">
                                {!props.token && <Navbar className="bg-body-tertiary">
                                    <Navbar.Brand href="/">
                                        <img
                                            alt=""
                                            src="/logo.png"
                                            width="30"
                                            height="30"
                                            className="d-inline-block align-top"
                                        />{' '}
                                        <span className='text-light' style={{ fontSize: '16px' }}> TMDex</span>
                                    </Navbar.Brand>
                                </Navbar>}
                                {props.token && <Navbar style={{ height: '48px' }} className="bg-body-tertiary">
                                    {/* <a href="/"> */}
                                    <span onClick={() => {
                                        if (routerHistory.length > 1) {
                                            window.history.back()
                                        } else {
                                            history.push('/')
                                        }

                                    }}>
                                        <i style={{ fontSize: '30px' }} className='mdi mdi-chevron-left'></i>
                                    </span>
                                    {/* </a> */}
                                    <Navbar.Brand >
                                        <Logo size="1.9" chain={props.token.chain_name} contract={props.token.address} logo={props.token.logo} />
                                        <span className='text-light' style={{ fontSize: '16px', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '150px', textOverflow: 'ellipsis' }}> {props.token.symbol}</span>
                                    </Navbar.Brand>
                                </Navbar>}

                                <div className="header-right d-flex my-2 align-items-center">
                                    <div className='d-xs-none d-sm-block d-md-block d-lg-block' style={{ 'marginRight': '15px' }}>
                                        <div className="tm-search-box" onClick={() => {
                                            setShowSearchModal(true)
                                        }}>
                                            <Search style={{ 'marginLeft': '5px', 'marginRight': '10px' }} />      {t('搜索代币合约或名称')}
                                        </div>
                                    </div>
                                    <div className='d-sm-none d-md-none d-lg-none' style={{ 'marginRight': '15px' }}>
                                        <div onClick={() => {
                                            setShowSearchModal(true)
                                        }}>
                                            <Search style={{ 'marginLeft': '5px', 'marginRight': '10px' }} />
                                        </div>
                                    </div>
                                    <div className="dashboard_log">
                                        {isLogin && <Dropdown className="profile_log">
                                            <Dropdown.Toggle as={ProfileToggle} />
                                            <Dropdown.Menu size="sm" title="">
                                                <div className="user-email">
                                                    <div className="user">
                                                        <span className="thumb"><i className="mdi mdi-account"></i></span>
                                                        <div className="user-info">
                                                            <h6>{getShortAddress(currentUser.wallet || '')}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div onClick={() => {
                                                    logout()

                                                }} className="dropdown-item logout">
                                                    <i className="mdi mdi-logout"></i> {t('退出登录')}
                                                </div>

                                            </Dropdown.Menu>
                                        </Dropdown>}

                                        {!isLogin && <div className='profile_log'>
                                            <Button onClick={() => {
                                                // 展示连接钱包的弹窗
                                                setShowLoginModal(true)
                                            }} style={{ color: '#ffffff' }} variant="primary">{t('连接钱包')}</Button>
                                        </div>}

                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>}
            </div>
        </>
    )
}

export default Header2;