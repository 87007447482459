import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Header2 from "../../layout/header2";
import Sidebar from "../../layout/sidebar";
import { Form, Nav, Spinner, Tab } from "react-bootstrap";
import { getAllTaskNotifications, getTaskRecordList } from "../../../api/login";
import MonitorModal, { NotificationTypeBox, TASK_NEW_POOL, TaskListTable } from "../../components/DashboardComponents/MonitorModal";
import { eventBus, formatTimeString, isAddress, isMobile } from "../../../utils/tools";
import { formatTaskRecordContent, formatTaskRecordDetail } from "../../../utils/task_handler";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { StoreTokenMonitorTasks } from "../../../api/monitor";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TaskRecordDetailModel = (props) => {
    const { t } = useTranslation()
    const history = useHistory()
    const [record, setRecord] = useState({}) // 当前选中的任务记录
    useEffect(() => {
        const x = formatTaskRecordDetail(JSON.parse(props.info || '{}'))
        setRecord(x)
    }, [props.info])

    return <Modal centered contentClassName="search-modal" show={props.show} onHide={() => {
        props.handleClose && props.handleClose()
    }} >
        <Modal.Body>
            <div>
                <div className="login-header">{t('任务日志')}</div>
                <div>
                    <div style={{ wordBreak: 'break-word' }} className="mt-2 text-light">
                        {record.message}
                    </div>
                    <div>
                        <table className="table">
                            <tbody>
                                {record.columns && record.columns.map((item, ind) => {
                                    return <tr key={'task-r-ind-' + ind}>
                                        <td style={{ width: '50%', wordBreak: 'break-all' }} className="text-light task-record-td">{item.name}</td>
                                        <td style={{ width: '50%', wordBreak: 'break-all' }} className="task-record-td">{item.value}
                                            {item.copy && <span onClick={() => {
                                                navigator.clipboard.writeText(item.copyValue)
                                                toast.success(t('已复制'))
                                            }}>
                                                <i style={{ marginLeft: '8px' }} className="fa fa-copy pointer-btn"></i>
                                            </span>}
                                        </td>
                                    </tr>
                                })}

                            </tbody>
                        </table>
                    </div>
                    <div>
                        {record.links && record.links.map((item, ind) => {
                            return item.value ? <a style={{ marginRight: '10px', marginTop: '10px' }} target="_blank" rel="noopener noreferrer" href={item.value} className="btn btn-outline-primary">{item.name}</a> : <span onClick={() => {
                                if (item.router) {
                                    history.push(item.router)
                                }

                            }} style={{ marginRight: '10px', marginTop: '10px' }} className="btn btn-outline-primary pointer-btn">{item.name}</span>
                        })}
                        <div onClick={() => {
                            props.handleClose && props.handleClose()
                        }} style={{ marginTop: '10px' }} className="btn btn-outline-danger "> {t('关闭')}</div>
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
}

const AddNewPoolMonitorModal = (props) => {
    const { t } = useTranslation()
    const [keywords, setKeywords] = useState('') // 关键字
    const [minPoolSize, setMinPoolSize] = useState('') // 最低池子大小
    const [language, setLanguage] = useState('zh') // 语言
    const [timezone, setTimezone] = useState('') // 时区
    const [selectedNotificationTypes, setSelectedNotificationTypes] = useState([]) // 选中的通知类型
    const [note, setNote] = useState('') // 关键字
    const submit = async () => {
        // 是否有关键字
        // 验证池子大小是否正确
        // 验证通知方式
        // 验证语言时区备注
        if (!keywords) {
            toast.error(t('请输入关键字'))
            return
        }
        if (isNaN(parseInt(minPoolSize)) && minPoolSize !== '') {
            toast.error(t('请输入正确的池子大小'))
            return
        }

        const notificationIdList = []
        Object.keys(selectedNotificationTypes).forEach(key => {
            if (selectedNotificationTypes[key] > 0) {
                notificationIdList.push(selectedNotificationTypes[key])
            }
        })
        if (notificationIdList.length === 0) {
            toast.error(t('请至少选择一种通知方式'))
            return
        }

        const task = {
            type: TASK_NEW_POOL,
            keywords: [keywords],
            min_pool_value: minPoolSize + '',
            notification_id_list: notificationIdList,
            language,
            timezone,
            note
        }
        const { data } = await StoreTokenMonitorTasks({
            list: [task]
        })
        if (data.code === 200) {
            toast.success(t('已添加监控'))
            props.ok && props.ok() // 刷新外部的任务列表
            props.handleClose && props.handleClose()
            return
        }
        if (data.code === 10024) {
            toast.error(t('目前最多只能添加100个监控喔~'))
            return
        }

        // 关闭页面
        toast.error(t('添加失败'))

    }

    return <Modal centered contentClassName="search-modal" show={props.show} onHide={() => {
        props.handleClose && props.handleClose()

    }} >
        <Modal.Body>
            <div>
                <div className="login-header">{t('添加新池监控')}</div>
                <div className="mt-2">
                    {/* 输入关键字
                    最低池子大小
                    高级筛选: 包含其他信息等 */}
                    <Form>
                        <div className="mt-4">
                            <div className="d-flex flex-direction-row align-items-center mt-2">
                                <div>{t('关键字')}:</div>
                                <div style={{ marginLeft: '10px', width: '80%' }}>
                                    <input type="text" value={keywords} onChange={e => {
                                        setKeywords(e.target.value)
                                    }} className="form-control form-control-sm" style={{ fontSize: '12px' }} placeholder={t('名称中包含关键字的代币将会被提醒')} />
                                </div>
                            </div>
                        </div>
                        <div className="mt-2">
                            {t('报警阈值')}:  {t('池子价值超过')}  $<input type="text" value={minPoolSize} onChange={e => {
                                setMinPoolSize(e.target.value)
                            }} className="input-sm" style={{ width: '100px' }} />  {t('时进行通知')}
                        </div>
                        <NotificationTypeBox onChange={(e) => {
                            setSelectedNotificationTypes(e.selectedNotificationTypes || {})
                            setLanguage(e.language)
                            setTimezone(e.timezone)
                            setNote(e.note || '')

                        }} />
                        <div className="mt-2">
                            <div onClick={() => submit()} className="btn btn-sm btn-primary  text-light">{t('添加')}</div>
                            <div onClick={() => {
                                props.handleClose && props.handleClose()
                            }} className="btn btn-sm btn-danger text-light " style={{ marginLeft: '8px' }}>{t('取消')}</div>
                        </div>
                    </Form>
                </div>
            </div>
        </Modal.Body>
    </Modal>
}

const AddWalletMonitorModal = (props) => {
    const { t } = useTranslation()
    const [wallet, setWallet] = useState('') // 钱包地址
    return <Modal centered contentClassName="search-modal" show={props.show} onHide={() => {
        props.handleClose && props.handleClose()
    }} >
        <Modal.Body>
            <div>
                <div className="login-header">{t('添加钱包监控')}</div>
                <div className="mt-2">
                    {/* 输入钱包地址 */}
                    {/* 确认 */}
                    <Form>
                        <div>
                            <Form.Control value={wallet} onChange={(e) => {
                                setWallet(e.target.value)
                            }} placeholder={t('输入钱包地址')} />
                        </div>
                        <div className="mt-2">
                            <div onClick={() => {
                                if (!isAddress(wallet)) {
                                    toast.error(t('钱包地址格式不正确'))
                                    return
                                }
                                props.add && props.add(wallet)
                            }} className="btn btn-primary text-light">
                                {t('确认')}
                            </div>
                            <div onClick={() => {
                                setWallet('')
                                props.handleClose && props.handleClose()
                            }} style={{ marginLeft: '10px' }} className="btn btn-danger text-light">
                                {t('取消')}
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </Modal.Body>
    </Modal>
}

const AddNewPoolMonitorModalBtn = (props) => {
    const [show, setShow] = useState(false)
    const [wallet, setWallet] = useState('') // 钱包地址
    const [showAddModal, setShowAddModal] = useState(false) // 是否显示添加modal

    const currentUser = useSelector(state => state.currentUser)



    return <>
        <MonitorModal type={'wallet'} wallet={wallet} show={showAddModal} ok={() => {
            props.ok && props.ok()
        }} handleClose={() => {
            setShowAddModal(false)
        }} />
        <AddNewPoolMonitorModal
            show={show}
            add={(wallet) => {
                // 设置wallet, 并显示添加modal
                setWallet(wallet)
                setShow(false)
                setShowAddModal(true)
            }}
            handleClose={() => {
                setShow(false)
            }} />
        <div onClick={() => {
            if (!currentUser || !currentUser.id) {
                eventBus.emit('request_login')
                return
            }
            setShow(true)
        }} style={{ width: '44px', height: '44px', borderRadius: '50%', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '24px', fontWeight: '500', justifyContent: 'center', backgroundColor: '#999999', color: '#ffffff', position: 'fixed', right: '30px', bottom: '90px', zIndex: 5 }}>
            <div>+</div>
        </div>
    </>
}

const AddWalletMonitorBtn = (props) => {
    const [show, setShow] = useState(false)
    const [wallet, setWallet] = useState('') // 钱包地址
    const [showAddModal, setShowAddModal] = useState(false) // 是否显示添加modal

    const currentUser = useSelector(state => state.currentUser)



    return <>
        <MonitorModal type={'wallet'} wallet={wallet} show={showAddModal} ok={() => {
            props.ok && props.ok()
        }} handleClose={() => {
            setShowAddModal(false)
        }} />
        <AddWalletMonitorModal
            show={show}
            add={(wallet) => {
                // 设置wallet, 并显示添加modal
                setWallet(wallet)
                setShow(false)
                setShowAddModal(true)
            }}
            handleClose={() => {
                setShow(false)
            }} />
        <div onClick={() => {
            if (!currentUser || !currentUser.id) {
                eventBus.emit('request_login')
                return
            }
            setShow(true)
        }} style={{ width: '44px', height: '44px', borderRadius: '50%', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '24px', fontWeight: '500', justifyContent: 'center', backgroundColor: '#999999', color: '#ffffff', position: 'fixed', right: '30px', bottom: '90px', zIndex: 5 }}>
            <div>+</div>
        </div>
    </>
}

export default function MonitorIndex(props) {
    const { t } = useTranslation()
    const [notifications, setNotifications] = useState([]) // 当前已有的通知
    const [records, setRecords] = useState([]) // 当前已有的通知
    const [selectTaskId, setSelectTaskId] = useState(0) // 当前选中的任务id
    const [selectRecord, setSelectRecord] = useState(0) // 当前选中的任务记录的id, 用于显示任务记录详情
    const [showTaskRecordDetailModel, setShowTaskRecordDetailModel] = useState(false) // 是否显示任务记录详情
    const [showRecordModal, setShowRecordModal] = useState(false) // 是否显示任务记录详情Modal

    const [taskRecordLoading, setTaskRecordLoading] = useState(false) // 是否显示任务记录详情的加载icon

    const getNotifications = async () => {
        const { data } = await getAllTaskNotifications({})
        if (data.code === 200) {
            setNotifications(data.data || [])
            if (data.data?.length > 0) {
                setSelectTaskId(data.data[0].id)
            }
        }
    }

    const getTaskRecords = async (page) => {
        if (selectTaskId === 0) {
            return
        }
        setRecords([])
        setTaskRecordLoading(true)
        try {
            const { data } = await getTaskRecordList({ page, task_id: selectTaskId })
            if (data.code === 200) {
                setRecords(data.data)
            }
        } catch (e) {
            console.log('获取任务日志失败', e)
        }

        setTaskRecordLoading(false)
    }

    useEffect(() => {
        // 获取任务记录
        getTaskRecords(1)
    }, [selectTaskId])

    useEffect(() => {
        // 获取任务列表
        getNotifications()
    }, [])

    useEffect(() => {
        eventBus.addListener('login_success', getNotifications)
        return () => {
            eventBus.removeListener('login_success', getNotifications)
        }
    }, [])


    const renderRecordList = () => {
        return <div className="card" style={{ height: '100%' }}>
            {records.length === 0 && <div className="no-tasks-records">
                {taskRecordLoading ? <div>
                    <Spinner />
                </div> : <>
                    {t('暂无记录')}
                </>}

            </div>}
            {/* table-responsive */}
            {records.length > 0 && <div className="table-responsive " style={{ maxHeight: '100vh', position: 'relative', overflowY: 'scroll' }}>
                <table className="table table-striped" style={{ 'wordWrap': 'break-word', 'tableLayout': 'fixed', 'textAlign': 'center', 'wordBreak': 'break-all' }}>
                    <thead>
                        <tr className="mt-2">
                            <th className="text-center" style={{ paddingTop: '15px' }}>
                                {t('时间')}
                            </th>
                            <th className="text-center" style={{ paddingTop: '15px' }}>
                                {t('内容')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {records.length > 0 && records.map((item, ind) => {
                            return <tr key={item.id + '-task-records2-' + ind} className="task-modal-list-item-tr" onClick={() => {
                                setSelectRecord(item)
                                setShowTaskRecordDetailModel(true)
                            }}>
                                <td className="text-center">
                                    {item.created_at ? formatTimeString(item.created_at * 1000) : ''}
                                </td>
                                <td className="text-center">
                                    {formatTaskRecordContent(JSON.parse(item.info))}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            }
        </div>

    }

    return <>
        <Header2 />
        <Sidebar />
        <Modal centered contentClassName="search-modal" show={showRecordModal} onHide={() => {
            setShowRecordModal(false)
        }} >
            <Modal.Body>
                <div style={{ minHeight: '500px' }}>
                    {renderRecordList()}
                </div>
            </Modal.Body>
        </Modal>
        <TaskRecordDetailModel info={selectRecord.info} show={showTaskRecordDetailModel} handleClose={() => {
            setShowTaskRecordDetailModel(false)
        }} />
        <div className="content-body" id="monitor-index" >
            <div className="container-fluid">

                <div className="row " style={{ minHeight: '90vh' }}>
                    <div className="col-12 col-md-6 col-lg-6 col-sm-6 col-xs-12 " >

                        <div className="card" style={{ height: '100%' }}>
                            <Tab.Container defaultActiveKey="token-monitor" onSelect={(k) => {
                            }}>
                                <div className="card-header">
                                    <Nav variant="pills">
                                        <Nav.Link eventKey="token-monitor">
                                            {t('代币监控')}
                                        </Nav.Link>
                                        <Nav.Link eventKey="wallet-monitor">
                                            {t('钱包监控')}
                                        </Nav.Link>
                                        <Nav.Link eventKey="new-pool-monitor">
                                            {t('新池监控')}
                                        </Nav.Link>
                                    </Nav>
                                </div>
                                <div style={{ height: '100%' }}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="token-monitor">
                                            <TaskListTable type="token" selectedId={selectTaskId} hidenToken={false} tasks={notifications} refresh={() => {
                                                getNotifications()
                                            }} onClickTask={(item) => {
                                                // 获取记录
                                                setSelectTaskId(item.id)
                                                // 显示记录modal
                                                if (isMobile()) {
                                                    setShowRecordModal(true)
                                                }

                                            }} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="wallet-monitor">
                                            <TaskListTable loading={taskRecordLoading} type="wallet" selectedId={selectTaskId} hidenToken={false} tasks={notifications} refresh={() => {
                                                getNotifications()
                                            }} onClickTask={(item) => {
                                                // 获取记录
                                                setSelectTaskId(item.id)
                                                // 显示记录modal
                                                if (isMobile()) {
                                                    setShowRecordModal(true)
                                                }
                                            }} />
                                            <AddWalletMonitorBtn ok={() => {
                                                getNotifications()
                                            }} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="new-pool-monitor">
                                            <TaskListTable type="new-pool" selectedId={selectTaskId} hidenToken={false} tasks={notifications} refresh={() => {
                                                getNotifications()
                                            }} onClickTask={(item) => {
                                                // 获取记录
                                                setSelectTaskId(item.id)
                                                // 显示记录modal
                                                if (isMobile()) {
                                                    setShowRecordModal(true)
                                                }

                                            }} />

                                            <AddNewPoolMonitorModalBtn ok={() => {
                                            }} />
                                        </Tab.Pane>
                                    </Tab.Content>

                                </div>
                            </Tab.Container>
                        </div>

                    </div>
                    {/* 右侧是监控记录 */}
                    <div className="col-6 col-lg-6 col-xs-12 d-xs-none d-sm-block d-md-block d-lg-block ">
                        {renderRecordList()}
                    </div>
                </div>
            </div>
        </div>
    </>
}