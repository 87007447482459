import * as React from 'react';
import './index.css';
import { widget } from '../../../charting_library';
import DataFeed from '../../../assets/datafeed/datafeed'
import { converToNumberString, convertNumberToString, convertToMaxUnit, eventBus, isMobile } from '../../../utils/tools'

import i18n from '../../../utils/i18n';
import { TimeCountDown } from '../../MobileChartContainer';
function getLanguageFromURL() {
    // return i18n.language || 'en'
    const regex = new RegExp('[\\?&]lang=([^&#]*)');
    const results = regex.exec(window.location.search);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}



export class TVChartContainer extends React.PureComponent {
    static defaultProps = {
        symbol: 'TMD TOKEN',
        interval: 'D',
        // datafeedUrl: 'https://demo_feed.tradingview.com',
        datafeed: new DataFeed(),

        libraryPath: '/charting_library/',
        // chartsStorageUrl: 'https://saveload.tradingview.com',
        // chartsStorageApiVersion: '1.1',
        clientId: 'tradingview.com',
        userId: 'public_user_id',

        fullscreen: false,
        autosize: true,
        studiesOverrides: {},
        toolbar_bg: "transparent",
        loading_screen: {
            backgroundColor: "transparent",
        },


    };

    tvWidget = null;



    constructor(props) {
        super(props);
        console.log('初始化.....initttt')
        this.ref = React.createRef();
        this.state = {
            countdownInfo: {
                day: '00',
                hour: '00',
                minute: '00',
                second: '00'
            },
            openTime: 0,
            showOpenTime: false
        }
    }

    init(props) {
        if (!props.datafeed.pair) return
        if (!props.datafeed.chain_id) return

        let disabledFeatures = [
            "header_symbol_search",
            "indicators_templates",
            "legend_context_menu",
            "header_compare",
            "header_saveload",
            "edit_buttons_in_legend",
            "use_localstorage_for_settings",
        ]

        if (isMobile()) {
            disabledFeatures.push('left_toolbar')
            disabledFeatures.push('timeframes_toolbar')
        }

        const widgetOptions = {
            symbol: props.datafeed.symbol || 'TMD TOKEN',
            datafeed: new DataFeed({
                ...(props.datafeed || {})
            }),
            // debug: true,
            interval: '1',
            // timeframe: '1W', // 设置为 1 小时
            container: this.ref.current,
            library_path: this.props.libraryPath,

            // locale: getLanguageFromURL(),
            // charts_storage_url: this.props.chartsStorageUrl,
            // charts_storage_api_version: this.props.chartsStorageApiVersion,
            // client_id: this.props.clientId,
            // user_id: this.props.userId,
            fullscreen: this.props.fullscreen,
            autosize: this.props.autosize,
            favorites: {
                intervals: [1, 3, 5, 15, 30],
                chartTypes: ["Candles"]
            },
            theme: "dark",
            time_frames: [],
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || "America/New_York",
            disabled_features: disabledFeatures,
            // enabled_features: ["move_logo_to_main_pane"],
            widgetbar: {
                details: !1,
                watchlist: !1,
                watchlist_settings: {
                    default_symbols: []
                }
            },
            shiftVisibleRangeOnNewBar: true,
            overrides: {
                volumePaneSize: "small",
                "paneProperties.topMargin": "10",
                "paneProperties.vertGridProperties.style": 2,
                "paneProperties.legendProperties.showLegend": !1,
                "paneProperties.legendProperties.showStudyArguments": !0,
                "paneProperties.legendProperties.showStudyTitles": !0,
                "paneProperties.legendProperties.showStudyValues": !0,
                "paneProperties.legendProperties.showSeriesTitle": !0,
                "paneProperties.legendProperties.showSeriesOHLC": !0,
                "mainSeriesProperties.candleStyle.drawWick": !0,
                "mainSeriesProperties.candleStyle.drawBorder": !0,
                "mainSeriesProperties.candleStyle.barColorsOnPrevClose": !1,
                "mainSeriesProperties.hollowCandleStyle.drawWick": !0,
                "mainSeriesProperties.hollowCandleStyle.drawBorder": !0,
                "mainSeriesProperties.haStyle.drawWick": !0,
                "mainSeriesProperties.haStyle.drawBorder": !0,
                "mainSeriesProperties.haStyle.barColorsOnPrevClose": !1,
                "mainSeriesProperties.barStyle.barColorsOnPrevClose": !1,
                "mainSeriesProperties.barStyle.dontDrawOpen": !1,
                "mainSeriesProperties.lineStyle.linewidth": 2,
                "mainSeriesProperties.lineStyle.priceSource": "close",
                "mainSeriesProperties.areaStyle.linewidth": 2,
                "mainSeriesProperties.areaStyle.priceSource": "close",

                "mainSeriesProperties.candleStyle.upColor": "#27A69A",//买入颜色
                "mainSeriesProperties.candleStyle.borderUpColor": "#27A69A",//买入边框颜色
                "mainSeriesProperties.candleStyle.wickUpColor": "#27A69A",//买入线条颜色
                "mainSeriesProperties.candleStyle.downColor": "#EF534F",//卖出颜色
                "mainSeriesProperties.candleStyle.borderDownColor": "#EF534F",//卖出边框颜色
                "mainSeriesProperties.candleStyle.wickDownColor": "#EF534F",//卖出线条颜色
            },
            // time_scale: {
            //     min_bar_spacing: 10,
            // },
            custom_formatters: {
                priceFormatterFactory: (symbolInfo, minTick) => {
                    // return null
                    return {
                        format: (price, signPositive) => {
                            return converToNumberString(convertNumberToString(price))
                        }
                    }
                },
                studyFormatterFactory: (format, symbolInfo) => {
                    if (format.type === 'volume') {
                        return {
                            format: (value) => {
                                return convertToMaxUnit(value)
                            }
                        };
                    }
                }

            }

        };

        this.tvWidget = new widget(widgetOptions);

        this.pair = props.datafeed.pair
        this.currency = props.datafeed.currency
        this.tvWidget.onChartReady(() => {

            this.tvWidget.headerReady().then(() => {

                const button = this.tvWidget.createButton();
                button.setAttribute('title', 'Click to show swap currency and token');
                button.classList.add('apply-common-tooltip');
                button.addEventListener('click', () => {
                    props.swapCurrency && props.swapCurrency()
                }
                    // tvWidget.showNoticeDialog({
                    // props.swapCurrency && props.swapCurrency()
                    // title: '交换底池',
                    // body: '将当前代币/底池之间的交易对互换',
                    // callback: () => {
                    //     console.log('Noticed!');
                    // },
                    // })
                );

                button.innerHTML = i18n.t('交换底池');
            });
        });
    }
    componentDidMount() {
        try {
            this.init(this.props)

        } catch (e) {
            console.log('生成K线图失败', this.props, e)
        }
        // 接收事件, 先清除marks, 再添加marks
        eventBus.addListener('marksUpdate', this.handleMarksUpdateEvent.bind(this))

    }
 
    handleMarksUpdateEvent(d) {
        if (this.tvWidget) {
            this.tvWidget.activeChart().clearMarks()
        }
        setTimeout(() => {
            eventBus.emit('marksUpdate2', d)
        }, 500);


    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.datafeed.pair !== this.pair ||
            nextProps.datafeed.currency !== this.currency
        ) {
            try {
                this.init(nextProps)
            } catch (e) {
                console.log('生成K线图失败', nextProps, e)
            }

        }
        // if(nextProps.openTime)
        this.handleOpenTime(nextProps.openTime)
    }

    handleOpenTime(openTime) {
        // 如果openTime存在并大于当前时间, 则展示倒计时
        const now = Date.parse(new Date()) / 1000
        if (openTime && openTime > now) {
            this.setState({
                showOpenTime: true,
                openTime: openTime
            }, () => {
                // this.startTimer()

            })
        } else {
            this.setState({
                showOpenTime: false,
                openTime: openTime
            }, () => {
                // this.stopTimer()
            })
        }
    }



    stopTimer() {
        if (this.countdownTimer) {
            clearInterval(this.countdownTimer)
            this.countdownTimer = null
        }
    }

    startTimer() {
        if (this.countdownTimer) {
            // this.stopTimer()
            return
        }
        this.countdownTimer = setInterval(() => {
            const now = Date.parse(new Date()) / 1000
            const { openTime } = this.state
            const diff = openTime - now
            if (diff <= 0) {
                this.stopTimer()
                this.setState({
                    showOpenTime: false
                })
                return
            }
            let day = Math.floor(diff / (24 * 60 * 60))
            let hour = Math.floor((diff - day * 24 * 60 * 60) / (60 * 60))
            let minute = Math.floor((diff - day * 24 * 60 * 60 - hour * 60 * 60) / 60)
            let second = Math.floor(diff - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60)
            if (day < 10) {
                day = '0' + day
            }
            if (hour < 10) {
                hour = '0' + hour
            }
            if (minute < 10) {
                minute = '0' + minute
            }
            if (second < 10) {
                second = '0' + second
            }
            this.setState({
                countdownInfo: {
                    day: day + '',
                    hour: hour + '',
                    minute: minute + '',
                    second: second + ''
                }

            })

        }, 1000);
    }


    componentWillUnmount() {
        if (this.tvWidget !== null) {
            this.tvWidget.remove();
            this.tvWidget = null;
        }
        this.stopTimer()
        eventBus.removeListener('marksUpdate', this.handleMarksUpdateEvent.bind(this))
    }

    render() {
        return (
            <div className='tx-mask-box'>
                <div
                    ref={this.ref}
                    style={{ height: '100%' }}
                    className={'TVChartContainer'}
                />
                {this.state.showOpenTime && <div className='tv-mask'>
                    <div style={{ marginBottom: '20px', fontSize: '15px' }}>
                        {i18n.t('开盘倒计时')}
                    </div>
                    <TimeCountDown time={this.state.openTime} countdownEnd={() => {
                        this.setState({
                            showOpenTime: false
                        })
                    }} />
                </div>}
            </div>

        );
    }
}
