import React, { useEffect, useRef, useState } from "react";
import Header2 from "../../layout/header2";
import Sidebar from "../../layout/sidebar";
import { Col, Form, Modal, Nav, Row, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { AddChainApi, AddSwapApi, AddSwapPairsApi, UpdateSwapRouter, UpdateTokenCheckApi, clearTokenOwner, getAllChains, getAllSwapList, updateTokenOwner } from "../../../api/admin";
import { toast } from "react-toastify";
import { getShortAddress, isAddress } from "../../../utils/tools";
import { ethers } from "ethers";
import { NETWORK_LIST } from "../../../utils/networks";
import { Contract, Provider } from "ethers-multicall";
import { FactoryAbi, PairAbi } from "../../../utils/abi";
import { CheckToken } from "../../../api/check";

const adminList = [
    '0x48aF96a94fD8e5ad45A48803B281Df13DeAa07Da'.toLowerCase()
]

export const SyncPairsModal = (props) => {
    const [syncInfo, setSyncInfo] = useState({ chain: {}, swap: {} })
    const [currentRpc, setCurrentRpc] = useState('')
    const [pairsLength, setPairsLength] = useState(0) // 交易对总量
    const [start, setStart] = useState(0) // 开始索引
    const [end, setEnd] = useState(0) // 结束索引
    const [log, setLog] = useState([]) // 日志
    const [starting, setStarting] = useState(false) // 是否正在同步
    const [multicallContract, setMulticallContract] = useState('') // multicall合约
    const textareaRef = useRef(null);

    const currentLog = useRef([])

    useEffect(() => {
        setSyncInfo(props.info)
        setCurrentRpc(props.info?.chain?.rpc || '')
        const chain = NETWORK_LIST.find(item => parseInt(item.chainId) === parseInt(props.info?.chain?.chain_id))
        if (chain) {
            setMulticallContract(chain.multicall || '')
        } else {
            setMulticallContract('')
        }

    }, [props.info])

    const getProvider = () => {
        // 获取provider
        if (!currentRpc) {
            toast.error('请输入rpc')
            return
        }
        const provider = new ethers.providers.JsonRpcProvider(currentRpc)
        return provider
    }

    useEffect(() => {
        if (currentRpc && syncInfo?.swap?.exchange_contract) {
            try {
                getAllPairsLength().catch(e => {
                    console.log(e)
                })
            } catch (e) {
                console.log(e)
            }

        }
    }, [syncInfo, currentRpc])

    const getAllPairsLength = async () => {
        const provider = getProvider()
        const factory = syncInfo?.swap?.exchange_contract
        if (!factory) {
            toast.error('未找到Swap的工厂Factory合约')
            return
        }
        const contract = new ethers.Contract(factory, [
            'function allPairsLength() view returns (uint256)',
        ], provider)
        try {
            const length = await contract.allPairsLength()
            setPairsLength(length.toString())
            setEnd(length)
        } catch (e) {
            console.log(e)
        }


    }

    const addLog = (str) => {
        // setLog(log + '\n' + str)
        currentLog.current.push(str)
        setLog([...currentLog.current])
        if (textareaRef.current) {
            textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
        }
    }

    return <Modal centered contentClassName="search-modal" show={props.show} onHide={() => {
        props.handleClose && props.handleClose()
    }} >
        <Modal.Body>
            <div>
                <div className="login-header">同步交易对</div>
                <div>
                    <div className="mt-2">公链名称: {syncInfo?.chain?.name}</div>
                    <div className="mt-2">公链ChainID: {syncInfo?.chain?.chain_id} </div>
                    <div className="row mt-2">
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} className="col-2">公链RPC</div>
                        <div className="col-10"><input value={currentRpc} onChange={e => {
                            setCurrentRpc(e.target.value)
                        }} type="text" className="form-control" /></div>
                    </div>
                    <div className="mt-2">Swap名称: {syncInfo?.swap?.exchange_name}</div>
                    <div className="mt-2">Swap Factory: {syncInfo?.swap?.exchange_contract}</div>
                    <div className="mt-2">Pair总量: {pairsLength}</div>
                    <div className="row mt-2">
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} className="col-2">Multicall合约</div>
                        <div className="col-10"><input value={multicallContract} onChange={e => {
                            setMulticallContract(e.target.value)
                        }} type="text" className="form-control" /></div>
                    </div>
                    <div className="row mt-2">
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} className="col-2">开始索引</div>
                        <div className="col-10"><input value={start} onChange={e => {
                            setStart(e.target.value)
                        }} type="text" className="form-control" /></div>
                    </div>
                    <div className="row mt-2">
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} className="col-2">结束索引</div>
                        <div className="col-10"><input value={end} onChange={e => {
                            setEnd(e.target.value)
                        }} type="text" className="form-control" /></div>
                    </div>
                    <div className="mt-2">
                        <Form.Control ref={textareaRef} value={log.join("\n")} style={{ backgroundColor: '#000000', fontSize: '12px' }} readOnly as="textarea" rows={3} placeholder="日志"></Form.Control>
                    </div>
                </div>
                <div className="mt-2">
                    <div onClick={async () => {
                        if (starting) return
                        setStarting(true)
                        // 将start到end进行分组, 每100个一组
                        const arr = []
                        for (let i = start; i < end; i++) {
                            arr.push(i)
                        }
                        const groupArr = []
                        for (let i = 0; i < arr.length; i += 100) {
                            groupArr.push(arr.slice(i, i + 100))
                        }
                        if (!multicallContract) {
                            toast.error('请输入multicall合约地址')
                            return
                        }
                        // 开始同步
                        const provider = getProvider()
                        const ethProvider = new Provider(provider)
                        ethProvider._multicallAddress = multicallContract
                        const factory = syncInfo?.swap?.exchange_contract
                        const f = new Contract(factory, FactoryAbi)
                        for (let i = 0; i < groupArr.length; i++) {
                            if (groupArr[i].length === 0) {
                                addLog(`第${i + 1}组为空, 结束同步`)
                                break
                            }
                            // if (!starting) return // 如果已经停止同步, 则停止
                            addLog(`正在同步第${i + 1}组, 索引范围: ${groupArr[i][0]} - ${groupArr[i][groupArr[i].length - 1]}`)
                            // 获取pair token0 token1
                            // 先获取pair
                            try {
                                const calls = []
                                for (let j = 0; j < groupArr[i].length; j++) {
                                    calls.push(f.allPairs(groupArr[i][j]))
                                }
                                const res = await ethProvider.all(calls)

                                // 获取token0 和token1
                                const calls1 = []
                                for (let j = 0; j < res.length; j++) {
                                    const p = new Contract(res[j], PairAbi)
                                    calls1.push(p.token0())
                                    calls1.push(p.token1())
                                }
                                const res1 = await ethProvider.all(calls1)
                                let list = []
                                for (let j = 0; j < res.length; j++) {
                                    list.push({
                                        chain_id: parseInt(syncInfo?.chain?.chain_id),
                                        pair: res[j],
                                        token0: res1[j * 2],
                                        token1: res1[j * 2 + 1]
                                    })
                                }
                                addLog('Pair获取完成, 同步至服务器...')
                                const d = {
                                    chain_id: syncInfo?.chain?.chain_id,
                                    factory: syncInfo?.swap?.exchange_contract,
                                    list,
                                }
                                // 发送到服务器
                                const { data } = await AddSwapPairsApi(d)
                                if (data.code === 200) {
                                    const { add_count, exist_count } = data.data
                                    addLog(`批次同步完成, 索引范围: ${groupArr[i][0]} - ${groupArr[i][groupArr[i].length - 1]}\n 已存在${exist_count}条,新增${add_count}条记录`)
                                } else {
                                    addLog(`批次同步失败, 失败原因: ${data.message || ''}, 索引范围: ${groupArr[i][0]} - ${groupArr[i][groupArr[i].length - 1]}`)
                                }
                            } catch (e) {
                                addLog(`批次同步失败, 失败原因: ${JSON.stringify(e)}, 索引范围: ${groupArr[i][0]} - ${groupArr[i][groupArr[i].length - 1]}`)
                                continue
                            }

                        }
                        setStarting(false)
                    }} style={{ marginRight: '5px' }} className="btn btn-outline-primary btn-sm">
                        {starting ? '正在同步...' : '开始同步'}</div>
                    <div onClick={() => {
                        getAllPairsLength()
                    }} style={{ marginRight: '5px' }} className="btn btn-outline-warning btn-sm">获取总量</div>
                    <div onClick={() => {
                        setStarting(false)
                        props.onHide && props.onHide()
                    }} className="btn btn-outline-danger btn-sm">关闭</div>

                </div>
            </div>
        </Modal.Body>
    </Modal>
}

export const SetRouterModal = (props) => {

    const [params, setParams] = useState({
        router: '',
        version: 'v2',
        weth: ''
    })

    return <Modal centered contentClassName="search-modal" show={props.show} onHide={() => {
        props.handleClose && props.handleClose()
    }} >
        <Modal.Body>
            <div>
                <div className="login-header">设置路由</div>
                <div>
                    {/* 路由 版本 weth */}
                    <Form>
                        <Form.Group>
                            <Form.Label>路由</Form.Label>
                            <Form.Control onChange={e => {
                                setParams({
                                    ...params,
                                    router: e.target.value
                                })
                            }} type="text" placeholder="路由" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>版本</Form.Label>
                            <select value={params['version']} className="form-control" onChange={e => {
                                const r = e.target.value
                                setParams({
                                    ...params,
                                    version: r
                                })
                            }}>
                                <option value="v2">v2</option>
                                <option value="v3">v3</option>
                            </select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>WETH</Form.Label>
                            <Form.Control onChange={e => {
                                setParams({
                                    ...params,
                                    weth: e.target.value
                                })
                            }} type="text" placeholder="WETH" />
                        </Form.Group>
                        <Form.Group className="mt-2">
                            <div onClick={() => {
                                props.onSubmit && props.onSubmit(params)
                            }} className="btn btn-primary">确认</div>
                            <div className="btn btn-danger" style={{ marginLeft: '5px' }} onClick={() => {
                                props.onHide && props.onHide()
                            }}>取消</div>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        </Modal.Body>
    </Modal>
}

const columns = {
    "score": {
        "name": "score",
        "type": "number",
        "desc": "评分"
    },
    "circulating_supply": {
        "name": "circulating_supply",
        "type": "string",
        "desc": "流通量"
    },
    "total_supply": {
        "name": "total_supply",
        "type": "string",
        "desc": "总量"
    },
    "burn_amount": {
        "name": "burn_amount",
        "type": "string",
        "desc": "销毁数量"
    },
    "buy_gas": {
        "name": "buy_gas",
        "type": "string",
        "desc": "买入gas"
    },
    "buy_tax": {
        "name": "buy_tax",
        "type": "string",
        "desc": "买入税率"
    },
    "sell_gas": {
        "name": "sell_gas",
        "type": "string",
        "desc": "卖出gas"
    },
    "sell_tax": {
        "name": "sell_tax",
        "type": "string",
        "desc": "卖出税率"
    },
    "buy_gas_in_usd": {
        "name": "buy_gas_in_usd",
        "type": "string",
        "desc": "买入gas($)"
    },
    "sell_gas_in_usd": {
        "name": "sell_gas_in_usd",
        "type": "string",
        "desc": "卖出gas($)"
    },
    "holders_count": {
        "name": "holders_count",
        "type": "string",
        "desc": "持有人数"
    },
    "lp_holders_count": {
        "name": "lp_holders_count",
        "type": "string",
        "desc": "LP持有人数"
    },
    "lp_total_supply": {
        "name": "lp_total_supply",
        "type": "string",
        "desc": "LP总量"
    },
    "is_honeypot_result.holderAnalysis.averageGas": {
        "name": "is_honeypot_result.holderAnalysis.averageGas",
        "type": "number",
        "desc": "平均gas"
    },
    "is_honeypot_result.holderAnalysis.averageTax": {
        "name": "is_honeypot_result.holderAnalysis.averageTax",
        "type": "number",
        "desc": "平均税率"
    },
    "is_honeypot_result.holderAnalysis.failed": {
        "name": "is_honeypot_result.holderAnalysis.failed",
        "type": "string",
        "desc": "交易是否失败"
    },
    "is_honeypot_result.holderAnalysis.highTaxWallets": {
        "name": "is_honeypot_result.holderAnalysis.highTaxWallets",
        "type": "string",
        "desc": "高税地址数"
    },
    "is_honeypot_result.holderAnalysis.highestTax": {
        "name": "is_honeypot_result.holderAnalysis.highestTax",
        "type": "number",
        "desc": "最高税率"
    },
    "is_honeypot_result.simulationResult.transferTax": {
        "name": "is_honeypot_result.simulationResult.transferTax",
        "type": "number",
        "desc": "转账税率"
    },
    "owner_address": {
        "name": "owner_address",
        "type": "string",
        "desc": "owner地址"
    },
    "creator_address": {
        "name": "creator_address",
        "type": "string",
        "desc": "创建者地址"
    },
    "tip": {
        "name": "tip",
        "type": "string",
        "desc": "备注"
    },
    "tx_check.cannot_buy": {
        "name": "tx_check.cannot_buy",
        "type": "boolean",
        "desc": "不能买"
    },
    "tx_check.cannot_sell_all": {
        "name": "tx_check.cannot_sell_all",
        "type": "boolean",
        "desc": "不能全部卖出"
    },
    "tx_check.is_anti_whale": {
        "name": "tx_check.is_anti_whale",
        "type": "boolean",
        "desc": "是否有限购"
    },
    "tx_check.is_honeypot": {
        "name": "tx_check.is_honeypot",
        "type": "boolean",
        "desc": "是否是蜜罐"
    },
    "tx_check.trading_cooldown": {
        "name": "tx_check.trading_cooldown",
        "type": "boolean",
        "desc": "存在交易冷却机制"
    },
    "tx_check.low_liquidity": {
        "name": "tx_check.low_liquidity",
        "type": "boolean",
        "desc": "流动性过低"
    },
    "tx_check.creator_holds_too_many_tokens": {
        "name": "tx_check.creator_holds_too_many_tokens",
        "type": "boolean",
        "desc": "创建者持有过多代币"
    },
    "tx_check.owner_holds_too_many_tokens": {
        "name": "tx_check.owner_holds_too_many_tokens",
        "type": "boolean",
        "desc": "所有者持有过多代币"
    },
    "tx_check.unlock_liquidity": {
        "name": "tx_check.unlock_liquidity",
        "type": "boolean",
        "desc": "流动性未锁定"
    },
    "tx_check.simulate_trade_failed": {
        "name": "tx_check.simulate_trade_failed",
        "type": "boolean",
        "desc": "模拟交易失败"
    },
    "tx_check.black_record_exists": {
        "name": "tx_check.black_record_exists",
        "type": "boolean",
        "desc": "存在拉黑记录"
    },
    "tx_check.high_tax_wallets": {
        "name": "tx_check.high_tax_wallets",
        "type": "boolean",
        "desc": "存在高税钱包"
    },
    "contract_check.is_proxy": {
        "name": "contract_check.is_proxy",
        "type": "boolean",
        "desc": "存在代理合约"
    },
    "contract_check.anti_whale_modifiable": {
        "name": "contract_check.anti_whale_modifiable",
        "type": "boolean",
        "desc": "可修改限购/限卖额度"
    },
    "contract_check.can_take_back_ownership": {
        "name": "contract_check.can_take_back_ownership",
        "type": "boolean",
        "desc": "可取回所有权"
    },
    "contract_check.external_call": {
        "name": "contract_check.external_call",
        "type": "boolean",
        "desc": "存在外部调用"
    },
    "contract_check.hidden_owner": {
        "name": "contract_check.hidden_owner",
        "type": "boolean",
        "desc": "存在隐藏Owner"
    },
    "contract_check.inBlackList": {
        "name": "contract_check.inBlackList",
        "type": "boolean",
        "desc": "人工审计为黑名单高危合约"
    },
    "contract_check.inWhiteList": {
        "name": "contract_check.inWhiteList",
        "type": "boolean",
        "desc": "人工审计为白名单安全合约"
    },
    "contract_check.is_blacklisted": {
        "name": "contract_check.is_blacklisted",
        "type": "boolean",
        "desc": "有黑名单功能"
    },
    "contract_check.is_mintable": {
        "name": "contract_check.is_mintable",
        "type": "boolean",
        "desc": "可增发"
    },
    "contract_check.owner_change_balance": {
        "name": "contract_check.owner_change_balance",
        "type": "boolean",
        "desc": "所有者可篡改余额"
    },
    "contract_check.personal_slippage_modifiable": {
        "name": "contract_check.personal_slippage_modifiable",
        "type": "boolean",
        "desc": "可针对特定地址修改税率"
    },
    "contract_check.serial_scammer": {
        "name": "contract_check.serial_scammer",
        "type": "boolean",
        "desc": "职业骗子"
    },
    "contract_check.slippage_modifiable": {
        "name": "contract_check.slippage_modifiable",
        "type": "boolean",
        "desc": "可修改税率"
    },
    "contract_check.transfer_pausable": {
        "name": "contract_check.transfer_pausable",
        "type": "boolean",
        "desc": "可暂停交易"
    },
    "contract_check.verified": {
        "name": "contract_check.verified",
        "type": "boolean",
        "desc": "已开源"
    }
}

const excludesColumns = [
    'chain_id',
    'name',
    'symbol',
    'decimals',
    'address',
    'is_honeypot_result.chain.currency',
    'is_honeypot_result.chain.id',
    'is_honeypot_result.chain.name',
    'is_honeypot_result.chain.shortName',
    'is_honeypot_result.holderAnalysis.siphoned',
    'is_honeypot_result.holderAnalysis.successful',
    'is_honeypot_result.pair.chainId',
    'is_honeypot_result.pair.createdAtTimestamp',
    'is_honeypot_result.pair.creationTxHash',
    'is_honeypot_result.pair.liquidity',
    'is_honeypot_result.pair.reserves0',
    'is_honeypot_result.pair.reserves1',
    'is_honeypot_result.pair.router',
    'is_honeypot_result.pairAddress',
    'is_honeypot_result.router',
    'is_honeypot_result.token.address',
    'is_honeypot_result.token.decimals',
    'is_honeypot_result.token.name',
    'is_honeypot_result.token.symbol',
    'is_honeypot_result.withToken.address',
    'is_honeypot_result.withToken.decimals',
    'is_honeypot_result.withToken.name',
    'is_honeypot_result.withToken.symbol',
    'is_honeypot_result.holderAnalysis.holders',
    'owner_percent',
    'owner_balance',
    'creator_percent',
    'creator_balance',
    'is_honeypot_result.simulationResult.sellTax',
    'is_honeypot_result.simulationResult.buyTax',
    'is_honeypot_result.simulationResult.sellGas',
    'is_honeypot_result.simulationResult.buyGas'
]



export const TokenCheckSetting = (props) => {
    const [chainList, setChainList] = useState([])
    const [filter, setFilter] = useState({
        chain_id: '1',
        contract: ''
    })
    const [checkBoxIndex, setCheckBoxIndex] = useState({}) // 索引 index=>true/false
    const [settingList, setSettingList] = useState([])
    useEffect(() => {
        getAllChains().then(({ data }) => {
            if (data.code === 200) {
                setChainList(data.data)
            }
        })
    }, [])
    return <div style={{ padding: '10px' }}>
        <div>
            <Form>
                <Form.Group as={Row} className="mt-2">
                    <Form.Label column md={2}>选择公链:</Form.Label>
                    <Col md={10}>
                        <select value={filter['chain_id']} onChange={(e) => {
                            setFilter({
                                ...filter,
                                chain_id: e.target.value
                            })
                        }} className="form-control form-select form-select-sm text-light">
                            {chainList && chainList.map(item => {
                                return <option key={item.name + '_tc_' + item.id} value={item.chain_id}>{item.name}</option>
                            })}
                        </select>
                    </Col>


                </Form.Group>
                <Form.Group as={Row} className="mt-2">
                    <Form.Label column md={2}>输入合约:</Form.Label>
                    <Col md={10}>
                        <Form.Control value={filter['contract']} onChange={(e) => {
                            setFilter({
                                ...filter,
                                contract: e.target.value
                            })
                        }
                        } type="text" placeholder="输入合约地址" />
                    </Col>


                </Form.Group>
                <Form.Group className="mt-2">
                    <div onClick={async () => {
                        const { data } = await CheckToken({
                            chain_id: parseInt(filter.chain_id),
                            contract: filter.contract,
                        })
                        console.log(data, data.code)
                        if (data.code === 200) {
                            // 拆成列表
                            const list = []
                            console.log(111)
                            for (let n in data.data) {

                                if (Array.isArray(data.data[n])) {
                                    if (n === 'contract_check' || n === 'tx_check') {
                                        for (let i = 0; i < data.data[n].length; i++) {
                                            list.push({
                                                name: n + '.' + data.data[n][i].name,
                                                value: data.data[n][i].result,
                                                type: 'bool'
                                            })
                                        }
                                    }
                                    continue

                                }
                                if (n === 'fat_token') {
                                    continue
                                }
                                if (typeof data.data[n] === 'object') {
                                    for (let m in data.data[n]) {
                                        if (typeof data.data[n][m] === 'number' || typeof data.data[n][m] === 'string') {
                                            list.push({
                                                name: n + '.' + m,
                                                value: data.data[n][m]
                                            })
                                        }
                                        if (typeof data.data[n][m] === 'object') {
                                            for (let k in data.data[n][m]) {
                                                if (typeof data.data[n][m][k] === 'number' || typeof data.data[n][m][k] === 'string') {
                                                    list.push({
                                                        name: n + '.' + m + '.' + k,
                                                        value: data.data[n][m][k]
                                                    })
                                                }
                                            }
                                        }

                                    }
                                } else {
                                    if (typeof data.data[n] === 'number' || typeof data.data[n] === 'string') {
                                        list.push({
                                            name: n,
                                            value: data.data[n]
                                        })
                                    }
                                }

                                let mList = []
                                for (let i = 0; i < list.length; i++) {
                                    if (!excludesColumns.includes(list[i].name)) {
                                        mList.push(list[i])
                                    }
                                }
                                setSettingList(mList)

                                let m = {}
                                for (let i = 0; i < mList.length; i++) {
                                    m[mList[i].name] = {
                                        name: mList[i].name,
                                        type: typeof mList[i].value,
                                        desc: columns[mList[i].name] ? columns[mList[i].name].desc : ''
                                    }
                                }
                                console.log(JSON.stringify(m))
                            }
                        } else {
                            toast.error('查询失败')

                        }
                    }} className="btn btn-primary">查询</div>
                    <div onClick={async () => {

                    }} style={{ marginLeft: '10px' }} className="btn btn-outline-primary">清空缓存并查询</div>
                </Form.Group>
            </Form>

        </div>
        {settingList && settingList.length > 0 && <>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>
                            </th>
                            <th>备注</th>
                            <th>字段名</th>
                            <th>值</th>
                        </tr>
                    </thead>
                    <tbody>
                        {settingList && settingList.map((item, ind) => {
                            return <tr key={item.name + '_' + ind}>
                                <td style={{ width: '20px' }}>
                                    <input type="checkbox" value={checkBoxIndex[ind]} onChange={e => {
                                        const x = { ...checkBoxIndex }
                                        x[ind] = e.target.checked
                                        setCheckBoxIndex(x)
                                    }} />
                                </td>
                                <td style={{ width: '150px' }}>
                                    {columns[item.name] && columns[item.name].desc}
                                </td>
                                <td style={{ minWidth: '100px' }}>
                                    {item.name}
                                </td>
                                <td style={{ minWidth: '200px' }}>
                                    {item.type === 'bool' ? <>
                                        <select value={item.value} onChange={e => {
                                            const x = [...settingList]
                                            x[ind].value = e.target.value
                                            setSettingList(x)
                                        }} className="form-control form-select form-select-sm text-light">
                                            <option value="true">是</option>
                                            <option value="false">否</option>
                                        </select>
                                    </> : <>
                                        <Form.Control value={item.value} onChange={e => {
                                            const x = [...settingList]
                                            x[ind].value = e.target.value
                                            setSettingList(x)
                                        }} />
                                    </>}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            <div>
                <div onClick={async () => {
                    // 保存信息
                    const x = {}
                    let count = 0
                    for (let i = 0; i < settingList.length; i++) {
                        if (checkBoxIndex[i]) {
                            // 如果是number, 则parseFloat一下, 如果是string, 则直接保存
                            if (columns[settingList[i].name].type === 'number') {
                                count += 1
                                // x[settingList[i].name] = parseFloat(settingList[i].value)
                                x[settingList[i].name] = settingList[i].value + ''
                            } else {
                                count += 1
                                x[settingList[i].name] = settingList[i].value + ''
                            }
                        }
                    }
                    if (count === 0) {
                        toast.error('请勾选要保存的字段')
                        return
                    }
                    console.log(x)
                    // 请求接口
                    const { data } = await UpdateTokenCheckApi({
                        chain_id: parseInt(filter.chain_id),
                        address: filter.contract.toLowerCase(),
                        special_map: x
                    })
                    if (data.code === 200) {
                        toast.success('保存成功')
                    }

                }} className="btn btn-primary">保存复查结果</div>
            </div>
        </>}
    </div>
}


export const TokenSetting = (props) => {
    const [chainList, setChainList] = useState([])
    const [tokenForm, setTokenForm] = useState({
        'token': '',
        'chain_id': '1',
        'owner': ''
    })
    useEffect(() => {
        // 获取全部公链
        // 获取全部swap
        getAllChains().then(({ data }) => {
            if (data.code === 200) {
                setChainList(data.data)
            }
        })

    }, [])
    // 代币合约
    // 公链
    // 设置owner
    const setTokenFormInfo = (k, e) => {
        const x = { ...tokenForm }
        x[k] = e.target.value
        setTokenForm(x)

    }
    return <div>
        <Form style={{ padding: '10px' }}>
            <h4 className="mt-2">绑定项目方管理员钱包</h4>

            <Form.Group>
                <Form.Label>代币合约:</Form.Label>
                <Form.Control value={tokenForm['token']} onChange={setTokenFormInfo.bind(this, 'token')} type="text" placeholder="输入代币合约" />
            </Form.Group>
            <Form.Group>
                <Form.Label>选择公链:</Form.Label>
                <select value={tokenForm['chain_id']} onChange={setTokenFormInfo.bind(this, 'chain_id')} className="form-control form-select form-select-sm text-light">
                    {chainList && chainList.map(item => {
                        return <option key={item.name + '_' + item.id} value={item.chain_id}>{item.name}</option>
                    })}
                </select>
            </Form.Group>
            <Form.Group>
                <Form.Label>设置管理员钱包地址:</Form.Label>
                <Form.Control value={tokenForm['owner']} onChange={setTokenFormInfo.bind(this, 'owner')} type="text" placeholder="输入管理员钱包地址" />
            </Form.Group>
            <Form.Group className="mt-2">
                <div onClick={async () => {
                    if (!isAddress(tokenForm.token) || !isAddress(tokenForm.owner)) {
                        toast.error('请填写完整信息')
                        return
                    }

                    if (window.confirm('确认绑定管理员?')) {
                        const { data } = await updateTokenOwner({
                            chain_id: parseInt(tokenForm.chain_id),
                            token: tokenForm.token.toLowerCase(),
                            owner: tokenForm.owner.toLowerCase()
                        })
                        if (data.code === 200) {
                            toast.success('绑定成功')
                        } else {
                            toast.error(data.message || '绑定失败')
                        }
                    }
                }} className="btn btn-primary">确认</div>
                <div onClick={async () => {
                    if (!isAddress(tokenForm.token)) {
                        toast.error('请填写token合约')
                        return
                    }
                    if (window.confirm('确认解绑管理员?')) {
                        const { data } = await clearTokenOwner({
                            chain_id: parseInt(tokenForm.chain_id),
                            token: tokenForm.token.toLowerCase(),
                        })
                        if (data.code === 200) {
                            toast.success('解绑成功')
                        } else {
                            toast.error(data.message || '解绑失败')
                        }
                    }
                }} style={{ marginLeft: '5px' }} className="btn btn-danger">清空</div>
            </Form.Group>
        </Form>

    </div>
}

export const AddChain = (props) => {
    const [showSyncPairsModal, setShowSyncPairsModal] = useState(false) // 展示同步交易对的modal
    const [syncPairInfo, setSyncPairInfo] = useState({})
    const [chainList, setChainList] = useState([])
    const [swapList, setSwapList] = useState([])
    const [showSetRouterModal, setShowSetRouterModal] = useState(false) // 展示设置路由的modal
    const [tmpRouterInfo, setTmpRouterInfo] = useState({})
    const [swapForm, setSwapForm] = useState({
        chain_id: '1',
        discord: '',
        github: '',
        logo: '',
        factory: '',
        router: '',
        telegram: '',
        twitter: '',
        website: '',
        name: '',
        description: '',
        description_cn: '',
        version: '',
        weth: ''
    })
    const [swapFilter, setSwapFilter] = useState({
        chain_id: '',
        search_name: ''
    })
    const [filteredSwapList, setFilteredSwapList] = useState([])
    const [chainForm, setChainForm] = useState({
        name: '',
        short_name: '',
        logo: '',
        chain_id: '',
        rpc: '',
        symbol: '',
        decimals: '18'
    })
    const setChainFormInfo = (key, e) => {
        const x = { ...chainForm }
        x[key] = e.target.value
        setChainForm(x)
    }
    const setSwapFormInfo = (key, e) => {
        const x = { ...swapForm }
        x[key] = e.target.value
        setSwapForm(x)
    }

    useEffect(() => {
        // 获取全部公链
        // 获取全部swap
        getAllChains().then(({ data }) => {
            if (data.code === 200) {
                setChainList(data.data)
            }
        })
        getAllSwapList().then(({ data }) => {
            if (data.code === 200) {
                setSwapList(data.data)
            }
        })

    }, [])

    useEffect(() => {
        let list = [...swapList]
        if (swapFilter.chain_id !== '') {
            list = list.filter(item => parseInt(item.chain_id) === parseInt(swapFilter.chain_id))
        }
        if (swapFilter.search_name !== '') {
            const swapLowerList = list.map(item => {
                return {
                    ...item,
                    exchange_name: item.exchange_name.toLowerCase()
                }
            })
            list = swapLowerList.filter(item => item.exchange_name && item.exchange_name.includes(swapFilter['search_name'].toLowerCase()))
        }
        setFilteredSwapList(list)
    }, [swapList, swapFilter])

    return <div>
        <SyncPairsModal show={showSyncPairsModal} info={syncPairInfo} onHide={() => {
            setShowSyncPairsModal(false)
        }} />
        <SetRouterModal show={showSetRouterModal} onSubmit={async (params) => {
            const { data } = await UpdateSwapRouter({
                ...tmpRouterInfo,
                ...params
            })
            if (data.code === 200) {
                toast.success('设置成功')
                setShowSetRouterModal(false)
            } else {
                toast.error(data.message || '设置失败')
            }

        }} onHide={() => {
            setShowSetRouterModal(false)
        }} />
        <Tab.Container defaultActiveKey="add-chain" >
            <div className="card-header">
                <Nav variant="pills">
                    <Nav.Link eventKey="add-chain">
                        添加公链
                    </Nav.Link>
                    <Nav.Link eventKey="add-swap">
                        添加Swap
                    </Nav.Link>
                    <Nav.Link eventKey="sync-swap-pairs">
                        同步Swap交易对
                    </Nav.Link>
                </Nav>
            </div>
            <div style={{ height: '100%' }}>
                <Tab.Content>
                    <Tab.Pane eventKey="add-chain">
                        {/* 添加公链的表单 */}
                        <Form style={{ padding: '10px' }}>
                            <Form.Group>
                                <Form.Label>公链名称:</Form.Label>
                                <Form.Control value={chainForm['name']} onChange={setChainFormInfo.bind(this, 'name')} type="text" placeholder="输入公链名称" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>简称:</Form.Label>
                                <Form.Control value={chainForm['short_name']} onChange={setChainFormInfo.bind(this, 'short_name')} type="text" placeholder="输入公链简称" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>LOGO:</Form.Label>
                                <Form.Control value={chainForm['logo']} onChange={setChainFormInfo.bind(this, 'logo')} type="text" placeholder="输入LOGO地址(https://)" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>链ID:</Form.Label>
                                <Form.Control value={chainForm['chain_id']} onChange={setChainFormInfo.bind(this, 'chain_id')} type="text" placeholder="输入公链ChainID" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>RPC:</Form.Label>
                                <Form.Control value={chainForm['rpc']} onChange={setChainFormInfo.bind(this, 'rpc')} type="text" placeholder="输入一个可用的RPC节点地址" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>货币symbol:</Form.Label>
                                <Form.Control value={chainForm['symbol']} onChange={setChainFormInfo.bind(this, 'symbol')} type="text" placeholder="输入本位货币简称" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>货币精度(decimals):</Form.Label>
                                <Form.Control value={chainForm['decimals']} onChange={setChainFormInfo.bind(this, 'decimals')} type="text" placeholder="输入本位货币的精度(默认18)" />
                            </Form.Group>
                            <Form.Group>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mt-2">
                                    <div onClick={async () => {
                                        console.log('chainForm', chainForm)
                                        if (!chainForm.name) {
                                            alert('请输入公链名称')
                                            return
                                        }
                                        if (!chainForm.short_name) {
                                            alert('请输入公链简称')
                                            return
                                        }
                                        if (!chainForm.logo) {
                                            alert('请输入公链LOGO')
                                            return
                                        }
                                        if (!chainForm.chain_id) {
                                            alert('请输入公链ChainID')
                                            return
                                        }
                                        if (!chainForm.rpc) {
                                            alert('请输入公链RPC')
                                            return
                                        }
                                        if (!chainForm.symbol) {
                                            alert('请输入公链symbol')
                                            return
                                        }

                                        if (window.confirm('确认添加?')) {
                                            const { data } = await AddChainApi({
                                                ...chainForm,
                                                chain_id: parseInt(chainForm.chain_id),
                                                decimals: parseInt(chainForm.decimals)
                                            })
                                            if (data.code === 200) {
                                                toast.success('添加公链成功')
                                                setTimeout(() => {
                                                    window.location.reload()
                                                }, 3000);
                                            } else {
                                                toast.error(data.message || '添加失败')
                                            }
                                        }

                                    }} className="btn btn-primary"> 添加 </div>
                                    <div onClick={() => {
                                        setChainForm({
                                            name: '',
                                            short_name: '',
                                            logo: '',
                                            chain_id: '',
                                            rpc: '',
                                            symbol: '',
                                            decimals: '18'
                                        })
                                    }} style={{ marginLeft: '10px' }} className="btn btn-danger"> 清空 </div>
                                </div>

                            </Form.Group>
                        </Form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="add-swap">
                        <Form style={{ padding: '10px' }}>
                            <Form.Group>
                                <Form.Label>Swap名称:</Form.Label>
                                <Form.Control value={swapForm['name']} onChange={setSwapFormInfo.bind(this, 'name')} type="text" placeholder="输入swap名称" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>选择公链:</Form.Label>
                                <select value={swapForm['chain_id']} onChange={setSwapFormInfo.bind(this, 'chain_id')} className="form-control form-select form-select-sm text-light">
                                    {chainList && chainList.map(item => {
                                        return <option key={item.name + '_' + item.id} value={item.chain_id}>{item.name}</option>
                                    })}
                                </select>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>工厂地址(Factory):</Form.Label>
                                <Form.Control value={swapForm['factory']} onChange={setSwapFormInfo.bind(this, 'factory')} type="text" placeholder="输入对应的路由工厂factory合约,用于扫描所有流动池" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>路由地址(router):</Form.Label>
                                <Form.Control value={swapForm['router']} onChange={setSwapFormInfo.bind(this, 'router')} type="text" placeholder="输入对应的路由合约,用于交易/流动性操作" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>版本(router):</Form.Label>
                                <select className="form-control" onChange={e => {
                                    const x = { ...swapForm }
                                    x['version'] = e.target.value
                                    setSwapForm(x)
                                }}>
                                    <option value={'v2'}>v2</option>
                                    <option value={'v3'}>v3</option>
                                </select>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>WETH:</Form.Label>
                                <Form.Control value={swapForm['weth']} onChange={setSwapFormInfo.bind(this, 'weth')} type="text" placeholder="输入路由对应的weth地址" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>LOGO:</Form.Label>
                                <Form.Control value={swapForm['logo']} onChange={setSwapFormInfo.bind(this, 'logo')} type="text" placeholder="输入LOGO地址" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>描述(英文):</Form.Label>
                                <Form.Control value={swapForm['description']} onChange={setSwapFormInfo.bind(this, 'description')} type="text" placeholder="描述(英文)" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>描述(中文):</Form.Label>
                                <Form.Control value={swapForm['description_cn']} onChange={setSwapFormInfo.bind(this, 'description_cn')} type="text" placeholder="描述(中文)" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>官网:</Form.Label>
                                <Form.Control value={swapForm['website']} onChange={setSwapFormInfo.bind(this, 'website')} type="text" placeholder="选填" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Discord:</Form.Label>
                                <Form.Control value={swapForm['discord']} onChange={setSwapFormInfo.bind(this, 'discord')} type="text" placeholder="选填" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Github:</Form.Label>
                                <Form.Control value={swapForm['github']} onChange={setSwapFormInfo.bind(this, 'github')} type="text" placeholder="选填" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Telegram:</Form.Label>
                                <Form.Control value={swapForm['telegram']} onChange={setSwapFormInfo.bind(this, 'telegram')} type="text" placeholder="选填" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Twitter:</Form.Label>
                                <Form.Control value={swapForm['twitter']} onChange={setSwapFormInfo.bind(this, 'twitter')} type="text" placeholder="选填" />
                            </Form.Group>
                            <Form.Group>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mt-2">
                                    <div onClick={async () => {
                                        console.log('chainForm', swapForm)
                                        if (!swapForm.name) {
                                            alert('请输入swap名称')
                                            return
                                        }
                                        if (!swapForm.logo) {
                                            alert('请输入swap LOGO')
                                            return
                                        }
                                        if (!swapForm.chain_id) {
                                            alert('请选择公链')
                                            return
                                        }
                                        if (!swapForm.factory) {
                                            alert('请输入swap Factory')
                                            return
                                        }
                                        if (!swapForm.router) {
                                            alert('请输入swap 路由')
                                            return
                                        }
                                        const chain = chainList.find(item => parseInt(item.chain_id) === parseInt(swapForm.chain_id))

                                        if (!chain) {
                                            alert('请选择公链')
                                            return
                                        }

                                        if (window.confirm('确认添加?')) {
                                            const { data } = await AddSwapApi({
                                                ...swapForm,
                                                chain_name: chain.short_name,
                                                chain_id: parseInt(swapForm.chain_id),
                                                media: JSON.stringify({
                                                    discord: swapForm.discord,
                                                    github: swapForm.github,
                                                    logoUrl: swapForm.logo,
                                                    telegram: swapForm.telegram,
                                                    twitter: swapForm.twitter,
                                                    website: swapForm.website
                                                })
                                            })
                                            if (data.code === 200) {
                                                toast.success('添加Swap成功')
                                                setTimeout(() => {
                                                    window.location.reload()
                                                }, 3000);
                                            } else {
                                                toast.error(data.message || '添加失败')
                                            }
                                        }

                                    }} className="btn btn-primary"> 添加 </div>
                                    <div onClick={() => {
                                        setSwapForm({
                                            chain_id: '',
                                            discord: '',
                                            github: '',
                                            logo: '',
                                            telegram: '',
                                            twitter: '',
                                            website: '',
                                            name: '',
                                            description: '',
                                            description_cn: '',
                                            factory: ''
                                        })

                                    }} style={{ marginLeft: '10px' }} className="btn btn-danger"> 清空 </div>
                                </div>

                            </Form.Group>

                        </Form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="sync-swap-pairs">
                        {/*  同步流动池 展示所有的swap列表 后面跟着同步按钮, 点击同步, 弹出同步框, 从0 获取同步信息 */}
                        <div className="mt-4 table-responsive" style={{ width: '100%' }}>
                            <div style={{ marginBottom: '10px', padding: '10px' }}>
                                <Form>
                                    <Form.Group as={Row}>
                                        <Form.Label column md={2}>选择公链:</Form.Label>
                                        <Col md={10}>
                                            <select value={swapFilter['chain_id']} onChange={(e) => {
                                                setSwapFilter({
                                                    ...swapFilter,
                                                    chain_id: e.target.value
                                                })
                                            }} className="form-control form-select form-select-sm text-light">
                                                {chainList && chainList.map(item => {
                                                    return <option key={item.name + '_filter_' + item.id} value={item.chain_id}>{item.name}</option>
                                                })}
                                            </select>
                                        </Col>


                                    </Form.Group>
                                    <Form.Group as={Row} className="mt-2">
                                        <Form.Label column md={2}>Swap名称:</Form.Label>
                                        <Col md={10}>
                                            <Form.Control value={swapFilter['search_name']} onChange={(e) => {
                                                setSwapFilter({
                                                    ...swapFilter,
                                                    search_name: e.target.value
                                                })
                                            }} type="text" placeholder="输入要过滤的swap名称" />
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </div>
                            <table style={{ 'wordWrap': 'break-word', 'tableLayout': 'fixed', 'textAlign': 'center', 'wordBreak': 'break-all' }} className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Swap名称</th>
                                        <th>公链</th>
                                        <th>Factory</th>
                                        <th>Router</th>
                                        <th>操作</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredSwapList && filteredSwapList.map((item, ind) => {
                                        return <tr key={item.id + 'swap'}>
                                            <td style={{ textAlign: 'left' }}> <img style={{ width: '30px', borderRadius: '50%', marginRight: '8px' }} src={item.logo} alt="" /> {item.exchange_name} </td>
                                            <td> {item.chain_name} </td>
                                            <td> {getShortAddress(item.exchange_contract)} </td>
                                            <td> {getShortAddress(item.router)} </td>
                                            <td>
                                                <div onClick={async () => {
                                                    setShowSetRouterModal(true)
                                                    setTmpRouterInfo({
                                                        exchange_id: parseInt(item.exchange_id),
                                                        chain_id: parseInt(item.chain_id),
                                                        factory: item?.exchange_contract?.toLowerCase()
                                                    })
                                                    // const r = prompt('请输入新的router地址')
                                                    // if (!isAddress(r)) {
                                                    //     toast.error('输入的地址不正确')
                                                    //     return
                                                    // }
                                                    // const { data } = await UpdateSwapRouter({
                                                    //     exchange_id: parseInt(item.exchange_id),
                                                    //     chain_id: parseInt(item.chain_id),
                                                    //     router: r.toLowerCase(),
                                                    //     factory: item?.exchange_contract?.toLowerCase()
                                                    // })
                                                    // if (data.code === 200) {
                                                    //     toast.success('设置成功')
                                                    //     const list = [...swapList]
                                                    //     list[ind].router = r.toLowerCase()
                                                    //     setSwapList(list)

                                                    // } else {
                                                    //     toast.error(data.message || '设置失败')
                                                    // }


                                                    // console.log(r)
                                                }} className="btn btn-sm btn-outline-primary" style={{ marginRight: '4px' }}>
                                                    设置router
                                                </div>
                                                <div onClick={() => {
                                                    // 设置显示同步交易对的modal
                                                    // 展示rpc、当前总量、同步的起止索引, 每次100个, 将交易对同步到数据库
                                                    const chain = chainList.find(chain => parseInt(chain.chain_id) === parseInt(item.chain_id))
                                                    if (!chain) {
                                                        toast.error('找不到对应的公链')
                                                        return
                                                    }
                                                    setSyncPairInfo({
                                                        chain,
                                                        swap: item
                                                    })
                                                    setShowSyncPairsModal(true)
                                                }} className="btn btn-outline-primary btn-sm" >同步交易对</div>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>

                    </Tab.Pane>
                </Tab.Content>
            </div>

        </Tab.Container >

    </div >
}

export default function AdminIndex(props) {


    const account = useSelector(state => state.account)

    const [isAdmin, setIsAdmin] = useState(false)

    useEffect(() => {
        console.log('account', account)
        if (account && adminList.includes(account.toLowerCase())) {
            // 是管理员
            console.log('是管理员')
            setIsAdmin(true)
        } else {
            // 不是管理员
            // window.location.href = '/'
            setIsAdmin(false)
        }

    }, [account])

    return <div>
        <Header2 />
        <Sidebar />
        <div className="content-body" >
            {isAdmin ? <>
                <div className="container-fluid">
                    <div className="row " style={{ minHeight: '90vh' }}>
                        <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 " >
                            <div className="card" style={{ height: '100%' }}>
                                <Tab.Container defaultActiveKey="chain-setting" >
                                    <div className="card-header">
                                        <Nav variant="pills">
                                            <Nav.Link eventKey="chain-setting">
                                                公链管理
                                            </Nav.Link>
                                            <Nav.Link eventKey="contract-setting">
                                                查币设置
                                            </Nav.Link>
                                            <Nav.Link eventKey="token-setting">
                                                代币设置
                                            </Nav.Link>
                                        </Nav>
                                    </div>
                                    <div style={{ height: '100%' }}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="chain-setting">
                                                <AddChain />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="contract-setting">
                                                <TokenCheckSetting />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="token-setting">
                                                <TokenSetting />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>

                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>

            </> : <>
                <div>
                    404 Not Found
                </div>
            </>}
            <div style={{ height: '200px' }}></div>
        </div>
    </div>
}