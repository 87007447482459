import axios from "axios"
import { toast } from "react-toastify"
// import { getCurrentUserToken } from "./login"
import store from '../store/index.js'
import { aesDecrypt, eventBus } from "../utils/tools.js"
import { TmdEnv, baseUrl } from "../utils/config.js"

// const baseUrl = "http://127.0.0.1:8909"
// const baseUrl = "http://127.0.0.1:8090"
// const baseUrl = 'https://api.tmdex.org'


const Network = {
    request(p) {
        // const token = getCurrentUserToken()
        if (p["url"].indexOf(baseUrl) === -1) {
            p["url"] = baseUrl + p["url"]
        }

        const token = store.getState().token
        if (token) {
            p.headers = {
                ...p?.headers,
                Authorization: 'Bearer ' + token
            }
        }


        return new Promise((resolve, reject) => {
            // 对接app的时候,这里改成调用app的方法
            axios(p, {
                cancelToken: p.extra?.cancelToken
            }).then(response => {
                const { data } = response
                // 如果是生产环境, 则解密
                let x = {}
                if (TmdEnv === 'production') {
                    // 解密data
                    x = JSON.parse(aesDecrypt(data))
                } else {
                    x = data
                }
                console.log('request====>', p['url'], x)
                if (x.code === 2005) {
                    // token过期
                    store.dispatch({ type: 'setToken', value: '' })
                    eventBus.emit('request_login')
                    eventBus.emit('toast_message', {
                        type: 'warn',
                        message: '登录过期,请重新登录'
                    })
                }
                resolve({ data: x })
            }).catch(e => {
                reject(e)
            })

        })
    }
}

export default Network
