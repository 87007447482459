import React, { useCallback, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Form, Modal, Nav, Offcanvas, Tab } from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import AutoScrollView from "react-auto-scrollview";

import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";
import { useHistory, useParams } from "react-router-dom";
// import { Link } from "react-router-dom";
// import TradingViewWidget, { Themes } from "react-tradingview-widget";
// import { TradingViewChart } from "../components/TradingViewChart"
import { TVChartContainer } from '../components/TVChartContainer/index';
import ScoreDashboard from '../components/ScoreProgress/index'
// import DepthChart from "../charts/depth";
// import RangeSlider from "../element/range-slider";
import Footer2 from "../layout/footer2";
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import TextScroll from 'react-textscroll'
import safeImg from "../../assets/img/safe6.png";
import doubtImg from "../../assets/img/doubt2.png";
import collectImg from "../../assets/img/collection.png";
import collectImg2 from "../../assets/img/collection2.png";
import watchImg from "../../assets/img/watch.png";
import watchImg2 from "../../assets/img/watch2.png";
import checkImg from "../../assets/img/check.png";
import dangerImg from "../../assets/img/danger2.png";
import warnImg from "../../assets/img/warn.png";
import swapImg from '../../assets/img/swap.png'
import explorerImg from '../../assets/img/explorer.png'

import { addTokenOptionRecord, getIncreaseRankList, getLiquidityRecordList, getNewPoolList, getTokenCollectionList, getTokenDetail, getTokenHoldersList, getTokenOptionsList, getTokenOverviewInfo, getTokenPairList, getTokenPairPriceInfo, getTokenTxRecords, removeTokenOptionRecord } from "../../api/token"
import { convertToMaxUnit, converToNumberString, formatTimeString, getBlockBrowerTxUrl, StreamLineUrl, getShortAddress, randomNum, eventBus, isAddress, socialMediaInfoList, isUrl, isMobile, getChainNameWithChainId, convertNanToEmpty } from "../../utils/tools"
import Logo from "../components/logo"
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import CertifiedModal from "../components/CertifiedModal";
import EditProjectInfoModal from "../components/EditProjectInfo";
import OptionGroupListModal from '../components/OptionGroupModal/index'
import SetTopAddressCount from "../components/DashboardComponents/SetTopAddressCount";
import TokenHoldersChart from "../components/DashboardComponents/TokenHoldersPieChart";
import WalletObserver from "../components/WalletObserver";
import WalletTxStatistic from "../components/WalletTxStatistic";
import GlobalAnaylizePanel from "../components/GlobalAnaylizePanel";
import { getCheckLogic } from "../../utils/check";
import store from "../../store";
import HolderActionModal from "../components/DashboardComponents/HolderActionModal";
import MonitorModal from "../components/DashboardComponents/MonitorModal";
import { CheckInfoResPanel } from "./check";
import MobileChartContainer from "../MobileChartContainer";
import { useTranslation } from "react-i18next";
import { SwapBox } from "./swap/swap";
import { NETWORK_LIST } from "../../utils/networks";
import { set } from "date-fns";




function LiquidityList(props) {
    const [liquidityRecords, setLiquidityRecords] = useState([]) // 流动性记录
    const [liquidityRecordPage, setLiquidityRecordPage] = useState(1) // 流动性记录页数
    const [showLiquidityRecordBtn, setShowLiquidityRecordBtn] = useState(true) // 是否显示查看更多按钮

    const { t } = useTranslation()
    useEffect(() => {
        const records = props.liquidityRecords || []
        // 去重复
        const newRecords = []
        const map = {}
        records.forEach(item => {
            if (!map[item.tx_hash]) {
                newRecords.push(item)
                map[item.tx_hash] = true
            }
        })
        setLiquidityRecords(newRecords)
        setLiquidityRecordPage(props.liquidityRecordPage)
        setShowLiquidityRecordBtn(props.showLiquidityRecordBtn)
    }, [props])

    return <table className="table mt-4 tx-table-filters-scroll scroll-hidden" >
        <thead>
            <tr>
                <th scope="col">
                    {t('时间')}
                </th>
                <th style={{ 'textAlign': 'center' }} scope="col">
                    {t('流动性')}
                </th>
                <th scope="col">{t('价值')}</th>
                <th scope="col">{t('用户')}</th>
            </tr>
        </thead>
        <tbody>
            {liquidityRecords && liquidityRecords.map((item, ind) => {
                return <tr onClick={() => {
                    // 获取要跳转的链接
                    // 打开新的页面
                    // const url = getBlockBrowerTxUrl(item.chain_id, item.tx_hash)
                    // const w = window.open('about:blank')
                    // w.location.href = url
                    props.onClickItem && props.onClickItem(item)
                }} style={{ 'cursor': 'pointer' }} key={ind + 'liquidity-record'}>
                    <td className={`${item.type === 1 ? 'text-success' : 'text-danger'} `}>
                        {formatTimeString(item.time, isMobile())}
                    </td>
                    <td style={{ 'textAlign': 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {convertToMaxUnit(item.token0_amount)} <div style={{
                                maxWidth: '80px',
                                overflowX: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}>
                                {item.token0_symbol}
                            </div>
                        </div>

                        {/* <br /> */}
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {convertToMaxUnit(item.token1_amount)}
                            {convertToMaxUnit(item.token0_amount)} <span style={{
                                maxWidth: '80px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}>
                                {item.token1_symbol}
                            </span>
                        </div>

                    </td>
                    <td>${convertToMaxUnit(item.value)}</td>
                    <td> {getShortAddress(item.from_address)} </td>
                </tr>
            })}
            {showLiquidityRecordBtn && liquidityRecords && liquidityRecords.length > 10 && <tr style={{ 'textAlign': 'center' }}>
                <td onClick={() => {
                    props.showMorePoolRecords && props.showMorePoolRecords(liquidityRecordPage + 1)
                }} colSpan={4} style={{ 'textAlign': 'center', 'cursor': 'pointer' }} className="text-primary"> ⬇ {t('查看更多')}</td>
            </tr>}
        </tbody>
    </table>
}

function PoolInfoBtn(props) {
    const [currentPair, setCurrentPair] = useState({}) // 当前的交易对
    const [pairBalanceInfo, setPairBalanceInfo] = useState({
        token0Balance: '',
        token1Balance: ''
    }) // 交易对的余额信息
    useEffect(() => {
        setCurrentPair(props.currentPair)
    }, [props])


    const { t } = useTranslation()
    const pairUpdateState = useSelector(state => state.pairUpdate)

    useEffect(() => {
        let token0Balance = currentPair.token0_balance
        let token1Balance = currentPair.token1_balance
        const pairId = parseInt(currentPair.id)
        if (pairUpdateState[pairId] && pairUpdateState[pairId].token0_balance && pairUpdateState[pairId].token1_balance) {
            token0Balance = pairUpdateState[pairId].token0_balance
            token1Balance = pairUpdateState[pairId].token1_balance
        }

        setPairBalanceInfo({
            token0Balance: convertToMaxUnit(token0Balance),
            token1Balance: convertToMaxUnit(token1Balance)
        })

    }, [currentPair, pairUpdateState])

    return <div onClick={() => {
        props.setShowAllPool && props.setShowAllPool(true)
    }} className="pool-info-header pointer-btn">
        <div className="pool-info-header-img">
            <img style={{ borderRadius: '50%', backgroundColor: '#ffffff' }} src={currentPair.exchange_logo || 'https://token-monitor.s3.amazonaws.com/16880319431861688031905289cropped-1688031905242%20%281%29.png'} alt="" />
        </div>
        <div className="pool-info-header-item">
            <div style={{ maxWidth: '100px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} className="fw-bold">{currentPair.token0_symbol}</div>
            <div style={{ fontSize: '12px' }} className="text-light">
                {pairBalanceInfo['token0Balance']}
            </div>
        </div>
        <div className="pool-info-header-item">
            <div style={{ maxWidth: '100px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} className="fw-bold">{currentPair.token1_symbol}</div>
            <div style={{ fontSize: '12px' }} className="text-light">
                {pairBalanceInfo['token1Balance']}
            </div>
        </div>
    </div>

}

function CollectBtnList(props) {
    const [tokenInfo, setTokenInfo] = useState({}) // 代币信息

    useEffect(() => {
        setTokenInfo(props.tokenInfo)
    }, [props])

    const { t } = useTranslation()

    return <div className="d-flex flex-row justify-content-center align-items-between">
        <div style={{ flex: 1, paddingRight: '3px' }}>
            {tokenInfo.is_collected ? <div onClick={() => {
                // removeCollected.bind(this)
                props.removeCollected && props.removeCollected()
            }} style={{ width: '100%' }} className="btn btn-outline-primary">
                <img className="dash-header-icon" src={collectImg} alt="" />
                {t('移除自选')}
            </div> : <div onClick={() => {
                // addCollected.bind(this)
                props.addCollected && props.addCollected()
            }} style={{ width: '100%' }} className="btn btn-outline-primary">
                <img className="dash-header-icon" src={collectImg} alt="" />
                {t('自选')}
            </div>}
        </div>
        <div style={{ flex: 1, paddingLeft: '3px' }}>
            <div onClick={() => {
                // addMonitor.bind(this)
                props.addMonitor && props.addMonitor()
            }} style={{ width: '100%' }} className="btn btn-outline-primary">
                <img className="dash-header-icon" src={watchImg} alt="" />
                {t('监控')}
            </div>
        </div>
        <div style={{ flex: 1, paddingLeft: '3px' }}>
            <div onClick={() => {
                // addMonitor.bind(this)
                // props.addMonitor && props.addMonitor()
                props.showSwapPanel && props.showSwapPanel({
                    show: true,
                    type: 'buy'
                })
            }} style={{ width: '100%' }} className="btn btn-outline-primary">
                {/* <img className="dash-header-icon" src={watchImg} alt="" /> */}
                {/* <i style={{ fontSize: '20px' }} className="mdi mdi-swap-horizontal"></i> */}
                <img className="dash-header-icon" src={swapImg} alt="" />
                &nbsp; {t('Swap')}&nbsp;
            </div>
        </div>
    </div>
}

function TxOverviewPanel(props) {
    const { t } = useTranslation()
    const [txOverviewParams, setTxOverviewParams] = useState({
        minutes: 5 // 默认查看5分钟的交易数和交易额
    }) // 交易统计信息的参数
    const [tokenInfo, setTokenInfo] = useState({}) // 代币信息
    const [currentPair, setCurrentPair] = useState({}) // 当前的交易对
    const [txOverview, setTxOverview] = useState({
        buy_count: 0,
        sell_count: 0,
        buy_volume: 0,
        sell_volume: 0
    }) // 交易统计信息结果 有买入次数和买入额, 卖出次数和卖出额

    const getTokenTxOverview = async () => {
        // 获取交易统计信息
        if (txOverviewParams.minutes === 0 || !currentPair.pair || !tokenInfo.address) return
        const now = parseInt(Date.parse(new Date()) / 1000)
        const start = now - txOverviewParams.minutes * 60
        const { data } = await getTokenOverviewInfo({
            pair: currentPair.pair,
            token: tokenInfo.address,
            chain_id: tokenInfo.chain_id + '',
            start,
            end: now
        })
        if (data.code === 200) {
            setTxOverview(data.data)
        }

    }


    useEffect(() => {
        getTokenTxOverview()
    }, [txOverviewParams.minutes])


    useEffect(() => {
        getTokenTxOverview()
    }, [currentPair, tokenInfo])

    useEffect(() => {
        // setTxOverview(props.txOverview)
        // setTxOverviewParams({
        //     ...txOverviewParams,
        //     ...props.txOverviewParams
        // })
        setTokenInfo(props.tokenInfo)
        setCurrentPair(props.currentPair)
    }, [props])

    return <>
        <div className="d-flex flex-row justify-content-between align-items-center full-width">
            <div style={{ width: '80px' }} className="tx-time-item" ></div>
            <div onClick={() => setTxOverviewParams({ minutes: 5 })} className={"tx-time-item " + (txOverviewParams.minutes === 5 ? 'tx-time-item-active' : '')} >5M</div>
            <div onClick={() => setTxOverviewParams({ minutes: 60 })} className={"tx-time-item " + (txOverviewParams.minutes === 60 ? 'tx-time-item-active' : '')} >1H</div>
            <div onClick={() => setTxOverviewParams({ minutes: 360 })} className={"tx-time-item " + (txOverviewParams.minutes === 360 ? 'tx-time-item-active' : '')}  >6H</div>
            <div onClick={() => setTxOverviewParams({ minutes: 24 * 60 })} className={"tx-time-item " + (txOverviewParams.minutes === 60 * 24 ? 'tx-time-item-active' : '')}  >24H</div>
        </div>
        <div style={{ height: '40px' }} className="d-flex flex-row justify-content-between align-items-center mt-2">
            <div style={{ width: '70px' }} className="d-flex flex-column justify-content-center align-items-center">
                <div className="text-light tx-text">
                    {txOverview.buy_count + txOverview.sell_count}
                </div>
                <div className="tx-text">
                    {t('交易数')}
                </div>
            </div>
            <div className="tx-slide">

            </div>
            <div style={{ width: '100%', flex: 4 }} className="d-flex flex-column justify-content-center align-items-center">
                <div style={{ width: '100%' }} className="d-flex flex-row justify-content-between align-items-center tx-text">
                    <div>{t('买')}: {txOverview.buy_count || 0}</div>
                    <div>{t('卖')}: {txOverview.sell_count || 0}</div>
                </div>
                <div style={{ width: '100%' }}>
                    {(txOverview.buy_count + txOverview.sell_count) > 0 && <ProgressBar style={{ height: '5px', marginTop: '3px' }} animated={true}>
                        <ProgressBar variant="success" now={(txOverview.buy_count / (txOverview.buy_count + txOverview.sell_count)).toFixed(2) * 100} key={1} />
                        <ProgressBar variant="danger" now={100 - (txOverview.buy_count / (txOverview.buy_count + txOverview.sell_count)).toFixed(2) * 100} key={2} />
                    </ProgressBar>}
                    {(txOverview.buy_count + txOverview.sell_count) === 0 && <ProgressBar style={{ height: '5px', marginTop: '3px' }} variant="success" now={0} key={3} />}
                </div>
            </div>
        </div>
        <div style={{ height: '40px' }} className="d-flex flex-row justify-content-between align-items-center mt-2">
            <div style={{ width: '70px', overflow: 'hidden' }} className="d-flex flex-column justify-content-center align-items-center">
                <div className="text-light tx-text">
                    ${convertToMaxUnit(txOverview.buy_volume + txOverview.sell_volume)}
                </div>
                <div className="tx-text">
                    {t('交易额')}
                </div>
            </div>
            <div className="tx-slide">

            </div>
            <div style={{ width: '100%', flex: 4 }} className="d-flex flex-column justify-content-center align-items-center">
                <div style={{ width: '100%' }} className="d-flex flex-row justify-content-between align-items-center tx-text">
                    <div>{t('买')}:  ${convertToMaxUnit(txOverview.buy_volume) || 0}</div>
                    <div>{t('卖')}: ${convertToMaxUnit(txOverview.sell_volume || 0)}</div>
                </div>
                <div style={{ width: '100%' }}>
                    {(txOverview.buy_volume + txOverview.sell_volume) > 0 && <ProgressBar style={{ height: '5px', marginTop: '3px' }} animated={true}>
                        <ProgressBar variant="success" now={(Number(txOverview.buy_volume) / (Number(txOverview.buy_volume) + (Number(txOverview.sell_volume)))).toFixed(2) * 100} key={1} />
                        <ProgressBar variant="danger" now={100 - (Number(txOverview.buy_volume) / (Number(txOverview.buy_volume) + Number(txOverview.sell_volume))).toFixed(2) * 100} key={2} />
                    </ProgressBar>}
                    {(txOverview.buy_volume + txOverview.sell_volume) === 0 && <ProgressBar style={{ height: '5px', marginTop: '3px' }} variant="success" now={0} key={3} />}
                </div>
            </div>
        </div>
    </>
}

function TokenTabsInfo(props) {
    const [currentActiveKey, setCurrentActiveKey] = useState('tx-records') // 当前激活的tab
    const [tokenInfo, setTokenInfo] = useState({}) // 代币信息
    const [holdersList, setHoldersList] = useState([]) // 持币地址列表
    const [txRecords, setTxRecords] = useState([]) // 交易记录
    const [showStatisticsModal, setShowStatisticsModal] = useState(false) // 是否显示统计信息的modal
    const [txOverview, setTxOverview] = useState({
        buy_count: 0,
        sell_count: 0,
        buy_volume: 0,
        sell_volume: 0
    })
    const [txOverviewParams, setTxOverviewParams] = useState({
        minutes: 5 // 默认查看5分钟的交易数和交易额
    })
    const [txRecordFilters, setTxRecordFilters] = useState({
        onlyBuy: true,
        onlySell: true,
        topAddress: 100,
        amountStart: 0,
        amountEnd: 10000,
        amountEnable: false,
        topEnable: false,
        customAddressEnable: false,
        customAddressList: []
    }) // 交易记录筛选条件
    const [filteredTxRecords, setFilteredTxRecords] = useState([]) // 过滤后的交易记录
    const [showSetTopAddressCountModal, setShowSetTopAddressCountModal] = useState(false) // 是否显示设置持币地址数量的modal
    const [showAddressActionModal, setShowAddressActionModal] = useState(false) // 是否显示地址操作的modal
    const [currentSelectHolder, setCurrentSelectHolder] = useState({}) // 当前选择的地址
    const [tmpWalletTxStatistic, setTmpWalletTxStatistic] = useState({}) // 临时的钱包交易统计信息
    const [currentPair, setCurrentPair] = useState({}) // 当前的交易对
    const [liquidityRecords, setLiquidityRecords] = useState([]) // 流动性记录
    const [liquidityRecordPage, setLiquidityRecordPage] = useState(1) // 流动性记录页数
    const [showLiquidityRecordBtn, setShowLiquidityRecordBtn] = useState(true) // 是否显示查看更多按钮

    const [holdersMap, setHoldersMap] = useState({}) // 持币地址map, 用于快速查找

    const { t, i18n } = useTranslation()

    useEffect(() => {
        setTokenInfo(props.tokenInfo)
        setHoldersList(props.holdersList)
        setTxRecords(props.txRecords)
        setCurrentPair(props.currentPair)
        setCurrentActiveKey(props.currentActiveKey)
        setHoldersMap(props.holdersMap)
        setTmpWalletTxStatistic(props.tmpWalletTxStatistic)
        setTxOverview(props.txOverview)
        setTxOverviewParams(props.txOverviewParams)
        setLiquidityRecords(props.liquidityRecords)
        setLiquidityRecordPage(props.liquidityRecordPage)
        setShowLiquidityRecordBtn(props.showLiquidityRecordBtn)

    }, [props])

    useEffect(() => {
        // 根据交易记录筛选条件, 过滤交易记录
        // 买单 卖单 持币前x名, 交易额区间
        const { onlyBuy = false, onlySell = false, topEnable = false, topAddress = 0, amountEnable = false, amountStart = -1, amountEnd = -1, customAddressEnable, customAddressList } = txRecordFilters
        const f = txRecords.filter(item => {
            let buyFlag = false
            let topFlag = false
            let amountFlag = false
            // 买单卖单是一组 前100和自定义地址是一组 交易额筛选是一组, 都必须为true, 才算

            if (onlyBuy && item.type === 1) { // 买单
                buyFlag = true
            }
            if (onlySell && item.type === 2) { // 卖单
                buyFlag = true
            }
            if (!amountEnable) amountFlag = true
            if (amountEnable) {
                if (amountStart !== -1 && parseFloat(item.volume) >= amountStart && amountEnd !== -1 && parseFloat(item.volume) <= amountEnd) {
                    amountFlag = true
                }
            }
            // 交易记录是否在持币前x名
            if (!topEnable && !customAddressEnable) topFlag = true
            if (topEnable) {
                if (topAddress > 0 && holdersMap[item.from.toLowerCase()] !== undefined && holdersMap[item.from.toLowerCase()] <= topAddress && holdersMap[item.from.toLowerCase()] > 0) {
                    topFlag = true
                }
            }

            if (customAddressEnable) {
                if (customAddressList.includes(item.from.toLowerCase())) {
                    topFlag = true
                }
            }

            return buyFlag && topFlag && amountFlag
        })

        // 去重
        const newF = []
        const map = {}
        f.forEach(item => {
            if (!map[item.unique_id] && item?.pair?.toLowerCase() === currentPair?.pair?.toLowerCase()) {
                newF.push(item)
                map[item.unique_id] = true
            }
        })

        setFilteredTxRecords(newF)

    }, [txRecords, txRecordFilters, holdersMap])

    return <div>
        <Modal centered contentClassName="search-modal" show={showStatisticsModal} onHide={() => {
            setShowStatisticsModal(false)
        }} >
            <div>
                <div style={{ minHeight: '700px' }}>
                    <WalletTxStatistic
                        wallet={tmpWalletTxStatistic}
                        tokenInfo={tokenInfo}
                        pair={currentPair}
                    />
                </div>
            </div>
        </Modal>
        <HolderActionModal show={showAddressActionModal} info={currentSelectHolder} handleClose={() => {
            setShowAddressActionModal(false)
        }} />

        <SetTopAddressCount
            config={txRecordFilters}
            show={showSetTopAddressCountModal}
            handleClose={() => {
                setShowSetTopAddressCountModal(false)
            }}
            ok={(info) => {
                // 保存信息
                const { topAddressEnable, topAddressCount, customAddressEnable, customAddressList, amountEnable, amountStart, amountEnd } = info
                setTxRecordFilters({
                    ...txRecordFilters,
                    topEnable: topAddressEnable,
                    topAddress: topAddressCount,
                    customAddressEnable,
                    customAddressList,
                    amountEnable,
                    amountStart,
                    amountEnd
                })
                console.log('info', info)
                setShowSetTopAddressCountModal(false)
            }}
        />
        <Tab.Container defaultActiveKey="tx-records" activeKey={currentActiveKey} onSelect={(k) => {
            props.handleSetCurrentActiveKey && props.handleSetCurrentActiveKey(k)
        }}>
            <div className="card">
                <div className="card-header">
                    <Nav variant="pills" className="token-option-header-nav">
                        {isMobile() && <Nav.Link eventKey="pool-info">
                            {t('池子')}
                        </Nav.Link>}
                        <Nav.Link eventKey="tx-records">
                            {t('交易记录')}
                        </Nav.Link>
                        {holdersList && holdersList.length > 0 && <Nav.Link eventKey="active-holders">
                            {t('持币地址')}
                        </Nav.Link>}
                        <Nav.Link eventKey="my-orders">
                            {t('个体分析')}
                        </Nav.Link>
                        <Nav.Link eventKey="global-anaylize">
                            {t('总体分析')}
                        </Nav.Link>
                    </Nav>
                </div>
                {/* <PerfectScrollbar> */}
                <div style={{ height: '770px' }} className={`${!isMobile() ? 'card-body ' : ''} open-position-table`}>
                    <div className="market-history market-order">
                        <Tab.Content>
                            <Tab.Pane eventKey="pool-info">
                                <div className="full-width card-body">
                                    <TxOverviewPanel tokenInfo={tokenInfo} currentPair={currentPair} txOverview={txOverview} txOverviewParams={txOverviewParams} />

                                    <div className="mt-4"></div>
                                    <PoolInfoBtn currentPair={currentPair} setShowAllPool={(k) => {
                                        props.setShowAllPool && props.setShowAllPool(k)
                                    }} />
                                    <div className="mt-2"></div>
                                    <div style={{ position: 'fixed', zIndex: 100, bottom: '0px', left: '0px', height: '70px', backgroundColor: 'rgb(20,23,35)', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div className="div-row-center" style={{ flex: 1 }}>
                                            {tokenInfo.is_collected ? <div onClick={() => {
                                                props.removeCollected && props.removeCollected()
                                            }} style={{ fontSize: '12px', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#5d78ff', cursor: 'pointer' }}>
                                                <img src={collectImg} style={{ width: '23px', height: '23px' }} alt="" />
                                                {t('移除自选')}
                                            </div> : <div onClick={() => {
                                                props.addCollected && props.addCollected()
                                            }} style={{ fontSize: '12px', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                                                <img src={collectImg2} style={{ width: '23px', height: '23px' }} alt="" />
                                                {t('自选')}
                                            </div>}
                                            <div onClick={() => {
                                                props.addMonitor && props.addMonitor()
                                            }} style={{ fontSize: '12px', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                                                <img src={watchImg2} style={{ width: '22px', height: '22px', marginBottom: '1px' }} alt="" />
                                                {t('监控')}
                                            </div>
                                            <div onClick={() => {
                                                props.showExplorer && props.showExplorer()
                                            }} style={{ fontSize: '12px', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                                                <img src={explorerImg} style={{ width: '22px', height: '22px', }} alt="" />
                                                {t('浏览器')}
                                            </div>
                                        </div>
                                        <div style={{ flex: 1.9 }} className="div-row-center">
                                            <div onClick={() => {
                                                props.showSwapPanel && props.showSwapPanel({
                                                    show: true,
                                                    type: 'buy'
                                                })
                                            }} style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} >
                                                <div style={{ width: '95%', height: '44px', borderRadius: '5px', color: '#ffffff', backgroundColor: 'rgb(39,166,154)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                    {t('买入')}
                                                </div>
                                            </div>
                                            <div onClick={() => {
                                                props.showSwapPanel && props.showSwapPanel({
                                                    show: true,
                                                    type: 'sell'
                                                })
                                            }} style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} >
                                                <div style={{ width: '95%', height: '44px', borderRadius: '5px', color: '#ffffff', backgroundColor: 'rgb(239,83,79)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                    {t('卖出')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <CollectBtnList tokenInfo={tokenInfo} removeCollected={() => {
                                        props.removeCollected && props.removeCollected()
                                    }} addCollected={() => {
                                        props.addCollected && props.addCollected()
                                    }} addMonitor={() => {
                                        props.addMonitor && props.addMonitor()
                                    }} showSwapPanel={(d) => {
                                        props.showSwapPanel && props.showSwapPanel(d)
                                    }} /> */}
                                    <div className="mt-4"></div>
                                    <h6>{t('流动性记录')}</h6>
                                    <LiquidityList liquidityRecords={liquidityRecords}
                                        liquidityRecordPage={liquidityRecordPage}
                                        showLiquidityRecordBtn={showLiquidityRecordBtn}
                                        showMorePoolRecords={() => {
                                            // showMorePoolRecords(liquidityRecordPage + 1)
                                            props.showMorePoolRecords && props.showMorePoolRecords(liquidityRecordPage + 1)
                                        }}
                                        onClickItem={item => {
                                            console.log(item)
                                            setCurrentSelectHolder({
                                                type: 'lp',
                                                info: {
                                                    ...item,
                                                    chain_id: tokenInfo.chain_id,
                                                }
                                            })
                                            setShowAddressActionModal(true)
                                        }}
                                    />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tx-records" >
                                <div style={{ 'padding': '0px 10px', marginTop: '10px', marginBottom: '10px' }} className="d-flex flex-row align-items-center tx-table-filters tx-table-filters-scroll scroll-hidden">
                                    <div className="d-flex flex-row align-items-center tx-table-filters-item"> <input onChange={(e) => {
                                        setTxRecordFilters({
                                            ...txRecordFilters,
                                            onlyBuy: e.target.checked
                                        })
                                    }}
                                        checked={txRecordFilters.onlyBuy}
                                        type="checkbox" />{t('买单')}</div>
                                    <div className="d-flex flex-row align-items-center tx-table-filters-item"> <input
                                        onChange={(e) => {
                                            setTxRecordFilters({
                                                ...txRecordFilters,
                                                onlySell: e.target.checked
                                            })
                                        }}
                                        checked={txRecordFilters.onlySell}
                                        type="checkbox" />{t('卖单')}</div>
                                    <div className="d-flex flex-row align-items-center tx-table-filters-item"> <input onChange={(e) => {
                                        setTxRecordFilters({
                                            ...txRecordFilters,
                                            topEnable: e.target.checked
                                        })
                                    }}
                                        checked={txRecordFilters.topEnable}
                                        type="checkbox" />

                                        {i18n.language === 'en' ? <>
                                            Only top <span onClick={() => {
                                                setShowSetTopAddressCountModal(true)
                                            }} className="tx-record-filter-span">{txRecordFilters.topAddress}</span> holders

                                        </> : <>
                                            仅看持币前 <span onClick={() => {
                                                setShowSetTopAddressCountModal(true)
                                            }} className="tx-record-filter-span">{txRecordFilters.topAddress}</span> 名
                                        </>}


                                    </div>

                                    <div className="d-flex flex-row align-items-center tx-table-filters-item"> <input onChange={(e) => {
                                        setTxRecordFilters({
                                            ...txRecordFilters,
                                            customAddressEnable: e.target.checked
                                        })
                                    }}
                                        checked={txRecordFilters.customAddressEnable}
                                        type="checkbox" />
                                        <span onClick={() => {
                                            setShowSetTopAddressCountModal(true)
                                        }} className="tx-record-filter-span">{t('按自定义地址筛选')}
                                            {txRecordFilters.customAddressList.length > 0 && `(${txRecordFilters.customAddressList.length})`}
                                        </span>
                                    </div>
                                    <div className="d-flex flex-row align-items-center tx-table-filters-item"> <input
                                        onChange={e => {
                                            setTxRecordFilters({
                                                ...txRecordFilters,
                                                amountEnable: e.target.checked
                                            })
                                        }}
                                        checked={txRecordFilters.amountEnable}
                                        type="checkbox" />{t('按成交额筛选')}
                                        <span onClick={() => {
                                            setShowSetTopAddressCountModal(true)
                                        }} className="tx-record-filter-span">
                                            (${txRecordFilters.amountStart}~${txRecordFilters.amountEnd})
                                        </span>
                                    </div>
                                </div>
                                <PerfectScrollbar>
                                    <div style={{ overflowX: 'hidden', overflowY: 'scroll', scrollbarWidth: 'none', maxHeight: '700px' }} className="table-responsive">
                                        <table
                                            className="table table-striped"
                                            id="tbUser"
                                            style={{ fontSize: '12px' }}
                                        >
                                            <thead>
                                                <tr>
                                                    <th scope="col">
                                                        {t('时间')}
                                                    </th>
                                                    {isMobile() === false && <>
                                                        <th scope="col">
                                                            {t('交易哈希')}
                                                        </th>
                                                        <th scope="col">
                                                            {t('操作')}
                                                        </th>
                                                    </>}
                                                    <th scope="col">
                                                        {t('成交价')}
                                                    </th>
                                                    <th scope="col">
                                                        {t('成交量')}
                                                    </th>
                                                    <th scope="col">
                                                        {t('成交额')}($)
                                                    </th>
                                                    <th scope="col">
                                                        {t('用户')}
                                                    </th>

                                                </tr>
                                            </thead>

                                            <tbody >

                                                {filteredTxRecords && filteredTxRecords.map((item, ind) => {
                                                    return <tr onClick={() => {
                                                        setCurrentSelectHolder({
                                                            type: 'tx',
                                                            info: {
                                                                ...item,
                                                                chain_id: tokenInfo.chain_id,
                                                            }
                                                        })
                                                        setShowAddressActionModal(true)
                                                    }} style={{ 'fontSize': '12px' }} key={item.unique_id} className={'pointer-btn table-row-annimate ' + (item.type === 1 ? 'text-success' : 'text-danger')}>
                                                        <td >
                                                            <div className="table-row-annimate-active">
                                                                {formatTimeString(item.time * 1000, isMobile())}
                                                            </div>

                                                        </td>
                                                        {isMobile() === false && <>
                                                            <td>
                                                                <div className="table-row-annimate-active">
                                                                    {getShortAddress(item.tx_hash)}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="table-row-annimate-active">
                                                                    {item.type === 1 ? t('买入') : t('卖出')}
                                                                </div>
                                                            </td>
                                                        </>}
                                                        <td>
                                                            <div className="table-row-annimate-active">
                                                                {item.price_symbol === "$" && "$"}
                                                                {convertToMaxUnit(item.price)}
                                                                {item.price_symbol !== "$" && item.price_symbol}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="table-row-annimate-active">
                                                                {convertToMaxUnit(item.amount)}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="table-row-annimate-active">
                                                                {convertToMaxUnit(item.volume)}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="table-row-annimate-active">
                                                                {getShortAddress(item.from)}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                })}

                                            </tbody>

                                        </table>

                                    </div>
                                </PerfectScrollbar>
                            </Tab.Pane>
                            <Tab.Pane eventKey="active-holders">
                                <PerfectScrollbar>
                                    <div className="row">
                                        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-xxl-6 col-sm-12">
                                            <TokenHoldersChart
                                                total={tokenInfo?.total_supply || 0}
                                                holders={holdersList || []}
                                            />
                                        </div>
                                        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-xxl-6 col-sm-12">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">
                                                                #
                                                            </th>
                                                            <th scope="col">
                                                                {t('余额')}
                                                            </th>
                                                            <th>
                                                                {t('占比')}
                                                            </th>
                                                            <th scope="col">
                                                                {t('地址')}
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {holdersList && holdersList.map((item, ind) => {
                                                            return <tr onClick={() => {
                                                                // 设置当前选择的行信息
                                                                // 弹出modal
                                                                // 是跳转到区块浏览器, 还是监控此地址, 还是进行个体分析
                                                                setCurrentSelectHolder({
                                                                    type: 'holder',
                                                                    info: {
                                                                        ...item,
                                                                        chain_id: tokenInfo.chain_id,
                                                                        tokenAddress: tokenInfo.address
                                                                    }
                                                                })
                                                                setShowAddressActionModal(true)
                                                            }} style={{ 'fontSize': '12px' }} key={item.address + 'holders' + ind}>
                                                                <td >
                                                                    {ind + 1}
                                                                </td>
                                                                <td>
                                                                    {convertToMaxUnit(item.balance)}
                                                                </td>
                                                                <td>
                                                                    {(parseFloat(item.balance) / parseFloat(tokenInfo.total_supply) * 100).toFixed(2)}%
                                                                </td>
                                                                <td>
                                                                    {getShortAddress(item.address)}
                                                                </td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </PerfectScrollbar>
                            </Tab.Pane>
                            <Tab.Pane eventKey="my-orders">
                                <div className="table-responsive row">
                                    <div className="col-12 col-xl-5 col-lg-5 col-md-5 col-xxl-5 col-sm-12">
                                        <WalletObserver tokenInfo={tokenInfo}
                                            onChange={(wallet) => {
                                                console.log('wallet', wallet)
                                                setTmpWalletTxStatistic(wallet)
                                                if (isMobile()) {
                                                    // 弹出显示分析详情的modal
                                                    setShowStatisticsModal(true)
                                                }
                                            }}
                                        />
                                    </div>
                                    {isMobile() === false && <div className="col-12 col-xl-7 col-lg-7 col-md-7 col-xxl-7 col-sm-12">
                                        <WalletTxStatistic
                                            wallet={tmpWalletTxStatistic}
                                            tokenInfo={tokenInfo}
                                            pair={currentPair}
                                        />
                                    </div>}
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="global-anaylize">
                                {/* <PerfectScrollbar> */}
                                <GlobalAnaylizePanel
                                    tokenInfo={tokenInfo}
                                    pair={currentPair}
                                    topAddressList={holdersList || []}
                                    onClickUser={(address) => {
                                        setCurrentSelectHolder({
                                            type: 'holder',
                                            info: {
                                                address,
                                                chain_id: tokenInfo.chain_id,
                                                tokenAddress: tokenInfo.address
                                            }
                                        })
                                        setShowAddressActionModal(true)
                                    }}
                                />
                                {/* </PerfectScrollbar> */}
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </div>
                {/* </PerfectScrollbar> */}
            </div>
        </Tab.Container>
    </div>
}

function CheckInfo(props) {

    const history = useHistory()
    const [tokenInfo, setTokenInfo] = useState({})
    const [tokenCheckRes, setTokenCheckRes] = useState({}) // 代币检测结果

    const { t } = useTranslation()
    useEffect(() => {
        setTokenInfo(props.tokenInfo)
        setTokenCheckRes(props.tokenCheckRes)
    }, [props])


    return <div style={{ width: '100%', height: '100%' }} className="card">
        <div style={{ position: 'relative' }}>
            <ScoreDashboard score={tokenCheckRes?.score?.toFixed(0)} />
            <div className="security-safe-list">
                <div className="security-safe-list-item">
                    <img className="security-safe-list-item-img" src={dangerImg} alt="" />
                    {tokenCheckRes?.danger_list?.length || 0}
                </div>
                <div className="security-safe-list-item">
                    <img className="security-safe-list-item-img" src={warnImg} alt="" />
                    {tokenCheckRes?.warning_list?.length || 0}
                </div>
                <div className="security-safe-list-item">
                    <img className="security-safe-list-item-img" src={safeImg} alt="" />
                    {tokenCheckRes?.security_list?.length || 0}
                </div>
            </div>
        </div>
        <div className="card-body">
            <div>
                <div className="check-report-row">
                    <div className="check-report-row-item">
                        {t('买入税率')}
                    </div>
                    <div className={`check-report-row-item `}>{parseFloat(tokenCheckRes.buy_tax) * 100} %</div>
                </div>
                <div className="check-report-row">
                    <div className="check-report-row-item">
                        {t('卖出税率')}
                    </div>
                    <div className={`check-report-row-item `}>{parseFloat(tokenCheckRes.sell_tax) * 100} %</div>
                </div>
                <div className="check-report-row">
                    <div className="check-report-row-item">
                        {t('买入Gas')}
                    </div>
                    <div className={`check-report-row-item `}>{tokenCheckRes.buy_gas}(≈${parseFloat(tokenCheckRes.buy_gas_in_usd).toFixed(2)})</div>
                </div>
                <div className="check-report-row">
                    <div className="check-report-row-item">
                        {t('卖出Gas')}
                    </div>
                    <div className={`check-report-row-item `}>{tokenCheckRes.sell_gas}(≈${parseFloat(tokenCheckRes.sell_gas_in_usd).toFixed(2)})</div>
                </div>
                {tokenCheckRes?.dangerList?.map((item, ind) => {
                    return <div key={'danger-list-' + ind} className="check-report-row">
                        <div className="check-report-row-item">
                            <img className="check-report-row-icon" src={dangerImg} alt="" />
                        </div>
                        <div className={`check-report-row-item text-danger`}>{item.label}</div>
                    </div>
                })}
                {tokenCheckRes?.warningList?.map((item, ind) => {
                    return <div key={'warn-list-' + ind} className="check-report-row">
                        <div className="check-report-row-item">
                            <img className="check-report-row-icon" src={warnImg} alt="" />
                        </div>
                        <div className={`check-report-row-item text-warning`}>{item.label}</div>
                    </div>
                })}
            </div>
            <div>
                <div onClick={() => {
                    const shortname = getChainNameWithChainId(tokenInfo.chain_id)
                    history.push(`/check/${shortname}/${tokenInfo.address}`)
                }} style={{ width: '100%' }} className="btn btn-outline-primary">
                    {t('查看完整报告')}
                </div>
            </div>

        </div>
    </div>

}

function MobileChartInfo(props) {
    const [tokenInfo, setTokenInfo] = useState({})
    const [currentPair, setCurrentPair] = useState({})
    const [showAnnouncementModal, setShowAnnouncementModal] = useState(false) // 是否显示公告的modal
    useEffect(() => {
        setTokenInfo(props.tokenInfo)
        setCurrentPair(props.currentPair)
    }, [props])
    const { t } = useTranslation()

    const tvHeight = isMobile() ? 440 : 540

    return <div className="col-xl-8 col-lg-8 col-md-12 col-xxl-8">
        <Modal centered contentClassName="search-modal" show={showAnnouncementModal} onHide={() => {
            setShowAnnouncementModal(false)
        }} >
            <Modal.Body>
                <div>
                    <div className="login-header">{t('项目公告')}</div>
                    <div className="mt-4">
                        <div style={{ whiteSpace: 'pre-wrap', fontSize: '14px', lineHeight: '16px' }}>{tokenInfo.announcement}</div>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
        {tokenInfo.announcement && <div onClick={() => {
            setShowAnnouncementModal(true)
        }} style={{ width: '100%' }} className="dash-announcement">
            <TextScroll
                mode='horizontal'
                text={[`🪧${t('项目公告')}: ` + tokenInfo.announcement]}
                speed={5000}
            />
        </div>}
        <div
            className="tradingview-widget-container card"
            style={{ height: (tvHeight - (tokenInfo.announcement ? 45 : 0)) + "px", borderRadius: '5px' }}
        >
            <MobileChartContainer
                openTime={tokenInfo.open_time}
                swapCurrency={() => {
                    let cp = JSON.parse(JSON.stringify(currentPair))
                    cp.tokenAddress = currentPair.currency
                    cp.token = currentPair.currency
                    cp.currency = currentPair.token
                    setCurrentPair(cp)
                }}
                datafeed={{ pair: currentPair.pair, chain_id: tokenInfo.chain_id, currency: currentPair.currency, count: 500, symbol: tokenInfo.symbol }}
            />
        </div>

    </div>
}

function ChartInfo(props) {
    const [tokenInfo, setTokenInfo] = useState({})
    const [currentPair, setCurrentPair] = useState({})
    const [showAnnouncementModal, setShowAnnouncementModal] = useState(false) // 是否显示公告的modal
    useEffect(() => {
        setTokenInfo(props.tokenInfo)
        setCurrentPair(props.currentPair)
    }, [props])

    const tvHeight = isMobile() ? 440 : 540

    const { t } = useTranslation()
    return <div className="col-xl-8 col-lg-12 col-md-12 col-xxl-8">
        <Modal centered contentClassName="search-modal" show={showAnnouncementModal} onHide={() => {
            setShowAnnouncementModal(false)
        }} >
            <Modal.Body>
                <div>
                    <div className="login-header">{t('项目公告')}</div>
                    <div className="mt-4">
                        <div style={{ whiteSpace: 'pre-wrap', fontSize: '14px', lineHeight: '16px' }}>{tokenInfo.announcement}</div>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
        {tokenInfo.announcement && <div onClick={() => {
            setShowAnnouncementModal(true)
        }} className="dash-announcement">
            <TextScroll
                mode='horizontal'
                text={[`🪧${t('项目公告')}: ` + tokenInfo.announcement]}
                speed={5000}
            />
        </div>}
        <div
            className="tradingview-widget-container card"
            style={{ height: (tvHeight - (tokenInfo.announcement ? 45 : 0)) + "px", borderRadius: '5px' }}
        >
            <TVChartContainer
                openTime={tokenInfo.open_time}
                swapCurrency={() => {
                    let cp = JSON.parse(JSON.stringify(currentPair))
                    cp.tokenAddress = currentPair.currency
                    cp.token = currentPair.currency
                    cp.currency = currentPair.token
                    setCurrentPair(cp)
                }}
                datafeed={{ pair: currentPair.pair, chain_id: tokenInfo.chain_id, currency: currentPair.currency, count: 500, symbol: tokenInfo.symbol }}
            />
        </div>

    </div>
}

function TokenInfo(props) {

    const [tokenInfo, setTokenInfo] = useState({})
    const [currentPrice, setCurrentPrice] = useState({})
    const currentUser = useSelector(state => state.currentUser)
    const [isProjectOwner, setIsProjectOwner] = useState(false) // 当前登录账号是否是项目方

    const tokenPriceUpdateState = useSelector(state => state.tokenPriceUpdate)
    const getSocialMediaList = () => {
        // 官方的和用户附加的
        const arr = socialMediaInfoList.filter(item => {
            return tokenInfo[item]
        }).map(item => {
            return {
                name: item,
                value: tokenInfo[item]
            }
        })
        const list = [...arr, ...JSON.parse(tokenInfo.extra || '[]')]
        return list
    }

    useEffect(() => {
        setTokenInfo(props.tokenInfo)
        setCurrentPrice(props.currentPrice)
        setIsProjectOwner(props.isProjectOwner)
    }, [props])

    const { t } = useTranslation()
    const getCurrentPrice = () => {
        let price = currentPrice.price
        let rate = currentPrice.increase_24h
        const token = tokenInfo.address?.toLowerCase()
        if (!token) return 0
        if (tokenPriceUpdateState[token]) {
            price = tokenPriceUpdateState[token].price
            // 重新计算涨幅
            const open = Number(currentPrice.open_24h)
            if (open > 0) {
                rate = (Number(price) - Number(open)) / Number(open)
            }
        }
        return {
            price, rate
        }
    }

    return <div style={{ width: '100%', height: '100%' }} className="card">
        <div className="card-header">
            <div>
                <Logo size="3.7" logoPreview={true} chain={tokenInfo.chain_name} contract={tokenInfo.address} logo={tokenInfo.logo} />
            </div>
            <div style={{ color: '#ffffff', fontSize: '22px', textAlign: 'right', overflow: 'hidden' }} className="card-title">
                <div>
                    {currentPrice.price_symbol === "$" && "$"}
                    {converToNumberString(getCurrentPrice()['price'])}
                    {currentPrice.price_symbol !== "$" && currentPrice.price_symbol}
                    <sup style={{ fontWeight: 300 }} className={getCurrentPrice()['rate'] >= 0 ? "text-success" : 'text-danger'}>
                        {" "}
                        {convertNanToEmpty((getCurrentPrice()['rate'] * 100).toFixed(2))}%
                    </sup>
                </div>
                <div style={{ fontSize: '18px', fontWeight: 'bold', wordWrap: 'break-word', wordBreak: 'break-all' }} className="card-subtitle text-muted">
                    {/* {tokenInfo && tokenInfo.name} */}
                    {tokenInfo && tokenInfo.symbol}
                </div>
            </div>
        </div>
        <div className="card-body">

            <div className="text-body mt-2 text-muted token-info-header">
                {t('基本信息')}
            </div>
            <div style={{ 'fontSize': '13px', lineHeight: '25px' }} className="d-flex flex-column">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div>{t('名称')}</div>
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all', maxWidth: '70%' }}> {tokenInfo.name || ''} </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div>{t('简称')}</div>
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all', maxWidth: '70%' }}>{tokenInfo.symbol || ''}</div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div>{t('精度')}</div>
                    <div>{tokenInfo.decimals}</div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div>{t('发行量')}</div>
                    <div>{convertToMaxUnit(tokenInfo.total_supply)}</div>
                </div>

            </div>

            <div className="text-body mt-2 text-muted project-info-row token-info-header">
                <span>
                    {t('项目信息')}
                </span>
                {isProjectOwner && <span onClick={() => {
                    // setShowEditProjectModal(true)
                    props.handleEditProjectInfo && props.handleEditProjectInfo()
                }}>
                    <i style={{ fontSize: '15px', cursor: 'pointer' }} className="fa fa-edit"></i>
                </span>}

            </div>
            <div style={{ 'fontSize': '13px', lineHeight: '25px' }} className="d-flex flex-column">
                {tokenInfo?.intro && <div className="d-flex flex-row justify-content-between align-items-center">
                    {tokenInfo.intro}
                </div>}
                {!tokenInfo.intro && <div style={{ height: '50px' }} className="d-flex flex-row justify-content-center align-items-center">
                    {t('暂无简介')}
                </div>}
                {/* <iframe style={{ 'width': '100%', 'borderRadius': '5px' }} src="https://www.youtube.com/embed/-ogM8tE6w2E?modestbranding=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
            </div>

            <div className="text-body mt-2  text-muted project-info-row token-info-header">
                <div>
                    {t('区块浏览器')}
                </div>
                <div onClick={() => {
                    props.showExplorer && props.showExplorer()
                }} style={{ fontSize: '20px', cursor: 'pointer' }}>
                    <i className="mdi mdi-search-web"></i>
                </div>
            </div>
            <div className="text-body mt-2 text-muted project-info-row token-info-header">
                <span>
                    {t('社交媒体')}
                </span>
                {isProjectOwner && <span onClick={() => {
                    // setShowEditProjectModal(true)
                    props.handleEditProjectInfo && props.handleEditProjectInfo()
                }}>
                    <i style={{ fontSize: '15px', cursor: 'pointer' }} className="fa fa-edit"></i>
                </span>}
            </div>

            <div style={{ 'fontSize': '13px', lineHeight: '25px' }} className="d-flex flex-column  mb-4">
                {getSocialMediaList().map(item => {
                    return <div key={item.name.toUpperCase()} className="d-flex flex-row justify-content-between align-items-center">
                        <div>{item.name.toUpperCase()}</div>
                        <div style={{ maxWidth: '50%', whiteSpace: 'nowrap', overflowX: 'hidden' }}>
                            {isUrl(item.value) ? <a href={item.value} target="_blank" rel="noopener noreferrer">
                                {StreamLineUrl(item.value)}
                            </a> : <span onClick={() => {
                                navigator.clipboard.writeText(item.value)
                                toast.success(t('已复制'))
                            }}>{item.value} <i className="fa fa-copy pointer-btn"></i> </span>}
                        </div>
                    </div>
                })}
                {getSocialMediaList().length === 0 && <div className="d-flex flex-row justify-content-center align-items-center">
                    {t('暂无信息')}
                </div>}
            </div>


            {tokenInfo?.user_id === 0 && < div className="certified-box">
                <div>{t('这是您的项目?')} </div>
                {(currentUser && currentUser.id && currentUser.id > 0) ? <Button onClick={() => {
                    // certifiedLogic.bind(this)
                    props.handleCertifiedLogic && props.handleCertifiedLogic()
                }} className="certified-btn mt-2" size="sm">{t('认证项目方')}</Button> :
                    <Button onClick={() => {
                        eventBus.emit('request_login')
                    }} className="certified-btn mt-2" size="sm"> {t('请先登录')}</Button>}

            </div>}
            <div style={{ 'height': '10px' }}></div>

        </div>

    </div >
}


function SwapPanel(props) {
    return <>
        <Offcanvas placement="end" show={props.show} scroll={true} backdrop={false} onHide={() => {
            props.onHide && props.onHide()
        }}>
            <Offcanvas.Body>
                {props.show && <SwapBox
                    showCloseBtn={true}
                    fullWidth={true}
                    onClickCloseBtn={() => {
                        props.onHide && props.onHide()
                    }}
                    defaultSwapAction={props.action || 'buy'} // 默认买入
                    tokenInfo={props.tokenInfo}
                    pair={props.currentPair}
                />}
            </Offcanvas.Body>
        </Offcanvas>
    </>
}

export const ExplorerModal = (props) => {

    const [tokenInfo, setTokenInfo] = useState({})
    const [currentPair, setCurrentPair] = useState({})

    const [sourceCodeUrl, setSourceCodeUrl] = useState('') // 源码链接
    const [holderUrl, setHolderUrl] = useState('') // 持仓链接
    const [txUrl, setTxUrl] = useState('') // 交易链接
    const [lpUrl, setLpUrl] = useState('') // LP链接

    const [chainName, setChainName] = useState('') // 链名称
    const [explorerUrl, setExplorerUrl] = useState('') // 区块浏览器链接

    const [showTmpTxRecordModal, setShowTmpTxRecordModal] = useState(false) // 是否显示临时的交易记录modal
    const [tmpWallet, setTmpWallet] = useState('') // 临时的钱包地址

    useEffect(() => {
        setTokenInfo(props.tokenInfo)
        setCurrentPair(props.currentPair)
    }, [props.tokenInfo, props.currentPair])

    useEffect(() => {
        if (!tokenInfo?.chain_id) return
        const n = NETWORK_LIST.find(item => { return item.chainId === tokenInfo.chain_id })
        if (!n) return
        setChainName(n.shortname)
        const explorer = n.explorer
        setExplorerUrl(explorer)
        setSourceCodeUrl(`${explorer}/address/${tokenInfo.address}#code`)
        setHolderUrl(`${explorer}/token/${tokenInfo.address}#balances`)
        setTxUrl(`${explorer}/token/${tokenInfo.address}`)

        if (currentPair.pair) {
            setLpUrl(`${explorer}/token/${currentPair?.pair}#balances`)
        }
    }, [tokenInfo, currentPair])


    const { t } = useTranslation()

    return <>
        <Modal centered contentClassName="search-modal" show={showTmpTxRecordModal} onHide={() => {
            setShowTmpTxRecordModal(false)
        }} >
            <Modal.Body>
                <div>
                    <div className="login-header"> {t('输入钱包地址')} </div>
                    <div className="mt-2">
                        <Form.Control placeholder="0x..." value={tmpWallet} onChange={e => {
                            setTmpWallet(e.target.value)
                        }} />
                    </div>
                    <div className="mt-2">
                        <div onClick={() => {
                            if (!isAddress(tmpWallet)) {
                                toast.error(t('请输入正确的钱包地址'))
                                return
                            }

                            const u = `${txUrl}?a=${tmpWallet}`
                            window.open(u, '_blank')

                        }} className="btn btn-primary text-light">{t('查看此钱包交易记录')}</div>
                        <div onClick={() => {
                            window.open(txUrl, '_blank')
                        }} className="btn text-primary"> {t('查看全部交易记录')} </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        <Modal centered contentClassName="search-modal" show={props.show} onHide={() => {
            props.handleClose && props.handleClose()
        }} >
            <Modal.Body>
                <div>
                    <div className="login-header"> {t('区块浏览器')} </div>
                    <div>
                        <div className="d-flex flex-row justify-content-between align-items-center mt-2">
                            <div>{t('BSC区块浏览器').replace('{name}', chainName?.toUpperCase())}</div>
                            <div onClick={() => {
                                navigator.clipboard.writeText(tokenInfo?.address)
                                toast.success(t('已复制'))
                            }}>
                                <span style={{ marginRight: '5px', wordBreak: 'break-all', maxWidth: '200px' }}>
                                    <a target="_blank" rel="noopener noreferrer" href={explorerUrl}>{explorerUrl}</a>
                                </span>
                                <span onClick={() => {
                                    navigator.clipboard.writeText(explorerUrl)
                                    toast.success(t('已复制'))
                                }}>
                                    <i className="fa fa-copy pointer-btn"></i>
                                </span>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center mt-2">
                            <div>{t('代币合约')}</div>
                            <div onClick={() => {
                                navigator.clipboard.writeText(tokenInfo?.address)
                                toast.success(t('已复制'))
                            }}>{getShortAddress(tokenInfo?.address, 5)}  <i className="fa fa-copy pointer-btn"></i> </div>
                        </div>
                        {currentPair?.pair && <div className="mt-2">
                            <div className="d-flex flex-row justify-content-between align-items-center mt-2">
                                <div>{t('流动池合约')}</div>
                                <div onClick={() => {
                                    navigator.clipboard.writeText(currentPair?.pair)
                                    toast.success(t('已复制'))
                                }}>{getShortAddress(currentPair?.pair, 5)}  <i className="fa fa-copy pointer-btn"></i> </div>
                            </div>
                        </div>}
                        <div className="mt-2">
                            <div className="d-flex flex-row justify-content-between align-items-center mt-2">
                                <div>{t('查看源码')}</div>
                                <div >
                                    <span
                                        onClick={() => {
                                            navigator.clipboard.writeText(sourceCodeUrl)
                                            toast.success(t('已复制'))
                                        }}
                                        className="btn btn-sm text-primary">[{t('复制链接')}]</span>
                                    <span onClick={() => {
                                        window.open(sourceCodeUrl, '_blank')
                                    }} className="btn btn-sm text-primary">[{t('打开链接')}]</span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-2">
                            <div className="d-flex flex-row justify-content-between align-items-center mt-2">
                                <div>{t('查看持仓')}</div>
                                <div >
                                    <span
                                        onClick={() => {
                                            navigator.clipboard.writeText(holderUrl)
                                            toast.success(t('已复制'))
                                        }}
                                        className="btn btn-sm text-primary">[{t('复制链接')}]</span>
                                    <span onClick={() => {
                                        window.open(holderUrl, '_blank')
                                    }} className="btn btn-sm text-primary">[{t('打开链接')}]</span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-2">
                            <div className="d-flex flex-row justify-content-between align-items-center mt-2">
                                <div>{t('查看交易记录')}</div>
                                <div >
                                    {/* <span
                                        onClick={() => {
                                            navigator.clipboard.writeText(txUrl)
                                            toast.success(t('已复制'))
                                        }}
                                        className="btn btn-sm text-primary">[{t('复制链接')}]</span> */}
                                    <span onClick={() => {
                                        // window.open(txUrl, '_blank')
                                        setShowTmpTxRecordModal(true)
                                    }} className="btn btn-sm text-primary">[{t('打开链接')}]</span>
                                </div>
                            </div>
                        </div>
                        {currentPair?.pair && <div className="mt-2">
                            <div className="d-flex flex-row justify-content-between align-items-center mt-2">
                                <div>{t('查看LP持仓')}</div>
                                <div >
                                    <span
                                        onClick={() => {
                                            navigator.clipboard.writeText(lpUrl)
                                            toast.success(t('已复制'))
                                        }}
                                        className="btn btn-sm text-primary">[{t('复制链接')}]</span>
                                    <span onClick={() => {
                                        window.open(lpUrl, '_blank')
                                    }} className="btn btn-sm text-primary">[{t('打开链接')}]</span>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </>
}

function Dashboard() {
    const history = useHistory()
    const [urlParams, setUrlParams] = useState({})
    const [tokenInfo, setTokenInfo] = useState({})
    const [pairList, setPairList] = useState([])
    const [currentPair, setCurrentPair] = useState({})
    const [currentPrice, setCurrentPrice] = useState({})
    const [gainList, setGainList] = useState([]) // 涨幅榜
    const [declineList, setDeclineList] = useState([]) // 跌幅榜
    const [newPoolList, setNewPoolList] = useState([]) // 新增流动池
    const [liquidityRecords, setLiquidityRecords] = useState([]) // 流动性记录
    const [liquidityPage, setLiquidityPage] = useState(1) // 流动性记录页码
    const [txRecords, setTxRecords] = useState([]) // 交易记录
    const [txPage, setTxPage] = useState(1) // 交易记录页码
    const [showLiquidityBtn, setShowLiquidityBtn] = useState(true) // 是否显示加载更多按钮
    const [showCertified, setShowCertified] = useState(false) // 是否显示加载更多按钮
    const currentUser = useSelector(state => state.currentUser)
    const [isProjectOwner, setIsProjectOwner] = useState(false) // 当前登录账号是否是项目方
    const [showEditProjectModal, setShowEditProjectModal] = useState(false) // 是否显示编辑项目信息弹窗
    const [optionLoading, setOptionLoading] = useState(false) // 在添加/移除自选的时候是否显示loading
    const [showOptionGroupList, setShowOptionGroupList] = useState(false) // 显示自定义分组列表
    const [showSetTopAddressCountModal, setShowSetTopAddressCountModal] = useState(false) // 显示设置前多少名地址
    const [showMonitorModal, setShowMonitorModal] = useState(false) // 显示添加监控弹窗
    const [tokenCheckRes, setTokenCheckRes] = useState({}) // 代币检测结果
    const [showAllPool, setShowAllPool] = useState(false) // 是否显示所有的流动池的Modal
    const [currentActiveKey, setCurrentActiveKey] = useState('tx-records') // 当前激活的tab
    const [monitorModalType, setMonitorModalType] = useState('token') // 当前显示的监控类型 监控token或监控wallet
    const [monitorTmpWallet, setMonitorTmpWallet] = useState('') // 要监控的钱包地址
    const [showSwapModal, setShowSwapModal] = useState({
        show: false,
        type: 'buy'
    }) // 是否显示交易弹窗

    const [currentSelectHolder, setCurrentSelectHolder] = useState({}) // 当前选择的持币地址/交易哈希信息
    const [showAddressActionModal, setShowAddressActionModal] = useState(false) // 是否显示地址操作弹窗 查看区块浏览器、监控、个体分析
    const [showExplorerModal, setShowExplorerModal] = useState(false) // 是否显示区块浏览器弹窗

    const { t } = useTranslation()

    const tmpTxList = useRef([]) // 临时存储交易记录
    const tmpCurrentPair = useRef({}) // 临时存储当前的pair

    useEffect(() => {
        if (isMobile()) {
            setCurrentActiveKey('pool-info')
        }
    }, [])

    useEffect(() => {
        tmpTxList.current = txRecords
    }, [txRecords])

    useEffect(() => {
        tmpCurrentPair.current = currentPair
    }, [currentPair])

    const [txOverviewParams, setTxOverviewParams] = useState({
        minutes: 5 // 默认查看5分钟的交易数和交易额
    }) // 交易统计信息的参数
    const [txOverview, setTxOverview] = useState({
        buy_count: 0,
        sell_count: 0,
        buy_volume: 0,
        sell_volume: 0
    }) // 交易统计信息结果 有买入次数和买入额, 卖出次数和卖出额

    const [holdersList, setHoldersList] = useState([]) // 持币地址列表
    const [holdersMap, setHoldersMap] = useState({}) // 持币地址列表 地址->排名

    const [liquidityRecordPage, setLiquidityRecordPage] = useState(1) // 添加/撤出流动性记录页码
    const [showLiquidityRecordBtn, setShowLiquidityRecordBtn] = useState(true) // 添加撤出流动性位置是否显示加载更多按钮
    const [tokenOptionCollects, setTokenOptionCollects] = useState([]) // 代币自定义分组列表
    const [txRecordFilters, setTxRecordFilters] = useState({
        onlyBuy: true,
        onlySell: true,
        topAddress: 100,
        amountStart: 0,
        amountEnd: 10000,
        amountEnable: false,
        topEnable: false,
        customAddressEnable: false,
        customAddressList: []
    }) // 交易记录筛选条件
    const [filteredTxRecords, setFilteredTxRecords] = useState([]) // 筛选后的交易记录

    const [showSecurityIntroModal, setShowSecurityIntroModal] = useState(false) // 是否显示安全检测的弹窗
    const [tmpWalletTxStatistic, setTmpWalletTxStatistic] = useState('') // 获取钱包交易统计, 保存钱包地址
    const [showScrollTokenInfoModal, setShowScrollTokenInfoModal] = useState(false) // 是否显示滚动的token信息弹窗
    const tokenPriceUpdateState = useSelector(state => state.tokenPriceUpdate)
    const apiRef = useRef({})

    const params = useParams()
    // console.log('paramssss', params)
    useEffect(() => {
        setParamsLogic()
    }, [params])

    const setParamsLogic = () => {
        const { chain, token } = params
        if (chain && token) {
            setUrlParams({
                chainName: chain,
                tokenAddress: token?.toLowerCase()
            })
        }
    }

    useEffect(() => {
        if (isAddress(tokenInfo?.address)) {
            if (tokenInfo?.auth_owner.toLowerCase() === currentUser?.wallet?.toLowerCase()) {
                setIsProjectOwner(true)
            } else {
                setIsProjectOwner(false)
            }
        }

        setTmpWalletTxStatistic(currentUser.wallet)

    }, [tokenInfo, currentUser])

    const fetchData = async () => {
        let ignore = false;
        // 1. 获取代币基本信息
        getTokenDetail({
            chain: urlParams.chainName,
            contract: urlParams.tokenAddress
        }).then(info => {
            const { data } = info
            if (data.code === 200) {
                if (!ignore) {
                    setTokenInfo(info.data.data)
                }
            }
        })

        // 2. 获取流动池信息
        showMorePools(1)
        return () => { ignore = true }
    }


    useEffect(() => {
        let ignore = false;
        if (!tokenInfo.chain_id || !tokenInfo.address) {
            return
        }
        getTokenHoldersList({
            chain_id: tokenInfo.chain_id,
            token: tokenInfo.address,
        })
            .then(holdersListRes => {
                if (holdersListRes.data.code === 200) {
                    if (!ignore) {
                        if (holdersListRes.data && holdersListRes.data.data && holdersListRes.data.data.top100) {
                            setHoldersList(holdersListRes.data.data.top100 || [])
                            let m = {}
                            holdersListRes.data.data.top100.forEach((item, ind) => {
                                m[item.address.toLowerCase()] = ind + 1
                            })
                            setHoldersMap(m)
                        }

                    }
                }
            })

        return () => { ignore = true }
    }, [tokenInfo])


    const getTokenOptionCollectionsList = async () => {
        // 获取分组列表
        const { data } = await getTokenCollectionList()
        if (data.code === 200) {
            setTokenOptionCollects(data.data || [])
        }

    }


    const morePoolParams = useRef({})

    const showMorePools = async (page) => {
        let ignore = false;
        if (!urlParams.chainName || !urlParams.tokenAddress) return

        // 获取参数中的pair参数
        const pattern = /pair=(0x[a-fA-F0-9]{40})/
        const matchPair = window.location.href.match(pattern)
        let selectPair = ''
        if (matchPair && matchPair.length > 1) {
            selectPair = matchPair[1] || ''
        }

        const params = {
            chain: urlParams.chainName,
            contract: urlParams.tokenAddress,
            select_pair: selectPair,
            page,
        }
        // if (apiRef.current[JSON.stringify(params)]) return
        getTokenPairList(params).then(pairListRes => {
            if (pairListRes.data.code === 200) {
                if (!ignore) {
                    const last = JSON.parse(JSON.stringify(morePoolParams.current))
                    morePoolParams.current = params
                    const list = pairListRes.data.data || []

                    let r = []
                    if (last.contract === params.contract) {
                        if (page === 1) {
                            r = [...list]
                        } else {
                            r = [...pairList, ...list]
                        }
                    } else {
                        r = [...list]
                    }

                    setPairList(r)
                    setLiquidityPage(1)

                    if (r.length === 0) {
                        setShowLiquidityBtn(false)
                    }
                }
            }
        })

        // apiRef.current[JSON.stringify(params)] = true
        return () => { ignore = true }
    }


    const morePoolRecordParams = useRef({})
    const showMorePoolRecords = async (page) => {
        let ignore = false;
        if (!tokenInfo.chain_id) return
        if (!currentPair.pair) return
        const params = {
            chain_id: tokenInfo.chain_id,
            pair: currentPair.pair,
            page
        }
        // if (apiRef.current[JSON.stringify(params)]) {
        // return
        // }
        getLiquidityRecordList(params).then(({ data }) => {
            if (data.code === 200) {
                const list = data.data || []

                const last = JSON.parse(JSON.stringify(morePoolRecordParams.current))
                morePoolRecordParams.current = params
                let r = []
                if (last.pair === params.pair) {
                    r = [...liquidityRecords, ...list]
                } else {
                    r = [...list]
                }

                setLiquidityRecords(r)
                setLiquidityRecordPage(page)

                if (list.length === 0) {
                    setShowLiquidityRecordBtn(false)
                }
            } else {
                setShowLiquidityRecordBtn(false)
            }
        })
        // apiRef.current[JSON.stringify(params)] = true

        return () => { ignore = true }
    }

    const txRequestParams = useRef({})

    const showMoreTxRecords = async (page) => {
        let ignore = false;
        if (tokenInfo.chain_id && currentPair.pair && currentPair.currency) {
            const params = {
                chain_id: tokenInfo.chain_id,
                pair: currentPair.pair,
                currency: currentPair.currency,
                page
            }
            getTokenTxRecords(params).then(({ data }) => {
                const lastReq = JSON.parse(JSON.stringify(txRequestParams.current))
                if (data.code === 200) {
                    txRequestParams.current = params

                    if (!ignore) {
                        const resList = data.data || []
                        // 和原本的记录合并, 并去重
                        let existList = []
                        // 和上次请求的pair相同的话, 就合并
                        if (lastReq.pair === params.pair) {
                            existList = [...txRecords]
                        }

                        const tmp = [...existList, ...resList]
                        const map = {}
                        const res = []
                        tmp.forEach(item => {
                            if (!map[item.unique_id]) {
                                map[item.unique_id] = true
                                res.push(item)
                            }
                        })
                        setTxRecords(res)

                        setTxPage(page)
                    }
                }
            })

        }

        return () => { ignore = true }
    }

    useEffect(() => {
        let ignore = false
        if (pairList) {
            // 设置当前的pair
            let p = {}
            let currency = ''
            if (pairList.length > 0) {
                p = pairList[0]
                currency = p.token0
                if (urlParams.tokenAddress.toLowerCase() === p.token0) {
                    currency = p.token1
                }
            }
            if (!ignore) {
                setCurrentPair({
                    ...pairList[0],
                    currency: currency,
                    token: urlParams.tokenAddress
                })
            }

        }
        return () => { ignore = true }
    }, [pairList, urlParams])


    useEffect(() => {
        let ignore = false
        if (!tokenInfo.chain_id || !currentPair.pair || !tokenInfo.address) {
            return
        }
        store.dispatch({
            type: 'setPairUpdate',
            value: {
                [currentPair.id]: {
                    ...currentPair
                }
            }
        })
        // 获取价格信息
        getTokenPairPriceInfo({
            chain_id: tokenInfo.chain_id,
            pair: currentPair.pair,
            token: tokenInfo.address
        }).then(info => {
            if (info.data.code === 200) {
                // 价格列表
                if (!ignore) {
                    setCurrentPrice(info.data.data)
                    subscribeInfo()
                }

            }

        })
        showMorePoolRecords(1)
        // 获取加池记录

        // 获取交易记录
        showMoreTxRecords(1)
        // 获取k线
        return () => { ignore = true }
    }, [currentPair, tokenInfo])

    const subscribeInfo = () => {
        // 订阅最新价格
        eventBus.emit('subscribePrice', {
            token: tokenInfo.address,
            pair: currentPair.pair,
            currency: currentPair.currency
        })


        // 订阅交易记录
        eventBus.emit('subscribeTxs', {
            token: tokenInfo.address,
            pair: currentPair.pair,
            currency: currentPair.currency
        })

        // 订阅池子变化记录
        eventBus.emit('subscribePoolChange', {
            pairId: currentPair.id + '',
        })
    }

    const handlePageShowEvent = () => {
        setTimeout(() => {
            if (!document.hidden) {
                console.log('xxxxx开始更新tx', tokenInfo, currentPair)
                subscribeInfo()
                // 获取最新的订单记录
                showMoreTxRecords(1)
            }
        }, 0);

    }
    useEffect(() => {
        eventBus.addListener('pageShow', handlePageShowEvent)
        return () => {
            eventBus.removeListener('pageShow', handlePageShowEvent)
        }
    }, [tokenInfo, currentPair])

    useEffect(() => {
        if (!urlParams.chainName || !urlParams.tokenAddress) return
        // 获取信息
        const x = {
            name: 'urlParams',
            chainName: urlParams.chainName,
            tokenAddress: urlParams.tokenAddress
        }
        if (apiRef.current[JSON.stringify(x)]) return
        fetchData()
        apiRef.current[JSON.stringify(x)] = true
    }, [urlParams])

    const addCollected = async () => {
        if (!currentUser || !currentUser.id) {
            toast(t('请先登录'))
            eventBus.emit('request_login')
            return
        }
        // 添加自选
        // 先显示分组列表
        setShowOptionGroupList(true)

    }

    const removeCollected = async () => {

        if (!currentUser || !currentUser.id) {
            toast(t('请先登录'))
            eventBus.emit('request_login')
            return
        }

        // 移除自选
        if (optionLoading) return
        setOptionLoading(true)
        try {
            const { data } = await removeTokenOptionRecord({
                chain_id: tokenInfo.chain_id + '',
                contract: tokenInfo.address
            })
            if (data.code === 200) {
                toast.success(t('移除成功'))
                fetchData()
            } else {
                toast.error(data.msg || t('操作失败'))
            }
        } catch (e) {
            toast.error(t('操作失败'))
        }

        setOptionLoading(false)
    }

    const addMonitor = async () => {
        if (!currentUser || !currentUser.id) {
            toast(t('请先登录'))
            eventBus.emit('request_login')
            return
        }
        setMonitorModalType('token')
        setShowMonitorModal(true)
    }

    useEffect(() => {
        if (!tokenInfo.address) return
        if (!tokenInfo.chain_id) return
        getCheckLogic(
            tokenInfo.chain_id,
            tokenInfo.address
        ).then(res => {
            if (res) {
                // 根据检测结果, 设置tokenInfo的check_result
                setTokenCheckRes(res)
            }
        })
    }, [tokenInfo.address, tokenInfo.chain_id])

    const certifiedLogic = async () => {
        if (currentUser && currentUser.id && currentUser.id > 0) {
            // 展示认证框
            setShowCertified(true)
        } else {
            toast(t('请先登录'))
            eventBus.emit('request_login')
        }
    }



    const loginSuccessEvent = () => {
        setParamsLogic()
        // fetchData()
        // 获取分组列表
        getTokenOptionCollectionsList() // 所有需要登陆后获取的方法, 都在这个方法中调用
    }
    const logoutSuccessEvent = () => {
        setParamsLogic()
        fetchData()
    }





    const handleTxsUpdateLogic = (d) => {
        console.log('txsUpdateEvent', d, tmpCurrentPair, tmpTxList)
        if (d.pair === tmpCurrentPair.current.pair) {
            const list = [...d.list, ...tmpTxList.current]
            setTxRecords(list)
        }
    }

    const handleAddWalletObserver = (d) => {
        setTmpWalletTxStatistic(d)
        setCurrentActiveKey('my-orders')
    }
    const handleAddAddressToWalletObserver = (d) => {
        setCurrentActiveKey('my-orders')
    }

    const handleAddWalletMonitor = (wallet) => {
        // 展示
        setMonitorModalType('wallet')
        setMonitorTmpWallet(wallet)
        setShowMonitorModal(true)
    }

    const getCurrentPrice = () => {
        let price = currentPrice.price
        const open = Number(currentPrice.open_24h) || 0
        let rate = 0
        if (open > 0) {
            rate = (Number(price) - Number(open)) / Number(open)
        }
        const token = tokenInfo.address?.toLowerCase()
        let priceInCurrency = currentPrice.price_in_currency
        if (!token) return {
            price: 0,
            rate: 0,
            priceInCurrency: 0
        }
        if (tokenPriceUpdateState[token]) {
            price = tokenPriceUpdateState[token].price
            priceInCurrency = tokenPriceUpdateState[token].price_in_currency
            // 先根据原始数据算出24h前的价格, 然后重新计算涨幅
            const open = Number(currentPrice.open_24h)
            if (open > 0) {
                rate = (Number(price) - Number(open)) / Number(open)
            }

            // console.log(currentPrice, '24h前的原始数据为', open, '当前价格为', price, '计算的涨幅为', rate)
        }
        return {
            price, rate, priceInCurrency
        }
    }

    useEffect(() => {
        // 接收信息
        eventBus.addListener('login_success', loginSuccessEvent)
        eventBus.addListener('logout_success', logoutSuccessEvent)
        eventBus.addListener('txsUpdate', handleTxsUpdateLogic)
        eventBus.addListener('addWalletObserver', handleAddWalletObserver)
        eventBus.addListener('addWalletMonitor', handleAddWalletMonitor)
        eventBus.addListener('addAddressToWalletObserver', handleAddAddressToWalletObserver)
        // 接收价格更新
        // 接收k线更新
        // 接受交易记录更新
        return () => {
            eventBus.removeListener('login_success', loginSuccessEvent)
            eventBus.removeListener('logout_success', logoutSuccessEvent)
            eventBus.removeListener('txsUpdate', handleTxsUpdateLogic)
            eventBus.removeListener('addWalletObserver', handleAddWalletObserver)
            eventBus.removeListener('addWalletMonitor', handleAddWalletMonitor)
            eventBus.removeListener('addAddressToWalletObserver', handleAddAddressToWalletObserver)
        }
    }, [])

    const renderScrollTokenInfo = (scroll) => {
        return <>
            {scroll && <div onClick={() => {
                setShowScrollTokenInfoModal(true)
            }}> <AutoScrollView height={67} speed={10}>

                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <div>{t('合约')}</div>
                        <div onClick={() => {
                            navigator.clipboard.writeText(tokenInfo.address)
                            toast.success(t('已复制'))
                        }}> {getShortAddress(tokenInfo.address)}
                            <i style={{ marginLeft: '8px' }} className="fa fa-copy pointer-btn"></i>
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <div>{t('池子')}</div>
                        <div onClick={() => {
                            navigator.clipboard.writeText(currentPair.pair)
                            toast.success(t('已复制'))
                        }}>{getShortAddress(currentPair.pair)}
                            <i style={{ marginLeft: '8px' }} className="fa fa-copy pointer-btn"></i>
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <div>{t('流动市值')}</div>
                        <div> ${convertToMaxUnit(currentPair.cap)} </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <div>{t('现价')}(USD)</div>
                        <div>
                            {currentPrice.price_symbol === "$" && "$"}
                            {converToNumberString(currentPrice.price)}
                            {currentPrice.price_symbol !== "$" && currentPrice.price_symbol}
                        </div>
                    </div>
                    {parseInt(currentPrice.opening_first) === 0 ? <div className="d-flex flex-row justify-content-between align-items-center">
                        <div>{t('首次开盘价')}</div>
                        <div>
                            -
                        </div>
                    </div> : <div className="d-flex flex-row justify-content-between align-items-center">
                        <div>{t('首次开盘价')}</div>
                        <div>
                            {currentPrice.price_symbol === "$" && "$"}
                            {converToNumberString(currentPrice.opening_first)}
                            {currentPrice.price_symbol !== "$" && currentPrice.price_symbol}
                        </div>
                    </div>}


                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <div>{t('换手率')}</div>
                        <div>{convertNanToEmpty((currentPrice.turnover_24h * 100).toFixed(2))}%</div>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <div>{t('24h最低价')}</div>
                        <div>
                            {currentPrice.price_symbol === "$" && "$"}
                            {convertToMaxUnit(currentPrice.low_24h)}
                            {currentPrice.price_symbol !== "$" && currentPrice.price_symbol}
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <div>{t('24h最高价')}</div>
                        <div>
                            {currentPrice.price_symbol === "$" && "$"}
                            {convertToMaxUnit(currentPrice.high_24h)}
                            {currentPrice.price_symbol !== "$" && currentPrice.price_symbol}
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <div>{t('24h交易量')}</div>
                        <div>
                            {convertToMaxUnit(currentPrice.volume_24h)}
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <div>{t('24h交易额')}</div>
                        <div> {currentPrice.price_symbol === "$" && "$"}
                            {convertToMaxUnit(currentPrice.amount_24h)}
                            {currentPrice.price_symbol !== "$" && currentPrice.price_symbol} </div>
                    </div>
                </AutoScrollView></div>}
            {!scroll && <>
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div>{t('合约')}</div>
                    <div onClick={() => {
                        navigator.clipboard.writeText(tokenInfo.address)
                        toast.success(t('已复制'))
                    }}> {getShortAddress(tokenInfo.address)}
                        <i style={{ marginLeft: '8px' }} className="fa fa-copy pointer-btn"></i>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div>{t('池子')}</div>
                    <div onClick={() => {
                        navigator.clipboard.writeText(currentPair.pair)
                        toast.success(t('已复制'))
                    }}> {getShortAddress(currentPair.pair)}
                        <i style={{ marginLeft: '8px' }} className="fa fa-copy pointer-btn"></i>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div>{t('流动市值')}</div>
                    <div> ${convertToMaxUnit(currentPair.cap)} </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div>{t('现价')}(USD)</div>
                    <div>
                        {currentPrice.price_symbol === "$" && "$"}
                        {converToNumberString(currentPrice.price)}
                        {currentPrice.price_symbol !== "$" && currentPrice.price_symbol}
                    </div>
                </div>
                {parseInt(currentPrice.opening_first) === 0 ? <div className="d-flex flex-row justify-content-between align-items-center">
                    <div>{t('首次开盘价')}</div>
                    <div>
                        -
                    </div>
                </div> : <div className="d-flex flex-row justify-content-between align-items-center">
                    <div>{t('首次开盘价')}</div>
                    <div>
                        {currentPrice.price_symbol === "$" && "$"}
                        {converToNumberString(currentPrice.opening_first)}
                        {currentPrice.price_symbol !== "$" && currentPrice.price_symbol}
                    </div>
                </div>}


                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div>{t('换手率')}</div>
                    <div>{convertNanToEmpty((currentPrice.turnover_24h * 100).toFixed(2))}%</div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div>{t('24h最低价')}</div>
                    <div>
                        {currentPrice.price_symbol === "$" && "$"}
                        {convertToMaxUnit(currentPrice.low_24h)}
                        {currentPrice.price_symbol !== "$" && currentPrice.price_symbol}
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div>{t('24h最高价')}</div>
                    <div>
                        {currentPrice.price_symbol === "$" && "$"}
                        {convertToMaxUnit(currentPrice.high_24h)}
                        {currentPrice.price_symbol !== "$" && currentPrice.price_symbol}
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div>{t('24h交易量')}</div>
                    <div>
                        {convertToMaxUnit(currentPrice.volume_24h)}
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div>{t('24h交易额')}</div>
                    <div> {currentPrice.price_symbol === "$" && "$"}
                        {convertToMaxUnit(currentPrice.amount_24h)}
                        {currentPrice.price_symbol !== "$" && currentPrice.price_symbol} </div>
                </div>
            </>}

        </>
    }

    const renderPoolInfoDiv = () => {
        return <div className="d-flex flex-row position-relative">
            <div style={{ width: '100%', height: '540px', overflowY: 'scroll' }} className="card scroll-hidden">
                <div className="pool-info">
                    <PoolInfoBtn currentPair={currentPair} setShowAllPool={(k) => {
                        setShowAllPool(k)
                    }} />
                    <div>
                        <div style={{ 'fontSize': '13px', lineHeight: '25px' }} className="d-flex flex-column mt-2">
                            <CollectBtnList tokenInfo={tokenInfo}
                                removeCollected={removeCollected.bind(this)}
                                addCollected={addCollected.bind(this)}
                                addMonitor={addMonitor.bind(this)}
                                showSwapPanel={(d) => {
                                    setShowSwapModal({
                                        show: !showSwapModal.show,
                                        type: d.type
                                    })
                                }} />
                            <div className="d-flex flex-row justify-content-between align-items-center mt-2">
                                <div>{t('代币合约')}</div>
                                <div onClick={() => {
                                    navigator.clipboard.writeText(tokenInfo.address)
                                    toast.success(t('已复制'))
                                }}>{getShortAddress(tokenInfo.address, 5)}  <i className="fa fa-copy pointer-btn"></i> </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center ">
                                <div>{t('流动池合约')}</div>
                                <div onClick={() => {
                                    navigator.clipboard.writeText(currentPair.pair
                                    )
                                    toast.success(t('已复制'))
                                }}>{getShortAddress(currentPair.pair, 5)}  <i className="fa fa-copy pointer-btn"></i> </div>
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div>{t('流动市值')}</div>
                                <div> ${convertToMaxUnit(currentPair.cap)} </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div>{t('现价')}(USD)</div>
                                <div>
                                    {currentPrice.price_symbol === "$" && "$"}
                                    {converToNumberString(currentPrice.price)}
                                    {currentPrice.price_symbol !== "$" && currentPrice.price_symbol}
                                </div>
                            </div>
                            {parseInt(currentPrice.opening_first) === 0 && <div className="d-flex flex-row justify-content-between align-items-center">
                                <div>{t('首次开盘价')}</div>
                                <div>
                                    -
                                </div>
                            </div>}

                            {parseInt(currentPrice.opening_first) > 0 && <div className="d-flex flex-row justify-content-between align-items-center">
                                <div>{t('首次开盘价')}</div>
                                <div>
                                    {currentPrice.price_symbol === "$" && "$"}
                                    {converToNumberString(currentPrice.opening_first)}
                                    {currentPrice.price_symbol !== "$" && currentPrice.price_symbol}
                                </div>
                            </div>}
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div>{t('换手率')}</div>
                                <div>{convertNanToEmpty((currentPrice.turnover_24h * 100).toFixed(2))}%</div>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div>{t('24h最低价')}</div>
                                <div>
                                    {currentPrice.price_symbol === "$" && "$"}
                                    {convertToMaxUnit(currentPrice.low_24h)}
                                    {currentPrice.price_symbol !== "$" && currentPrice.price_symbol}
                                </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div>{t('24h最高价')}</div>
                                <div>
                                    {currentPrice.price_symbol === "$" && "$"}
                                    {convertToMaxUnit(currentPrice.high_24h)}
                                    {currentPrice.price_symbol !== "$" && currentPrice.price_symbol}
                                </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div>{t('24h交易量')}</div>
                                <div>
                                    {convertToMaxUnit(currentPrice.volume_24h)}
                                </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div>{t('24h交易额')}</div>
                                <div> {currentPrice.price_symbol === "$" && "$"}
                                    {convertToMaxUnit(currentPrice.amount_24h)}
                                    {currentPrice.price_symbol !== "$" && currentPrice.price_symbol} </div>
                            </div>
                        </div>
                        <div className="mt-2">
                            <TxOverviewPanel tokenInfo={tokenInfo} currentPair={currentPair} txOverview={txOverview} txOverviewParams={txOverviewParams} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    document.title = `$${convertToMaxUnit(getCurrentPrice()['price']) || ''} ${tokenInfo?.symbol || ''} | TMDex`

    return (
        <>
            <Header2 token={tokenInfo} />
            {isMobile() === false && <Sidebar />}
            <Modal centered contentClassName="search-modal" show={showScrollTokenInfoModal} onHide={() => {
                setShowScrollTokenInfoModal(false)
            }} >
                <Modal.Body>
                    <div>
                        {renderScrollTokenInfo(false)}
                    </div>
                </Modal.Body>
            </Modal>
            <ExplorerModal
                show={showExplorerModal}
                tokenInfo={tokenInfo}
                currentPair={currentPair}
                handleClose={() => {
                    setShowExplorerModal(false)
                }}
            />
            <Modal onHide={() => setShowAllPool(false)} show={showAllPool} centered contentClassName="search-modal">
                <Modal.Body>
                    <div>
                        <div className="login-header">{t('全部流动池')}</div>
                        <div style={{ 'fontSize': '12px', maxHeight: '500px', overflowY: 'scroll' }} className="mt-4">
                            <table className="table" style={{ 'wordWrap': 'break-word', 'tableLayout': 'fixed', 'textAlign': 'center', 'wordBreak': 'break-all', 'maxHeight': '200px' }}>
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            {t('交易对')}
                                        </th>
                                        <th style={{ 'width': '40px' }} scope="col">
                                            DEX
                                        </th>
                                        <th scope="col">{t('流动池')}</th>
                                        <th scope="col">{t('价值')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pairList && pairList.map((item, ind) => {
                                        return <tr style={{ 'cursor': 'pointer' }} onClick={() => {
                                            const p = pairList[ind]
                                            let currency = p.token0
                                            if (urlParams.tokenAddress.toLowerCase() === p.token0) {
                                                currency = p.token1
                                            }
                                            if (currentPair.pair !== p.pair) {
                                                setCurrentPair({
                                                    ...pairList[ind],
                                                    currency: currency,
                                                    token: urlParams.tokenAddress
                                                })
                                                setShowAllPool(false)
                                            }
                                        }} key={ind + 'pair-list'} className="overflow-hidden">
                                            <td style={{ 'wordBreak': 'keep-all', 'wordWrap': 'break-word' }}>
                                                <span>{item.token0_symbol}</span> <span>/</span> <span>{item.token1_symbol}</span>
                                            </td>
                                            <td>
                                                {<img className="rounded-circle" style={{ 'width': '20px', 'height': '20px' }} src={item.exchange_logo || 'https://token-monitor.s3.amazonaws.com/16880319431861688031905289cropped-1688031905242%20%281%29.png'} alt="" />}
                                                {!item.exchange_logo && <span>{item.exchange_name}</span>}
                                            </td>
                                            <td style={{ 'width': '100px', 'wordWrap': 'break-word', 'overflow': 'hidden', 'wordBreak': 'keep-all' }}>{convertToMaxUnit(item.token0_balance)}
                                                /{convertToMaxUnit(item.token1_balance)}</td>
                                            <td>${convertToMaxUnit(item.cap)}</td>
                                        </tr>
                                    })}
                                    {showLiquidityBtn && <tr style={{ 'textAlign': 'center' }}>
                                        <td onClick={() => {
                                            showMorePools(liquidityPage + 1)
                                        }} colSpan={4} style={{ 'textAlign': 'center', 'cursor': 'pointer' }} className="text-primary"> ⬇ {t('查看更多')}</td>
                                    </tr>}

                                </tbody>
                            </table>
                            <div className="login-header">{t('流动性记录')}</div>
                            <LiquidityList liquidityRecords={liquidityRecords}
                                liquidityRecordPage={liquidityRecordPage}
                                showLiquidityRecordBtn={showLiquidityRecordBtn}
                                showMorePoolRecords={() => {
                                    showMorePoolRecords(liquidityRecordPage + 1)
                                }}
                            />
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
            <CertifiedModal
                tokenInfo={tokenInfo}
                show={showCertified} handleClose={() => {
                    setShowCertified(false)
                }}
                ok={() => {
                    setShowCertified(false)
                    fetchData()
                }}
            />
            <SwapPanel
                show={showSwapModal.show}
                tokenInfo={tokenInfo}
                currentPair={currentPair}
                action={showSwapModal.type}
                onHide={() => {
                    setShowSwapModal({
                        show: false,
                        type: 'buy'
                    })
                }} />
            <MonitorModal type={monitorModalType} wallet={monitorTmpWallet} token={tokenInfo} price={currentPrice} pair={currentPair} show={showMonitorModal} handleClose={() => {
                setShowMonitorModal(false)
            }} />
            <OptionGroupListModal show={showOptionGroupList}
                tokenInfo={tokenInfo}
                handleClose={() => {
                    setShowOptionGroupList(false)
                }}
                list={tokenOptionCollects || []}
                updateAll={() => {
                    fetchData()
                }}
                ok={() => {
                    getTokenOptionCollectionsList()
                }}
            />
            <EditProjectInfoModal tokenInfo={tokenInfo}
                show={showEditProjectModal} handleClose={() => {
                    setShowEditProjectModal(false)
                }}
                ok={() => {
                    setShowEditProjectModal(false)
                    fetchData()
                    toast.success(t('修改成功'))
                }}
            />
            <div className="content-body" id="dashboard">

                <Modal centered contentClassName="search-modal" show={showSecurityIntroModal} onHide={() => {
                    setShowSecurityIntroModal(false)
                }} >
                    <Modal.Body>
                        <div>
                            <div className="login-header">{t('免责声明')}</div>
                            <div className="mt-2">
                                <p>
                                    {t('该代币信息经由Owner上传, 未经过人工审计, 请注意风险。')}
                                </p>
                                <p>
                                    {t('合约检查结果由机器扫描生成, 结果仅供参考, 并不作为投资依据, 请永远保持 DYOR。')}
                                </p>
                                <p>
                                    {t('提交人工审计请联系')} <a target="_blank" rel="noopener noreferrer" href="https://t.me/TMDexChecker">@TMDexChecker</a>
                                </p>

                            </div>
                        </div>
                    </Modal.Body>
                </Modal>


                <div className="container-fluid">

                    {isMobile() && <div className="row ">
                        <Tab.Container defaultActiveKey="tx-info"  >
                            <div className="card mt-1">
                                <div className="card-header ">
                                    <Nav variant="pills" className="token-option-header-nav" >
                                        <Nav.Link eventKey="tx-info">
                                            {t('行情')}
                                        </Nav.Link>
                                        <Nav.Link eventKey="token-info">
                                            {t('代币信息')}
                                        </Nav.Link>
                                        {tokenCheckRes && tokenCheckRes.score && <Nav.Link eventKey="check-info">
                                            {(tokenCheckRes.score <= 20 && tokenCheckRes.fat_token && tokenCheckRes.fat_token.symbol) ? <div >
                                                <img src={safeImg} style={{ width: '20px' }} alt="safe" /> {t('人工审计通过')}
                                            </div> : <div>
                                                {tokenInfo?.logo && <img src={doubtImg} onClick={() => {
                                                    setShowSecurityIntroModal(true)
                                                }} style={{ width: '16px', marginRight: '5px', cursor: 'pointer' }} alt="safe" />}
                                                {t('安全检查')}
                                            </div>}
                                        </Nav.Link>}
                                    </Nav>
                                </div>
                                {/* <div style={{ height: '700px' }} className="card-body open-position-table"> */}
                                <div className="market-history market-order">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="tx-info">
                                            <div style={{ padding: '10px 18px' }} className="d-flex flex-direction-row full-width">
                                                <div style={{ flex: 1 }}>
                                                    <div style={{ fontSize: '28px' }} className={getCurrentPrice()['rate'] >= 0 ? "text-success" : 'text-danger'}>
                                                        {getCurrentPrice()['price'] ? `$${converToNumberString(getCurrentPrice()['price'])}` : t('暂无')}
                                                    </div>
                                                    {getCurrentPrice()['priceInCurrency'] && <div style={{ fontWeight: 300 }}>
                                                        ≈  {converToNumberString(getCurrentPrice()['priceInCurrency'])}
                                                        {currentPrice.currency_symbol}
                                                        <span className={getCurrentPrice()['rate'] >= 0 ? "text-success" : 'text-danger'}>  {(getCurrentPrice()['rate'] * 100).toFixed(2)}%</span>
                                                    </div>}
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    {renderScrollTokenInfo(true)}
                                                </div>
                                            </div>
                                            <div className="full-width" style={{ height: '2px', backgroundColor: '#000000' }}></div>
                                            <div className="full-width">
                                                <MobileChartInfo tokenInfo={tokenInfo} currentPair={currentPair} />
                                            </div>
                                            <div className="full-width" style={{ height: '2px', backgroundColor: '#000000' }}></div>
                                            <div className="row full-width">
                                                <TokenTabsInfo
                                                    txOverview={txOverview}
                                                    txOverviewParams={txOverviewParams}
                                                    tmpWalletTxStatistic={tmpWalletTxStatistic}
                                                    holdersList={holdersList}
                                                    txRecords={txRecords}
                                                    holdersMap={holdersMap}
                                                    currentActiveKey={currentActiveKey}
                                                    tokenInfo={tokenInfo}
                                                    currentPair={currentPair}
                                                    liquidityRecords={liquidityRecords}
                                                    liquidityRecordPage={liquidityRecordPage}
                                                    showLiquidityRecordBtn={showLiquidityRecordBtn}
                                                    removeCollected={removeCollected.bind(this)}
                                                    addCollected={addCollected.bind(this)}
                                                    addMonitor={addMonitor.bind(this)}
                                                    showExplorer={() => {
                                                        setShowExplorerModal(true)
                                                    }}
                                                    handleSetCurrentActiveKey={e => {
                                                        setCurrentActiveKey(e)
                                                    }}
                                                    setShowAllPool={k => {
                                                        setShowAllPool(k)
                                                    }}
                                                    showSwapPanel={d => {
                                                        setShowSwapModal({
                                                            show: !showSwapModal.show,
                                                            type: d.type
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="token-info">
                                            <div style={{ minHeight: '700px' }} >
                                                <div className="d-flex flex-row position-relative">
                                                    <TokenInfo
                                                        showExplorer={() => {
                                                            setShowExplorerModal(true)
                                                        }}
                                                        tokenInfo={tokenInfo} currentPrice={currentPrice} isProjectOwner={isProjectOwner} handleCertifiedLogic={certifiedLogic.bind(this)} handleEditProjectInfo={() => {
                                                            setShowEditProjectModal(true)
                                                        }} />
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        {tokenCheckRes && tokenCheckRes.score && <Tab.Pane eventKey="check-info">
                                            <div style={{ minHeight: '700px', backgroundColor: '#000000' }} >
                                                <div className="">
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                                                        <CheckInfoResPanel apiRes={tokenCheckRes} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Pane>}
                                    </Tab.Content>
                                </div>
                            </div>
                            {/* </div> */}

                        </Tab.Container>
                    </div>}
                    {!isMobile() && <div className="row">
                        {/* 左侧显示代币信息/检查结果 */}
                        <div className="row" >
                            <div className="col-xl-3 col-lg-4 col-md-12 col-xxl-4  d-lg-block d-xl-block d-xxl-block">
                                <div className="d-flex flex-row position-relative">
                                    <TokenInfo
                                        showExplorer={() => {
                                            setShowExplorerModal(true)
                                        }}
                                        tokenInfo={tokenInfo} currentPrice={currentPrice} isProjectOwner={isProjectOwner} handleCertifiedLogic={certifiedLogic.bind(this)} handleEditProjectInfo={() => {
                                            setShowEditProjectModal(true)
                                        }} />
                                </div>
                                <div className="d-none  d-md-none d-xl-none d-xxl-none d-lg-block d-lg-12">
                                    {renderPoolInfoDiv()}
                                </div>
                                {tokenCheckRes && tokenCheckRes.score && <div className="d-flex flex-row position-relative">
                                    <CheckInfo tokenInfo={tokenInfo} tokenCheckRes={tokenCheckRes} />
                                </div>}
                            </div>

                            <div className="col-xl-9 col-lg-8 col-md-12 col-xxl-8  d-lg-block d-xl-block d-xxl-block">
                                <div className="row">
                                    <ChartInfo tokenInfo={tokenInfo} currentPair={currentPair} />
                                    <div className="col-xl-4 col-md-12 col-lg-4 col-md-4 col-xxl-4 d-lg-none d-xl-block d-xxl-block">
                                        {renderPoolInfoDiv()}
                                    </div>
                                </div>
                                <div className="row">

                                    <TokenTabsInfo
                                        txOverview={txOverview}
                                        txOverviewParams={txOverviewParams}
                                        tmpWalletTxStatistic={tmpWalletTxStatistic}
                                        holdersList={holdersList}
                                        txRecords={txRecords}
                                        holdersMap={holdersMap}
                                        currentActiveKey={currentActiveKey}
                                        tokenInfo={tokenInfo}
                                        currentPair={currentPair}
                                        liquidityRecords={liquidityRecords}
                                        liquidityRecordPage={liquidityRecordPage}
                                        showLiquidityRecordBtn={showLiquidityRecordBtn}
                                        removeCollected={removeCollected.bind(this)}
                                        addCollected={addCollected.bind(this)}
                                        addMonitor={addMonitor.bind(this)}
                                        handleSetCurrentActiveKey={e => {
                                            setCurrentActiveKey(e)
                                        }}
                                        setShowAllPool={k => {
                                            setShowAllPool(k)
                                        }}
                                        showMorePoolRecords={() => {
                                            showMorePoolRecords(liquidityRecordPage + 1)
                                        }}
                                        showSwapPanel={d => {
                                            setShowSwapModal({
                                                show: !showSwapModal.show,
                                                type: d.type
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>


                    </div>}


                </div>
            </div >

            <Footer2 />
        </>
    );
}

export default Dashboard;
