import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropper";
import { useTranslation } from "react-i18next";

export default function ImageCropper(props) {

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const { t } = useTranslation()
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        console.log(croppedArea, croppedAreaPixels)
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = async () => {
        try {
            const croppedFile = await getCroppedImg(
                props.image,
                croppedAreaPixels,
                0
            )
            props.ok && props.ok(croppedFile)
        } catch (e) {
            console.error(e)
        }
    }

    return <Modal backdrop={'static'} centered contentClassName="search-modal cropper-modal" show={props.show} onHide={() => {
        props.handleClose && props.handleClose()
    }} >
        <Modal.Body>
            <div className="login-header d-flex justify-content-between mt-0 align-items-center">
                <div>
                    {t('裁剪框')}
                </div>
                <div onClick={() => {
                    props.handleClose && props.handleClose()
                }}>
                    <i className="fa fa-close"></i>
                </div>
            </div>

            <div className="cropper-body">
                <Cropper
                    image={props.image}
                    crop={crop}
                    zoom={zoom}
                    aspect={1 / 1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    cropSize={{ width: 200, height: 200 }}
                />
            </div>
            <div className="mt-2">
                <Button onClick={showCroppedImage.bind(this)} size="sm" variant="primary" style={{ color: '#ffffff' }}>{t('确定')}</Button>
                <Button onClick={() => {
                    props.handleClose && props.handleClose()
                }} size="sm" variant="danger" style={{ color: '#ffffff', marginLeft: '10px' }}>{t('取消')}</Button>
            </div>
        </Modal.Body>
    </Modal>
}