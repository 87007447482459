import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Button, Alert, Form, DateTimePicker, InputGroup } from 'react-bootstrap'
import FileUploader from "../FileUploader";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment-timezone'
import es from 'date-fns/locale/es';
import { updateTokenInfo } from "../../../api/token";
import { toast } from "react-toastify";
import { isUrl, socialMediaInfoList } from "../../../utils/tools";
import { useTranslation } from "react-i18next";
registerLocale('es', es)

moment.tz.setDefault('UTC') // global style
export default function EditProjectInfoModal(props) {
    const [errorMsg, setErrorMsg] = useState('') // 错误信息
    const [avatar, setAvatar] = useState('') // 头像
    const [openTime, setOpenTime] = useState(0) // 开盘时间
    const [socialMedia, setSocialMedia] = useState({}) // 社交媒体信息
    const [extraSocialMedia, setExtraSocialMedia] = useState([]) // 额外的社交媒体信息
    const [loading, setLoading] = useState(false) // 是否正在加载

    const { t } = useTranslation()
    const setSocialMediaInfo = (key, value) => {
        console.log(socialMedia)
        let temp = { ...socialMedia }
        temp[key] = value.target.value
        setSocialMedia(temp)
    }

    const addExtraSocialMedia = () => {
        let temp = [...extraSocialMedia]
        temp.push({
            name: '',
            value: ''
        })
        setExtraSocialMedia(temp)
    }



    useEffect(() => {
        if (props.tokenInfo.logo) {
            setAvatar(props.tokenInfo.logo)
        }
        if (props.tokenInfo.open_time) {
            setOpenTime(moment(props.tokenInfo.open_time * 1000).toDate())
        }
        if (props.tokenInfo.extra) {
            setExtraSocialMedia(JSON.parse(props.tokenInfo.extra))
        }
        let s = {}
        for (let i = 0; i < socialMediaInfoList.length; i++) {
            s[socialMediaInfoList[i]] = props.tokenInfo[socialMediaInfoList[i]] || ''
        }
        if (props.tokenInfo.intro) {
            s['intro'] = props.tokenInfo.intro
        }
        if (props.tokenInfo.announcement) {
            s['announcement'] = props.tokenInfo.announcement
        }
        setSocialMedia(s)
    }, [props.tokenInfo])

    const saveInfo = async () => {
        setErrorMsg('')
        if (loading) {
            return
        }
        // 保存信息
        if (avatar && !isUrl(avatar)) {
            setErrorMsg(t('头像地址不正确'))
            return
        }
        for (let i = 0; i < socialMediaInfoList.length; i++) {
            if (socialMediaInfoList[i] !== 'qq' && socialMedia[socialMediaInfoList[i]] && !isUrl(socialMedia[socialMediaInfoList[i]])) {
                setErrorMsg(`${socialMediaInfoList[i]}${t('地址不正确')}`)
                return
            }
        }
        console.log(props.tokenInfo)

        setLoading(true)

        // 请求接口
        try {
            const { data } = await updateTokenInfo({
                ...socialMedia,
                extra: JSON.stringify(extraSocialMedia),
                chain_id: props.tokenInfo.chain_id + '',
                contract: props.tokenInfo.address,
                open_time: isNaN(parseInt((moment(openTime).valueOf() / 1000))) ? '0' : (parseInt((moment(openTime).valueOf() / 1000)) + ''),
                logo: avatar,
                chain: props.tokenInfo.chain_name
            })

            console.log(data)
            if (data.code === 200) {
                // 保存成功
                props.ok && props.ok()
            } else {
                setErrorMsg(data.message || t('保存失败'))
            }
        } catch (e) {
            setErrorMsg(t('保存失败'))
            toast.error(t('保存失败'))
        }

        setLoading(false)
    }

    return <Modal centered contentClassName="search-modal" show={props.show} onHide={() => {
        props.handleClose && props.handleClose()
    }} >
        <Modal.Body>
            <div>
                <div>
                    <div className="login-header">{t('编辑项目信息')}</div>
                </div>
                <div className="project-info-body">
                    <Form>
                        <Form.Group className="mt-2">
                            <Form.Label>{t('头像')}</Form.Label>
                            <FileUploader width="100px" height="100px" onBeforeUpload={(f) => {
                                console.log(f)
                                if (f.type.indexOf('image') === -1) {
                                    setErrorMsg(t('文件格式不正确'))
                                    return false
                                }

                                return true
                            }} url={avatar} onFileUpload={(url) => {
                                // 设置背景图
                                // 上传成功
                                console.log('上传成功', url)
                                setAvatar(url)

                            }} />
                        </Form.Group>
                        <Form.Group className="mt-2">
                            <Form.Label>{t('简介')}</Form.Label>
                            <Form.Control value={socialMedia['intro']} onChange={setSocialMediaInfo.bind(this, 'intro')} as="textarea" rows={3} placeholder={t('(选填)最多输入500字')} />
                        </Form.Group>
                        <Form.Group className="mt-2">
                            <Form.Label>{t('公告')}</Form.Label>
                            <Form.Control value={socialMedia['announcement']} onChange={setSocialMediaInfo.bind(this, 'announcement')} as="textarea" rows={3} placeholder={t('(选填)将会项目页面顶部滚动展示')} />
                        </Form.Group>
                        <Form.Group className="mt-2">
                            <Form.Label>{t('开盘时间(UTC)')}:</Form.Label>
                            <DatePicker
                                timeFormat="HH:mm"
                                dateFormat="yyyy/MM/dd HH:mm"
                                className="form-control date-picker-b"
                                showTimeSelect
                                locale="es"
                                timeIntervals={15}
                                selected={openTime}
                                onChange={(t) => {
                                    console.log('得到的time', t)
                                    console.log('格式化的time', moment(t).valueOf())
                                    setOpenTime(t)
                                }}
                            />
                        </Form.Group>
                        <div className="row project-info-row mt-2">
                            <div style={{ paddingLeft: '5px', paddingRight: '5px' }} className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <Form.Group>
                                    <Form.Label>{t('官网')}:</Form.Label>
                                    <Form.Control value={socialMedia['website']} onChange={setSocialMediaInfo.bind(this, 'website')} type="text" placeholder="https://" />
                                </Form.Group>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <Form.Group>
                                    <Form.Label>Telegram:</Form.Label>
                                    <Form.Control value={socialMedia['telegram']} onChange={setSocialMediaInfo.bind(this, 'telegram')} type="text" placeholder="https://" />
                                </Form.Group>
                            </div>
                            <div style={{ paddingLeft: '5px', paddingRight: '5px' }} className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-2">
                                <Form.Group>
                                    <Form.Label>Twitter:</Form.Label>
                                    <Form.Control value={socialMedia['twitter']} onChange={setSocialMediaInfo.bind(this, 'twitter')} type="text" placeholder="https://" />
                                </Form.Group>
                            </div>
                            <div style={{ paddingLeft: '5px', paddingRight: '5px' }} className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-2">
                                <Form.Group>
                                    <Form.Label>{t('QQ群')}:</Form.Label>
                                    <Form.Control value={socialMedia['qq']} onChange={setSocialMediaInfo.bind(this, 'qq')} type="text" placeholder="" />
                                </Form.Group>
                            </div>
                            <div style={{ paddingLeft: '5px', paddingRight: '5px' }} className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-2">
                                <Form.Group>
                                    <Form.Label>Facebook:</Form.Label>
                                    <Form.Control value={socialMedia['facebook']} onChange={setSocialMediaInfo.bind(this, 'facebook')} type="text" placeholder="https://" />
                                </Form.Group>
                            </div>
                            <div style={{ paddingLeft: '5px', paddingRight: '5px' }} className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-2">
                                <Form.Group>
                                    <Form.Label>Github:</Form.Label>
                                    <Form.Control value={socialMedia['github']} onChange={setSocialMediaInfo.bind(this, 'github')} type="text" placeholder="https://" />
                                </Form.Group>
                            </div>
                            <div style={{ paddingLeft: '5px', paddingRight: '5px' }} className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-2">
                                <Form.Group>
                                    <Form.Label>Discord:</Form.Label>
                                    <Form.Control value={socialMedia['discord']} onChange={setSocialMediaInfo.bind(this, 'discord')} type="text" placeholder="https://" />
                                </Form.Group>
                            </div>
                            <div style={{ paddingLeft: '5px', paddingRight: '5px' }} className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-2">
                                <Form.Group>
                                    <Form.Label>Instagram:</Form.Label>
                                    <Form.Control value={socialMedia['instagram']} onChange={setSocialMediaInfo.bind(this, 'instagram')} type="text" placeholder="https://" />
                                </Form.Group>
                            </div>
                            <div style={{ paddingLeft: '5px', paddingRight: '5px' }} className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-2">
                                <Form.Group>
                                    <Form.Label>Reddit:</Form.Label>
                                    <Form.Control value={socialMedia['reddit']} onChange={setSocialMediaInfo.bind(this, 'reddit')} type="text" placeholder="https://" />
                                </Form.Group>
                            </div>
                            <div style={{ paddingLeft: '5px', paddingRight: '5px' }} className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-2">
                                <Form.Group>
                                    <Form.Label>Mirror:</Form.Label>
                                    <Form.Control value={socialMedia['mirror']} onChange={setSocialMediaInfo.bind(this, 'mirror')} type="text" placeholder="https://" />
                                </Form.Group>
                            </div>
                            <div style={{ paddingLeft: '5px', paddingRight: '5px' }} className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-2">
                                <Form.Group>
                                    <Form.Label>Youtube:</Form.Label>
                                    <Form.Control value={socialMedia['youtube']} onChange={setSocialMediaInfo.bind(this, 'youtube')} type="text" placeholder="https://" />
                                </Form.Group>
                            </div>
                        </div>
                        {extraSocialMedia && extraSocialMedia.length > 0 && extraSocialMedia.map((item, ind) => {
                            return <div key={'extra-m-' + ind} className="row project-info-row mt-2">
                                <div className="d-flex flex-row">
                                    <Form.Control onChange={e => {
                                        let val = e.target.value
                                        let arr = [...extraSocialMedia]
                                        arr[ind].name = val
                                        console.log(arr)
                                        setExtraSocialMedia(arr)
                                    }} value={item.name} style={{ width: '30%' }} type="text" placeholder={t('字段名')} />
                                    <InputGroup>
                                        <Form.Control onChange={e => {
                                            let val = e.target.value
                                            let arr = [...extraSocialMedia]
                                            arr[ind].value = val
                                            setExtraSocialMedia(arr)
                                        }} value={item.value} style={{ width: '68%', marginLeft: '5px' }} type="text" placeholder={'字段值'} />
                                        <InputGroup.Text>
                                            <i onClick={() => {
                                                let arr = [...extraSocialMedia]
                                                arr.splice(ind, 1)
                                                setExtraSocialMedia(arr)
                                            }} style={{ cursor: 'pointer' }} className="fa fa-close"></i>
                                        </InputGroup.Text>
                                    </InputGroup>

                                </div>
                            </div>
                        })}
                        <div className="row project-info-row mt-2">
                            <Form.Group>
                                <div onClick={addExtraSocialMedia.bind(this)} className="btn btn-info">{t('添加其他社交媒体信息')}</div>
                            </Form.Group>
                        </div>
                        {errorMsg && <div className="row project-info-row mt-2">
                            <Alert variant={'danger'}>
                                {errorMsg}
                            </Alert>
                        </div>}
                        <div className="row project-info-row mt-4">
                            <Form.Group>
                                <Button onClick={saveInfo.bind(this)} style={{ color: '#ffffff' }} variant={'primary'}>
                                    {loading ? t('保存中...') : t('保存')}
                                </Button>
                                <Button onClick={() => {
                                    props.handleClose && props.handleClose()
                                }} style={{ color: '#ffffff', marginLeft: '5px' }} variant={'danger'}>{t('取消')}</Button>
                            </Form.Group>
                        </div>
                    </Form>

                </div>
            </div>
        </Modal.Body>
    </Modal>
}