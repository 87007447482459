import Network from "./network";


export const LoginGetNonce = (params) => {
    // 获取代币信息
    return Network.request({
        url: '/login',
        method: 'get',
        params
    })
}

export const AuthSignature = (data) => {
    // 获取代币信息
    return Network.request({
        url: '/auth',
        method: 'post',
        data
    })
}

export const getProfile = () => {
    return Network.request({
        url: '/profile',
        method: 'get',
    })
}
export const getBindTgCode = () => {
    return Network.request({
        url: '/account/notification-bind/tg-code',
        method: 'get'
    })
}

export const getNotificationTypeList = () => {
    return Network.request({
        url: '/account/notification-setting',
        method: 'get'
    })
}

export const unbindTelegramNotificationType = (data) => {
    return Network.request({
        url: '/account/notification-bind/tg-unbind',
        method: 'post',
        data
    })
}

export const getTaskRecordList = (params) => {
    return Network.request({
        url: '/account/monitor/task/records',
        method: 'get',
        params
    })
}

export const getTaskNotifications = (params) => {
    return Network.request({
        url: '/account/monitor/tasks',
        method: 'get',
        params
    })
}

export const getAllTaskNotifications = (params) => {
    // 获取所有的通知报警任务  
    return Network.request({
        url: '/account/monitor/tasks/all',
        method: 'get',
        params
    })
}

export const getAllDappCates = (params) => {
    return Network.request({
        url: '/dapps/all',
        method: 'get',
        params
    })
}