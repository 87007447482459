import React from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { isMobile } from '../../utils/tools';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from 'react-i18next';

const home = (
    <Tooltip id="home">
        Home
    </Tooltip>
);
const check = (
    <Tooltip id="check">
        Check
    </Tooltip>
)
const watcher = (
    <Tooltip id="watcher">
        Monitor
    </Tooltip>
)
const dapps = (
    <Tooltip id="dapps">
        Swap
    </Tooltip>
);
const settings = (
    <Tooltip id="settings">
        Setting
    </Tooltip>
);

function Sidebar() {
    const { t } = useTranslation()
    const { pathname } = useLocation()
    const style = { background: '#5d78ff' }
    return (
        <>
            {isMobile() === false ? <div className="sidebar">
                <div className="menu">
                    <ul>
                        <li>
                            <Link to={"/"} style={pathname === '/' ? style : {}} activeClassName="active">
                                <OverlayTrigger placement="right" overlay={home}>
                                    <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}><i className="mdi mdi-view-dashboard"></i> </span>
                                </OverlayTrigger>
                            </Link>
                        </li>
                        <li>
                            <Link to={"/check"} style={pathname === '/check' ? style : {}} activeClassName="active">
                                <OverlayTrigger placement="right" overlay={check}>
                                    {/* <span><i className="mdi mdi-crosshairs-gps"></i>  </span> */}
                                    <span><i className="mdi mdi-security"></i>  </span>
                                </OverlayTrigger>
                            </Link>
                        </li>
                        <li>
                            <Link to={"/monitors"} style={pathname === '/monitors' ? style : {}} activeClassName="active">
                                <OverlayTrigger placement="right" overlay={watcher}>
                                    <span><i className="mdi mdi-monitor-multiple"></i></span>
                                </OverlayTrigger>
                            </Link>
                        </li>
                        <li>
                            <Link to={"/swap"} style={pathname === '/swap' ? style : {}} activeClassName="active">
                                <OverlayTrigger placement="right" overlay={dapps}>
                                    {/* <span><i className="mdi mdi-apps"></i></span> */}
                                    <span><i className="mdi mdi-swap-horizontal"></i></span>
                                </OverlayTrigger>
                            </Link>
                        </li>
                        <li>
                            <Link to={"/settings"} style={pathname === '/settings' ? style : {}} activeClassName="active">
                                <OverlayTrigger placement="right" overlay={settings}>
                                    <span><i className="mdi mdi-settings"></i></span>
                                </OverlayTrigger>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div> : <div className='footer footer-menu'>
                <ul className='footer-menu-ul'>
                    <li>
                        <Link to={"/"} style={pathname === '/' ? style : {}} activeClassName="active">
                            <OverlayTrigger placement="top" overlay={home}>
                                <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}><i className="mdi mdi-view-dashboard"></i> </span>
                            </OverlayTrigger>
                        </Link>
                    </li>
                    <li>
                        <Link to={"/check"} style={pathname === '/check' ? style : {}} activeClassName="active">
                            <OverlayTrigger placement="top" overlay={check}>
                                {/* <span><i className="mdi mdi-crosshairs-gps"></i>  </span> */}
                                <span><i className="mdi mdi-security"></i>  </span>
                            </OverlayTrigger>
                        </Link>
                    </li>
                    <li>
                        <Link to={"/monitors"} style={pathname === '/monitors' ? style : {}} activeClassName="active">
                            <OverlayTrigger placement="top" overlay={watcher}>
                                <span><i className="mdi mdi-monitor-multiple"></i></span>
                            </OverlayTrigger>
                        </Link>
                    </li>

                    <li>
                        {/* <Link to={"/dapps"} style={pathname === '/dapps' ? style : {}} activeClassName="active">
                            <OverlayTrigger placement="top" overlay={dapps}>
                                <span><i className="mdi mdi-apps"></i></span>
                            </OverlayTrigger>
                        </Link> */}
                        <Link to={"/swap"} style={pathname === '/swap' ? style : {}} activeClassName="active">
                            <OverlayTrigger placement="right" overlay={dapps}>
                                {/* <span><i className="mdi mdi-apps"></i></span> */}
                                <span><i className="mdi mdi-swap-horizontal"></i></span>
                            </OverlayTrigger>
                        </Link>
                    </li>
                    <li>
                        <Link to={"/settings"} onClick={e => {
                            e.nativeEvent.stopImmediatePropagation()
                        }} style={pathname === '/settings' ? style : {}} activeClassName="active">
                            <OverlayTrigger placement="top" overlay={settings}>
                                <span><i className="mdi mdi-settings"></i></span>
                            </OverlayTrigger>
                        </Link>
                    </li>
                </ul>
            </div>}
        </>
    )
}

export default Sidebar;