import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Modem } from "react-bootstrap-icons";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import { addTokenOptionGroup, addTokenOptionRecord, deleteTokenOptionGroup } from "../../../api/token";
import { AddUserEoaOptionGroup, AddUserEoaOptionToGroup, DeleteUserEoaOptionGroup } from "../../../api/wallet";
import { useTranslation } from "react-i18next";

export default function EoaOptionGroupModal(props) {

    const [addGroup, setAddGroup] = useState(false) // 正在添加分组
    const [tmpInput, setTmpInput] = useState('') // 临时输入的分组名称
    const [loading, setLoading] = useState(false) // 是否正在添加中
    const [currentId, setCurrentId] = useState(0) // 当前选中的分组id
    const [deleteRow, setDeleteRow] = useState(null) // 要删除的分组id, 设置后会弹出确认框
    const [tmpWallet, setTmpWallet] = useState('') // 临时输入的钱包地址
    const [tmpAlias, setTmpAlias] = useState('') // 输入备注
    const { t } = useTranslation()
    useEffect(() => {
        if (currentId === 0 && props.list && props.list.length > 0) {
            setCurrentId(props.list[0].id)
        }
    }, [props.list])

    useEffect(() => {
        setTmpWallet(props.default || '')
    }, [props.default])

    const addOptionGroup = async () => {
        if (!tmpInput) {
            toast.error(t('请输入分组名称'))
            return
        }
        setLoading(true)
        const tmpT = tmpInput
        try {
            const { data } = await AddUserEoaOptionGroup({
                name: tmpInput
            })
            if (data.code === 200) {
                toast.success(t('添加成功'))
                setTmpInput('')
                setAddGroup(false)
                props.ok && props.ok(tmpT)
            } else {
                toast.error(data.msg || t('添加失败'))
            }
        } catch (e) {
            toast.error(t('添加失败'))
        }
        setLoading(false)

    }

    return <Modal centered contentClassName="search-modal" show={props.show} onHide={() => {
        props.handleClose && props.handleClose()
    }} >
        {deleteRow?.id ?
            <div>
                <Modal.Body>
                    <div>
                        <div className="login-header">{t('确认删除分组?')}</div>
                    </div>
                    <div>
                        {t('确认删除分组吗? 将会删除该分组以及该分组下所有的钱包地址').replace('{name}', deleteRow.name)}
                    </div>
                    <div className="mt-2">
                        <div className="btn btn-primary text-light" onClick={async () => {
                            if (loading) return
                            if (!deleteRow.id) {
                                return
                            }
                            setLoading(true)
                            try {
                                const { data } = await DeleteUserEoaOptionGroup({
                                    option_id: deleteRow.id + ''
                                })
                                if (data.code === 200) {
                                    toast.success(t('删除成功'))
                                    if (currentId === deleteRow.id && props.list && props.list.length > 0) {
                                        setCurrentId(props.list[0].id)
                                    }
                                    setDeleteRow({})
                                    props.ok && props.ok()
                                } else {
                                    toast.error(data.msg || t('删除失败'))
                                }

                            } catch (e) {
                                toast.error(t('删除失败'))
                            }
                            setLoading(false)
                        }}>{t('确定')}</div>
                        <div onClick={() => {
                            setDeleteRow({})
                        }} style={{ marginLeft: '10px' }} className="btn btn-danger" >{t('取消')}</div>
                    </div>
                </Modal.Body>
            </div>
            : <Modal.Body>
                <div>
                    <div className="login-header">{t('添加观察钱包')}</div>
                </div>
                <div>
                    {props.list && props.list.map((item, ind) => {
                        return <div onClick={() => {
                            setCurrentId(item.id)
                            console.log(item.id)
                        }} key={'op-t-' + item.name + ind} className="option-list-item">
                            <div className="d-flex flex-row">
                                <div style={{ marginRight: '10px', width: '20px' }}>
                                    {item.id === currentId && <span>   <i className="fa fa-check"></i></span>}
                                </div>
                                {item.name || t('默认')}
                            </div>
                            {item.name && <span onClick={() => {
                                setDeleteRow(item)
                            }} > <i className="fa fa-close"></i> </span>}
                        </div>
                    })}
                    {addGroup ? <div>
                        <InputGroup>
                            <Form.Control onChange={e => {
                                setTmpInput(e.target.value)
                            }} value={tmpInput} type="text" placeholder={t('输入分组名称')} />
                            <InputGroup.Text>
                                <span onClick={addOptionGroup.bind(this)} className="btn btn-outline-primary btn-sm">
                                    {t('确认')}
                                </span>
                                <span onClick={() => {
                                    setAddGroup(false)
                                    setTmpInput('')
                                }} style={{ marginLeft: '5px' }} className="btn btn-outline-danger btn-sm">
                                    {t('取消')}
                                </span>

                            </InputGroup.Text>
                        </InputGroup>
                    </div> : <div onClick={() => {
                        setAddGroup(true)
                    }} style={{ width: '100%' }} className="btn btn-outline-primary btn-sm">
                        + {t('添加分组')}
                    </div>}
                </div>
                <div className="mt-2">
                    <Form.Control onChange={e => {
                        setTmpWallet(e.target.value)
                    }} value={tmpWallet} type="text" placeholder={t('输入钱包地址')} />

                </div>
                <div className="mt-2">
                    <Form.Control onChange={e => {
                        setTmpAlias(e.target.value)
                    }} value={tmpAlias} type="text" placeholder={t('输入备注')} />

                </div>
                <div className="mt-2">
                    <div className="btn btn-primary text-light" onClick={async () => {
                        if (loading) return
                        if (!currentId) {
                            toast.error(t('请选择分组'))
                            return
                        }
                        const x = props.list.find(item => item.id === currentId)
                        if (!x) {
                            toast.error(t('请选择分组'))
                            return
                        }

                        if (!tmpWallet) {
                            toast.error(t('请输入钱包地址'))
                            return
                        }

                        setLoading(true)
                        try {
                            const t = tmpWallet
                            const { data } = await AddUserEoaOptionToGroup({
                                chain_id: props.chainId + '',
                                wallet: tmpWallet,
                                option_id: currentId + '',
                                alias: tmpAlias || ''

                            })
                            if (data.code === 200) {
                                toast.success(t('添加成功'))
                                props.updateAll && props.updateAll(t)
                                props.handleClose && props.handleClose()
                            } else {
                                toast.error(data.msg || t('添加失败'))
                            }
                        } catch (e) {
                            toast.error(t('添加失败'))
                        }
                        setLoading(false)
                        setTmpWallet('')


                    }}>{t('确定')}</div>
                    <div style={{ marginLeft: '10px' }} className="btn btn-danger" onClick={() => {
                        props.handleClose && props.handleClose()
                    }}>{t('取消')}</div>
                </div>
            </Modal.Body>}
    </Modal>
}