import Network from "./network";


// 获取用户观察钱包的分组信息
export const GetUserEoaOptionGroupList = (params) => {
    return Network.request({
        url: '/user/eoa-options/groups',
        method: 'get',
        params
    })
}

// 根据用户观察钱包分组id获取观察钱包列表
export const GetUserEoaOptionList = (params) => {
    return Network.request({
        url: '/user/eoa-options/list',
        method: 'get',
        params
    })
}

// 添加分组
export const AddUserEoaOptionGroup = (data) => {
    return Network.request({
        url: '/user/eoa-options/group/add',
        method: 'post',
        data
    })
}
// 删除分组
export const DeleteUserEoaOptionGroup = (data) => {
    return Network.request({
        url: '/user/eoa-options/group/del',
        method: 'post',
        data
    })
}
// 向分组里添加观察钱包
export const AddUserEoaOptionToGroup = (data) => {
    return Network.request({
        url: '/user/eoa-options/add',
        method: 'post',
        data
    })
}
// 从分组里移除观察钱包
export const DeleteUserEoaOptionFromGroup = (data) => {
    return Network.request({
        url: '/user/eoa-options/del',
        method: 'post',
        data
    })
}

