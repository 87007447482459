import React, { useEffect, useRef, useState } from "react";
import { createChart, CrosshairMode, TrackingModeExitMode } from 'lightweight-charts';
import { getTokenKline } from "../../api/token";
import { converToNumberString, convertToMaxUnit, eventBus, formatTimeString } from "../../utils/tools";
import { sma, bollingerbands, ema, stochastic, rsi } from 'technicalindicators'
import { set } from "date-fns";
import store from "../../store";
import { useTranslation } from "react-i18next";


export const SpinerLoading = (props) => {
    return <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
    </div>
}

export const TimeCountDown = (props) => {
    const [countdownInfo, setCountdownInfo] = useState({
        day: '00',
        hour: '00',
        minute: '00',
        second: '00',
    })
    const [time, setTime] = useState(0) // 倒计时的时间戳
    useEffect(() => {
        setTime(props.time)
    }, [props.time])

    const currentTimer = useRef(null)
    const currentTime = useRef(0) // 当前的临时时间, 设置为0

    useEffect(() => {
        const stopTimer = () => {
            if (currentTimer.current) {
                clearInterval(currentTimer.current)
                currentTimer.current = null
                currentTime.current = 0
            }
        }
        // 根据传入的时间戳, 计算出倒计时

        stopTimer()
        if (time <= 0) {
            setCountdownInfo({
                day: '00',
                hour: '00',
                minute: '00',
                second: '00',
            })
            return
        }
        currentTime.current = time
        currentTimer.current = setInterval(() => {
            const now = Date.parse(new Date()) / 1000
            const diff = time - now
            if (diff <= 0) {
                stopTimer()
                // 达到倒计时时间
                props.countdownEnd && props.countdownEnd()
                return
            }
            let day = Math.floor(diff / (24 * 60 * 60))
            let hour = Math.floor((diff - day * 24 * 60 * 60) / (60 * 60))
            let minute = Math.floor((diff - day * 24 * 60 * 60 - hour * 60 * 60) / 60)
            let second = Math.floor(diff - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60)
            if (day < 10) {
                day = '0' + day
            }
            if (hour < 10) {
                hour = '0' + hour
            }
            if (minute < 10) {
                minute = '0' + minute
            }
            if (second < 10) {
                second = '0' + second
            }
            setCountdownInfo({
                day: day + '',
                hour: hour + '',
                minute: minute + '',
                second: second + ''
            })


        }, 1000);


    }, [time])

    // 根据传入的数据, 来进行倒计时

    return <div className='tv-mask-countdown'>
        <div className='tv-mask-countdown-item'>{countdownInfo.day}</div>
        <div className='tv-mask-countdown-slide'>:</div>
        <div className='tv-mask-countdown-item'>{countdownInfo.hour}</div>
        <div className='tv-mask-countdown-slide'>:</div>
        <div className='tv-mask-countdown-item'>{countdownInfo.minute}</div>
        <div className='tv-mask-countdown-slide'>:</div>
        <div className='tv-mask-countdown-item'>{countdownInfo.second}</div>
    </div>
}

const timeFrames = [
    { text: '1m', window: 1, interval: '1m', zh_text: '1分' },
    { text: '3m', window: 3, interval: '3m', zh_text: '3分' },
    { text: '5m', window: 5, interval: '5m', zh_text: '5分' },
    { text: '10m', window: 10, interval: '10m', zh_text: '10分' },
    { text: '15m', window: 15, interval: '15m', zh_text: '15分' },
    { text: '30m', window: 30, interval: '30m', zh_text: '30分' },
    { text: '1h', window: 60, interval: '1h', zh_text: '1时' },
    { text: '4h', window: 240, interval: '4h', zh_text: '4时' },
    { text: '1d', window: 1440, interval: '1d', zh_text: '1天' },
]

const defautlConfig = {
    // width: 400,
    height: 390,
    pane: 0,
    watermark: {
        visible: true,
        text: 'TMDex.org',
        fontSize: '40',
        color: "rgba(200,200,200,0.2)",
        horzAlign: 'left',
        vertAlign: 'bottom'
    },
    layout: {
        background: {
            type: 'solid',
            color: 'rgb(20,23,34)',
        },
        textColor: 'rgba(255, 255, 255, 0.9)',
    },
    grid: {
        vertLines: {
            color: 'rgba(200,200,200,0.1)'
        },
        horzLines: {
            color: 'rgba(200,200,200,0.1)'
        },
    },
    leftPriceScale: {
        // visible: true,
        color: '#ffffff'
    },
    rightPriceScale: {
        visible: true,
        ticksVisible: true,
        textColor: 'rgba(197, 203, 206, 1)',
        borderColor: 'rgba(197, 203, 206, 0.4)',
    },
    crosshair: {
        horzLine: {
            visible: true,
        }
    },
    handleScroll: {
        mouseWheel: true,
        pressedMouseMove: true,
        horzTouchDrag: true,
        // vertTouchDrag: true,
    },
    scaleMargins: {
        top: 0,
        bottom: 0.25
    },
    timeScale: {
        timeVisible: true,
        tickMarkFormatter: (time) => {
            return formatTimeString(time * 1000, false, true, true)
        }
    },
    localization: {
        priceFormatter: function (price) {
            return converToNumberString(price)
        },
        timeFormatter: function (time) {
            return formatTimeString(time * 1000, false, true)
        }

    }
}

const candlestickConfig = {
    upColor: '#27A69A',
    downColor: '#EF534F',
    borderDownColor: '#EF534F',
    borderUpColor: '#27A69A',
    wickDownColor: '#EF534F',
    wickUpColor: '#27A69A',
}

export default function MobileChartContainer(props) {
    const [selectWindow, setSelectWindow] = useState(timeFrames[0]) // 1h, 1d, 1w, 1m, 1y
    const [currentDatafeed, setCurrentDatafeed] = useState(null) // 当前的数据源
    const [showKlineDetail, setShowKlineDetail] = useState(false) // 展示k线悬浮框
    const [chartBoxPositionClass, setChartBoxPositionClass] = useState('') // k线悬浮框的位置, ['mobile-chart-candle-info-box-top-right'
    const [klineDetail, setKlineDetail] = useState(null) // k线悬浮框的数据

    const [selectIndicator, setSelectIndicator] = useState('') // 当前选中的指标
    const [selectPaneIndicator, setSelectPaneIndicator] = useState('vol') // 选中的底部指标: vol macd kd rsi
    const [currentChartData, setCurrentChartData] = useState([]) // 当前的数据

    const [firstIndicatorMessage, setFirstIndicatorMessage] = useState([]) // 第一行指标的提示信息 [{text:'aaa','color:''}]
    const [secondIndicatorMessage, setSecondIndicatorMessage] = useState([]) // 第二行指标的提示信息
    const [selectedTime, setSelectedTime] = useState(0) // 当前选中的时间戳 (根据这个时间戳, 来算上面的提示信息)
    const [showLoading, setShowLoading] = useState(false) // 是否显示loading
    const [showOpenTime, setShowOpenTime] = useState(false) // 是否显示开盘时间

    const { t } = useTranslation()
    const containerRef = useRef(null);
    const chartInstance = useRef(null)
    const klineParams = useRef({})
    const tmpSeriesInfo = useRef({}) // 暂存的series信息
    const currentChartDataRef = useRef([]) // 暂存的series信息
    const volumeSeriesInstance = useRef({
        'up': null, // 上涨柱子
        'down': null, // 下跌柱子
    })
    const lineSeriesInstance = useRef({
        'upper': null,
        'middle': null,
        'lower': null,
        'ma7': null,
        'ma25': null,
        'ma99': null,
        'ema7': null,
        'ema25': null,
        'ema99': null,
    })

    const candleStickSeriesInstance = useRef(null)
    const datafeedCache = useRef(null)
    const barsCache = useRef({})

    const showKlineDetailLogic = (time, x) => {
        // 传入时间和x坐标, 计算出悬浮框的位置
        // 展示悬浮框
        // 如果点击的是左侧, 那么位置在右侧
        // 如果点击的是右侧, 那么悬浮框位置在左侧
        if (!barsCache.current[time]) {
            setShowKlineDetail(false)
            setKlineDetail(null)
            return
        }



        let positionClass = 'mobile-chart-candle-info-box-top-left'
        if (x < containerRef.current.offsetWidth / 2) {
            positionClass = 'mobile-chart-candle-info-box-top-right'
        }
        setChartBoxPositionClass(positionClass)
        setShowKlineDetail(true)
        setKlineDetail(barsCache.current[time])

    }
    useEffect(() => {
        const chart = createChart(containerRef.current, defautlConfig);

        function myCrosshairMoveHandler(param) {
            if (!param.point) {
                return;
            }
            // 显示悬浮框
            setSelectedTime(param.time)
            showKlineDetailLogic(param.time, param.point.x)
        }
        chart.subscribeCrosshairMove(myCrosshairMoveHandler)
        chartInstance.current = chart

        return () => {
            if (chartInstance.current) {
                chartInstance.current.unsubscribeCrosshairMove(myCrosshairMoveHandler)
                chartInstance.current.remove()
                chartInstance.current = null
            }
            // 组件移除时取消订阅过的所有信息
            clearAllSubscription()
        }
    }, [])

    useEffect(() => {
        // 根据datafeed 创建
        if (!props.datafeed.pair) return
        if (!props.datafeed.chain_id) return


        setCurrentDatafeed(props.datafeed)

    }, [props.datafeed])


    useEffect(() => {
        eventBus.addListener('pageShow', handlePageShowEvent)
        return () => {
            eventBus.removeListener('pageShow', handlePageShowEvent)
        }
    }, [currentDatafeed])

    const handlePageShowEvent = () => {
        setTimeout(() => {
            if (!document.hidden) {
                refreshSubscription()
                generateKline()
            }
        }, 0);
    }


    const refreshSubscription = () => {
        // 先取消掉其他的订阅
        clearAllSubscription()
        // 重新订阅K线/价格更新
        if (currentDatafeed?.pair && currentDatafeed?.currency && selectWindow?.interval) {
            eventBus.emit('subscribeKline', {
                pair: currentDatafeed.pair,
                currency: currentDatafeed.currency,
                window: selectWindow.interval,
            })
        }

        eventBus.addListener('klineUpdate', handleKlineUpdateEvent.bind(this))
        eventBus.addListener('priceUpdate', handlePriceUpdateEvent.bind(this))
        eventBus.addListener('marksUpdate', handleMarksUpdateEvent.bind(this))
    }



    const clearAllSubscription = () => {
        eventBus.removeListener('klineUpdate', handleKlineUpdateEvent.bind(this))
        eventBus.removeListener('priceUpdate', handlePriceUpdateEvent.bind(this))
        eventBus.removeListener('marksUpdate', handleMarksUpdateEvent.bind(this))
    }
    const getStoreKey = () => {
        return `${currentDatafeed.pair}-${currentDatafeed.currency}`
    }
    const handleMarksUpdateEvent = (d) => {
        const sk = getStoreKey()
        store.dispatch({
            type: 'setGetMarksRecords',
            value: {
                [sk]: true
            }
        })
        // 处理标记更新
        const { key, info } = d
        if (sk === key) {
            // 设置标记
            candleStickSeriesInstance.current && candleStickSeriesInstance.current.setMarkers([])
            if (info) {
                const list = info.map((item, ind) => {
                    return {
                        id: ind + 1,
                        time: item.time,
                        position: 'aboveBar',
                        color: item.type === 1 ? 'yellow' : 'red',
                        shape: 'arrowDown',
                        text: item.type === 1 ? 'Buy' : 'Sell',
                    }
                })
                candleStickSeriesInstance.current.setMarkers(list)
            }


        }
    }

    const handlePriceUpdateEvent = (d) => {

        if (d.token !== currentDatafeed.currency.toLowerCase() && d.pair === currentDatafeed.pair.toLowerCase()) {
            if (currentChartDataRef.current && currentChartDataRef.current.length > 0) {
                const c = {
                    ...currentChartDataRef.current[currentChartDataRef.current.length - 1]
                }
                c.close = Number(d.price)
                if (c.close < c.low) {
                    c.low = c.close
                }
                if (c.close > c.high) {
                    c.high = c.close
                }
                const tmp = [...currentChartDataRef.current]
                tmp[tmp.length - 1] = c
                setCurrentChartData(tmp)
                currentChartDataRef.current = tmp
                candleStickSeriesInstance.current.update(c);
            }
            if (currentChartDataRef.current && currentChartDataRef.current.length === 0) {
                // 这是第一笔交易, 给定k线和开盘价
                const price = Number(d.price)
                const tmp = {
                    high: price,
                    low: price,
                    open: price,
                    close: price,
                    volume: 0,
                    time: parseInt(d.time / 1000)
                }
                refreshCandleStickSeries([tmp])
                setCurrentChartData([tmp])
                currentChartDataRef.current = [tmp]
                console.log('当前k线设置为', tmp)
            }
        }
    }

    const handleKlineUpdateEvent = (d) => {
        if (d.currency === currentDatafeed.currency.toLowerCase() && d.pair === currentDatafeed.pair.toLowerCase() && d.window === selectWindow.interval) {
            setTimeout(async () => {
                const { data } = await getTokenKline({
                    ...klineParams.current, // 参数
                    to: Date.parse(new Date()) / 1000,
                    count: 3
                })
                if (data.code === 200 && data.data) {
                    data.data.forEach(item => {
                        barsCache.current[formatTime(item.time)] = item
                    })
                    // 将结果合并到现有的k线里, 如果有重复的time, 则替换掉
                    const tmp = [...currentChartDataRef.current]
                    const tmpMap = {}
                    tmp.forEach(item => {
                        tmpMap[item.time] = item
                    })
                    const resList = data.data.map((item) => {
                        return {
                            time: formatTime(item.time),
                            open: item.open,
                            high: item.high,
                            low: item.low,
                            close: item.close,
                            volume: Number(item.volume)
                        }
                    }).sort(function (item1, item2) {
                        return item1.time - item2.time
                    })

                    resList.forEach(item => {
                        tmpMap[item.time] = item
                    })
                    const tmpList = []
                    for (let key in tmpMap) {
                        tmpList.push(tmpMap[key])
                    }
                    tmpList.sort((a, b) => {
                        return a.time - b.time
                    })
                    // 让当前柱子的开盘价等于上一个柱子的收盘价
                    for (let i = 0; i < tmpList.length; i++) {
                        if (i === 0) continue
                        tmpList[i].open = tmpList[i - 1].close
                    }
                    // 更新数据
                    setCurrentChartData(tmpList)
                    currentChartDataRef.current = tmpList

                    // 更新图表
                    candleStickSeriesInstance.current && candleStickSeriesInstance.current.setData(tmpList)
                    refreshCandleStickSeries(tmpList)


                }
            }, 500);


        }
    }

    const clearCrossHair = () => {
        if (chartInstance.current) {
            chartInstance.current.setCrossHairXY(0, 0, false);
        }
        setChartBoxPositionClass('')
        setShowKlineDetail(false)
        setKlineDetail(null)
        setSelectedTime(0)
    }
    const clearLineSeries = () => {
        clearCrossHair()
        for (let key in lineSeriesInstance.current) {
            if (lineSeriesInstance.current[key]) {
                chartInstance.current.removeSeries(lineSeriesInstance.current[key])
                lineSeriesInstance.current[key] = null
            }
        }
    }
    const defaultLineSeriesConfig = {
        color: 'rgb(242,186,13)',
        lineWidth: 1,
        lineStyle: 0,
        crosshairMarkerVisible: false,
        crosshairMarkerRadius: 0,
        crosshairMarkerLineStyle: 0,
        crosshairMarkerLineWidth: 0,
        lastValueVisible: false,
        priceLineVisible: false,
        baseLineVisible: false,
        scaleMargins: {
            top: 0.07,
            bottom: 0.2,
        },
    }
    useEffect(() => {

        clearLineSeries()
        if (selectIndicator === 'boll') {
            // 计算布林带
            const input = {
                period: 21,
                values: currentChartData.map(item => Number(item.close)),
                stdDev: 2
            }
            const a = bollingerbands(input)
            let upper = []
            let middle = []
            let lower = []
            let ind = 0
            for (let i = a.length - 1; i > 0; i--) {
                if (!currentChartData[currentChartData.length - 1 - ind]) break
                upper.push({
                    value: Number(a[i].upper),
                    time: currentChartData[currentChartData.length - 1 - ind].time
                })
                middle.push({
                    value: Number(a[i].middle),
                    time: currentChartData[currentChartData.length - 1 - ind].time
                })
                lower.push({
                    value: Number(a[i].lower),
                    time: currentChartData[currentChartData.length - 1 - ind].time
                })
                ind += 1
            }

            upper.reverse()
            middle.reverse()
            lower.reverse()
            // 给图表上添加三条线
            const upperSeries = chartInstance.current.addLineSeries({
                ...defaultLineSeriesConfig,
                priceScaleId: 'main',
            })
            const middleSeries = chartInstance.current.addLineSeries({
                ...defaultLineSeriesConfig,
                color: 'rgb(230,66,179)',
                priceScaleId: 'main',
            })
            const downSeries = chartInstance.current.addLineSeries({
                ...defaultLineSeriesConfig,
                color: 'rgb(137,104,195)',
                priceScaleId: 'main',
            })
            upperSeries.setData(upper)
            middleSeries.setData(middle)
            downSeries.setData(lower)
            tmpSeriesInfo.current['boll_upper'] = upper
            tmpSeriesInfo.current['boll_middle'] = middle
            tmpSeriesInfo.current['boll_lower'] = lower

            lineSeriesInstance.current['upper'] = upperSeries
            lineSeriesInstance.current['middle'] = middleSeries
            lineSeriesInstance.current['lower'] = downSeries
        }
        if (selectIndicator === 'ma') {
            const input = {
                period: 7,
                values: currentChartData.map(item => Number(item.close)),
            }
            const a = sma(input)
            const a25 = sma({
                ...input,
                period: 25
            })
            const a99 = sma({
                ...input,
                period: 99
            })

            const generateListLogic = (maList) => {
                const mm = []
                let ind = 0
                for (let i = maList.length - 1; i > 0; i--) {
                    if (!currentChartData[currentChartData.length - 1 - ind]) break
                    mm.push({
                        value: Number(a[i]),
                        time: currentChartData[currentChartData.length - 1 - ind].time
                    })
                    ind += 1
                }
                mm.reverse()
                return mm
            }
            const ma7 = generateListLogic(a)
            const ma25 = generateListLogic(a25)
            const ma99 = generateListLogic(a99)

            const ma7Series = chartInstance.current.addLineSeries({
                ...defaultLineSeriesConfig,
                priceScaleId: 'main'
                // title: 'MA7'
            })
            ma7Series.setData(ma7)
            tmpSeriesInfo.current['ma7'] = ma7
            lineSeriesInstance.current['ma7'] = ma7Series

            const ma25Series = chartInstance.current.addLineSeries({
                ...defaultLineSeriesConfig,
                // title: 'MA25',
                color: 'rgb(230,66,179)',
                priceScaleId: 'main'
            })
            ma25Series.setData(ma25)
            tmpSeriesInfo.current['ma25'] = ma25
            lineSeriesInstance.current['ma25'] = ma25Series

            const ma99Series = chartInstance.current.addLineSeries({
                ...defaultLineSeriesConfig,
                // title: 'MA99',
                color: 'rgb(137,104,195)',
                priceScaleId: 'main'
            })
            ma99Series.setData(ma99)
            tmpSeriesInfo.current['ma99'] = ma99
            lineSeriesInstance.current['ma99'] = ma99Series

        }
        if (selectIndicator === 'ema') {
            const input = {
                period: 7,
                values: currentChartData.map(item => Number(item.close)),
            }
            const a = ema(input)
            const a25 = ema({
                ...input,
                period: 25
            })
            const a99 = ema({
                ...input,
                period: 99
            })

            const generateListLogic = (maList) => {
                const mm = []
                let ind = 0
                for (let i = maList.length - 1; i > 0; i--) {
                    if (!currentChartData[currentChartData.length - 1 - ind]) break
                    mm.push({
                        value: Number(a[i]),
                        time: currentChartData[currentChartData.length - 1 - ind].time
                    })
                    ind += 1
                }
                mm.reverse()
                return mm
            }
            const ema7 = generateListLogic(a)
            const ema25 = generateListLogic(a25)
            const ema99 = generateListLogic(a99)

            const ema7Series = chartInstance.current.addLineSeries({
                ...defaultLineSeriesConfig,
                // title: 'EMA7'
                priceScaleId: 'main'
            })
            ema7Series.setData(ema7)
            tmpSeriesInfo.current['ema7'] = ema7
            lineSeriesInstance.current['ema7'] = ema7Series

            const ema25Series = chartInstance.current.addLineSeries({
                ...defaultLineSeriesConfig,
                // title: 'EMA25',
                color: 'rgb(230,66,179)',
                priceScaleId: 'main'
            })
            ema25Series.setData(ema25)
            tmpSeriesInfo.current['ema25'] = ema25
            lineSeriesInstance.current['ema25'] = ema25Series

            const ema99Series = chartInstance.current.addLineSeries({
                ...defaultLineSeriesConfig,
                color: 'rgb(137,104,195)',
                priceScaleId: 'main'
                // title: 'EMA99',

            })
            ema99Series.setData(ema99)
            tmpSeriesInfo.current['ema99'] = ema99
            lineSeriesInstance.current['ema99'] = ema99Series

        }

        refreshIndicatorMessage()
    }, [selectIndicator, currentChartData])

    useEffect(() => {
        if (JSON.stringify(currentDatafeed) === JSON.stringify(datafeedCache.current)) return
        datafeedCache.current = currentDatafeed

        clearCrossHair()

        // 订阅对应的数据, 并且设置到图表上
        // 订阅k线、订阅价格更新
        refreshSubscription()

        // 获取列表 + 设置到图表上
        generateKline()
        const sk = getStoreKey()
        store.dispatch({
            type: 'setGetMarksRecords',
            value: {
                [sk]: true
            }
        })
    }, [currentDatafeed])

    const clearPaneIndicator = () => {
        // 清空
        clearCrossHair()
        for (let key in volumeSeriesInstance.current) {
            if (volumeSeriesInstance.current[key]) {
                chartInstance.current.removeSeries(volumeSeriesInstance.current[key])
                volumeSeriesInstance.current[key] = null
            }
        }
    }

    const refreshChartPane = async () => {
        clearPaneIndicator()
        if (selectPaneIndicator === 'vol') {
            // 上涨
            const up = currentChartData.filter(item => {
                return Number(item.close) >= Number(item.open)
            }).map(item => {
                return {
                    time: item.time,
                    value: Number(item.volume)
                }
            })
            // 下跌
            const down = currentChartData.filter(item => {
                return Number(item.close) < Number(item.open)
            }).map(item => {
                return {
                    time: item.time,
                    value: Number(item.volume)
                }
            })


            const defaultVolumeConfig = {
                color: 'rgb(44,190,135)',
                priceFormat: {
                    type: 'volume',
                },
                crosshairMarkerVisible: false,
                crosshairMarkerRadius: 0,
                crosshairMarkerLineStyle: 0,
                crosshairMarkerLineWidth: 0,
                lastValueVisible: false,
                priceLineVisible: false,
                priceScaleId: 'volume',
                scaleMargins: {
                    top: 0.8,
                    bottom: 0,
                },
            }

            volumeSeriesInstance.current['up'] = chartInstance.current.addHistogramSeries(defaultVolumeConfig)
            volumeSeriesInstance.current['down'] = chartInstance.current.addHistogramSeries({
                ...defaultVolumeConfig,
                color: 'rgb(246,71,92)',
            })

            volumeSeriesInstance.current['up'].setData(up)
            volumeSeriesInstance.current['down'].setData(down)
            tmpSeriesInfo.current['volume_up'] = up
            tmpSeriesInfo.current['volume_down'] = down

        }

        if (selectPaneIndicator === 'macd') {
            const values = currentChartData.map(item => Number(item.close))

            const generateListLogic = (maList, cDataList) => {
                const mm = []
                let ind = 0
                for (let i = maList.length - 1; i > 0; i--) {
                    if (!cDataList[cDataList.length - 1 - ind]) break
                    mm.push({
                        value: Number(maList[i]),
                        time: cDataList[cDataList.length - 1 - ind].time
                    })
                    ind += 1
                }
                mm.reverse()
                return mm
            }

            // 计算diff 和 dea
            const ema12 = generateListLogic(ema({
                period: 12,
                values,
            }), currentChartData)
            const ema26 = generateListLogic(ema({
                period: 26,
                values,
            }), currentChartData) // {time: value}

            // 根据时间对应上
            let ema26Map = {}
            ema26.forEach(item => {
                ema26Map[item.time] = item.value
            })
            const diff = []
            for (let i = 0; i < ema12.length; i++) {
                let val = 0
                if (!ema26Map[ema12[i].time]) {
                    val = 0
                } else {
                    val = ema12[i].value - ema26Map[ema12[i].time]
                }
                diff.push({
                    time: ema12[i].time,
                    value: val
                })
            }


            const dea = generateListLogic(ema({
                period: 9,
                values: diff.map(item => item.value),
            }), diff)


            // 画两条线
            const deaSeries = chartInstance.current.addLineSeries({
                color: 'rgb(231,65,178)',
                priceFormat: {
                    type: 'volume',
                },
                crosshairMarkerVisible: false,
                crosshairMarkerRadius: 0,
                crosshairMarkerLineStyle: 0,
                crosshairMarkerLineWidth: 0,
                lastValueVisible: false,
                priceLineVisible: false,
                lineWidth: 1,
                priceScaleId: 'macd',
                scaleMargins: {
                    top: 0.85,
                    bottom: 0.05,
                },
            })
            const diffSeries = chartInstance.current.addLineSeries({
                color: 'rgb(242,186,13)',
                lineWidth: 1,
                crosshairMarkerVisible: false,
                crosshairMarkerRadius: 0,
                crosshairMarkerLineStyle: 0,
                crosshairMarkerLineWidth: 0,
                lastValueVisible: false,
                priceLineVisible: false,
                priceFormat: {
                    type: 'volume',
                },
                priceScaleId: 'macd',
                scaleMargins: {
                    top: 0.85,
                    bottom: 0.05,
                },
            })
            deaSeries.setData(dea)
            diffSeries.setData(diff)
            tmpSeriesInfo.current['macd_dea'] = dea
            tmpSeriesInfo.current['macd_diff'] = diff
            volumeSeriesInstance.current['dea'] = deaSeries
            volumeSeriesInstance.current['diff'] = diffSeries



            let deaMap = {}
            dea.forEach(item => {
                deaMap[item.time] = item.value
            })
            const macdList = []
            for (let i = 0; i < diff.length; i++) {
                let val = 0
                if (!deaMap[diff[i].time]) {
                    continue
                } else {
                    val = 2 * (diff[i].value - deaMap[diff[i].time])
                }
                macdList.push({
                    time: diff[i].time,
                    value: val
                })
            }

            const upSolid = [] // 大于0 的实心柱子
            const downSolid = [] // 小于0的实心柱子
            const upHollow = [] // 大于0的空心柱子
            const downHollow = [] // 小于0的空心柱子

            for (let i = 0; i < macdList.length; i++) {
                if (macdList[i].value > 0) {
                    if (macdList[i].value > macdList[i - 1]?.value) {
                        upSolid.push(macdList[i])
                    } else {
                        upHollow.push(macdList[i])
                    }
                } else {
                    if (macdList[i].value > macdList[i - 1]?.value) {
                        downHollow.push(macdList[i])
                    } else {
                        downSolid.push(macdList[i])
                    }
                }
            }


            const defaultMacdConfig = {
                color: 'rgb(44,190,135)',
                crosshairMarkerVisible: false,
                crosshairMarkerRadius: 0,
                crosshairMarkerLineStyle: 0,
                crosshairMarkerLineWidth: 0,
                lastValueVisible: false,
                priceLineVisible: false,
                priceFormat: {
                    type: 'volume',
                },
                priceScaleId: 'macd',
                scaleMargins: {
                    top: 0.85,
                    bottom: 0.05,
                },
            }

            const upSolidSeries = chartInstance.current.addHistogramSeries(defaultMacdConfig)
            const downSolidSeries = chartInstance.current.addHistogramSeries({
                ...defaultMacdConfig,
                color: 'rgb(246,71,92)',
            })
            const upHollowSeries = chartInstance.current.addHistogramSeries({
                ...defaultMacdConfig
            })
            const downHollowSeries = chartInstance.current.addHistogramSeries({
                ...defaultMacdConfig,
                color: 'rgb(246,71,92)',
            })
            upSolidSeries.setData(upSolid)
            downSolidSeries.setData(downSolid)
            upHollowSeries.setData(upHollow)
            downHollowSeries.setData(downHollow)

            tmpSeriesInfo.current['macd_up_solid'] = upSolid
            tmpSeriesInfo.current['macd_down_solid'] = downSolid
            tmpSeriesInfo.current['macd_up_hollow'] = upHollow
            tmpSeriesInfo.current['macd_down_hollow'] = downHollow


            volumeSeriesInstance.current['macd_up_solid'] = upSolidSeries
            volumeSeriesInstance.current['macd_down_solid'] = downSolidSeries
            volumeSeriesInstance.current['macd_up_hollow'] = upHollowSeries
            volumeSeriesInstance.current['macd_down_hollow'] = downHollowSeries
        }

        if (selectPaneIndicator === 'kd') {
            const a = stochastic({
                high: currentChartData.map(item => Number(item.high)),
                low: currentChartData.map(item => Number(item.low)),
                close: currentChartData.map(item => Number(item.close)),
                period: 9,
                signalPeriod: 3
            })
            // 绘制两条线, k,d
            const generateListLogic = (maList, cDataList) => {
                const mm = []
                let ind = 0
                for (let i = maList.length - 1; i > 0; i--) {
                    if (!cDataList[cDataList.length - 1 - ind]) break
                    mm.push({
                        value: Number(maList[i]),
                        time: cDataList[cDataList.length - 1 - ind].time
                    })
                    ind += 1
                }
                mm.reverse()
                return mm
            }

            const k = generateListLogic(a.map(item => item.k), currentChartData)
            const d = generateListLogic(a.map(item => item.d), currentChartData)

            const kSeries = chartInstance.current.addLineSeries({
                ...defaultLineSeriesConfig,
                priceScaleId: 'kd',
                color: 'rgb(231,65,178)',
                scaleMargins: {
                    top: 0.85,
                    bottom: 0.05,
                }
            })
            const dSeries = chartInstance.current.addLineSeries({
                ...defaultLineSeriesConfig,
                priceScaleId: 'kd',
                color: 'rgb(242,186,13)',
                scaleMargins: {
                    top: 0.85,
                    bottom: 0.05,
                }
            })
            kSeries.setData(k)
            dSeries.setData(d)
            tmpSeriesInfo.current['kd_k'] = k
            tmpSeriesInfo.current['kd_d'] = d
            volumeSeriesInstance.current['kd_k'] = kSeries
            volumeSeriesInstance.current['kd_d'] = dSeries


        }
        if (selectPaneIndicator === 'rsi') {
            const a = rsi({
                values: currentChartData.map(item => Number(item.close)),
                period: 6
            })
            const generateListLogic = (maList, cDataList) => {
                const mm = []
                let ind = 0
                for (let i = maList.length - 1; i > 0; i--) {
                    if (!cDataList[cDataList.length - 1 - ind]) break
                    mm.push({
                        value: Number(maList[i]),
                        time: cDataList[cDataList.length - 1 - ind].time
                    })
                    ind += 1
                }
                mm.reverse()
                return mm
            }
            const rsi6 = generateListLogic(a, currentChartData)
            const rsi6Series = chartInstance.current.addLineSeries({
                ...defaultLineSeriesConfig,
                priceScaleId: 'rsi',
                color: 'rgb(242,186,13)',
                scaleMargins: {
                    top: 0.85,
                    bottom: 0.05,
                }
            })
            rsi6Series.setData(rsi6)
            tmpSeriesInfo.current['rsi6'] = rsi6
            volumeSeriesInstance.current['rsi6'] = rsi6Series

        }
        refreshIndicatorMessage()
    }


    useEffect(() => {
        refreshIndicatorMessage()
    }, [currentChartData, selectedTime])

    const refreshIndicatorMessage = () => {
        // 判断当前是否有选择的时间
        // 如果没有, 则时间选择为k线的最后一根蜡烛图的时间
        // 判断当前选择的k线指标, 显示对应的提示信息
        // 如果没有选择, 则不显示  
        setFirstIndicatorMessage([])
        setSecondIndicatorMessage([])
        let time = 0
        if (currentChartData.length > 0) {
            time = currentChartData[currentChartData.length - 1].time
        }
        if (selectedTime > 0) {
            time = selectedTime
        }
        // 在这里算第一个指标的文案
        if (selectIndicator === 'boll') {
            const upper = tmpSeriesInfo.current['boll_upper']
            const middle = tmpSeriesInfo.current['boll_middle']
            const lower = tmpSeriesInfo.current['boll_lower']
            if (upper && middle && lower) {
                const upperVal = upper.find(item => item.time === time)?.value || 0
                const middleVal = middle.find(item => item.time === time)?.value || 0
                const lowerVal = lower.find(item => item.time === time)?.value || 0
                setFirstIndicatorMessage([
                    {
                        text: `BOLL(21,2)`,
                        color: 'rgb(231,65,178)'
                    },
                    {
                        text: `UP:${converToNumberString(upperVal)}`,
                        color: 'rgb(44,190,135)'
                    },
                    {
                        text: `MID:${converToNumberString(middleVal)}`,
                        color: 'rgb(242,186,13)'
                    },
                    {
                        text: `DOWN:${converToNumberString(lowerVal)}`,
                        color: 'rgb(137,104,195)'
                    }
                ])
            }

        }
        if (selectIndicator === 'ma') {
            const ma7 = tmpSeriesInfo.current['ma7']
            const ma25 = tmpSeriesInfo.current['ma25']
            const ma99 = tmpSeriesInfo.current['ma99']
            if (ma7 && ma25 && ma99) {
                const ma7Val = ma7.find(item => item.time === time)?.value || 0
                const ma25Val = ma25.find(item => item.time === time)?.value || 0
                const ma99Val = ma99.find(item => item.time === time)?.value || 0
                setFirstIndicatorMessage([
                    {
                        text: `MA(7)`,
                        color: 'rgb(231,65,178)'
                    },
                    {
                        text: `${converToNumberString(ma7Val)}`,
                        color: 'rgb(44,190,135)'
                    },
                    {
                        text: `MA(25)`,
                        color: 'rgb(242,186,13)'
                    },
                    {
                        text: `${converToNumberString(ma25Val)}`,
                        color: 'rgb(137,104,195)'
                    },
                    {
                        text: `MA(99)`,
                        color: 'rgb(231,65,178)'
                    },
                    {
                        text: `${converToNumberString(ma99Val)}`,
                        color: 'rgb(44,190,135)'
                    },
                ])
            }
        }
        if (selectIndicator === 'ema') {
            const ema7 = tmpSeriesInfo.current['ema7']
            const ema25 = tmpSeriesInfo.current['ema25']
            const ema99 = tmpSeriesInfo.current['ema99']
            if (ema7 && ema25 && ema99) {
                const ema7Val = ema7.find(item => item.time === time)?.value || 0
                const ema25Val = ema25.find(item => item.time === time)?.value || 0
                const ema99Val = ema99.find(item => item.time === time)?.value || 0
                setFirstIndicatorMessage([
                    {
                        text: `EMA(7)`,
                        color: 'rgb(231,65,178)'
                    },
                    {
                        text: `${converToNumberString(ema7Val)}`,
                        color: 'rgb(44,190,135)'
                    },
                    {
                        text: `EMA(25)`,
                        color: 'rgb(242,186,13)'
                    },
                    {
                        text: `${converToNumberString(ema25Val)}`,
                        color: 'rgb(137,104,195)'
                    },
                    {
                        text: `EMA(99)`,
                        color: 'rgb(231,65,178)'
                    },
                    {
                        text: `${converToNumberString(ema99Val)}`,
                        color: 'rgb(44,190,135)'
                    },
                ])
            }
        }
        // 计算第二个指标的文案
        if (selectPaneIndicator === 'vol') {
            const up = tmpSeriesInfo.current['volume_up']
            const down = tmpSeriesInfo.current['volume_down']
            if (up && down) {
                const upVal = up.find(item => item.time === time)?.value || 0
                const downVal = down.find(item => item.time === time)?.value || 0
                let color = ''
                let val = 0
                if (upVal) {
                    color = 'rgb(44,190,135)'
                    val = upVal
                }
                if (downVal) {
                    color = 'rgb(246,71,92)'
                    val = downVal
                }
                if (color) {
                    setSecondIndicatorMessage([
                        {
                            text: `VOL`,
                            color: 'rgb(231,65,178)'
                        },
                        {
                            text: `${converToNumberString(val)}`,
                            color
                        },
                    ])
                }

            }
        }
        if (selectPaneIndicator === 'macd') {
            const diff = tmpSeriesInfo.current['macd_diff']
            const dea = tmpSeriesInfo.current['macd_dea']
            const macd = tmpSeriesInfo.current['macd_up_solid'].concat(tmpSeriesInfo.current['macd_down_solid']).concat(tmpSeriesInfo.current['macd_up_hollow']).concat(tmpSeriesInfo.current['macd_down_hollow'])
            if (diff && dea) {
                const diffVal = diff.find(item => item.time === time)?.value || 0
                const deaVal = dea.find(item => item.time === time)?.value || 0
                const macdVal = macd.find(item => item.time === time)?.value || 0
                setSecondIndicatorMessage([
                    {
                        text: `MACD(12,26,9): ${converToNumberString(macdVal)}`,
                        color: 'rgb(231,65,178)'
                    },
                    {
                        text: `DIF: ${converToNumberString(diffVal)}`,
                        color: 'rgb(44,190,135)'
                    },
                    {
                        text: `DEA: ${converToNumberString(deaVal)}`,
                        color: 'rgb(242,186,13)'
                    },
                ])
            }
        }
        if (selectPaneIndicator === 'kd') {
            const k = tmpSeriesInfo.current['kd_k']
            const d = tmpSeriesInfo.current['kd_d']
            if (k && d) {
                const kVal = k.find(item => item.time === time)?.value || 0
                const dVal = d.find(item => item.time === time)?.value || 0
                setSecondIndicatorMessage([
                    {
                        text: `K: ${converToNumberString(kVal)}`,
                        color: 'rgb(231,65,178)'
                    },
                    {
                        text: `D: ${converToNumberString(dVal)}`,
                        color: 'rgb(242,186,13)'
                    },
                ])
            }
        }
        if (selectPaneIndicator === 'rsi') {
            const rsi6 = tmpSeriesInfo.current['rsi6']
            if (rsi6) {
                const rsi6Val = rsi6.find(item => item.time === time)?.value || 0
                setSecondIndicatorMessage([
                    {
                        text: `RSI(6): ${converToNumberString(rsi6Val)}`,
                        color: 'rgb(231,65,178)'
                    },
                ])
            }
        }
    }

    useEffect(() => {
        refreshChartPane()
    }, [selectPaneIndicator])

    const formatTime = (time) => {
        const d = new Date(time)
        return d.getTime() / 1000
    }

    useEffect(() => {
        setShowKlineDetail(false)
        setChartBoxPositionClass('')
        setKlineDetail(null)
        barsCache.current = {}
        generateKline()
    }, [selectWindow])

    useEffect(() => {
        const now = Date.parse(new Date()) / 1000
        if (props.openTime && props.openTime > now) {
            setShowOpenTime(true)
        }
    }, [props.openTime])

    const generateKline = () => {
        return new Promise((resolve, reject) => {
            // 获取数据
            const { datafeed } = props
            if (!datafeed.pair || !datafeed.chain_id || !datafeed.currency) return
            const from = Date.parse(new Date()) / 1000 - 60 * 60 * 24 * 30
            const to = Date.parse(new Date()) / 1000
            let n = {
                from: from,
                // to: from.firstDataRequest ? Date.parse(new Date()) / 1000 : from.to,
                to: to,
                pair: datafeed.pair,
                count: 800,
                chain_id: datafeed.chain_id,
                currency: datafeed.currency,
                window: selectWindow.window,
                interval: selectWindow.interval
            }
            klineParams.current = n
            setShowLoading(true)
            getTokenKline(n).then(({ data }) => {
                if (data.code === 200 && data.data && data.data.length > 0) {
                    // 有数据
                    let bars = []
                    // 缓存数据
                    data.data.forEach(item => {
                        barsCache.current[formatTime(item.time)] = item
                    })
                    bars = data.data.map((item) => {
                        return {
                            time: formatTime(item.time),
                            open: item.open,
                            high: item.high,
                            low: item.low,
                            close: item.close,
                            volume: Number(item.volume)
                        }
                    }).sort(function (item1, item2) {
                        return item1.time - item2.time
                    })

                    setCurrentChartData(bars)
                    currentChartDataRef.current = bars
                    refreshCandleStickSeries(bars)

                }
                setShowLoading(false)
            }).catch(e => {
                setShowLoading(false)
            })
            refreshSubscription()
            resolve()
        })
    }

    const refreshCandleStickSeries = (bars) => {
        if (chartInstance.current) {
            if (candleStickSeriesInstance.current) {
                chartInstance.current.removeSeries(candleStickSeriesInstance.current)
                candleStickSeriesInstance.current = null
            }
            candleStickSeriesInstance.current = chartInstance.current.addCandlestickSeries({
                ...candlestickConfig,
                // priceScaleId: 'main',
                scaleMargins: {
                    top: 0,
                    bottom: 0.2
                }
            });

            // bars去重
            const barsMap = {}
            const barsList = []
            bars.forEach(item => {
                if (!barsMap[item.time]) {
                    barsMap[item.time] = true
                    barsList.push(item)
                }
            })
            candleStickSeriesInstance.current.setData(barsList)
        }

    }

    useEffect(() => {
        refreshChartPane()
    }, [currentChartData])

    const setIndicatorLogic = (indicator) => {
        if (selectIndicator === indicator) {
            setSelectIndicator('')
        } else {
            setSelectIndicator(indicator)
        }
    }

    const setPaneIndicatorLogic = (indicator) => {
        if (selectPaneIndicator === indicator) {
            setSelectPaneIndicator('')
        } else {
            setSelectPaneIndicator(indicator)
        }
    }
    return <div style={{ position: 'relative' }}>
        <div>
            <div className="token-option-header-nav">
                {timeFrames.length > 0 && timeFrames.map((item, ind) => {
                    return <div onClick={() => {
                        setSelectWindow(item)
                    }} className={`mobile-chart-container-header-item ${item.interval === selectWindow.interval ? 'mobile-chart-container-header-item-active' : ''}`} key={`${item.text}-${ind}`}>
                        <div className="mobile-chart-container-header-item-text">
                            {item.text}
                        </div>
                    </div>
                })}
            </div>
        </div>
        <div className="mobile-chart-box">
            <div onMouseDown={(e) => {
                chartInstance.current.setCrossHairXY(e.clientX, e.clientY, true)
                const x = chartInstance.current.timeScale().coordinateToTime(e.clientX)
                showKlineDetailLogic(x, e.clientX)
                setSelectedTime(x)
            }} ref={containerRef} >
            </div>
            {showKlineDetail && klineDetail && <div style={{ width: `${containerRef.current.offsetWidth / 3}px` }} className={`mobile-chart-candle-info-box ${chartBoxPositionClass}`}>
                <div className="mobile-chart-candle-info-box-row">
                    <div >{t('时间')}</div>
                    <div className="mobile-chart-candle-info-box-row-right">{formatTimeString(klineDetail.time, false, true, true)}</div>
                </div>
                <div className="mobile-chart-candle-info-box-row">
                    <div>{t('开')}</div>
                    <div className="mobile-chart-candle-info-box-row-right">{converToNumberString(klineDetail.open)}</div>
                </div>
                <div className="mobile-chart-candle-info-box-row">
                    <div>{t('收')}</div>
                    <div className="mobile-chart-candle-info-box-row-right">{converToNumberString(klineDetail.close)}</div>
                </div>
                <div className="mobile-chart-candle-info-box-row">
                    <div>{t('高')}</div>
                    <div className="mobile-chart-candle-info-box-row-right">{converToNumberString(klineDetail.high)}</div>
                </div>
                <div className="mobile-chart-candle-info-box-row">
                    <div>{t('低')}</div>
                    <div className="mobile-chart-candle-info-box-row-right">{converToNumberString(klineDetail.low)}</div>
                </div>
                <div className="mobile-chart-candle-info-box-row">
                    <div>{t('涨跌幅')}</div>
                    <div className={`mobile-chart-candle-info-box-row-right ${klineDetail.close > klineDetail.open ? 'text-success' : 'text-danger'}`}>
                        {((klineDetail.close - klineDetail.open) / klineDetail.open * 100).toFixed(2)}%
                    </div>
                </div>
                <div className="mobile-chart-candle-info-box-row">
                    <div>{t('交易量')}</div>
                    <div className="mobile-chart-candle-info-box-row-right">{convertToMaxUnit(klineDetail.volume)}</div>
                </div>
                <div className="mobile-chart-candle-info-box-row">
                    <div>{t('交易额')}</div>
                    <div className="mobile-chart-candle-info-box-row-right">${convertToMaxUnit(klineDetail.close * Number(klineDetail.volume))}</div>
                </div>


            </div>}

            {firstIndicatorMessage && firstIndicatorMessage.length > 0 && <div className="indicator-message-box" >
                <div className="indicator-message" >
                    {firstIndicatorMessage.map((item, ind) => {
                        return <div key={`${item.text}-${ind}`} style={{ color: item.color }} className="indicator-message-item">
                            {item.text}
                        </div>
                    })}

                </div>
            </div>}
            {secondIndicatorMessage && secondIndicatorMessage.length > 0 && <div style={{ top: '73%' }} className="indicator-message-box" >
                <div className="indicator-message" >
                    {secondIndicatorMessage.map((item, ind) => {
                        return <div key={`${item.text}-sec-${ind}`} style={{ color: item.color }} className="indicator-message-item">
                            {item.text}
                        </div>
                    })}

                </div>
            </div>}
        </div>
        <div>
            <div className="token-option-header-nav">
                <div onClick={() => setIndicatorLogic('ma')} className={`mobile-chart-options-item ${selectIndicator === 'ma' ? 'mobile-chart-options-item-active' : ''}`}>
                    <div >
                        MA
                    </div>
                </div>
                <div onClick={() => setIndicatorLogic('ema')} className={`mobile-chart-options-item ${selectIndicator === 'ema' ? 'mobile-chart-options-item-active' : ''}`}>
                    <div >
                        EMA
                    </div>
                </div>
                <div onClick={() => setIndicatorLogic('boll')} className={`mobile-chart-options-item ${selectIndicator === 'boll' ? 'mobile-chart-options-item-active' : ''}`}>

                    <div >
                        BOLL
                    </div>
                </div>
                <div onClick={() => {
                    setPaneIndicatorLogic('vol')
                }} className={`mobile-chart-options-item ${selectPaneIndicator === 'vol' ? 'mobile-chart-options-item-active' : ''}`}>
                    <div >
                        VOL
                    </div>
                </div>
                <div onClick={() => {
                    setPaneIndicatorLogic('macd')
                }} className={`mobile-chart-options-item ${selectPaneIndicator === 'macd' ? 'mobile-chart-options-item-active' : ''}`}>
                    <div >
                        MACD
                    </div>
                </div>
                <div onClick={() => {
                    setPaneIndicatorLogic('kd')
                }} className={`mobile-chart-options-item ${selectPaneIndicator === 'kd' ? 'mobile-chart-options-item-active' : ''}`}>
                    <div >
                        KD
                    </div>
                </div>
                <div onClick={() => {
                    setPaneIndicatorLogic('rsi')
                }} className={`mobile-chart-options-item ${selectPaneIndicator === 'rsi' ? 'mobile-chart-options-item-active' : ''}`}>
                    <div >
                        RSI
                    </div>
                </div>
                <div onClick={() => {
                    props.swapCurrency && props.swapCurrency()
                }} style={{ transform: 'scale(0.9)', wordBreak: 'break-all', whiteSpace: 'nowrap' }} className="mobile-chart-options-item">

                    <div >
                        {t('交换底池')}
                    </div>
                </div>
            </div>
        </div>
        {showLoading && <div className="tv-loading-mask">
            <SpinerLoading />
        </div>}

        {showOpenTime && <div className='tv-mask'>
            <div style={{ marginBottom: '20px', fontSize: '15px' }}>
                {t('开盘倒计时')}
            </div>
            <TimeCountDown time={props.openTime} countdownEnd={() => {
                setShowOpenTime(false)
            }} />
        </div>}
    </div >
}