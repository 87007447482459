import React, { useEffect, useRef, useState } from "react";
import { Nav } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useSelector } from "react-redux";
import SweetAlert2, { withSwal } from "react-sweetalert2";
import { toast } from "react-toastify";
import { DeleteUserEoaOptionFromGroup, GetUserEoaOptionGroupList, GetUserEoaOptionList } from "../../../api/wallet";
import { eventBus, getShortAddress, isAddress } from "../../../utils/tools";
import EoaOptionGroupModal from "../OptionGroupModal/eoa";
import i18n from "../../../utils/i18n";
import { useTranslation } from "react-i18next";

const DelButton = withSwal(({ swal, ok = null }, ref) => (
    <span onClick={async () => {
        const a = await swal.fire({
            title: i18n.t('确认删除?'),
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: i18n.t('确认'),
            cancelButtonText: i18n.t('取消'),
        })

        console.log(a)
        if (a.isConfirmed) {
            // 删除
            ok && ok()
        } else {

        }

    }} className="wallet-observer-item-close">   <i className="fa fa-close " /></span>
));


function TokenOptionRank(props) {
    const [current, setCurrent] = useState(0) // 当前的分组id
    const [currentList, setCurrentList] = useState([]) // 当前的分组id
    const [swalProps, setSwalProps] = useState({});
    const { t } = useTranslation()
    const tmpCurrentList = useRef([])

    useEffect(() => {
        // 选择分组
        refreshList()

    }, [current])



    const refreshList = (id = 0) => {
        if (id === 0) id = current
        if (!id) return
        GetUserEoaOptionList({
            option_id: id
        }).then(({ data }) => {
            if (data.code === 200) {
                setCurrentList(data.data || [])
            }
        })
    }

    useEffect(() => {
        tmpCurrentList.current = currentList
    }, [currentList])

    useEffect(() => {
        if (!props.collects) return
        if (props.collects.length === 0) {
            return
        }
        if (current === 0) {
            // 默认分组
            setCurrent(props.collects[0].id)
        }
    }, [props.collects])

    useEffect(() => {
        const handleEvent = (list) => {
            // 如果list里包含了当前分组的id，那么就刷新
            // 如果不包含当前分组的id, 那么就取第一个, 然后刷新
            const idList = list.map(item => item.id)
            if (!idList.includes(current) && idList && idList.length > 0) {
                setCurrent(idList[0])
                refreshList(idList[0])
            } else {
                refreshList()
            }
        }



        const handleAddWalletObserver = (wallet) => {
            // 设置到列表中
            const list = [...(tmpCurrentList.current || []), { id: 0, wallet }]
            setCurrentList(list)
            props.onSelect && props.onSelect(wallet)
        }
        eventBus.on('refreshWalletObserver', handleEvent)
        eventBus.on('addWalletObserver', handleAddWalletObserver)
        return () => {
            eventBus.off('refreshWalletObserver', handleEvent)
            eventBus.off('addWalletObserver', handleAddWalletObserver)

        }
    }, [])


    return <>
        <div className="token-option-header">
            <Nav className="token-option-header-nav" variant="default">
                {props.collects && props.collects.map((item, ind) => {
                    return <Nav.Link key={'link-' + ind} onClick={() => {
                        setCurrent(item.id)
                    }} eventKey={ind + 'collect'}>
                        {item.name || t('默认')}
                    </Nav.Link>
                })}
            </Nav>
            <div onClick={() => {
                props.add && props.add()
            }} className="token-option-header-plus">
                <i className="fa fa-plus"></i>
            </div>
        </div>

        <div>
            {currentList && currentList.length > 0 && currentList.map((item) => {
                return <div
                    onClick={() => {
                        props.onSelect && props.onSelect(item.wallet)
                    }}
                    key={item.id + 'eoa-options'} className={"wallet-observer-item " + (item.wallet.toLowerCase() === props.selectedWallet.toLowerCase() ? 'follow-wallet-active' : '')}>
                    {!item.alias ? getShortAddress(item.wallet, 8) : <span >
                        {item.alias}({getShortAddress(item.wallet)})
                    </span>}

                    <DelButton id={item.id}
                        ok={async () => {
                            const { data } = await DeleteUserEoaOptionFromGroup({
                                id: item.id + ''
                            })
                            if (data.code === 200) {
                                refreshList()
                                toast.success(t('删除成功'))
                            } else {
                                toast.error(t('删除失败'))
                            }
                        }}
                    />

                </div>
            })}

        </div>
        <SweetAlert2 {...swalProps} />

    </>
}


export default function WalletObserver(props) {
    const [currentWallet, setCurrentWallet] = useState('') // 当前登录的钱包地址
    const currentUser = useSelector(state => state.currentUser)
    const [groupList, setGroupList] = useState([]) // 分组列表
    const [showOptionGroupList, setShowOptionGroupList] = useState(false) // 显示自定义分组列表
    const [selectWallet, setSelectWallet] = useState('') // 选择的钱包地址

    const { t } = useTranslation()
    const [tmpAddressToWalletObserver, setTmpAddressToWalletObserver] = useState('') // 添加观察钱包时, 临时的钱包地址

    useEffect(() => {

    }, [])

    const fetchData = async () => {
        if (currentUser.wallet) {
            // 获取分组
            const { data } = await GetUserEoaOptionGroupList({})
            setGroupList(data.data)
            eventBus.emit('refreshWalletObserver', data.data)
        }
    }

    const handleAddAddressToWalletObserver = (address) => {
        // 添加到观察钱包
        setShowOptionGroupList(true)
        setTmpAddressToWalletObserver(address)
    }

    // 获取我的观察钱包分组
    // 根据分组获取我的观察钱包列表
    useEffect(() => {
        if (!currentUser.wallet) {
            setCurrentWallet('')
        }
        if (currentUser && currentUser.wallet && currentUser.wallet !== currentWallet) {
            setCurrentWallet(currentUser.wallet)
            setSelectWallet(currentUser.wallet)
            fetchData()
        }
    }, [currentUser])

    useEffect(() => {
        eventBus.on('addAddressToWalletObserver', handleAddAddressToWalletObserver)
        return () => {
            eventBus.off('addAddressToWalletObserver', handleAddAddressToWalletObserver)
        }
    }, [])


    return <div className="wallet-observer">
        <EoaOptionGroupModal
            show={showOptionGroupList}
            chainId={props.tokenInfo?.chain_id || 0}
            wallet={currentWallet}
            default={tmpAddressToWalletObserver}
            handleClose={() => {
                setShowOptionGroupList(false)
            }}
            list={groupList || []}
            updateAll={(wallet) => {
                fetchData()
                    .then(() => {
                        if (isAddress(wallet)) {
                            console.log('wallet', wallet)
                            setSelectWallet(wallet)
                            props.onChange && props.onChange(wallet)
                            eventBus.emit('refreshWalletObserverWallet', wallet)
                        }
                    })

            }}
            ok={() => {
                fetchData()
            }}
        />
        {currentWallet ? <div>
            <div>
                <div className="fw-bold">{t('我的钱包')}</div>
                <div className="mt-2">
                    <div onClick={() => {
                        setSelectWallet(currentWallet)
                        props.onChange && props.onChange(currentWallet)
                        eventBus.emit('refreshWalletObserverWallet', currentWallet)
                    }} className={"wallet-observer-item text-light " + (currentWallet.toLowerCase() === selectWallet.toLowerCase() ? 'follow-wallet-active' : '')}>
                        {getShortAddress(currentWallet, 8)}
                    </div>
                </div>

            </div>
            <div className="mt-2">
                <div className="fw-bold">{t('观察钱包')}</div>
                <TokenOptionRank
                    selectedWallet={selectWallet}
                    add={() => {
                        setShowOptionGroupList(true)
                    }}
                    onSelect={(wallet) => {
                        setSelectWallet(wallet)
                        props.onChange && props.onChange(wallet)
                        eventBus.emit('refreshWalletObserverWallet', wallet)
                    }}
                    collects={groupList} />
                <div onClick={() => {
                    setShowOptionGroupList(true)
                }} className="btn btn-outline-primary btn-sm full-width mt-2">
                    {t('添加观察钱包')}
                </div>
            </div>
        </div> : <div className="full-width d-flex flex-row align-items-center justify-content-center">

            <div onClick={() => {
                eventBus.emit('request_login')
            }} className="btn btn-primary text-light mt-4" >{t('请先登录')}</div>

        </div>}

    </div>
}