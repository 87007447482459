import Network from "./network";


export const getTokenDetail = (params) => {
    // 获取代币信息
    return Network.request({
        url: '/token/detail',
        method: 'get',
        params
    })
}

export const getTokenPairList = (params) => {
    return Network.request(
        {
            url: "/token/pairs",
            method: 'get',
            params
        }
    )
}

export const getTokenPairPriceInfo = (params) => {
    return Network.request({
        url: '/token/pair/price',
        method: 'get',
        params
    })
}

export const getIncreaseRankList = (params) => {
    // 获取涨幅榜
    return Network.request({
        url: '/rank/gain',
        method: 'get',
        params
    })
}

export const getNewPoolList = (params) => {
    return Network.request({
        url: '/rank/pool',
        method: 'get',
        params
    })
}

export const getLiquidityRecordList = (params) => {
    return Network.request({
        url: "/token/pair/liquidity",
        method: 'get',
        params
    })
}

export const getTokenKline = (params) => {
    return Network.request({
        url: "/kline",
        method: 'get',
        params
    })
}

export const getTokenTxRecords = (params) => {
    return Network.request({
        url: "/tx",
        method: 'get',
        params
    })
}

export const getTokenHoldersList = (params) => {
    return Network.request({
        url: "/token/holders",
        method: 'get',
        params
    })
}

export const getHotRank = (params) => {
    return Network.request({
        url: "/rank/hot",
        method: 'get',
        params
    })
}

export const searchToken = (params, extra) => {
    return Network.request({
        url: "/token/search",
        method: 'get',
        params,
        extra
    })
}

export const getTokenOwner = (params) => {
    return Network.request({
        url: "/token/owner",
        method: 'get',
        params
    })
}

export const verifyTokenOwner = (data) => {
    return Network.request({
        url: "/token/verify",
        method: 'post',
        data
    })
}

export const updateTokenInfo = (data) => {
    return Network.request({
        url: "/token/update",
        method: 'post',
        data
    })
}

export const getTokenCollectionList = () => {
    return Network.request({
        url: "/user/options/collects",
        method: 'get',
    })
}

export const getTokenOptionsList = (params) => {
    return Network.request({
        url: "/user/options/list",
        method: 'get',
        params
    })
}

export const removeTokenOptionRecord = (data) => {
    return Network.request({
        url: "/user/options/del",
        method: 'post',
        data
    })
}

export const addTokenOptionRecord = (data) => {
    return Network.request({
        url: "/user/options/add",
        method: 'post',
        data
    })
}

export const addTokenOptionGroup = (data) => {
    return Network.request({
        url: "/user/options/group/add",
        method: 'post',
        data
    })
}

export const getHotSearchRankList = () => {
    return Network.request({
        url: "/rank/search",
        method: 'get',
    })
}

export const deleteTokenOptionGroup = (data) => {
    return Network.request({
        url: "/user/options/group/del",
        method: 'post',
        data
    })
}

export const getWalletTokenAnalyze = (params) => {
    return Network.request({
        url: '/wallet/token/analyze',
        method: 'get',
        params
    })
}

export const getWalletTokenLiquidityRecordsList = (params) => {
    return Network.request({
        url: '/wallet/token/liq',
        method: 'get',
        params
    })
}

export const getWalletTokenTransferRecordsList = (params) => {
    return Network.request({
        url: '/wallet/token/transfer',
        method: 'get',
        params
    })
}

export const getWalletTokenTxsList = (params) => {
    return Network.request({
        url: '/wallet/token/txs',
        method: 'get',
        params
    })
}

export const getWalletTokenTxStatistic = (data) => {
    return Network.request({
        url: '/wallet/token/tx-statistic',
        method: 'post',
        data
    })
}

export const getTokenOverviewInfo = (params) => {
    return Network.request({
        url: '/token/tx/overview',
        method: 'get',
        params
    })
}