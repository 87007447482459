import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./index.css";

const ScoreDashboard = (props) => {
    const [options, setOptions] = useState({
        series: [],
        options: {}
    });

    useEffect(() => {
        if (!props?.score) return
        setOptions({
            series: [props.score || 0],
            options: {
                chart: {
                    type: 'radialBar',
                    // offsetY: -20,
                    sparkline: {
                        enabled: true
                    }
                },
                colors: [
                    function ({ value, seriesIndex, w }) {
                        if (value <= 20) {
                            return '#73d13d'
                        }
                        if (value <= 40) {
                            return '#ffec3d'
                        }
                        if (value <= 55) {
                            return '#eb2f96'
                        }
                        if (value > 55) {
                            return '#f5222d'
                        }
                    }
                ],
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        hollow: {
                            size: '65   %'
                        },
                        track: {
                            background: "#000000",
                            strokeWidth: '97%',
                            // margin: 0, // margin is in pixels
                            // dropShadow: {
                            //     enabled: true,
                            //     top: 2,
                            //     left: 0,
                            //     color: '#000',
                            //     opacity: 1,
                            //     blur: 1
                            // }
                        },
                        dataLabels: {
                            name: {
                                show: false
                            },
                            value: {
                                offsetY: props.offsetY || -32,
                                fontSize: props.fontSize || '25px',
                                color: '#ffffff',
                                formatter: function (val) {
                                    return val
                                }
                            }
                        }
                    }
                },
                // grid: {
                //     padding: {
                //         top: -10
                //     }
                // },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'light',
                        shadeIntensity: 0.1,
                        inverseColors: false,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 25, 50, 75, 100]
                    },
                },
                // labels: ['Average Results'],
            },
        })
    }, [props.score])



    // const score = props.score;
    return (
        <div>
            <div style={{ width: '1px', height: '1px' }}></div>
            <ReactApexChart type="radialBar" options={options.options} series={options.series} />
        </div>
    );
};

export default ScoreDashboard;
