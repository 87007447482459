import { getTokenKline } from "../../api/token";
import store from "../../store";
import { convertToMaxUnit, eventBus, formatTimeString } from "../../utils/tools"
import i18n from "../../utils/i18n";

export default class DataFeed {
    constructor(data = {}) {
        this.data = data
        this.nextTime = 0
        this.nextTimeBars = []
        this.tmp = {}
        this.currentInterval = ''
    }
    onReady = (callback) => {
        // console.log('[onReady]: Method call');
        // setTimeout(() => callback(configurationData));
        callback({
            exchanges: [],
            symbols_types: [],
            // supports_time: true,
            supported_resolutions: [1, 3, 5, 10, 15, 30, '1h', '2h', '4h', '1d', '1w', '1M'],
            supports_marks: true,
            // supports_timescale_marks: true
        })
    }
    searchSymbols = () => {
        console.log('searchSymbols');
    };
    resolveSymbol = (
        symbolName,
        onSymbolResolvedCallback,
        onResolveErrorCallback,
    ) => {
        // 配置
        console.log(['调用[resolveSymbol]方法'])
        var data = {
            name: symbolName,
            ticker: symbolName,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || "America/New_York",
            type: 'bitcoin',
            has_intraday: true, // 分钟数据
            has_daily: true, // 日k线数据
            has_weekly_and_monthly: true, // 月，周数据
            has_no_volume: false,
            session: '24x7',
            regular_session: '24x7',
            has_emtpy_bars: false,
            data_status: 'streaming',
            pricescale: 10000000000000000,
            minmov: 1,                     //最小波动
            minmov2: 0,
            format: 10,
            volume_precision: 10,
            fractional: !1,
            intraday_multipliers: ['1', '5', '15', '30', '60', '120', '240']

        };

        if (!this.onSymbolResolvedCallback) {
            this.onSymbolResolvedCallback = onSymbolResolvedCallback;
        }

        setTimeout(function () {
            onSymbolResolvedCallback(data);
        }, 0);
    };

    getStoreKey() {
        return `${this.data.pair}-${this.data.currency}`
    }
    getMarks = (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
        console.log('调用[getMarks]方法', Date.parse(new Date()), symbolInfo, resolution, this.data)
        this.onDataCallback = onDataCallback

        // 这里设置一个store, 证明某个代币的getMarks方法已经执行过了, 执行过了之后, 才能发射更新marks的事件
        const key = this.getStoreKey()
        store.dispatch({
            type: 'setGetMarksRecords',
            value: {
                [key]: true
            }
        })


        // 等待订阅函数中订阅事件, 然后执行这里的函数

    }

    getBars = (
        symbolInfo,
        resolution,
        from,
        onHistoryCallback,
        onErrorCallback,
    ) => {
        console.log(['调用[getBars]方法'], "时间范围: ", formatTimeString(new Date(from.from * 1000)), "~", formatTimeString(new Date(from.to * 1000)), from, symbolInfo, resolution)
        this.onHistoryCallback = onHistoryCallback
        this.onErrorCallback = onErrorCallback
        // if (!from.firstDataRequest) {
        // return
        // }
        // this.firstDataRequest = from.firstDataRequest;
        // this.onHistoryCallback = onHistoryCallback;
        // this.resolution = resolution;
        // 封装函数渲染视图
        // this.history(from.from * 1000, from.to * 1000, from.countBack, onHistoryCallback);
        let window = 1
        let interval = '1m'
        switch (resolution + '') {
            case '3':
                window = 3
                interval = '3m'
                break
            case '5':
                window = 5
                interval = '5m'
                break
            case '10':
                window = 10
                interval = '10m'
                break
            case '15':
                window = 15
                interval = '15m'
                break
            case '30':
                window = 30
                interval = '30m'
                break
            case '60':
                window = 60
                interval = '1h'
                break
            case '120':
                window = 120
                interval = '2h'
                break
            case '240':
                window = 240
                interval = '4h'
                break
            case '1D':
                window = 60 * 24
                interval = '1d'
                break
            case '1W':
                window = 60 * 24 * 7
                interval = '1w'
                break
            case '1M':
                window = 60 * 24 * 7 * 30
                interval = '1mo'
                break
            case '1y':
                window = 60 * 24 * 7 * 30 * 12
                interval = '1y'
                break
            default:
                window = 1
                interval = '1m'
                break

        }

        console.log('准备获取getBars', interval, window)
        // if (this.nextTime && this.nextTime < from.from) {
        //     console.log('触发日rrrrr222', this.nextTime, from.from)
        //     this.onHistoryCallback([], {
        //         noData: true,
        //         nextTime: this.nextTime
        //     })
        //     return
        // }
        // if (this.nextTime && this.nextTime >= from.from) {
        //     console.log('触发日rrrrr', this.nextTime, from.from)

        //     return
        // }
        if (this.nextTime && this.currentInterval === interval) {
            // console.log('触发日rrrrr', this.nextTime, from.from, this.nextTimeBars)
            this.nextTimeBars = this.nextTimeBars.filter(item => { return item })
            if (this.nextTimeBars && this.nextTimeBars.length > 0) {
                const a = [...this.nextTimeBars]
                this.nextTimeBars = []
                onHistoryCallback(a, {
                    noData: false,
                })
            } else {
                this.nextTimeBars = []
                onHistoryCallback([], {
                    noData: true
                })
                // console.log('触发日rrrrr,已设置为空')
            }
            console.log('getBars 有nextTime跳过')
            return
        }


        const key = `${from.from}-${from.to}-${this.data.pair}-${this.data.currency}-${window}-${interval}`
        if (!this.tmp[key]) {
            this.tmp[key] = true
        } else {
            return
        }
        console.log('getBarsssss', this.currentInterval, interval, this.bars)
        if (this.currentInterval === interval && this.bars && this.bars.length > 0) {
            // this.nextTimeBars = []
            onHistoryCallback([], {
                noData: true
            })
            return
        }


        let n = {
            from: from.from,
            // to: from.firstDataRequest ? Date.parse(new Date()) / 1000 : from.to,
            to: from.to,
            pair: this.data.pair,
            count: 800,
            chain_id: this.data.chain_id,
            currency: this.data.currency,
            window: window,
            interval: interval
        }
        // console.log('nnnnn', n)
        this.klineParams = n

        this.getTokenKline(n)


    };

    getTokenKline(n) {
        getTokenKline(n)
            .then(({ data }) => {
                // console.log('getBars ', data)
                let bars = []
                if (data.code === 200 && data.data && data.data.length > 0) {
                    bars = data.data.filter(item => {
                        return 1
                        // console.log(item.timestamp, n.from, item.timestamp >= n.from * 1000, item.timestamp < n.to * 1000)
                        return item.timestamp >= n.from * 1000 && item.timestamp < n.to * 1000
                        // return 1
                    }).map(item => {
                        return {
                            time: item.timestamp,
                            open: Number(item.open),
                            high: Number(item.high),
                            low: Number(item.low),
                            close: Number(item.close),
                            volume: Number(item.volume),
                            t: item.time
                        }
                    }).sort(function (item1, item2) {
                        return item1.time - item2.time
                    })
                }

                if (this.bars && bars && bars.length > 0) {
                    // 只保存最大的一段数据
                    if (this.bars[this.bars.length - 1]) {
                        if (this.bars[this.bars.length - 1].time < bars[bars.length - 1].time) {
                            this.bars = bars
                        }
                    }
                } else {
                    if (!this.bars || this.bars.length === 0) {
                        this.bars = bars
                    }

                }
                // console.log('bars', bars, n.from, n.to)
                this.currentInterval = n.interval
                if (bars && bars.length > 0) {
                    this.onHistoryCallback(bars, {
                        noData: !1
                    })
                } else {
                    // 如果给定的数据最大值不在当前时间范围内, 则渲染新的数据
                    const nextTime = (data.data && data.data.length > 0) ? Number(data.data[0].timestamp) / 1000 : null
                    // 如果有nextTime, 则把当前的放进去, 直接退到下一个时间段
                    if (nextTime) {
                        this.nextTime = nextTime
                        this.nextTimeBars = data.data.map(item => {
                            return {
                                time: item.timestamp,
                                open: Number(item.open),
                                high: Number(item.high),
                                low: Number(item.low),
                                close: Number(item.close),
                                volume: Number(item.volume),
                                t: item.time
                            }
                        }).sort(function (item1, item2) {
                            return item1.time - item2.time
                        })
                    }

                    this.onHistoryCallback(bars, {
                        noData: !0,
                        nextTime
                    })


                }
            }).catch(e => {
                this.onErrorCallback(e)
                console.log('getBars出错', e)
            })
    }

    subscribeBars = (
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscriberUID,
        onResetCacheNeededCallback,
    ) => {
        // 订阅最新价格和最新k线
        // console.log(['调用[subscribeBars]方法'], symbolInfo, resolution, subscriberUID)
        // console.log('调用[getMarks]方法2', Date.parse(new Date()))
        // 订阅最新价格

        this.onRealtimeCallback = onRealtimeCallback
        this.addListeners()
    };

    addListeners() {
        // 当有最新价格的时候, 更新最后一根k线
        eventBus.addListener('priceUpdate', this.handlePriceUpdateEvent.bind(this))
        eventBus.addListener('marksUpdate2', this.handleMarksUpdateEvent.bind(this))
        // 当有最新k线的时候, 直接覆盖掉最新的几个k线
        // 订阅k线
        eventBus.emit('subscribeKline', {
            pair: this.data.pair,
            currency: this.data.currency,
            window: this.klineParams.interval,
        })
        eventBus.addListener('klineUpdate', this.handleKlineUpdateEvent.bind(this))
        eventBus.addListener('pageShow', this.handlePageShow.bind(this))
    }
    removeListeners() {
        // 取消订阅
        eventBus.removeListener('priceUpdate', this.handlePriceUpdateEvent.bind(this))
        eventBus.removeListener('klineUpdate', this.handleKlineUpdateEvent.bind(this))
        eventBus.removeListener('marksUpdate2', this.handleMarksUpdateEvent.bind(this))
        eventBus.removeListener('pageShow', this.handlePageShow.bind(this))
    }


    handleMarksUpdateEvent(d) {
        const sk = this.getStoreKey()
        const { key, info } = d
        if (sk === key && info) { // k线能对上
            const list = info.map((item, ind) => {
                return {
                    id: ind + 1,
                    time: item.time,
                    color: item.type === 1 ? 'yellow' : 'red',
                    text: `${item.type === 1 ? i18n.t('买入') : i18n.t('卖出')} \n ${i18n.t('单价')}: ${item.price_symbol}${convertToMaxUnit(item.price)} ${i18n.t('交易量')}: ${convertToMaxUnit(item.amount)} \n ${i18n.t('交易额')}: ${item.price_symbol}${convertToMaxUnit(item.volume)}`,
                    label: item.type === 1 ? 'Buy' : 'Sell',
                    labelFontColor: item.type === 1 ? 'white' : 'white',
                    minSize: 20,
                }
            })
            // 清空mark
            this.onDataCallback && this.onDataCallback(list)
        }

    }

    handleKlineUpdateEvent(d) {
        // 更新最近的3条k线
        // console.log('klineUpdate', d, this.data, this.klineParams)
        if (d.currency === this.data.currency.toLowerCase() && d.pair === this.data.pair.toLowerCase() && d.window === this.klineParams.interval) {
            setTimeout(() => {
                this.getTokenKline({
                    ...this.klineParams,
                    to: Date.parse(new Date()) / 1000,
                    count: 3
                })
                // console.log('klineUpdate已更新', d, this.data, this.klineParams)
            }, 500);


        }

    }
    handlePriceUpdateEvent(d) {
        // console.log('获取到价格更新222', d, this.data, this.bars)
        if (d.token !== this.data.currency.toLowerCase() && d.pair === this.data.pair.toLowerCase()) {
            if (this.bars && this.bars.length > 0) {
                const b = this.bars[this.bars.length - 1]
                b.close = Number(d.price)
                if (b.close < b.low) {
                    b.low = b.close
                }
                if (b.close > b.high) {
                    b.high = b.close
                }
                this.onRealtimeCallback({
                    time: Math.round(d.time),
                    ...b
                });
                // console.log('获取到价格更新', this.bars, d)
            } else {
                if (this.bars && this.bars.length === 0) {
                    this.onRealtimeCallback({
                        time: Math.round(d.time),
                        close: Number(d.price),
                        open: Number(d.price),
                        high: Number(d.price),
                        low: Number(d.price),
                        volume: 0,
                        t: d.time
                    });
                }
            }

        }
    }

    handlePageShow(d) {
        // 页面切换到了前台, 重新更新k线
        // this.removeListeners()
        // this.addListeners()
        setTimeout(() => {
            if (!document.hidden) {
                this.klineParams = {
                    ...this.klineParams,
                    to: Date.parse(new Date()) / 1000,
                }
                this.getTokenKline(this.klineParams)
                eventBus.emit('subscribeKline', {
                    pair: this.data.pair,
                    currency: this.data.currency,
                    window: this.klineParams.interval,
                })
            }
        }, 0);
    }
    unsubscribeBars = (subscriberUID) => {
        // console.log(['调用[unsubscribeBars]方法'], subscriberUID)
        this.removeListeners()
    }
}