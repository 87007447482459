import React, { useEffect, useState } from 'react';
import Header2 from '../../layout/header2';
import Sidebar from '../../layout/sidebar';
import Footer2 from '../../layout/footer2';
import { getIncreaseRankList } from '../../../api/token';
import { convertToMaxUnit, getChainNameWithChainId } from '../../../utils/tools';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Logo from '../../components/logo';
import RankHeaderFilterBox from './box';
import { useTranslation } from 'react-i18next';




function GainPage() {
    const { t } = useTranslation()
    const [filterParams, setFilterParams] = useState({
        chain_id: '0',
        window: '5m',
        desc: '0',
        buyTax: {
            min: 0,
            max: 0
        },
        sellTax: {
            min: 0,
            max: 0
        },
        cap: {
            min: 0,
            max: 0
        },
        rate: {
            min: 0,
            max: 0
        },
        volume: { // 交易额范围
            min: 0,
            max: 0
        },
        txCount: {
            min: 0, // 交易笔数
            max: 0
        },
        tokenCheckRes: {
            normal: false, // 交易正常
            noPause: false, // 不存在暂停交易开关
            canSell: false, // 代币可以全部卖出
            noLimit: false, // 不限购/限卖
            noChangeLimit: false, // 不可修改限购额度
            noChangeTax: false, // 不可修改交易税率
            noChangeTaxForAddr: false, // 不可针对特定地址修改税率
            noCoolDown: false, // 无交易冷却机制
            giveUpOwner: false, // 放弃所有权
            noMint: false, // 不可增发
            noTakeBack: false, // 不存在取回所有权开关
            noChangeBalance: false, // 不可篡改余额
            noHideOwner: false, // 无隐藏Owner
            noRisk: false, // 无外部合约调用风险
            lockOver70: false, // 已锁池超过70%
        }
    })
    const [filterLoading, setFilterLoading] = useState(false) // 是否正在筛选中
    const [list, setList] = useState([])

    const getList = async (page) => {
        setFilterLoading(true)

        // 走接口
        const { data } = await getIncreaseRankList({
            chain_id: filterParams.chain_id + '',
            page: page + '',
            size: 20 + '',
            window: filterParams.window,
            cap_min: filterParams.cap.min + '',
            cap_max: filterParams.cap.max + '',
            volume_min: filterParams.volume.min + '',
            volume_max: filterParams.volume.max + '',
            tx_count_min: filterParams.txCount.min + '',
            tx_count_max: filterParams.txCount.max + '',
            rate_min: filterParams.rate.min + '',
            rate_max: filterParams.rate.max + '',
            desc: filterParams.desc + ''
        })

        if (data.code === 200) {
            setList(data.data)
        }
        setFilterLoading(false)
    }

    useEffect(() => {
        getList(1)
    }, [filterParams])



    const history = useHistory()
    return <>
        <Header2 />
        <Sidebar />

        <div className='content-body'>
            <RankHeaderFilterBox type={'gain'} onChange={(p) => {
                setFilterParams(p)
            }} />
            <div className='row'>
                <div className="col-12">
                    <div className='card'>
                        <div className='card-body'>
                            <div style={{ height: '80vh', overflowY: 'scroll', overflowX: 'scroll' }} className=' scroll-hidden'>
                                <table
                                    className="table table-striped"
                                    style={{ fontSize: '12px' }}
                                >
                                    <thead>
                                        <tr >
                                            <th>{t('代币')}</th>
                                            <th>{t('现价')}</th>
                                            <th>{t('24h涨跌幅')}</th>
                                            <th>{t('流动市值')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list && list.map((item, ind) => {
                                            return <tr key={'title-' + ind} onClick={() => {
                                                const chainName = getChainNameWithChainId(item.chain_id)
                                                history.push('/token/' + chainName + '/' + item.address)
                                            }}>
                                                <td className='index-table-td' >
                                                    <Logo size="1.9" chain={getChainNameWithChainId(item.chain_id)} contract={item.address} logo={item.logo} />
                                                    <span style={{ marginLeft: '10px' }}>
                                                        {item.symbol}
                                                    </span>
                                                </td>
                                                <td className='text-light' style={{ width: '25%' }}>
                                                    ${convertToMaxUnit(item.price)}
                                                </td>
                                                <td className={item.increase >= 0 ? 'text-success' : 'text-danger'} style={{ width: '25%', wordWrap: 'break-word', whiteSpace: 'nowrap' }}>
                                                    {convertToMaxUnit(((item.increase || 0) * 100).toFixed(2)) + '%'}
                                                </td>
                                                <td>
                                                    ${convertToMaxUnit(item.cap)}
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div>
            {/* 这里应该有一个预览的k线 */}
        </div>

        <Footer2 />
    </>
}

export default GainPage;