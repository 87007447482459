import React from 'react';
import './css/style.css';
import Index from './jsx';
import { Provider } from 'react-redux'
import store from './store'
import { Web3ReactProvider } from '@web3-react/core'
// import Web3 from 'web3'
import { ethers } from 'ethers'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WebSocketProvider } from './jsx/components/WebsocketProvider/WebSocketContext';
import WebSocketComponent from './jsx/components/WebsocketProvider';

import { I18nextProvider } from 'react-i18next';
import i18n from './utils/i18n'

function getLibrary(provider) {
  // return new Web3(provider)
  return new ethers.providers.Web3Provider(provider)
}


function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <WebSocketProvider>
          <WebSocketComponent />
          <Provider store={store}>
            <div className="App">
              <Index />
              <ToastContainer theme="dark" />

            </div>
          </Provider>
        </WebSocketProvider>

      </Web3ReactProvider>
    </I18nextProvider>

  );
}

export default App;
