import Network from "./network";

export const StoreTokenMonitorTasks = (data) => {
    // 保存代币监控任务
    return Network.request({
        url: '/account/monitor/task/add',
        method: 'post',
        data
    })
}

export const updateTaskStatus = (data) => {
    // 更新任务状态
    return Network.request({
        url: '/account/monitor/task/update',
        method: 'post',
        data
    })
}

export const deleteTask = (data) => {
    // 更新任务状态
    return Network.request({
        url: '/account/monitor/task/delete',
        method: 'post',
        data
    })
}