import { Contract, ethers } from "ethers";
import { NETWORK_LIST } from "./networks";

export const Multicall3Abi = [
    "function aggregate(tuple(address target, bytes callData)[] calls) payable returns (uint256 blockNumber, bytes[] returnData)",
    "function aggregate3(tuple(address target, bool allowFailure, bytes callData)[] calls) payable returns (tuple(bool success, bytes returnData)[] returnData)",
    "function aggregate3Value(tuple(address target, bool allowFailure, uint256 value, bytes callData)[] calls) payable returns (tuple(bool success, bytes returnData)[] returnData)",
    "function blockAndAggregate(tuple(address target, bytes callData)[] calls) payable returns (uint256 blockNumber, bytes32 blockHash, tuple(bool success, bytes returnData)[] returnData)",
    "function getBasefee() view returns (uint256 basefee)",
    "function getBlockHash(uint256 blockNumber) view returns (bytes32 blockHash)",
    "function getBlockNumber() view returns (uint256 blockNumber)",
    "function getChainId() view returns (uint256 chainid)",
    "function getCurrentBlockCoinbase() view returns (address coinbase)",
    "function getCurrentBlockDifficulty() view returns (uint256 difficulty)",
    "function getCurrentBlockGasLimit() view returns (uint256 gaslimit)",
    "function getCurrentBlockTimestamp() view returns (uint256 timestamp)",
    "function getEthBalance(address addr) view returns (uint256 balance)",
    "function getLastBlockHash() view returns (bytes32 blockHash)",
    "function tryAggregate(bool requireSuccess, tuple(address target, bytes callData)[] calls) payable returns (tuple(bool success, bytes returnData)[] returnData)",
    "function tryBlockAndAggregate(bool requireSuccess, tuple(address target, bytes callData)[] calls) payable returns (uint256 blockNumber, bytes32 blockHash, tuple(bool success, bytes returnData)[] returnData)",
];

class MulticallContractFunctionData {
    functionName;
    params;

    contract;
    abi;


    constructor(functionName, params, abi, contract) {
        this.functionName = functionName;
        this.params = params;
        this.abi = abi;
        this.contract = contract
    }

    encode() {
        const iface = new ethers.utils.Interface(this.abi);
        return iface.encodeFunctionData(this.functionName, this.params);
    }
    decode(data) {
        const iface = new ethers.utils.Interface(this.abi);
        return iface.decodeFunctionResult(this.functionName, data);
    }
}

class MulticallContract {
    abi;
    contract;

    constructor(abi, address) {
        this.abi = abi;
        this.contract = address
    }

    encode(functionName, params) {
        return new MulticallContractFunctionData(functionName, params || [], this.abi, this.contract)
    }


}

export const NewMulticall3Contract = (address, abi) => {
    const n = new MulticallContract(abi, address)
    return n
}


export class Multicall3 {
    chainId;
    provider;

    constructor(chainId, provider) {
        this.chainId = chainId;
        this.provider = provider;
    }

    call(list) {
        return NewMulticall3(this.chainId, this.provider, list)
    }

    getEthBalance(addr) {
        // const n = 
        let m = ''
        NETWORK_LIST.forEach(item => {
            if (item.chainId === this.chainId) {
                m = item.multicall3
            }
        })
        const n = NewMulticall3Contract(m, Multicall3Abi)
        return n.encode('getEthBalance', [addr])
    }
}




// 传入的list每一个都是MulticallContractFunctionData的对象
export const NewMulticall3 = async (chainId, provider, list) => {

    let multicallContract = ''

    NETWORK_LIST.forEach(item => {
        if (item.chainId === chainId) {
            multicallContract = item.multicall3
        }
    })



    const _c = new Contract(multicallContract, Multicall3Abi, provider)

    const _list = list.map((item) => {
        // console.log(item, '.....xxxx')
        return {
            target: item.contract,
            allowFailure: true,
            callData: item.encode()
        }
    })
    // console.log('list2222', _list, _c.callStatic)
    try {
        // const functionSignature = _c.interface.getSighash("aggregate3");
        // console.log('签名为', functionSignature)
        // const encodedData = _c.interface.encodeFunctionData(functionSignature, [_list]);
        // console.log('参数为', encodedData, _c.callStatic)
        // const res = await _c.callStatic['aggregate3'](encodedData);

        const res = await _c.callStatic.aggregate3(_list)

        // 解析结果
        const resList = res.map((item, ind) => {
            if (item.success) {
                return {
                    success: item.success,
                    result: list[ind].decode(item.returnData)
                }
            } else {
                return {
                    success: item.success,
                    result: null
                }
            }

        })

        return resList
    } catch (e) {
        console.log(e)
    }


}